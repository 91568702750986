/**
 * ========================================================
 * Description:   Grid component
 * Creation Date: 12/15/15
 * Author: vasil
 * ========================================================
 **/
var DataListComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
            if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + msg + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },
    propTypes: {
        app_object: React.PropTypes.object,
        app_object_code: React.PropTypes.string,
        app_object_conditions: React.PropTypes.object,
        app_object_handler: React.PropTypes.func,
        delete_nested_entity: React.PropTypes.func,
        disable_create: React.PropTypes.bool,
        disallow_grid_filters: React.PropTypes.bool,
        disallow_grid_workflows: React.PropTypes.bool,
        drilldown_conditions_holder: React.PropTypes.object,
        entity_attributes: React.PropTypes.object,
        entity_instances: React.PropTypes.array,
        get_custom_grid_properties: React.PropTypes.func.isRequired,
        grid_init_callback: React.PropTypes.func,
        grid_is_on_edit_form: React.PropTypes.bool,
        handle_add: React.PropTypes.func,
        handle_delete: React.PropTypes.func,
        handle_executing: React.PropTypes.func,
        is_mobile: React.PropTypes.bool,
        is_nested: React.PropTypes.bool,
        is_open: React.PropTypes.bool,
        is_ref_lookup: React.PropTypes.bool,
        is_xenforma_note: React.PropTypes.bool,
        logged_in_false: React.PropTypes.func,
        navigation_handler: React.PropTypes.func,
        nested_entity_handler: React.PropTypes.func,
        nested_list_entities: React.PropTypes.object,
        nested_reference_entity_data: React.PropTypes.object,
        new_entity_json: React.PropTypes.object,
        on_change: React.PropTypes.func,
        parent_entity_array: React.PropTypes.array,
        parent_entity_field_attribute: React.PropTypes.object,
        parent_react_entity: React.PropTypes.object,
        read_only: React.PropTypes.bool,
        record_count: React.PropTypes.any,
        set_open_state: React.PropTypes.func,
        skip_location_push: React.PropTypes.bool,
        update_fields_for_entity_instances: React.PropTypes.array
    },
    getInitialState: function () {
        var context = this;
        context.debugLog('getInitialState', true);

        if ( !context.props.grid_is_on_edit_form ) {
            localStorage.removeItem('selectedViewsetting');
            localStorage.removeItem('storage');
        }
        /*
         if (!this._setState){
         this._setState = this.setState;
         this.setState = function(){
         try{throw new Error('dg_setState')}catch(e){console.log(e)}
         return this._setState.apply(this, arguments);
         };
         }
         if(!this._forceUpdate){
         this._forceUpdate = this.forceUpdate;
         this.forceUpdate = function(){
         try{throw new Error('dg_forceUpdate')}catch(e){console.log(e)}
         return this._forceUpdate.apply(this, arguments);
         };
         }
         */
        var state = context.initialize_data_list();
        if ( !state ) { //placeholder while waiting for response.
            state = { shouldInitGrid: false };
        }

        var selectedViewsetting = localStorage.getItem('selectedViewsetting');
        if ( !(state.app_object &&
            state.app_object.custom_properties.view_modes &&
            state.app_object.custom_properties.view_modes.indexOf(selectedViewsetting) != -1) ) {
            selectedViewsetting = null;
        }

        switch ( selectedViewsetting ) {
            case null:
                state.selectedViewsetting =
                    state.app_object && state.app_object.custom_properties.view_modes
                        ? state.app_object.custom_properties.view_modes[0]
                        : 'data_list'
                ;
                localStorage.setItem('selectedViewsetting', state.selectedViewsetting);
                break;
            default:
                state.selectedViewsetting = selectedViewsetting;
        }

        state.is_open = context.props.is_open;
        state.grid_id = create_uid();

        if ( context.props.record_count ) {
            state.get_count = true;
        }

        context.debugLog('getInitialState', false);
        return state;
    },
    componentDidMount: function () {
        var context = this;
        context.debugLog('componentDidMount', true);

        context.display_loader();

        window.addEventListener("resize", context.updateDimensions);
        //discard changes confirmation dialog
        if ( !context.props.grid_is_on_edit_form ) {
            window.addEventListener("beforeunload", context.remove_session_data);
        }

        context.debugLog('componentDidMount', false);
    },
    componentDidUpdate: function () {
        var context = this;
        context.debugLog('componentDidUpdate', true);

        if ( context.refs.refDataGridViewComponent ) {
            if ( context.state.shouldInitGrid && !context.props.is_nested && context.state.app_object ) {
                context.refs.refDataGridViewComponent.init_grid_columns();
                context.refs.refDataGridViewComponent.init_grid();
            }
            context.refs.refDataGridViewComponent.updateDimensions();
        }

        context.debugLog('componentDidUpdate', false);
    },
    componentWillReceiveProps: function (nextProps) {
        if(this.props.is_nested && nextProps.entity_instances && (nextProps.entity_instances != this.state.entity_instances)) {
            this.state.entity_instances = nextProps.entity_instances;
        }
    },
    componentWillUnmount: function () {
        var context = this;
        context.debugLog('componentWillUnmount', true);

        if ( !context.props.grid_is_on_edit_form ) {
            context.store_personalized_layout();
            localStorage.removeItem('selectedViewsetting');
            localStorage.removeItem('storage');
            context.remove_session_data();
        }
        window.removeEventListener("resize", context.updateDimensions);
        window.removeEventListener("beforeunload", context.remove_session_data);

        context.debugLog('componentWillUnmount', false);
    },


    /*************
     ** METHODS **
     ************/
    remove_session_data: function() {
        var context = this;
        context.debugLog('remove_session_data', true);

        if ( !context.props.grid_is_on_edit_form && !context.state.skip_clearing_session) {
            if ( context.state.app_object && context.state.app_object.code ) {
                sessionStorage.removeItem(context.state.app_object.code + "__pivot_grid");
            }
        }

        context.debugLog('remove_session_data', false);
    },

    /**
     * Initializes the data list, fetching the data if needed and returning the state for the component
     * @returns {{}}
     */
    initialize_data_list: function () {
        var context = this;
        context.debugLog('initialize_data_list', true);

        if ( context.props.is_nested ) {
            var state_object = {};
            state_object.app_object = context.props.app_object;
            state_object.entity_attributes = context.props.entity_attributes;
            state_object.entity_attributes.original_attributes = context.props.entity_attributes.attributes; //in case of personalizations
            state_object.entity_instances = context.props.entity_instances;
            state_object.shouldInitGrid = true;

            context.debugLog('initialize_data_list', false);
            return state_object;
        }

        var isMobile = context.props.is_mobile;
        var cachedAppObject = localStorage.getItem(context.props.app_object_code);

        var full_conditions = context.get_full_conditions();
        if ( full_conditions
            && Object.keys(full_conditions).length !== 0
            || cachedAppObject === null
            || cachedAppObject == ""
            || cachedAppObject === undefined ) {
            var request = {};
            request.entity = "app_object";
            request.method = "get_by_code";
            request.data = {
                app_object_code: context.props.app_object_code,
                conditions: full_conditions,
                advanced_search: true,
                force_data_list_nested_captions: true,
                attach_personalized_layout_info: true,
                is_mobile: isMobile
            };

            do_authenticated_http_call({
                method: "POST",
                url: "/api/entity/invoke_method",
                contentType: "application/json",
                dataType: 'json',
                data: JSON.stringify(request),
                success: function (data) {
                    context.debugLog('initialize_data_list success', true);

                    var localStoragePersonalizedLayoutCode = context.getLocalStoragePersonalizedLayoutCode(isMobile);
                    if ( data.data && data.data.personalized_layout ) {
                        localStorage.setItem(localStoragePersonalizedLayoutCode, JSON.stringify(data.data.personalized_layout));
                        delete data.data.personalized_layout;
                    }
                    else {
                        localStorage.removeItem(localStoragePersonalizedLayoutCode);
                    }
                    localStorage.setItem(context.props.app_object_code, JSON.stringify(data.data));
                    var custom_properties = data.data.app_object.custom_properties ? data.data.app_object.custom_properties : {};
                    context.state.app_object = data.data.app_object;
                    context.state.entity_attributes = data.data.entity_attributes;
                    context.state.entity_attributes.original_attributes = data.data.entity_attributes.attributes; //in case of personalizations
                    context.state.reports = custom_properties.reports;
                    context.state.entity_access = data.data.entity_access;
                    context.state.entity_instances = [];
                    context.apply_personalized_columns_from_local_storage(isMobile);
                    context.state.shouldInitGrid = true;
                    context.setState(context.state);

                    if ( !context.props.is_ref_lookup && !context.props.skip_location_push ) {
                        push_href_location(data.data.app_object.name + " - " + (R.client_application_title || "Xenforma"), "/data_list?code=" + data.data.app_object.code);
                    }

                    context.debugLog('initialize_data_list success', false);
                },
                error: function (error) {
                    context.debugLog('initialize_data_list error', true);

                    server_error_action(error);

                    if (error.responseJSON) {
                        /* Validate if session exist */
                        context.setState({ error: error.responseJSON.message, is_mobile: isMobile });
                    }

                    context.debugLog('initialize_data_list error', false);
                }
            });
        }
        else {
            var data = localStorage.getItem(context.props.app_object_code);
            data = JSON.parse(data);
            var context_state = {};
            var custom_properties = data.app_object.custom_properties ? data.app_object.custom_properties : {};
            context_state.app_object = data.app_object;
            context_state.entity_attributes = data.entity_attributes;
            context_state.entity_attributes.original_attributes = data.entity_attributes.attributes; //in case of personalizations
            context_state.reports = custom_properties.reports;
            context_state.entity_access = data.entity_access;
            context_state.entity_instances = [];
            context_state.shouldInitGrid = true;
            context_state.is_mobile = isMobile;

            var localStoragePersonalizedLayoutCode = context.getLocalStoragePersonalizedLayoutCode(isMobile);
            var personalized_layout = localStorage.getItem(localStoragePersonalizedLayoutCode);
            if ( personalized_layout == null || personalized_layout == "" ) {
                var request = {};
                request.entity = "user";
                request.method = "get_layout";
                request.data = {app_object_code: this.getDbStoragePersonalizedLayoutCode(isMobile)};
                do_authenticated_http_call({
                    method: "POST",
                    url: "/api/entity/invoke_method",
                    contentType: "application/json",
                    dataType: 'json',
                    data: JSON.stringify(request),
                    success: function (data) {
                        var localStoragePersonalizedLayoutCode = context.getLocalStoragePersonalizedLayoutCode(isMobile);
                        localStorage.setItem(localStoragePersonalizedLayoutCode, JSON.stringify(data.data));
                        context.apply_personalized_columns_from_local_storage(isMobile);
                        context.setState(context_state);

                        if(!context.props.is_ref_lookup && !context.props.skip_location_push) {
                            push_href_location(context_state.app_object.name + " - " + (R.client_application_title || "Xenforma"), "/data_list?code=" + context_state.app_object.code);
                        }
                    },
                    error: function (error) {
                        context.debugLog('initialize_data_list error', true);
                        server_error_action(error);
                        if (error.responseJSON) {
                            /* Validate if session exist */
                            context.setState({error: error.responseJSON.message, is_mobile: isMobile});
                        }
                        context.debugLog('initialize_data_list error', false);
                    }
                });
            }
            else {
                context.state = context_state;
                this.apply_personalized_columns_from_local_storage(isMobile);

                this.debugLog('initialize_data_list', false);
                return (context_state);
            }
        }

        this.debugLog('initialize_data_list', false);
    },

    /**
     * Returns the code for the local storage personalized layout
     * @param is_grid_switched_to_mobile_view_yet
     * @returns {*|string}
     */
    getLocalStoragePersonalizedLayoutCode: function (is_grid_switched_to_mobile_view_yet) {
        var context = this;

        return DataListUtils.getLocalStoragePersonalizedLayoutCode(
            is_grid_switched_to_mobile_view_yet,
            context.props.app_object_code,
            context.state ? context.state.columns_hidden_for_mobile_view : undefined,
            context.props.app_object_conditions
        );
    },
    /**
     * Returns the code for the database personalized layout
     * @param is_grid_switched_to_mobile_view_yet
     * @returns {*|string}
     */
    getDbStoragePersonalizedLayoutCode: function(is_grid_switched_to_mobile_view_yet) {
        var context = this;

        return DataListUtils.getDbStoragePersonalizedLayoutCode(
            is_grid_switched_to_mobile_view_yet,
            context.props.app_object_code,
            context.state ? context.state.columns_hidden_for_mobile_view : undefined,
            context.props.app_object_conditions
        )
    },

    /**
     * Loads the columns personalization stored in the local storage
     * @param isMobile
     */
    apply_personalized_columns_from_local_storage: function(isMobile) {
        var context = this;
        context.debugLog('apply_personalized_columns_from_local_storage', true);

        isMobile = isMobile || context.state.columns_hidden_for_mobile_view;
        context.state.columns_hidden_for_mobile_view = false;

        var localStoragePersonalizedLayoutCode = context.getLocalStoragePersonalizedLayoutCode(isMobile);
        var personalized_layout = localStorage.getItem(localStoragePersonalizedLayoutCode);
        if ( !personalized_layout || (personalized_layout == "") || (personalized_layout == "{}") ) {
            if ( context.state.entity_attributes && context.state.entity_attributes.original_attributes ) {
                context.state.entity_attributes.attributes = context.state.entity_attributes.original_attributes;
            }
            context.state.mobile_columns_are_personalized = false;
            context.debugLog('apply_personalized_columns_from_local_storage', false);
            return;
        }
        try {
            personalized_layout = JSON.parse(personalized_layout);
            var personalized_columns = personalized_layout.columns;
            var attribute_arr;
            if ( context.state.entity_attributes ) {
                if ( context.state.entity_attributes.original_attributes && context.state.entity_attributes.original_attributes.length ) {
                    attribute_arr = context.state.entity_attributes.original_attributes;
                }
                else if ( context.state.entity_attributes.attributes && context.state.entity_attributes.attributes.length ) {
                    attribute_arr = context.state.entity_attributes.attributes;
                }
            }
            if ( personalized_columns && Object.keys(personalized_columns).length && attribute_arr && attribute_arr.length ) {
                var new_entity_attributes = [];
                for ( var i = 0; i < attribute_arr.length; i++ ) {
                    var attribute = attribute_arr[i];
                    var override_attribute = personalized_columns[attribute.field_path];
                    if ( override_attribute ) {
                        attribute = $.extend({}, attribute); //make a shallow copy
                        if ( override_attribute.priority != null ) {
                            attribute.priority = override_attribute.priority;
                        }
                        if ( override_attribute.list_visible != null ) {
                            attribute.list_visible = override_attribute.list_visible;
                        }
                    }
                    new_entity_attributes.push(attribute);
                }

                sort_by_field_name(new_entity_attributes, "priority", {new_array: false, ascending: true});
                context.state.entity_attributes.original_attributes = context.state.entity_attributes.original_attributes || context.state.entity_attributes.attributes;
                context.state.entity_attributes.attributes = new_entity_attributes;
                context.state.mobile_columns_are_personalized = true;
            }
            else {
                context.state.mobile_columns_are_personalized = false;
            }
        }
        catch(ex) {
            context.state.mobile_columns_are_personalized = false;
        }

        context.debugLog('apply_personalized_columns_from_local_storage', false);
    },
    /**
     * Stores the personalized layout to the database and in the local storage
     */
    store_personalized_layout: function() {
        var context = this;
        context.debugLog('store_personalized_layout', true);

        var gridInstance = this.get_grid_object();
        if ( gridInstance && gridInstance.state && typeof gridInstance.state === "function" ) {
            var localStoragePersonalizedLayoutCode = context.getLocalStoragePersonalizedLayoutCode();
            var personalized_state = gridInstance.state();
            var personalized_columns;
            if ( personalized_state && personalized_state.columns && personalized_state.columns.length ) {
                personalized_columns = personalized_state.columns;
            }
            else {
                localStorage.removeItem(localStoragePersonalizedLayoutCode);
                this.debugLog('store_personalized_layout', false);
                return;
            }

            personalized_columns = personalized_columns.map(function (a) {
                var columnShouldBeDisplayed = context.state.entity_attributes.field_paths[a.dataField] && context.state.entity_attributes.field_paths[a.dataField].list_visible;
                var columnIsInColumnChooser = gridInstance.columnOption(a.dataField, 'showInColumnChooser');
                // We check if the column should be visible from the grid aspect
                // Then if not, we perform the checking on the database data only if this column doesn't figure in the column chooser
                var isColumnVisible = a.visible || (columnShouldBeDisplayed && !columnIsInColumnChooser);
                return {
                    field_path: a.dataField,
                    list_visible: isColumnVisible,
                    priority: a.visibleIndex
                };
            });
            personalized_columns = convert_to_dictionary_by_field_names(personalized_columns, {key: "field_path"});

            localStorage.setItem(localStoragePersonalizedLayoutCode, JSON.stringify({ columns: personalized_columns }));

            var success, error, complete;
            success = error = complete = function () {};
            invoke_method("user", "update_layout", {
                app_object_code: this.getDbStoragePersonalizedLayoutCode(),
                columns: personalized_columns
            }, success, error, complete);
        }

        context.debugLog('store_personalized_layout', false);
    },

    /**
     * Displays the loader
     */
    display_loader: function(){
        var context = this;
        context.debugLog('display_loader', true);

        if ( !context.props.grid_is_on_edit_form ) {
            context.props.handle_executing(true);
        }

        context.debugLog('display_loader', false);
    },

    /**
     * Redirects to the right page/component
     * @param entity_instance
     * @param force_refresh
     */
    navigation_handler: function(entity_instance, force_refresh){
        var context = this;
        context.debugLog('navigation_handler', true);

        var gridInstance = context.get_grid_object();

        if ( _.isString(entity_instance) ) {
            if ( entity_instance == "workflow_action" ) {
                if ( !(context.props.app_object_conditions
                    && context.props.app_object_conditions.workflow_status
                    && context.state.app_object
                    && context.state.app_object.workflow_states)
                    && context.state.editForm
                    && context.state.editForm.data_row
                    && context.state.selectedViewsetting == 'data_list' ) {
                    if ( gridInstance ) {
                        gridInstance.cancelEditData();
                    }
                }
            }

            force_refresh = force_refresh || (entity_instance == "workflow_action" || entity_instance == "home");

            if ( force_refresh ) {
                switch ( context.state.selectedViewsetting ) {
                    case 'data_list':
                        if ( context.props.is_ref_lookup && context.state.shouldInitGrid && context.refs.refDataGridViewComponent ) {
                            context.refs.refDataGridViewComponent.init_grid_columns();
                            context.refs.refDataGridViewComponent.init_grid();
                        }
                        else if ( gridInstance && gridInstance.refresh ) {
                            gridInstance.refresh();
                        }
                        break;
                    case 'tree_view':
                        if ( context.refs.refTreeViewComponent ) {
                            context.refs.refTreeViewComponent.get_tree_view_data();
                        }
                }
            }
        }
        else {
            if ( context.state.editForm.data_row && context.refs.refDataGridViewComponent ) {
                context.state.editForm.data_row = context.refs.refDataGridViewComponent.get_edit_form_data_row(context.state.editForm.data_row, entity_instance);
            }
            if ( gridInstance ) {
                if ( context.state.editForm.data_row ) {
                    gridInstance.cancelEditData(); // to refresh grid
                }
                else if (gridInstance.refresh) {
                    gridInstance.refresh();
                }
            }
        }

        var newState = {};
        if ( context.state.duplicate_id ) {
            newState.duplicate_id = undefined;
        }
        newState.editForm = undefined;

        context.setState(newState);

        context.debugLog('navigation_handler', false);
    },

    /**
     * Returns the dev express view object instance
     * @param e
     * @returns {null}
     */
    get_grid_object: function(e){
        var context = this;
        context.debugLog('get_grid_object', true);

        var grid;
        try {
            switch ( context.state.selectedViewsetting ) {
                case "data_list":
                    grid = context.refs.refDataGridViewComponent.getGridViewInstance();
                    break;
                case "tree_view":
                    grid = context.refs.refTreeViewComponent.getTreeViewInstance();
                    break;
                case "calendar_view":
                    grid = context.refs.refCalendarViewComponent.getCalendarViewInstance();
                    break;
                case "map_view":
                    grid = context.refs.refMapViewComponent.getMapViewInstance();
                    break;
                case "pivot_view":
                    grid = context.refs.refPivotViewComponent.getPivotViewInstance();
                    break;
                default:
                    grid = context.refs.refDataGridViewComponent.getGridViewInstance(); //Defaults to data_list
            }
        } catch(err) {}

        if ( !grid && e && e.component ) {
            grid = e.component;
        }

        context.debugLog('get_grid_object', false);
        return grid ? grid : null;
    },

    /**
     * Updates the dimensions of the data list
     * @param force_repaint
     */
    updateDimensions: function (force_repaint) {
        var context = this;
        context.debugLog('updateDimensions', true);

        var grid = this.get_grid_object(),
            isMobile = context.props.is_mobile,
            change,
            isChangingOrientation;

        if ( !this.window_width ) {
            this.window_width = 1;
        }
        if ( !this.window_height ) {
            this.window_height = 1;
        }

        change = window.innerWidth / this.window_width - 1;
        isChangingOrientation = (change > 0 ? change : (change*-1)) > 0.01;
        if ( isMobile && isChangingOrientation ) {
            this.window_height = window.innerHeight;
            this.window_width = window.innerWidth;
        }

        if ( context.refs.refDataGridViewComponent ) {
            var changeDataGrid = context.refs.refDataGridViewComponent.updateComponentDimensions(isMobile, context.state.mobile_columns_are_personalized);
            if ( changeDataGrid ) {
                change = changeDataGrid;
            }
        }

        if ( grid && grid.repaint ) {
            if ( force_repaint || (change != 0) || isChangingOrientation ) {
                grid.repaint();
            }
        }

        $("#datalist_cmp").css('visibility', 'visible');

        context.props.handle_executing(false);

        context.debugLog('updateDimensions', false);
    },

    /**
     * Returns the sorting configuration
     * @param grid_sort_obj
     * @returns {*}
     */
    apply_app_object_sorting: function (grid_sort_obj) {
        var context = this;
        context.debugLog('apply_app_object_sorting', true);

        if ( grid_sort_obj == null ) { //workflow_level sort applies:
            var wf_state = context.props.app_object_conditions
                && context.props.app_object_conditions.workflow_status
                && context.state.app_object
                && context.state.app_object.workflow_states
                ? context.state.app_object.workflow_states[context.props.app_object_conditions.workflow_status]
                : undefined;

            if ( wf_state ) {
                //use workflow sorting first
                if ( wf_state.view_options && wf_state.view_options.sort ) {
                    grid_sort_obj = wf_state.view_options.sort;
                }
            }
        }

        if ( grid_sort_obj == null ) { //app_object level sort applies:
            if ( context.state.app_object && context.state.app_object.custom_properties && context.state.app_object.custom_properties.sort ) {
                grid_sort_obj = context.state.app_object.custom_properties.sort;
            }
        }

        context.debugLog('apply_app_object_sorting', false);
        return grid_sort_obj;
    },

    /**
     * Displays the appropriate confirm box
     * @param title
     * @param message
     * @param callback
     */
    confirm: function(title, message, callback) {
        var context = this;
        if ( context.props.grid_is_on_edit_form ) {
            context.props.parent_react_entity.confirm(title, message, callback);
        } else {
            display_yes_no_dialogue(title, message, callback);
        }
    },

    /**
     * Function called when opening/closing the help to get the description
     * @param idGrid
     * @param e
     */
    openDescriptionGrid: function(idGrid, e) {
        var context = this;
        context.debugLog('openDescriptionGrid', true);

        $(".description_grid[data-unique-id='"+idGrid+"' ]").slideToggle(function() {
            if ( !context.props.grid_is_on_edit_form ) {
                context.updateDimensions(true);
            }
        });

        context.debugLog('openDescriptionGrid', false);
    },
    /**
     * Function called on opening/closing of the grid (using the '+' or '-' button)
     * @param idGrid
     * @returns {null}
     */
    openBodyGrid: function (idGrid) {
        var context = this;
        context.debugLog('openBodyGrid', true);

        context.is_grid_toggling = true;
        var gridInstance = this.get_grid_object();
        if ( gridInstance ) {
            gridInstance.option('columnAutoWidth', true);
        }

        $(".widget-body[data-unique-id='" + idGrid + "']").slideToggle("slow", function () {
            context.is_grid_toggling = false;
            var element = $("i[id='_toggle'][data-unique-id='" + idGrid + "']");
            var elementX = element.attr("class");
            if ( elementX && elementX.indexOf("plus") != -1 ) {
                element.removeClass().addClass("fa fa-minus");
                context.props.set_open_state(true);
                context.setState({ is_open: true });
                if ( context.props.is_ref_lookup ) {
                    if ( context.refs.refDataGridViewComponent ) {
                        context.refs.refDataGridViewComponent.init_grid();
                    }
                }
            }
            else {
                element.removeClass().addClass("fa fa-plus");
                context.props.set_open_state(false);
                context.setState({ is_open: false });
                if ( context.props.is_ref_lookup ) {
                    if ( context.refs.refDataGridViewComponent ) {
                        context.refs.refDataGridViewComponent.init_grid();
                    }
                    context.requestAndSetCount();
                }
            }
        });

        var currentText = $.trim($(".btn-default[data-unique-id='"+idGrid+"']").text()),
            newText = (currentText == "Hide" ? "<i class='fa fa-expand'></i>Show" : "<i class='fa fa-compress'></i>Hide");

        $(".btn-default[data-unique-id="+idGrid+"]").html(newText);

        var desc_button = $("i[data-unique-id='"+idGrid+"' ][data-type-element!='headBtn' ]");
        if ( this.state.is_open == desc_button.is(':visible') ) {
            context.is_desc_button_toggling = true;
            desc_button.slideToggle(400, function(){context.is_desc_button_toggling = false});
        }

        context.debugLog('openBodyGrid', false);
        return null;
    },

    /**
     * Requests the number of entries and sets it in the header
     */
    requestAndSetCount: function(on_init, callback) {
        var context = this;
        context.debugLog('requestAndSetCount', true);

        if ( context.state.get_count || context.props.is_ref_lookup ) {
            var entity_name = context.state.entity_attributes.entity;

            var success_func = function (data) {
                context.debugLog('requestAndSetCount success_func', true);

                context.setState({ record_count: data.count });

                context.debugLog('requestAndSetCount success_func', false);
            };

            var error_func = function (error) {
                context.debugLog('requestAndSetCount error_func', true);
                context.debugLog('requestAndSetCount error_func', false);
            };

            invoke_method(entity_name, "find_count", {conditions: context.get_full_conditions()}, success_func, error_func, callback);
        }

        context.debugLog('requestAndSetCount', false);
    },

    /**
     * Function called when changing of view (data-grid, tree, map, etc.)
     * @param selected
     */
    change_view_state: function(selected){
        var context = this;
        context.debugLog('change_view_state', true);

        if ( selected != context.state.selectedViewsetting ) {
            context.display_loader();

            if ( context.state.selectedViewsetting == "data_list" ) {
                var grid = context.get_grid_object();
                if ( grid ) {
                    context.row_count = grid.totalCount();
                }
            }

            if ( context.state.selectedViewsetting == "pivot_view" ) { //when leaving pivot_view mode:
                var drilldown_conditions_holder = context.props.drilldown_conditions_holder; //delete any drilldown_conditions to return to just using the app_object_conditions
                delete drilldown_conditions_holder.drilldown_conditions;
            }

            context.setState({ selectedViewsetting: selected });
            localStorage.setItem('selectedViewsetting', selected);

            switch ( selected ) {
                case "data_list":
                    var conditions = context.state.export_grid_conditions;
                    context.getInitialState();
                    context.state.export_grid_conditions = conditions;

                    context.display_loader();
                    break;
                case "calendar_view":
                    $(window).trigger('resize');
                    break;
            }
        }
        context.debugLog('change_view_state', false);
    },
    /**
     * Closes the frame
     * @param event
     */
    handle_close: function (event) {
        var context = this;
        context.debugLog('handle_close', true);

        if ( event && event.preventDefault ) {
            event.preventDefault();
        }
        current_navigation_listener = undefined;
        // navigate to home
        context.props.navigation_handler("home");

        context.debugLog('handle_close', false);
    },

    save_scroll_position: function(){
        this.debugLog('save_scroll_position', true);
        this.scrollPosition = $(window).scrollTop();
        this.debugLog('save_scroll_position', false);
    },
    restore_scroll_position: function () {
        this.debugLog('restore_scroll_position', true);
        $(window).scrollTop(this.scrollPosition);
        this.debugLog('restore_scroll_position', false);
    },

    /**
     * Updates the height of the data list
     * @param variable_offset
     * @param return_as_number
     * @returns {*}
     */
    update_height: function(variable_offset, return_as_number) {
        var context = this;
        return DataListUtils.update_height(context.props.is_ref_lookup, context.props.is_mobile, context.props.skip_location_push, context.state.selectedViewsetting, variable_offset, return_as_number);
    },

    /**
     * Closing the error notification
     */
    on_error_close: function() {
        var context = this;
        context.debugLog('on_error_close', true);

        context.setState({ error: undefined });

        context.debugLog('on_error_close', false);
    },

    /**
     * Click performed on a element of a list (such as calendar, map or tree)
     * @param elementId
     * @param app_object
     */
    on_click_data_list_element: function(app_object, elementId) {
        var context = this;
        context.debugLog('on_click_data_list_element', true);

        if ( context.props.is_ref_lookup ) {
            var ref_data = context.props.nested_reference_entity_data;
            context.props.parent_react_entity.setState({
                nested_reference_entity_data: ref_data,
                reference_entity_id: elementId,
                grid_is_on_edit_form: true
            });
        }
        else {
            context.setState({ editForm: app_object });
        }

        context.debugLog('on_click_data_list_element', true);
    },
    /**
     * Click on the add button in the tree view
     * @param e
     */
    on_click_add_tree_view_element: function(e) {
        var context = this,
            app_object = {};
        context.debugLog("on_click_add_tree_view_element", true);

        app_object.code = context.state.app_object.edit_app_object;
        if ( context.props.is_ref_lookup ) {
            var ref_data = context.props.nested_reference_entity_data;
            context.props.parent_react_entity.setState({
                nested_reference_entity_data: ref_data,
                grid_is_on_edit_form: true
            });
        }
        else {
            context.setState({ editForm: app_object });
        }

        context.debugLog("on_click_add_tree_view_element", false);
    },

    /**
     * Returns the drilldown conditions or null if there aren't
     * @returns {*}
     */
    get_drilldown_conditions: function () {
        var context = this;
        return (
            context.props.drilldown_conditions_holder
            && context.props.drilldown_conditions_holder.drilldown_conditions
            && Object.keys(context.props.drilldown_conditions_holder.drilldown_conditions).length
        )
        ? context.props.drilldown_conditions_holder.drilldown_conditions
        : null;
    },
    /**
     * Return the full conditions
     * @returns {*|Object}
     */
    get_full_conditions: function () {
        var context = this;
        //Use drilldown_conditions first (when they exist) then use app_object_conditions
        return context.get_drilldown_conditions() || context.props.app_object_conditions;
    },

    /**
     * Getting the state value for the chosen key
     * @param stateKey
     * @returns {*}
     */
    get_state_value: function(stateKey) {
        var context = this;
        context.debugLog('get_state_value', true);

        context.debugLog('get_state_value', false);
        return context.state[stateKey];
    },
    /**
     * Setting the new values in the component's state without calling setState
     * @param _state
     */
    set_state_value: function(_state) {
        var context = this;
        context.debugLog('set_state_value', true);

        for ( var stateKey in _state ) {
            context.state[stateKey] = _state[stateKey];
        }

        context.debugLog('set_state_value', false);
    },
    /**
     * Setting the new state for the component
     * @param _state
     */
    call_set_state: function(_state) {
        var context = this;
        context.debugLog('call_set_state', true);

        context.setState(_state);

        context.debugLog('call_set_state', false);
    },


    /********************
     ** RENDER METHODS **
     *******************/
    getErrorComponent: function() {
        if ( this.state.error ) {
            return (<div><ErrorNotificationComponent message={this.state.error} on_close={this.on_error_close}/><br /></div>);
        }
        return undefined;
    },
    getExportComponent: function() {
        if ( !this.props.is_nested ) {
            return (<div id={"hidden_export_" + this.state.grid_id}>&nbsp;</div>);
        }
        return undefined;
    },
    getDisplayCloseButton: function() {
        if ( !this.props.is_nested ) {
            return undefined;
        }
        return 'none';
    },
    getBorderColor: function() {
        return ((
            this.props.parent_react_entity && this.props.parent_react_entity.state && this.props.parent_react_entity.state.custom_html_template)
                ? "lightblue"
                : "palegreen"
        );
    },
    getWorkflowStatusHtml: function(borderColor) {
        var context = this;
        if ( context.state.app_object && context.state.app_object.workflow_states ) {
            if ( context.props.app_object_conditions && context.props.app_object_conditions.workflow_status
                && !context.get_drilldown_conditions() ) { //Do not display app_object_conditions workflow_status if drilldown_conditions are in effect
                var workflow_status = context.state.app_object.workflow_states[context.props.app_object_conditions.workflow_status];
                if ( workflow_status && workflow_status.caption && workflow_status.caption.trim() != "" ) {
                    if ( workflow_status && workflow_status.view_options && workflow_status.view_options.class_color ) {
                        borderColor = workflow_status.view_options.class_color;
                    }
                    return (
                        <span>
                            {" "}
                            <span className={"label label-"+borderColor}>
                                {" " + workflow_status.caption.toUpperCase() + " "}
                            </span>
                        </span>
                    );
                }
            }
        }

        return undefined;
    },
    getEditElement: function() {
        return <EditFormComponent ref="editForm"
                                  _id={this.state.editForm._id}
                                  key={this.state.editForm.code + this.state.editForm._id}
                                  app_object_code={this.state.editForm.code}
                                  app_object_handler={this.props.app_object_handler}
                                  data={undefined}
                                  duplicate_id={this.state.duplicate_id}
                                  handle_executing={this.props.handle_executing}
                                  isModal={true}
                                  is_mobile={this.props.is_mobile}
                                  is_nested_grid_child={this.props.grid_is_on_edit_form}
                                  navigation_handler={this.navigation_handler}
                                  set_data_list_error={function(error_obj){if(!error_obj){return;} context.setState({ error: ((error_obj.responseJSON && error_obj.responseJSON.message) ? error_obj.responseJSON.message : (error_obj.message || error_obj))});}}
                                  new_entity_json={this.props.new_entity_json}
                                  return_app_object_code={this.state.app_object.code}
                                  run_on_user_closes_window={this.remove_session_data}
                                  update_avatar={this.get_user_avatar}
                                  update_user_info={this.get_user_info}/>;
    },
    /**
     * Returns the number of entries in the data list
     * @returns {string}
     */
    getNumOfRegisters: function() {
        var numOfRegisters = "";

        if ( this.props.is_nested ) {
            numOfRegisters = " (" + this.state.entity_instances.length + ")";
        }
        else if ( this.state.record_count ) {
            //var elementX = $("i[id='_toggle'][data-unique-id='" + this.state.grid_id + "']").attr("class");
            //if ( elementX && elementX.indexOf("plus") != -1 ) {
                numOfRegisters = " (" + this.state.record_count + ")";
            //}
        }

        return $.trim(numOfRegisters) == '(0)' ? '' : numOfRegisters;
    },
    /**
     * Returns the help button in the header
     * @param colorSkip
     * @param displayDescription
     * @param marginRightskip
     * @returns {XML}
     */
    getDescriptionButton: function(colorSkip, displayDescription, marginRightskip) {
        var description_button_style = {
            cursor: "pointer" ,
            color: colorSkip,
            display: displayDescription,
            fontSize: ((this.props.is_mobile) ? "18px" : "14px"),
            verticalAlign: "inherit",
            marginRight: marginRightskip
        };

        return (
            <i style={description_button_style}
               data-unique-id={this.state.grid_id}
               onClick={ this.openDescriptionGrid.bind(this, this.state.grid_id) }
               className="fa fa-question fa-3 clickable" aria-hidden="true" is-nested="true">
                &nbsp;
            </i>
        );
    },
    /**
     * Returns the buttons that allow to switch of view in the data list header
     * @param displayDescription
     * @param marginRightskip
     * @returns {Array}
     */
    getViewButtons: function(displayDescription, marginRightskip) {
        var context = this;
        var view_buttons = [],
            view_modes = context.state.app_object.custom_properties.view_modes;

        if (!(context.props.grid_is_on_edit_form || context.props.is_nested) && this.state.app_object && view_modes && view_modes.length > 1 ) {
            var iconcolor = "#000";

            for ( var i = 0; i < view_modes.length; i++ ) {
                var iconsfa = "";
                iconcolor = view_modes[i] == context.state.selectedViewsetting ? "rgb(93, 178, 255)" : "#000";

                var new_button_style = {
                    cursor : "pointer" ,
                    color : iconcolor,
                    display : displayDescription,
                    marginRight: marginRightskip,
                    fontSize : ((this.props.is_mobile) ? "18px" : "14px")
                };

                switch ( view_modes[i] ) {
                    case "pivot_view":
                        iconsfa = "fa-sliders";
                        break;
                    case "tree_view":
                        iconsfa = "fa-list-ul";
                        break;
                    case "data_list":
                        iconsfa = "fa-table";
                        if (this.props.is_mobile) {
                            new_button_style.verticalAlign = "middle"; //fa-icon doesn't align well with others
                        }
                        break;
                    case "calendar_view":
                        iconsfa = "fa-calendar-o";
                        break;
                    case "map_view":
                        iconsfa = "fa-map-marker";
                        break;
                    default:
                }

                view_buttons.push(
                    <i style={new_button_style}
                       key={view_modes[i]+"id"}
                       onClick={this.change_view_state.bind(this,view_modes[i])}
                       className={"fa "+ iconsfa} aria-hidden="true" is-nested="true">&nbsp;</i>
                );
            }
        }
        else {
            view_buttons = "";
        }

        return view_buttons;
    },
    /**
     * Returns the close button for the data list
     * @param colorSkip
     * @param displayCloseButton
     * @returns {*}
     */
    getCloseButton: function(colorSkip, displayCloseButton) {
        if ( !this.props.is_ref_lookup && !this.props.skip_location_push ) {
            var close_button_style = {
                cursor : "pointer",
                color : colorSkip,
                display : displayCloseButton,
                fontSize : ((this.props.is_mobile) ? "18px" : "14.7px"),
                verticalAlign: "inherit",
                marginRight: "10px"
            };

            return (
                <i id="close-button"
                   style={close_button_style}
                   onClick={this.handle_close}
                   className="fa fa-times clickable" aria-hidden="true" is-nested="true">
                    &nbsp;
                </i>
            );
        }

        return undefined;
    },
    /**
     * Returns the description provided by the help button
     * @param borderColor
     * @returns {*}
     */
    getDataListDescription: function(borderColor) {
        var context = this;

        if ( (context.state.app_object && context.state.app_object.description)
            || (context.state.entity_attributes && context.state.entity_attributes.description_plural) ) {
            var app_object_description = context.state.app_object && context.state.app_object.description
                ? context.state.app_object.description
                : context.state.entity_attributes.description_plural;

            var stylecontainer = {
                "wordBreak" : "break-word",
                "borderStyle": "solid",
                "borderWidth": "2px",
                "borderRadius": "5px",
                "marginBottom": "10px",
                "position":"relative"
            };
            var styleback = {
                "backgroundColor" : borderColor,
                "width": "100%",
                "opacity":"0.3",
                "height":"100%",
                "position":"absolute"
            };
            var styletext = {
                "padding": "10px",
                "color":"black"
            };

            return (
                <div id="datalist_description"  className={"bordered-bottom bordered-"+borderColor} style={stylecontainer} >
                    <div  style={styleback}>
                    </div>
                    <div style={styletext}>
                        <i style={{"marginRight":"5px","fontSize":"15px"}} className="fa fa-check-square-o">&nbsp;</i>
                        {app_object_description}
                    </div>
                </div>
            );
        }

        return undefined;
    },
    /**
     * Returns the header for the data list (general case is title + buttons to switch between data-grid, calendar, tree, etc.)
     * @param app_object_name
     * @param numOfRegisters
     * @param workflow_status_html
     * @param view_buttons
     * @param descriptionButton
     * @param close_button
     * @param borderColor
     * @returns {XML}
     */
    getWidgetHeader: function(app_object_name, numOfRegisters, workflow_status_html, view_buttons, descriptionButton, close_button, borderColor) {
        var context = this;
        var widget_caption;

        if ( context.props.parent_react_entity ) {
            widget_caption = (
                <span className="widget-caption clickable"
                      onClick={ (!context.props.grid_is_on_edit_form) ? '' : context.openBodyGrid.bind(context, context.state.grid_id) }
                      style={{ cursor: "pointer"}}>
                    <span className="section_title_text">
                        {app_object_name}
                        {numOfRegisters}
                    </span>
                </span>
            );
        }
        else {
            widget_caption = (
                <span className="widget-caption clickable"
                      onClick={ (!context.props.grid_is_on_edit_form) ? '' : context.openBodyGrid.bind(context, context.state.grid_id) }
                      style={{ cursor: "pointer"}}>
                    {app_object_name}
                    {numOfRegisters}
                </span>
            );
        }
        var widget_buttons = (
            <div className={"widget-buttons" + (context.props.is_mobile ? (context.props.grid_is_on_edit_form ? " large_fa_icons_on_edit_form" : " large_fa_icons") : "")}>
                {view_buttons}
                {descriptionButton}
                <i data-type-element="headBtn"
                   id="_toggle"
                   data-count="0"
                   className={(context.state.is_open?"fa fa-minus":"fa fa-plus") + " clickable"}
                   data-unique-id={context.state.grid_id}
                   style={{
                       marginRight : "10px",
                       cursor : "pointer",
                       color  : "rgba(96,96,96,1)",
                       display : (context.props.grid_is_on_edit_form) ? "" : "none"
                   }}
                   onClick={ context.openBodyGrid.bind(context, context.state.grid_id) }
                   onKeyPress={function(gridID, event){if (event.key === "Enter") context.openBodyGrid(gridID);}.bind(context, context.state.grid_id)}
                   tabIndex="0">
                    &nbsp;
                </i>
                {close_button}
            </div>
        );

        return (
            <div className={"widget-header bordered-bottom bordered-" + borderColor + ((context.props.parent_react_entity) ? " compact_header" : "")}>
                {widget_caption}
                {workflow_status_html}
                {widget_buttons}
            </div>
        );
    },
    /**
     * Returns the help section (accessible from the help button in the header)
     * @param datalist_description
     * @returns {XML}
     */
    getDescriptionGrid: function (datalist_description) {
        var context = this;

        return (
            <section style={(context.state.app_object && context.state.app_object.custom_properties && context.state.app_object.custom_properties.autoexpand_description) ? { display : "block" } : { display : "none" }}
                     className={"description_grid"}
                     data-unique-id={context.state.grid_id}>
                {datalist_description}
            </section>
        );
    },

    render: function () {
        this.debugLog('render', true);
        var context = this;

        if ( this.state.force_full_refresh ) {
            var gridInstance = this.get_grid_object();
            if ( gridInstance && gridInstance.hideColumnChooser ) {
                gridInstance.hideColumnChooser();
            }
            localStorage.removeItem('selectedViewsetting'); //Clear dx's caching
            localStorage.removeItem('storage');

            setTimeout(function() {
                context.setState({ shouldInitGrid: true, force_full_refresh: false });
            }, 0);

            return (<div></div>);
        }

        // Render happens only if we have the app_object
        if ( !context.state.app_object ) {
            return (<div></div>);
        }

        var app_object_name = this.state.app_object ? this.state.app_object.name : "";
        var error_component = this.getErrorComponent();
        var export_component = this.getExportComponent();
        var displayCloseButton = this.getDisplayCloseButton();
        var borderColor = this.getBorderColor();
        var workflow_status_html = this.getWorkflowStatusHtml(borderColor);

        var editElement;
        if ( this.state.editForm ) {
            var gridInstance = this.get_grid_object();
            if ( gridInstance && gridInstance.hideColumnChooser ) {
                gridInstance.hideColumnChooser();
            }
            editElement = this.getEditElement();
        }

        var showBodyDatagrid = "block",
            displayDescription,
            numOfRegisters = this.getNumOfRegisters(),
            marginRightskip = ((this.props.is_mobile) ? "10px" : "5px"),
            colorSkip = 'rgba(96,96,96,1)';
        if ( this.props.grid_is_on_edit_form ) {
            displayDescription = (this.state.is_open || this.is_desc_button_toggling) ? "inline-block" : 'none';
            showBodyDatagrid = (this.state.is_open || this.is_grid_toggling) ? "block" : "none";
            marginRightskip = "10px";
        }

        var descriptionButton = this.getDescriptionButton(colorSkip, displayDescription, marginRightskip);
        var view_buttons = this.getViewButtons(displayDescription, marginRightskip);
        var close_button = this.getCloseButton(colorSkip, displayCloseButton);
        var datalist_description = this.getDataListDescription(borderColor);
        var description_grid = context.getDescriptionGrid(datalist_description);

        var viewToRender;
        switch ( context.state.selectedViewsetting ) {
            case 'pivot_view':
                numOfRegisters = '';
                workflow_status_html = '';

                viewToRender = <PivotViewComponent ref="refPivotViewComponent"
                                                   app_object_handler={context.props.app_object_handler}
                                                   app_object={context.state.app_object}
                                                   call_data_list_set_state={context.call_set_state}
                                                   data_list_react_entity={context}
                                                   description_grid={description_grid}
                                                   entity_attributes={context.state.entity_attributes}
                                                   error_component={error_component}
                                                   app_object_conditions={context.props.app_object_conditions}
                                                   handle_executing={context.props.handle_executing}
                                                   set_data_list_state={context.set_state_value}
                                                   update_height={context.update_height} />;
                break;
            case 'tree_view':
                numOfRegisters = '';
                workflow_status_html = '';

                viewToRender = <TreeViewComponent ref="refTreeViewComponent"
                                                  app_object={context.state.app_object}
                                                  call_data_list_set_state={context.call_set_state}
                                                  description_grid={description_grid}
                                                  entity_attributes={context.state.entity_attributes}
                                                  error_component={error_component}
                                                  export_grid_conditions={context.state.export_grid_conditions}
                                                  grid_id={context.state.grid_id}
                                                  handle_executing={context.props.handle_executing}
                                                  on_click_add_tree_view_element={context.on_click_add_tree_view_element}
                                                  on_click_data_list_element={context.on_click_data_list_element}
                                                  set_data_list_state={context.set_state_value}
                                                  showBodyDatagrid={showBodyDatagrid} />;
                break;
            case 'map_view':
                numOfRegisters = '';
                workflow_status_html = '';

                viewToRender = <MapViewComponent ref="refMapViewComponent"
                                                 app_object={context.state.app_object}
                                                 call_data_list_set_state={context.call_set_state}
                                                 description_grid={description_grid}
                                                 entity_attributes={context.state.entity_attributes}
                                                 entity_instances={context.state.entity_instances}
                                                 error_component={error_component}
                                                 export_grid_conditions={context.state.export_grid_conditions}
                                                 grid_id={context.state.grid_id}
                                                 handle_executing={context.props.handle_executing}
                                                 on_click_data_list_element={context.on_click_data_list_element}
                                                 row_count={context.row_count}
                                                 set_data_list_state={context.set_state_value}
                                                 showBodyDatagrid={showBodyDatagrid}
                                                 update_height={context.update_height} />;
                break;
            case 'calendar_view':
                viewToRender = <CalendarViewComponent ref="refCalendarViewComponent"
                                                      app_object={context.state.app_object}
                                                      apply_app_object_sorting={context.apply_app_object_sorting}
                                                      call_data_list_set_state={context.call_set_state}
                                                      description_grid={description_grid}
                                                      entity_attributes={context.state.entity_attributes}
                                                      error_component={error_component}
                                                      export_component={export_component}
                                                      export_grid_conditions={context.state.export_grid_conditions}
                                                      grid_id={context.state.grid_id}
                                                      handle_executing={context.props.handle_executing}
                                                      on_click_data_list_element={context.on_click_data_list_element}
                                                      set_data_list_state={context.set_state_value}
                                                      showBodyDatagrid={showBodyDatagrid}
                                                      update_height={context.update_height} />;
                break;
            default:
                viewToRender = <DataGridViewComponent ref="refDataGridViewComponent"
                                                      app_object={context.state.app_object}
                                                      app_object_code={context.props.app_object_code}
                                                      app_object_conditions={context.props.app_object_conditions}
                                                      apply_app_object_sorting={context.apply_app_object_sorting}
                                                      apply_personalized_columns_from_local_storage={context.apply_personalized_columns_from_local_storage}
                                                      call_data_list_set_state={context.call_set_state}
                                                      confirm={context.confirm}
                                                      data_list_parent_react_entity={context.props.parent_react_entity}
                                                      delete_nested_entity={context.props.delete_nested_entity}
                                                      description_grid={description_grid}
                                                      disable_create={context.props.disable_create || ((context.state.app_object && context.state.app_object.custom_properties && context.state.app_object.custom_properties.disable_create_in_default_list_view) && !(context.props.grid_is_on_edit_form || context.props.is_nested))}
                                                      disallow_grid_filters={context.props.disallow_grid_filters}
                                                      disallow_grid_workflows={context.props.disallow_grid_workflows}
                                                      display_loader={context.display_loader}
                                                      drilldown_conditions_holder={context.props.drilldown_conditions_holder}
                                                      entity_access={context.state.entity_access}
                                                      entity_attributes={context.state.entity_attributes}
                                                      entity_instances={context.state.entity_instances}
                                                      error_component={error_component}
                                                      export_component={export_component}
                                                      export_grid_conditions={context.state.export_grid_conditions}
                                                      get_count={context.state.get_count}
                                                      get_custom_grid_properties={context.props.get_custom_grid_properties}
                                                      get_data_list_state_value={context.get_state_value}
                                                      get_drilldown_conditions={context.get_drilldown_conditions}
                                                      get_full_conditions={context.get_full_conditions}
                                                      getDbStoragePersonalizedLayoutCode={context.getDbStoragePersonalizedLayoutCode}
                                                      getLocalStoragePersonalizedLayoutCode={context.getLocalStoragePersonalizedLayoutCode}
                                                      grid_id={context.state.grid_id}
                                                      grid_init_callback={context.props.grid_init_callback}
                                                      grid_is_on_edit_form={context.props.grid_is_on_edit_form}
                                                      handle_add={context.props.handle_add}
                                                      handle_delete={context.props.handle_delete}
                                                      handle_executing={context.props.handle_executing}
                                                      is_mobile={context.props.is_mobile}
                                                      is_nested={context.props.is_nested}
                                                      is_ref_lookup={context.props.is_ref_lookup}
                                                      is_xenforma_note={context.props.is_xenforma_note}
                                                      nested_entity_handler={context.props.nested_entity_handler}
                                                      nested_list_entities={context.props.nested_list_entities}
                                                      nested_reference_entity_data={context.props.nested_reference_entity_data}
                                                      parent_entity_array={context.props.parent_entity_array}
                                                      parent_entity_field_attribute={context.props.parent_entity_field_attribute}
                                                      read_only={context.props.read_only}
                                                      requestAndSetCount={context.requestAndSetCount}
                                                      set_data_list_state={context.set_state_value}
                                                      showBodyDatagrid={showBodyDatagrid}
                                                      skip_location_push={context.props.skip_location_push}
                                                      update_fields_for_entity_instances={context.props.update_fields_for_entity_instances}
                                                      update_height={context.update_height}
                                                      updateDimensions={context.updateDimensions} />;
        }

        var widget_header = this.getWidgetHeader(app_object_name, numOfRegisters, workflow_status_html, view_buttons, descriptionButton, close_button, borderColor);

        this.debugLog('render', false);
        return (
            <div id="datalist_cmp" className="widget">
                {editElement}
                {widget_header}
                {viewToRender}
            </div>
        );
    }
});
