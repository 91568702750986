var ToolbarViewer = React.createClass({
    propTypes: {
        onZoomIn: React.PropTypes.func,
        onZoomOut: React.PropTypes.func,
        downloadFile: React.PropTypes.func
    },
    getDefaultProps: function () {
        return {

        };
    },
    getInitialState: function () {
        return {
            scale: 0,
            url: null
        };
    },
    zoomIn: function (e) {
        if (this.props.onZoomIn) {
            this.props.onZoomIn(e);
        }
    },
    zoomOut: function (e) {
        if (this.props.onZoomOut) {
            this.props.onZoomOut(e);
        }
    },
    downloadFile: function (e) {
        window.open(this.state.url);  
    },
    componentDidMount: function(){
        this.setState({
            url: window.location.href.toString().split("report=")[1]
        });
    },
    buildDownloadTooltip: function(){
        if(this.state.url && this.state.url.toString().includes(".docx")){
            return "Download docx"
        }
        return "Download pdf"
    },
    shouldComponentUpdate: function (nextProps, nextState) {
        if (this.state.scale !== nextState.scale) {
            return true;
        }
        return false;
    },
    render: function () {
        var _this = this;
        return (
 
            <div className="Toolbar">
                <button id="download" className="toolbarButton download hiddenMediumView" title={this.buildDownloadTooltip()} tabindex="34" data-l10n-id="download" onClick= {function onClick(e) {return _this.downloadFile(e);}}>
                                            <span data-l10n-id="download_label">Download</span>
                </button>
            </div>

        );
    }
});