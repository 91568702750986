/**
 * ========================================================
 * Description: Component to render the Home dashboard,
 *              also see .dashboard, which mirrors much
 *              of the same functionality.
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var HomeDashboardComponent = React.createClass({
    getInitialState: function () {
        this.initialize_dashboard();
        return {components: {}, componentWillUnmount_array: []};
    },
    on_error_close: function() {
        this.setState({error:undefined});
    },
    get_component_by_div_id: function (div_id) {
        for (var key in this.state.components) {
            var component = this.state.components[key];
            if (component.div_id && component.div_id == div_id) {
                return component;
            }
        }
    },
    get_dashboard_part_loc: function (dashboard_part_id) {
        for (var i = 0; i < this.state.home_layout.length; i++) {
            var home_layout = this.state.home_layout[i];
            if (home_layout.dashboard_part_id == dashboard_part_id) {
                return home_layout;
            }
        }
    },
    set_dashboard_item_title: function (jqueryElement, title, component, description) {
        jqueryElement.html(title);
        if(description && description != "") {
            var parent_jquery_element = jqueryElement.parent();
            parent_jquery_element.append("<div>" + description + "</div>");
        }
        var context = this;
        if (component.dashboard_part.linked_app_object_code) {
            jqueryElement.attr("href", "");
            jqueryElement.on("click", function (event) {
                event.preventDefault();
                var app_object = {};
                app_object.type = "data_list";
                app_object.code = component.dashboard_part.linked_app_object_code;
                if(component.dashboard_part.visualization_type == "tile")
                {
                    app_object.conditions = component.dashboard_part.aggregation_data[0].conditions;
                }
                return context.props.app_object_handler(app_object);
            });
        }
    },
    fix_visual_control_size: function (jquery_element, jquery_element_title, component) {
        var a = jquery_element.parent().height();
        var b = jquery_element_title.parent().outerHeight(true);
        jquery_element.height(a - b);
        var c = jquery_element.height();
        var d = jquery_element_title.parent().width();
        if(component.update_size_function) {
            component.update_size_function({
                height: c,
                width: d
            });
        }
        else {
            var visual_control = component.visual_control;
            visual_control.option({
                size: {
                    height: c,
                    width: d
                }
            });
            visual_control.render();
        }
    },
    componentDidMount: function(){
        $(window).on('resize', this.on_resize);
    },
    componentWillUnmount: function (){
        for(var i = 0; i < this.state.componentWillUnmount_array.length; i++) {
            if(this.state.componentWillUnmount_array[i] && (typeof this.state.componentWillUnmount_array[i] == "function")) {
                this.state.componentWillUnmount_array[i]();
            }
        }
        $(window).off('resize');
    },
    on_resize: function(event){
        var maxFontSize = 150000000000, minFontSize = 50, parentWidthPercentage = 1, parentHeightPercentage=0.6, parentHeight, parentWidth;

        var context = this;
        if (!this.resizing){
            //Tile font size
            this.resizing = true;
            var element, e,parent;
            if (window instanceof event.target.constructor || event.target.constructor.name == "Window"){
                element = $('.dashboard-part-tile-body');
                element.each(function(){
                    e = $(this);
                    parent = $(e.parents()[5]);
                    parentHeight = parent.height() - $(parent.find('.dashboard-part-title')[0]).height();
                    parentWidth = parent.width();
                    e.css('font-size', Math.floor(Math.min(Math.max(Math.min(parentHeight*parentHeightPercentage,parentWidth*parentWidthPercentage/e.text().length),minFontSize),maxFontSize)) + "px");
                });

                //chart resizing
                element = $('.dxc');
                element.each(function(){
                    var dx_id = $(this).parent()[0].id;
                    if (dx_id) {
                        if((dx_id.length > 24) && (dx_id.indexOf("chart_") == 0)) {
                            dx_id = dx_id.substring(6);
                        }
                        var component = context.get_component_by_div_id(dx_id);
                        if (component && (component.update_size_function || (component.visual_control && component.visual_control.render))) {
                            context.fix_visual_control_size($('#' + dx_id), $('#' + dx_id + '-title'), component);
                        }
                    }
                });
            } else {
                e = $(event.target).find('.dashboard-part-tile-body');
                if (e.length){
                    parent = $(event.target);
                    parentHeight = parent.height() - $(parent.find('.dashboard-part-title')[0]).height();
                    parentWidth = parent.width();
                    e.css('font-size', Math.floor(Math.min(Math.max(Math.min(parentHeight*parentHeightPercentage,parentWidth*parentWidthPercentage/e.text().length),minFontSize),maxFontSize)) + "px");
                } else {
                    e = $(event.target).find('.dxc');
                    if (e.length){
                        var dx_id = $(e[0]).parents()[0].id;
                        if (dx_id) {
                            if((dx_id.length > 24) && (dx_id.indexOf("chart_") == 0)) {
                                dx_id = dx_id.substring(6);
                            }
                            var component = context.get_component_by_div_id(dx_id);
                            if (component && (component.update_size_function || (component.visual_control && component.visual_control.render))) {
                                context.fix_visual_control_size($('#' + dx_id), $('#' + dx_id + '-title'), component);
                            }
                        }
                    }
                }
            }
        }
        this.resizing = false;
    },
    componentDidUpdate: function () {
        //variable declarations
        var context = this,
            i,j,u,v,
            options = {
                animate: true,
                float: true,
                width: 12,
                cellHeight: 80,
                verticalMargin: 10,
                handle:'.grid-stack-item-content>*'
            },
            update_home_layout,
            home_grid = $('#home_grid'),
            gridstack,
            component,
            dp_width,
            dp_height,
            dp_x,
            dp_y,
            dp_loc,
            jquery_element,
            jquery_element_title,
            type,
            aggregation_data,
            value_field,
            data,
            label_format,
            argument_field,
            properties,
            pointclickevent,
            data_point,
            argument_name,
            unique_id;
        if (Object.keys(this.state.components).length == 0 || !this.state.home_layout || this.state.gridstack) {
            return;
        }
        update_home_layout = function () {
            var arr,
                node,
                dx_id,
                component,
                dashboard_part_id;

            if (context.state.gridstack) {
                arr = [];
                $('.grid-stack-item').each(function () {
                    node = $(this).data('_gridstack_node');
                    if (node == null) {
                        return;
                    }
                    dx_id = this.children[0].children[1].getAttribute('id');
                    if (dx_id) {
                        component = context.get_component_by_div_id(dx_id);
                        if (component == null) {
                            return;
                        }
                        dashboard_part_id = component.dashboard_part.code;
                        arr.push(
                            {
                                dashboard_part_id: dashboard_part_id,
                                x: node.x,
                                y: node.y,
                                width: node.width,
                                height: node.height
                            }
                        );
                    }
                });
                localStorage.removeItem('home');
                localStorage.removeItem('get_home_layout');
                invoke_method("user", "update_home_layout", arr);
            }
        };
        gridstack = home_grid.gridstack(options).data('gridstack');
        home_grid.on('dragstart', function () {
            context.noClick = true;
        });
        home_grid.on('dragstop', function () {
            update_home_layout();
        });
        home_grid.on('resizestop', function () {
            update_home_layout();
        });
        for (var key in this.state.components) {
            component = this.state.components[key];
            dp_width = component.dashboard_part.width;
            dp_height = component.dashboard_part.height;
            dp_x = component["row_number"];
            dp_y = component["col_number"];
            dp_loc = this.get_dashboard_part_loc(component.dashboard_part.code);
            if (dp_loc) {
                dp_width = dp_loc.width;
                dp_height = dp_loc.height;
                dp_x = dp_loc.x;
                dp_y = dp_loc.y;
            }
            var widgetBody = '' +
                '<div class="widget no-header dashboard-part">' +
                    '<div class="grid-stack-item-content widget-body">' +
                            '<div class="dashboard-part-title">' +
                                '<a id="' + component.div_id + '-title">' +
                                '</a>' +
                            '</div>' +
                            '<div id="' + component.div_id + '">' +
                            '</div>'+
                    '</div>' +
                '</div>';
            if (typeof dp_x != 'undefined' && typeof dp_y != 'undefined') {
                gridstack.addWidget(widgetBody, dp_x, dp_y, dp_width, dp_height, false);
            }
            else {
                gridstack.addWidget(widgetBody, 0, 0, dp_width, dp_height, true);
            }
            jquery_element = $("#" + component.div_id);
            jquery_element_title = $("#" + component.div_id + "-title");
            type = component.dashboard_part["visualization_type"];
            if(type == "none") {
                jquery_element.parent().parent().remove();
                continue;
            }
            var description = component.dashboard_part["description"];
            aggregation_data = component.dashboard_part.aggregation_data;
            if (aggregation_data && Array.isArray(aggregation_data) && aggregation_data.length > 0) {
                /*Auto translate everything that is translatable*/
                for (i = 0; i < aggregation_data.length; i++) {
                    value_field = component.dashboard_part.custom_properties.valueField;
                    data = aggregation_data[i][value_field];
                    /* We will try to migrate over to creating our own code instead of dev express,
                     * right now line and bar charts use dev express and tile and ranked lists use this.
                     */
                    label_format = component.dashboard_part.custom_properties.label_format;
                    if(label_format && (type == "ranked_list" || type == "tile")) {
                        switch(label_format) {
                            case "currency":
                                aggregation_data[i][value_field] = parseInt(aggregation_data[i][value_field]).toLocaleString();
                                if(navigator.language == 'en-US') { //Temporary until we decide how to use toLocaleString with an object as the curerncy type.
                                    aggregation_data[i][value_field] = '$' + aggregation_data[i][value_field];
                                }
                            break;
                            default:
                            break;
                        }
                    }
                    if (typeof data === "string" && R[data]) {
                        data = R[data];
                    }
                    argument_field = component.dashboard_part.custom_properties.argumentField;
                    if (typeof aggregation_data[i][argument_field] === "string" && R[aggregation_data[i][argument_field]]) {
                        aggregation_data[i][argument_field] = R[aggregation_data[i][argument_field]];
                    }
                }
                /*End of auto translate*/
                switch (type) {
                    case "pie_chart":
                        properties = {};
                        this.set_dashboard_item_title(jquery_element_title, component.dashboard_part.caption, component);
                        properties.dataSource = aggregation_data;
                        properties.legend = {visible: false};
                        properties.animation = {enabled: true};
                        properties.resolveLabelOverlapping = "shift";
                        pointclickevent = function(current_linked_app,properties,aggregationdata){
                            properties.onPointClick = function(e){
                                var app_object = {};
                                app_object.type = "data_list";
                                app_object.code = current_linked_app;
                                var elementPos = aggregationdata.map(function(x) {x._id = x._id || general_utils.create_uid(); return x._id; }).indexOf(e.target.argument);
                                app_object.conditions = aggregationdata[elementPos].conditions;
                                return context.props.app_object_handler(app_object);
                            }
                        }(component.dashboard_part.linked_app_object_code,properties,aggregation_data);
                        properties.series = [{
                            argumentField: component.dashboard_part.custom_properties.argumentField,
                            valueField: component.dashboard_part.custom_properties.valueField,
                            label: {
                                visible: true,
                                customizeText: function (arg) {
                                    return arg.argumentText + " (" + arg.valueText + ")";
                                }
                            }
                        }];
                        properties.palette = component.dashboard_part.initial_chart_display_palette || "Bright"; // || "Default"

                        label_format = component.dashboard_part.custom_properties['label_format'];
                        if(label_format) {
                            properties.commonAxisSettings = {
                                label: {
                                    format: {
                                        type: label_format,
                                        precision: 2
                                    }
                                }
                            };
                        }
                        jquery_element.dxPieChart(properties);
                        component.visual_control = jquery_element.dxPieChart("instance");
                        this.fix_visual_control_size(jquery_element, jquery_element_title, component);
                        break;
                    case "line_chart":
                        properties = {};
                        this.set_dashboard_item_title(jquery_element_title, component.dashboard_part.caption, component);
                        properties.dataSource = aggregation_data;
                        properties.legend = {
                            verticalAlignment: "bottom",
                            horizontalAlignment: "center",
                            itemTextPosition: "bottom"
                        };
                        properties.animation = {enabled: true};
                        properties.resolveLabelOverlapping = "shift";
                        pointclickevent = function(current_linked_app,properties,aggregationdata){
                            properties.onPointClick = function(e){
                                var app_object = {};
                                app_object.type = "data_list";
                                app_object.code = current_linked_app;
                                var condition = aggregationdata;
                                var argumentField = e.target.series.getArgumentField();
                                var elementPos = condition.map(function(x) {return x[argumentField];}).indexOf(e.target.argument);
                                var conditions = e.target.series.conditionField;
                                conditions = conditions?conditions:e.target.series.name.replace(/ /g,'') + 'Conditions';
                                app_object.conditions = aggregationdata[elementPos][conditions];
                                return context.props.app_object_handler(app_object);
                            }
                        }(component.dashboard_part.linked_app_object_code,properties,aggregation_data);

                        if (aggregation_data[0].series && aggregation_data[0].series.length){
                            properties.series = aggregation_data[0].series;
                            for (j = 0, v = properties.series.length; j<v;j++){
                                properties.series[j].valueField = properties.series[j].name.replace(/ /g,'');
                                properties.series[j].conditionField = properties.series[j].name.replace(/ /g,'') + 'Conditions';
                                for (i = 0, u = aggregation_data.length; i<u;i++){
                                    aggregation_data[i][properties.series[j].valueField] = aggregation_data[i].series[j]['fieldValue'];
                                    aggregation_data[i][properties.series[j].conditionField] = aggregation_data[i].series[j].conditions;
                                }
                            }
                            properties.dataSource = aggregation_data;
                        }
                        else{
                            if (component.dashboard_part.custom_properties.series){
                                properties.series = component.dashboard_part.custom_properties.series;
                            }
                            else{
                                properties.series = [{
                                    argumentField: component.dashboard_part.custom_properties.argumentField,
                                    valueField: component.dashboard_part.custom_properties.valueField,
                                    name: component.dashboard_part.custom_properties.name_caption,
                                    color: component.dashboard_part.custom_properties.color
                                }];
                            }
                        }
                        argument_field = component.dashboard_part.custom_properties.argumentField;
                        if (!argument_field){argument_field = "_id"}
                        properties.commonSeriesSettings = {
                            argumentField:  argument_field,
                            type: "line",
                            hoverMode: "allArgumentPoints",
                            selectionMode: "allArgumentPoints"
                        };

                        label_format = component.dashboard_part.custom_properties.label_format;
                        if(label_format) {
                            properties.commonAxisSettings = {
                                label: {
                                    format: {
                                        type: label_format,
                                        precision: 2
                                    }
                                }
                            };
                        }
                        properties.tooltip = {
                            enabled:true,
                            customizeTooltip:function(point_info){
                                return {
                                    text:point_info.argumentText
                                }
                            }
                        };
                        jquery_element.dxChart(properties);
                        component.visual_control = jquery_element.dxChart("instance");
                        this.fix_visual_control_size(jquery_element, jquery_element_title, component);
                        break;
                    case "bar_chart":
                        properties = {};
                        this.set_dashboard_item_title(jquery_element_title, component.dashboard_part.caption, component);
                        properties.dataSource = aggregation_data;
                        properties.animation = {enabled: true};
                        pointclickevent = function(current_linked_app,properties,aggregationdata){
                            properties.onPointClick = function(e){
                                var app_object = {};
                                app_object.type = "data_list";
                                app_object.code = current_linked_app;
                                var condition = aggregationdata;
                                var argumentField = e.target.series.getArgumentField();
                                var elementPos = condition.map(function(x) {return x[argumentField];}).indexOf(e.target.argument);
                                var conditions = e.target.series.conditionField;
                                conditions = conditions?conditions:e.target.series.name.replace(/ /g,'') + 'Conditions';
                                app_object.conditions = aggregationdata[elementPos][conditions];
                                app_object.conditions = app_object.conditions || aggregationdata[elementPos]['conditions'];
                                return context.props.app_object_handler(app_object);
                            }
                        }(component.dashboard_part.linked_app_object_code,properties,aggregation_data);
                        if (aggregation_data[0].series && aggregation_data[0].series.length){
                            properties.series = aggregation_data[0].series;
                            for (j = 0, v = properties.series.length; j<v;j++){
                                properties.series[j].valueField = properties.series[j].name.replace(/ /g,'');
                                properties.series[j].conditionField = properties.series[j].name.replace(/ /g,'') + 'Conditions';
                                for (i = 0, u = aggregation_data.length; i<u;i++){
                                    aggregation_data[i][properties.series[j].valueField] = aggregation_data[i].series[j].fieldValue;
                                    aggregation_data[i][properties.series[j].conditionField] = aggregation_data[i].series[j].conditions;
                                }
                            }
                            properties.dataSource = aggregation_data;
                        }
                        else{
                            if (component.dashboard_part.custom_properties.series){
                                properties.series = component.dashboard_part.custom_properties.series;
                            }
                            else{
                                properties.series = [{
                                    argumentField: component.dashboard_part.custom_properties.argumentField,
                                    valueField: component.dashboard_part.custom_properties.valueField,
                                    name: component.dashboard_part.custom_properties.name_caption,
                                    color: component.dashboard_part.custom_properties.color
                                }];
                            }
                        }
                        var argumentField = component.dashboard_part.custom_properties.argumentField;
                        if (!argumentField){argumentField = "_id"}
                        properties.commonSeriesSettings = {
                            argumentField:  argumentField,
                                type: "bar"
                        };
                        /*Implement in the near future
                        properties.tooltip = {
                            enabled: true
                        };
                        */
                        label_format = component.dashboard_part.custom_properties.label_format;
                        if(label_format) {
                            properties.commonAxisSettings = {
                                label: {
                                    format: {
                                        type: label_format,
                                        precision: 2
                                    }
                                }
                            };
                        }
                        jquery_element.dxChart(properties);
                        component.visual_control = jquery_element.dxChart("instance");
                        this.fix_visual_control_size(jquery_element, jquery_element_title, component);
                        break;
                    case "vector_map":
                        this.set_dashboard_item_title(jquery_element_title, component.dashboard_part.caption, component);
                        properties = {};
                        var dxProperties = component.dashboard_part.custom_properties.dx_properties,
                            mapSource = "",
                            bounds = "",
                            centermap = [0,0],
                            zoomfactor = 1,
                            dataPoints = convert_to_dictionary_by_field_names(aggregation_data, {key: "_id", value: "value"});
                        if(dxProperties.layers.dataSource == "DevExpress.viz.map.sources.usa"){
                            mapSource = DevExpress.viz.map.sources.usa;
                            bounds = [-160, 70, -65,20];
                            centermap = [-100, 38];
                            zoomfactor = 2.5;
                        }
                        else{
                            mapSource = DevExpress.viz.map.sources.world;
                            bounds = [-180, 85, 180, -60];
                        }
                        properties.layers = {
                                name: dxProperties.layers.name,
                                dataSource: mapSource,
                                palette: dxProperties.layers.palette,
                                colorGroups: dxProperties.layers.colorGroups,
                                colorGroupingField: dxProperties.layers.colorGroupingField,
                                label: dxProperties.layers.label,
                            customize: function (elements) {
                                $.each(elements, function (_, element) {
                                    var Data = dataPoints[element.attribute("name")];
                                    element.attribute("count", Data && Data.count || 0);
                                });
                            }
                        };
                        properties.legends =  [{
                            source: dxProperties.legends[0].source,
                            customizeText: function (arg) {
                                return format(arg.start) + " to " + format(arg.end);
                            }
                        }];
                        properties.bounds = bounds;
                        properties.zoomFactor= zoomfactor;
                        properties.center = centermap;
                        properties.redrawOnResize=true;
                        pointclickevent = function(current_linked_app,properties,aggregationdata){
                            properties.onClick = function(e){
                                var app_object = {};
                                app_object.type = "data_list";
                                app_object.code = current_linked_app;
                                var conditions = find_by_field('_id', e.target.attribute('name'), aggregationdata);
                                if (conditions){
                                    app_object.conditions = conditions.conditions;
                                }
                                return context.props.app_object_handler(app_object);
                            };
                        }(component.dashboard_part.linked_app_object_code,properties,aggregation_data);
                        jquery_element.dxVectorMap(properties);
                        component.visual_control = jquery_element.dxVectorMap("instance");
                        break;
                    case "ranked_list":
                        value_field = component.dashboard_part.custom_properties.valueField;
                        argument_field = component.dashboard_part.custom_properties.argumentField;
                        this.set_dashboard_item_title(jquery_element_title, component.dashboard_part.custom_properties.name_caption, component);
                        var thead = '<thead>';
                        thead += '<tr>';
                        thead += '<th>';
                        thead += component.dashboard_part.custom_properties.argument_field_caption;
                        thead += '</th>';
                        thead += '<th style="text-align: right;">';
                        thead += component.dashboard_part.custom_properties.value_field_caption;
                        thead += '</th>';
                        thead += '</tr>';
                        thead += '</thead>';
                        var tbody = '<tbody>';
                        for (i = 0; i < aggregation_data.length; i++) {
                            data_point = aggregation_data[i][value_field];
                            argument_name = aggregation_data[i][argument_field];
                            aggregation_data[i]._id = aggregation_data[i]._id || general_utils.create_uid();
                            unique_id = aggregation_data[i]._id.replace(/[ &,./]/g,'') + component.div_id+i;

                            tbody += '<tr><td style="text-align: left;"><div style="text-decoration: underline;cursor: pointer;" id="'+unique_id+'">' + argument_name + '</div></td><td style="text-align: right;">' + data_point + '</td></tr>';
                        }
                        tbody += '</tbody>';
                        var html = "<table style='width:100%' class='table table-striped'>";
                        html += thead;
                        html += tbody;
                        html += "</table>";
                        jquery_element.html(html);
                        for (i = 0; i < aggregation_data.length; i++) {
                            aggregation_data[i]._id = aggregation_data[i]._id || general_utils.create_uid();
                            unique_id = aggregation_data[i]._id.replace(/[ &,./]/g,'') + component.div_id+i;
                            pointclickevent = function(current_linked_app,aggregationdata,unique_id){

                                $('#'+unique_id).bind( "click", function() {

                                    var app_object = {};
                                    app_object.type = "data_list";
                                    app_object.code = current_linked_app;
                                    app_object.conditions = aggregationdata.conditions;
                                    return context.props.app_object_handler(app_object);
                                });

                            }(component.dashboard_part.linked_app_object_code,aggregation_data[i],unique_id)
                        }
                        break;
                    case "tile":
                        value_field = component.dashboard_part.custom_properties.valueField;
                        data_point = aggregation_data[0][value_field];

                        argument_field = component.dashboard_part.custom_properties.argumentField;
                        argument_name = aggregation_data[0][argument_field];

                        this.set_dashboard_item_title(jquery_element_title, argument_name, component, description);

                        var data_point_color;
                        if (component.dashboard_part.custom_properties && component.dashboard_part.custom_properties.data_point_color) {
                            data_point_color = component.dashboard_part.custom_properties.data_point_color;
                        }
                        else {
                            data_point_color = "black";
                        }
                        var fontSize = 4;
                        if (data_point.toString().length <= 6) {
                            fontSize = (12 - data_point.toString().length);
                        } else if (data_point.toString().length <= 8) {
                            fontSize = 6;
                        }
                        else if (data_point.toString().length <= 10) {
                            fontSize = 5;
                        }
                        var tile_html = "<div class=''>";
                        aggregation_data[0]._id = aggregation_data[0]._id || general_utils.create_uid();
                        unique_id = aggregation_data[0]._id.replace(/ /g,'').replace(/&/g,'').replace(/,/g,'').replace(/./g,'') + component.div_id;
                        tile_html += "<div id="+unique_id+">";
                        tile_html += "<div class='bg-white no-padding'>";
                        tile_html += "<h1 style='color:" + data_point_color + ";font-size: " + fontSize + "vw' class='dashboard-part-tile-body'>" + data_point + "</h1>";

                        tile_html += "</div></div></div>";

                        jquery_element.html(tile_html);
                        pointclickevent = function(current_linked_app,aggregationdata,unique_id){
                            $('#'+unique_id).bind( "click", function() {
                                if(context.noClick)
                                {
                                    context.noClick = false;
                                }
                                else{
                                    var app_object = {};
                                    app_object.type = "data_list";
                                    app_object.code = current_linked_app;
                                    app_object.conditions = aggregationdata.conditions;
                                    return context.props.app_object_handler(app_object);
                                }


                            });

                        }(component.dashboard_part.linked_app_object_code,aggregation_data[0],unique_id);
                        break;
                    case "dx_chart":
                        this.set_dashboard_item_title(jquery_element_title, component.dashboard_part.caption, component);
                        var pivot_chart_uid = jquery_element.attr("id");
                        var pivot_table_uid = create_uid();

                        jquery_element.append('<div id="chart_' + pivot_chart_uid + '">&nbsp;</div>' + '<div class="fa_icon_override" id="load_panel_' + pivot_table_uid + '">&nbsp;</div>' + '<div class="fa_icon_override" id="chart_tooltip_' + pivot_table_uid + '">&nbsp;</div>' + '<div class="fa_icon_override" id="controls_' + pivot_table_uid + '">&nbsp;</div>' + '<div class="fa_icon_override" id="pivot_' + pivot_table_uid + '">&nbsp;</div>');

                        var init_grid_columns_properties = {
                            for_export: false,
                            data_grid_view_component: {},
                            app_object: component.dashboard_part.custom_properties.app_object
                        };
                        var chart_jquery_element = jquery_element.find("#chart_" + pivot_chart_uid);

                        var update_height_function = function(local_jquery_element) {
                            return function(num, return_as_number, max_height) {
                                    var total_height = (max_height || local_jquery_element.height()) - 70;
                                    total_height = total_height - num;
                                    if(return_as_number) {
                                        return total_height;
                                    }
                                    return "" + total_height + "px";
                                };
                        }(jquery_element);

                        var remove_component_function = function(local_jquery_element) {
                            return function() {
                                local_jquery_element.parent().hide();
                            };
                        }(jquery_element);

                        var props_obj = {
                            chart_section_jquery_selector: chart_jquery_element,
                            controls_section_jquery_selector: jquery_element.find("#controls_" + pivot_table_uid),
                            pivot_grid_section_jquery_selector: jquery_element.find("#pivot_" + pivot_table_uid),
                            load_panel_div_selector: jquery_element.find("#load_panel_" + pivot_table_uid),
                            tooltip_div_selector: jquery_element.find("#chart_tooltip_" + pivot_table_uid),
                            mouse_y_offset: 25,
                            mouse_x_offset: 255,
                            app_object_handler: context.props.app_object_handler,
                            export_grid_conditions: null,//component.dashboard_part.custom_properties.export_grid_conditions, //Note: is built into initial aggregation already
                            entity_attributes: component.dashboard_part.custom_properties.entity_attributes || {},
                            update_height_function: update_height_function,
                            hide_pivot_grid_component_initially: true,
                            add_delete_option: (component.dashboard_part.add_delete_option === false) ? false : true,
                            error_function: function (error) {
                                server_error_action(error);
                            },
                            array_of_force_expanded_ref_fields: (component.dashboard_part.custom_properties.entity_attributes || {}).array_of_force_expanded_ref_fields,
                            dashboard_part: component.dashboard_part,
                            remove_component_function: remove_component_function,
                            initial_chart_display_type: component.dashboard_part.initial_chart_display_type,
                            initial_chart_display_palette: component.dashboard_part.initial_chart_display_palette,
                            dataFieldsDisplayMode: component.dashboard_part.dataFieldsDisplayMode,
                            chart_is_permanent: component.dashboard_part.chart_is_permanent,
                            disable_field_chooser: component.dashboard_part.disable_field_chooser,
                        };


                        var state_obj = pivot_chart_utils.initialize_pivot_grid_sync(
                            props_obj,
                            init_grid_columns_properties,
                            component.dashboard_part.caption,
                            320, //px
                            function(){},
                            aggregation_data,
                            component.dashboard_part.custom_properties.preset_columns_obj
                        );

                        this.state.componentWillUnmount_array.push(state_obj.componentWillUnmount);

                        component.visual_control = chart_jquery_element.dxChart("instance");
                        component.update_size_function = function(local_state_obj, local_props_obj) {
                            return function (size_obj) {
                                pivot_chart_utils.resize_func(local_state_obj, local_props_obj, size_obj);
                            };
                        }(state_obj, props_obj);
                        this.fix_visual_control_size(jquery_element, jquery_element_title, component);
                        break;
                    default:
                        break;
                }
            }
            else
            {
                this.set_dashboard_item_title(jquery_element_title, component.dashboard_part.caption, component);
                var noDataElement = $('<span class="dx-datagrid-nodata">' + R.no_data + '</span>');
                jquery_element.append(noDataElement);
                noDataElement.css({
                    marginTop: -Math.floor(noDataElement.height() / 2),
                    marginLeft: -Math.floor(noDataElement.width() / 2)
                });
            }
        }
        this.setState({gridstack:gridstack});
        $(window).trigger('resize');
    },
    initialize_dashboard: function () {
        var context = this;
        var get_dashboard_parts = function () {
            var error = function (err) {
                server_error_action(err);
            };

            var success = function (data) {
                localStorage.setItem("home", JSON.stringify(data));
                //Scoping the variables high enough to grant access to components and reduce redefining div_id multiple times
                var app_object = data.app_object;
                if(app_object.code && (app_object.code != "home")) {
                    return context.props.app_object_handler(app_object, true);
                }
                var components = {};
                var num_db_part_requests_resolved = 0;
                var dashboard_parts_length = data.app_object.dashboard_parts.length;

                var add_db_part_with_data_to_components = function(dashboard_part, custom_properties) {
                    var div_id = create_uid();
                    components[dashboard_part.code] = {};
                    components[dashboard_part.code].div_id = div_id;
                    components[dashboard_part.code].dashboard_part = dashboard_part;
                    if(custom_properties && dashboard_part && Object.keys(custom_properties).length) {
                        if(dashboard_part.custom_properties) {
                            $.extend(dashboard_part.custom_properties, custom_properties);
                        }
                        else {
                            dashboard_part.custom_properties = custom_properties;
                        }
                    }
                };

                var get_db_part_data = function (iteration){
                    var db_part_success = function (local_data) {
                        if(local_data && local_data.data) {
                            data.app_object.dashboard_parts[iteration].aggregation_data = local_data.data;
                            add_db_part_with_data_to_components(data.app_object.dashboard_parts[iteration], local_data.custom_properties);
                        }
                    };
                    var complete = function(){
                        num_db_part_requests_resolved++;
                        if (num_db_part_requests_resolved >= dashboard_parts_length){
                            context.setState({app_object: app_object, components: components});
                            context.props.handle_executing(false);
                        }
                    };
                    invoke_method("aggregation", "get_aggregation_data", data.app_object.dashboard_parts[iteration], db_part_success, error, complete);
                };
                if(dashboard_parts_length) {
                    for (var i = 0; i < dashboard_parts_length; i++) {
                        get_db_part_data(i); //sends off a separate request for each dashboard_part
                    }
                }else {
                    context.props.handle_executing(false);
                }
                push_href_location(data.app_object.name + " - " + (R.client_application_title || "Xenforma"), "/");
            };

            if (localStorage.getItem("home") === null || localStorage.getItem("home") =="" || localStorage.getItem("home") === undefined) {
                get_app_object("home", {}, success, error);
            }
            else {
                var storage_data = localStorage.getItem("home");
                storage_data = JSON.parse(storage_data);
                success(storage_data);
            }
        };

        var get_home_layout = function () {

            var success = function (data) {
                data = data.data;
                localStorage.setItem("get_home_layout", JSON.stringify(data));
                context.setState({home_layout: data});
            };

            var error = function (err) {
                context.props.navigation_handler("logout");
                context.props.logged_in_false(err.message);
            };

            if (localStorage.getItem("get_home_layout") === null || localStorage.getItem("get_home_layout") == "" || localStorage.getItem("get_home_layout") === undefined) {
                invoke_method("user", "get_home_layout", {}, success, error);
            }
            else {
                var data = localStorage.getItem("get_home_layout");
                data = JSON.parse(data);
                setTimeout(function () {
                    context.setState({home_layout: data});
                }, 10);

            }
        };

        get_home_layout();
        get_dashboard_parts();
    },
    render: function () {

        var error_component = "";
        if (this.state.error) {
            error_component =
                <div><ErrorNotificationComponent message={this.state.error} on_close={this.on_error_close}/><br /></div>

        }

        return (
            <div className="form-group">
                {error_component}
                <div id="home_grid" className="grid-stack"></div>
            </div>

        );
    }
});
