var ViewerPdf = React.createClass({
  propTypes: {
    onInit: React.PropTypes.func,
    onScaleChanged: React.PropTypes.func,
  },
  getInitialState: function() {
    this.initEventBus();
    return {
      doc: null,
      scale: undefined,
    };
  },
  initEventBus: function() {
    let eventBus = new pdfjsViewer.EventBus();
    var self = this;
    eventBus.on('pagesinit', function (e) {
      self.setState({
        scale: self._pdfViewer.currentScale
      });
      if (self.props.onInit) {
        self.props.onInit({});
      }
      if (self.props.onScaleChanged) {
        self.props.onScaleChanged({scale: self.state.scale});
      }
    });
    eventBus.on('scalechange', function(e){
      if (self.props.onScaleChanged) {
        self.props.onScaleChanged({scale: e.scale});
      }
    });
    self._eventBus = eventBus;
  },
  componentDidMount: function() {
    let viewerContainer = ReactDOM.findDOMNode(this);
    this._pdfViewer = new pdfjsViewer.PDFViewer({
      container: viewerContainer,
      eventBus: this._eventBus,
    });
  },
  componentWillUpdate: function(nextProps, nextState) {
    if (this.state.doc !== nextState.doc) {
      this._pdfViewer.setDocument(nextState.doc);
    }
    if (this.state.scale !== nextState.scale) {
      this._pdfViewer.currentScale = nextState.scale;
    }
  },
  shouldComponentUpdate: function(nextProps, nextState) {
    if (this.state.doc !== nextState.doc ||
        this.state.scale !== nextState.scale) {
      return true;
    }
    return false;
  },
  render: function() {
    return (<div className="Viewer">
      <div className="pdfViewer"></div>
    </div>);
  }
});
