var UploadMultiFilesComponent = React.createClass({
  getInitialState: function() {
    var forminput  = document.getElementsByClassName("forminput");
    document.getElementById("submit-button").style.display = "none";

    Object.keys(forminput).forEach(function(item) {
      forminput[item].style.display = "none";
     });

    return {
      files : this.props.files,
      file: null,
      entityFileId: null,
      editorFileData: []
    };
  },
  uploadFiles: function(event) {
    event.preventDefault();

    var self = this;
    self.props.files.map(function(file, index)  {
      var request = {};

      request.entity = "file";
      request.method = "upsert";
      request.app_object_code = "work_order_default_edit_form";
      request.data = {
        file_name: file.name,
        uploaded: false,
        entity: "file",
        method: "upsert",
      };

      var headers = {
        "Content-Type": "application/json"
      };

      do_authenticated_http_call({
        method: "POST",
        url: "/api/entity/invoke_method",
        headers: headers,
        data: JSON.stringify(request),
        complete: function (response) {
          var entityFileId = response.responseJSON.data._id;

          file._id = entityFileId;
          var editorFileData = self.state.editorFileData.concat({index: index, file: file, entityFileId: entityFileId });
          self.setState({editorFileData: editorFileData});
        }
      });
    });
  },
  setDescriptionByFile: function(file, event) {
    event.preventDefault();
    file.description = event.target.value;
  },
  uploadModal: function() {
    return this.state.EditorFileUploadModal
  },
  render: function() {
    var context = this;

    if(context.props.files.length === context.state.editorFileData.length) {
      return (
        <div>
          {
            context.state.editorFileData.map(function(data) {
              return (
               <EditorFileUploadModal
                 key={data.index}
                 file={data.file}
                 fileId={data.entityFileId}
                 onUploadEnd={context.props.onUploadEnd}
                 onUploadCancel={context.props.onUploadCancel}
               />  
              )
            })
          }
        </div>
      ); 
    } else {
      return (
        <div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">File Name</th>
                <th scope="col">File Description</th>
              </tr>
            </thead>
            <tbody>
              {
                context.props.files.map(function(file, index) {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <th>
                        <input type="text" className="form-control" value={file.name} readonly disabled/>
                      </th>
                      <th>
                        <input type="text" className="form-control" onChange={context.setDescriptionByFile.bind(context, file)}/>
                      </th>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <button className="btn1 btn btn-success shiny workflow_button_margin" onClick={context.uploadFiles.bind(context)}>
            Upload
          </button>
        </div>
      );
    }
  }
});
