
var Pdfjs = React.createClass({
  propTypes: {
    url: React.PropTypes.string,
  },
  getDefaultProps: function () {
    return {

    };
  },
  getInitialState: function () {
    return {
      checked: false,
      url: null,
      loading: true,
    };
  },
  componentDidMount: function () {
    var self = this;
    var report_link = window.location.href.toString().split("report=")[1];
    if (report_link.includes(".docx") && report_link) {
      var file_output_name = report_link.substring(report_link.indexOf("_files"), report_link.indexOf(".docx")).replace("_files/", "");
      var request = {
        "report_format": "pdf",
        "temp_files": ["/temp/".concat(file_output_name)],
        "output_name": file_output_name,
        "temp_file_path": file_output_name.concat(".docx"),
        "store_result_locally": true,
        "url": report_link
      }

      var headers = { "Content-Type": "application/json" };
      do_authenticated_http_call({
        method: "POST",
        url: "/api/convert_docx_to_pdf",
        headers: headers,
        data: JSON.stringify(request),
        success: function (response) {
          self.setState({loading: false});
          console.log('response', response);
          var pdf = response.data.url;
          self.getDocument(pdf);
        },
        error: function(e){
          console.log('error', e);
        }
      });
    } else {
      this.setState({loading: false});
      this.getDocument(report_link);
    }

  },
  getDocument: function (pdf) {
    self = this;
    let loadingTask = pdfjsLib.getDocument(pdf);
    loadingTask.promise.then(function(doc) {
      console.log( "Document ".concat(pdf, " loaded ").concat(doc.numPages, " page(s)"));
      self.viewer.setState({
        doc: doc,
      });
    }, function(reason){
      console.error("Error during ".concat(pdf, " loading: ").concat(reason));
    });
  },
  zoomIn: function (e) {
    this.viewer.setState({
      scale: this.viewer.state.scale * 1.1
    });
  },
  zoomOut: function (e) {
    this.viewer.setState({
      scale: this.viewer.state.scale / 1.1
    });
  },
  displayScaleChanged: function (e) {
    this.toolbar.setState({
      scale: e.scale
    });
  },
  render: function () {
    var _this = this;
    return (
      <div>
      { !this.state.loading ? <div className="App">
        <div className="App-header">
        <ToolbarViewer
          ref={function(ref) {_this.toolbar = ref} }
          onZoomIn={function(e) {_this.zoomIn(e)} }
          onZoomOut={function(e) {_this.zoomOut(e)}}></ToolbarViewer>
        </div>
        <div className="App-body">
          <ViewerPdf
            ref={function(ref) {_this.viewer = ref}}
            onScaleChanged={function(e) {_this.displayScaleChanged(e)}}>  
            </ViewerPdf>
        </div>
      </div>:
      <LoadingComponent  />
      }
      </div>
    );
  }
});