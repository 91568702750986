/**
 * ========================================================
 * Description: Renders the login page
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var LoginComponent = React.createClass({
    getInitialState: function () {
        return {logging_in: false, username: "", password: "", azure_ad_data: null, success: false};
    },

    handle_login: function (event ) {
        event.preventDefault();
        this.props.logged_in_false('');
        var logindata =  $('#login_form').serializeArray();
        try{
            this.setState({username: logindata[0].value});
            this.setState({password: logindata[1].value});
        }catch(err){/*console.log(err);*/}


        if (!this.state.logging_in) {

            // Validate login information here
            this.setState({logging_in: true});
            var credentials = logindata[0].value + ":" + logindata[1].value;
            credentials = window.btoa(credentials);

            var component_class = this;


            $.ajax({
                method: "POST",
                url: "/api/auth/login",
                //timeout: 5000,
                headers: {
                    Authorization: "Basic " + credentials
                },
                success: function (data) {
                    if (component_class.state.error) {
                        component_class.setState({error: undefined});
                    }


                    var login_token = data.login_token;
                    if (login_token !== undefined) {
                        localStorage.setItem("login_token", login_token);
                        g_login_token = login_token;

                        component_class.setState({success: true});
                        //return;
                        component_class.props.on_success();
                    }else{
                        // we did not receive a login_token
                    }
                },
                error: function (error) {
                    if (error.responseJSON) {

                        return component_class.setState({error: error.responseJSON.message});
                    }

                    return component_class.setState({error: error.statusText});
                },
                complete: function () {

                    if (!component_class.state.success) {
                        component_class.setState({logging_in: false});
                    }
                }
            });
        }
    },


    handle_forgot_password: function (event) {
        event.preventDefault();
        this.props.on_success(true);
    },
    componentWillUnmount: function(){
            document.body.className = "";
    },
    changeError:function(){
        this.setState({error: undefined});
        this.props.logged_in_false('');
    },
    componentDidMount: function(){
        //alert('a');

        /*
        var element = $('#login_form');
        element.on('submit', this.handle_login);

        var element = $('#forgot-link');
        element.on('click', this.handle_forgot_password);
        */
        this.tryHandleAzureAdLoginRedirect();
    },
    handleAzureAdLogin() {
        if (this.state.azure_ad_data) {
            return this.doHandleAzureAdLoginWithParams();
        }
        var component_class = this;
        $.ajax({
            method: "GET",
            url: "/api/auth/entra_id/params",
            success: function(data) {
                if (component_class.state.error) {
                    component_class.setState({ error: undefined });
                }
                component_class.setState({azure_ad_data: data && data.data});
                component_class.doHandleAzureAdLoginWithParams();
            },
            error: function(error) {
                if (error.responseJSON) {
                    return component_class.setState({ error: error.responseJSON.message });
                }

                return component_class.setState({ error: error.statusText });
            },
            complete: function() {
                if (!component_class.state.success) {
                    component_class.setState({ logging_in: false });
                }
            }
        });
    },
    doHandleAzureAdLoginWithParams() {
        if (!this.state.azure_ad_data) {
            return this.setState({ error: "Active Directory Login Failed to Initialize." });
        }
        // Generate state and code verifier
        const state = this.generateState();
        const codeVerifier = this.generateCodeVerifier();
        var component_class = this;
        this.generateCodeChallengeFromVerifier(codeVerifier).then(function (codeChallenge) {
            //Set cookies for some parts that will be needed in xenforma server-side validation:
            document.cookie = `state=${state}; path=/`;
            document.cookie = `codeVerifier=${codeVerifier}; path=/`;
            
            const authUrl = `https://login.microsoftonline.com/${component_class.state.azure_ad_data.tenantId}/oauth2/v2.0/authorize?` +
            `client_id=${component_class.state.azure_ad_data.clientId}&response_type=code&redirect_uri=${component_class.state.azure_ad_data.redirectUri}` +
            `&response_mode=query&scope=${component_class.state.azure_ad_data.scope}&state=${state}` +
            `&code_challenge=${codeChallenge}&code_challenge_method=S256`;

            // Redirect to Azure AD
            window.location.href = authUrl;
        });
    },
    generateState() {
        const array = new Uint32Array(10);
        window.crypto.getRandomValues(array);
        return Array.from(array, this.dec2hex).join("");
    },
    generateCodeVerifier() {
        var array = new Uint32Array(56 / 2);
        window.crypto.getRandomValues(array);
        return Array.from(array, this.dec2hex).join("");
    },
    dec2hex(dec) {
        return ("0" + dec.toString(16)).substr(-2);
    },      
    generateCodeChallengeFromVerifier(v) {
        let context = this;
        return this.sha256(v).then(function (hashed) {
            var base64encoded = context.base64urlencode(hashed);
            return base64encoded;
        });
    },
    sha256(plain) {
        // returns promise ArrayBuffer
        const encoder = new TextEncoder();
        const data = encoder.encode(plain);
        return window.crypto.subtle.digest("SHA-256", data);
    },
    base64urlencode(a) {
        var str = "";
        var bytes = new Uint8Array(a);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
          str += String.fromCharCode(bytes[i]);
        }
        return btoa(str)
          .replace(/\+/g, "-")
          .replace(/\//g, "_")
          .replace(/=+$/, "");
    },
    tryHandleAzureAdLoginRedirect: function() {
        var urlParams = new URLSearchParams(window.location.search);
        var code = urlParams.get('code');
        var sessionState = urlParams.get('session_state');
        var state = urlParams.get('state');

        if (!code || !sessionState) { return; } //No azure login redirect to handle
        // Both 'code' and 'session_state' are present in the URL
        // Make a GET request to your server
        
        this.setState({logging_in: true});
        var component_class = this;
        $.ajax({
            method: "GET",
            url: "/api/auth/entra_id/callback",
            data: {
                code: code,
                session_state: sessionState,
                state: state
            },
            success: function(data) {
                if (component_class.state.error) {
                    component_class.setState({ error: undefined });
                }

                var login_token = data.login_token;
                if (login_token !== undefined) {
                    localStorage.setItem("login_token", login_token);
                    g_login_token = login_token;

                    component_class.setState({ success: true });
                    component_class.props.on_success();
                } else {
                    // We did not receive a login_token
                }
            },
            error: function(error) {
                if (error.responseJSON) {
                    return component_class.setState({ error: error.responseJSON.message });
                }

                return component_class.setState({ error: error.statusText });
            },
            complete: function() {
                if (!component_class.state.success) {
                    component_class.setState({ logging_in: false });
                }
                component_class.deleteCookie("state");
                component_class.deleteCookie("codeVerifier");
            }
        });
    },
    deleteCookie: function(name) {
        document.cookie = name + "=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    },
    render: function () {
        var context = this;
        document.body.className = "login-body";
        if (this.state.logging_in) {

            return (<LoadingComponent  />);
        }
        else {
            var error_component = "";
            //  component_class.setState({error: undefined});
            var parent_message;
            try{ parent_message=this.props.request_error_parent(); }catch(err){}

            if (this.state.error || parent_message != undefined) {
                var messageError= this.state.error;
               if( parent_message != undefined ) messageError=parent_message;

                var on_close = function (context) {
                    return function () {
                        context.changeError();
                    };
                }(this);

                error_component = <ErrorNotificationComponent message={messageError} on_close={on_close} />;
            }

            return (<div className="login-container animated fadeInDown">
                    <table className="loginbox">
                        <tbody>
                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <div className="loginbox-header01">
                                    <div className="loginbox-header02">
                                        <div style={{"textAlign":"center"}}>
                                            <img src="images/logo.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td className="loginbox-menu-border-ribbon">&nbsp;</td>
                            <td>
                                <div className="loginbox-menu_right">
                                    <div className="loginbox-loggedout_right min-text">
                                        <img
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAeCAYAAAAy2w7YAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABCJJREFUeNq8Vm9oG2UYf++uae7SXHJJbk1Su/RqJ02b1qZbF4ubuI2Nsm6IboKIWIdMhIoydIIf/CCICCI4FPwgAzdQtg8FJ34QP7gvc+qgH1a6oQzrWiYhpbHJ5XZ3+R+f58zVozO5q4oP/Li79973/T3v8/ye547a8eJHxMYCgD2AEYDQHMsDFgFXm/e21tHmHQU4BDjcK7CB6UR3YrhHkHjW1YUvlWJF/SktL/14e/3MQlq5+G+InqAocuDEQ9t3Tj0YSwl+H+PxeEhHx59LqtUqF5c0cX+i8Nn8Umb6/W+XZv4J0TjgwCv7B/ZOje8YCwaDBElcLhehadqYUK/XSaVSIYIgMD7e+2xPwNN4dW7xuVZEdIuxx6dHwgPTE4NjkUiE+P1+wjDM18ViMVUoFGjEXVWbXCvoC/gO5wz3986cm9l5bCsnkhiaEp/ZE384FAoRjuNIqVT6MJ/Pn9r39hcNy7xrSWnbxOzhXVcfSUgpURSJphc/OPv06KWTFxbrTk7Uv7svEIlFt/kwXBCeW9ls9vQmEsOuL69VXz9/+Z3VXEHGuWIoGAPHUk5Dx4/1iWFcSIEaFEX5/OC7X1VahUTWyquXry/dwrm4BogmHecoxHs8qC5QFlFV9baNcmvLqzkZ56JY3G531CkRSJhGI7VaDUNXtauRUqVaRxXiGnDQ5ZiIMmqVbMjYzhqNhgFjLUW1VR0H6Gteu62TF+6sS826amWS9SEj691wiQNWALqVCPvXaT/XER6N8t1CF+uOCF0CkiHKtcZTRxLhR2stTsZAuOI9Qticr5Vre4+ORs+oxdLvPjfzxpc31u4YJ4Wm+th9AnvsraOJ46LAe7FuoNqNIsWw5XI5AgVq5OtviRiG+Hw+EggEjBzJskyg5oiu6ySvqNlqSR974eKNNJ7I/+Su2MgD/TEvz/NGL0P14Aa4EEm9Xu9GDu7JJ5wC1yDwHknRWVRhQFHETCYzC9PeNHIUDfm7cEOzdqybgFwNODV0EoGOobOapvVuqM7qkZ26IJw/q8XySqsTbj5pZ2fnX/I2E9m2KiFH4N2JwdmPh5KnPpHOfjM/1ypvVjJzX9pJONB76Nzzydc+PW+OvXfp2ne/prNrdidrW7CbDdWXzRWs+h4GDOSVuxUnBe2YCI11MRMXTk5ONR8nDw6F+7eHvFGn6x0Rocw5jqVBlc+bDeDQSG/cw3GU+cX9T4gwoagelmVvmkRZrbqOY3Yi2nLoMOnpnPob3IrY31aySs6pEOz+gu4h+mVVfvn+kGcfPg9G/OGtEjmSDVb51PhAMiUFk8ZfJfQ2HNsK0XK9bu8athXcHPuetdU4sJpJ9ENBL1+BehiyUx6IwYBTwxoDfL8Rut19wkvwS1UGD4/Dhs53ak9ShD3n4HNxzvwekf/D/hBgAByBgt7jg8aYAAAAAElFTkSuQmCC"/>
                                        &nbsp;{R.login_to_your_account}
                                    </div>
                                </div>
                            </td>
                            <td className="loginbox-menu-border-ribbon">&nbsp;</td>
                        </tr>
                        <tr>
                            <td className="loginbox-menu-left-ribbon">&nbsp;</td>
                            <td>
                                <div style={{"position":"relative"}}>
                                    <div className="loginbox-ribbon-shadow"></div>
                                    <div className="loginbox-content01">
                                    <div className="loginbox-content02">



                                        <form id="login_form" className="bv-form" onSubmit={this.handle_login}>
                                            {error_component}
                                            <div className="loginbox-textbox" >
                                                <input type="text" name="user_field" className="form-control" placeholder={R.placeholder_username}
                                                       data-bv-notempty="true"
                                                       data-bv-regexp="true"
                                                       data-bv-regexp-message={R.the_username_can_only_consist_of_alphabetical_number_dot_and_underscore}
                                                       data-bv-regexp-regexp="(^[a-zA-Z0-9_\.]+$)|(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|&quot;(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*&quot;)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])"/>
                                            </div>
                                            <div className="loginbox-textbox">
                                                <input
                                                    type="password"
                                                    name="pass_field"
                                                    className="form-control"
                                                    placeholder={R.placeholder_password}/>
                                            </div>
                                            <div className="loginbox-submit">
                                                <input
                                                    type="submit"
                                                    className="btn btn-primary btn-block"
                                                    value={R.label_login}
                                                    style={{cursor:'pointer'}}
                                                />
                                            </div>
                                            <div className="loginbox-submit">
                                                <input
                                                    type="button"
                                                    className="btn btn-primary btn-block"
                                                    value={R.label_login_using_ad}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={this.handleAzureAdLogin}
                                                />
                                            </div>
                                            <div className="loginbox-forgot">
                                                {R.help_i}&nbsp;<a id="forgot-link" href="javascript:void(0);" onClick={this.handle_forgot_password}>{R.label_forgot_password}</a>

                                            </div>
                                            <div id="callout-btn-group-tooltips" className="bs-callout bs-callout-warning" >

                                    </div>
                                        </form>
                                    </div>
                                </div>
                                </div>
                            </td>
                            <td className="loginbox-menu-right-ribbon">&nbsp;</td>
                        </tr>
                        </tbody>
                    </table>

            </div>);
        }
    }
});
