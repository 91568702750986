var FaQComponent = React.createClass({
  getInitialState: function() {
    return {
      faq_data : this.props.static_data
    };
  },
  filterByKeyword: function(event) {
    var filter_response = JSON.parse(JSON.stringify(this.props.static_data));
    var keyword = event.target.value;
    var regex_keyword = new RegExp(keyword, "g");

    filter_response = filter_response.filter(function(item) {
      return item.question_faq.toLowerCase().includes(keyword.toLowerCase());
    });

    filter_response.map(function(item) {
      item.question_faq = item.question_faq.replace(regex_keyword, "<span class='highlight-text'>" + keyword + "</span>");
      return item;
    });

    this.setState({ faq_data : filter_response});
  },
  render: function() {
    var state = this.state;
    return (
      <div className="widget">
        <div className="widget-header edit_form_header bordered-bottom bordered-lightgray">
          <strong className="faq-caption">FAQ</strong>
        </div>
        <div
          className="widget-body"
          style={{
            overflowX: "hidden",
            overflowY: "auto"
          }}
        >
          <input
            type="text"
            className="input-search-faq"
            onChange={this.filterByKeyword}
            placeholder="Search..."
          />
          
          {state.faq_data.map(function(item) {
            return (
              <div key={item.id} className="faq-container">
                {item.id}. <strong dangerouslySetInnerHTML={{__html: item.question_faq}}></strong>
                <em>
                  Ans: <span dangerouslySetInnerHTML={{__html: item.answe_faq}}></span>
                </em>

                {item.image_faq &&
                  <div className="img-faq">
                    <img src={"./assets/img/faq/" + item.image_faq}/>
                  </div>
                }

                {item.another_data &&
                  <div>
                    <br />
                    <strong className="strong-content">{item.another_data.text_1}</strong>
                    <br />
                    <div className="img-faq">
                      <img src={"./assets/img/faq/" + item.another_data.img_1}/>
                    </div>
                    <br />
                    <di>{item.another_data.text_2}</di>
                    <div className="img-faq">
                      <img src={"./assets/img/faq/" + item.another_data.img_2}/>
                    </div>
                  </div>
                }

                <br />
              </div>
            );
          })}

          {
            state.faq_data.length == 0 &&
              <div className="empty-result">
                There's no result. Try something else.
              </div>
          }
        </div>
      </div>
    );
  }
});
