/**
 * ========================================================
 * Description: Component to render a server error to the page
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var EditFormAttachment = React.createClass({
    propTypes: {
        handleSaveClick: React.PropTypes.func,
        handleCancelClick: React.PropTypes.func
    },
    getInitialState: function () {
        return {};
    },
    handleCancelClick: function(e)
    {
        e.preventDefault();
        this.props.handleCancelClick();
    },
    handleSaveClick: function(e)
    {
        e.preventDefault();
        this.props.handleSaveClick();
    },
    render: function () {
        return (
        <div id="comment-box">
            <div className="row">
                <div className="form-group">
                    <div className="col-sm-12 form-group" style={{textAlign:"center", lineHeight: "200px"}}>
                        <label className="col-sm-12">{ "Do you want to attach to the Notification Email the files attached to the Inspection Report?"}</label>
                    </div>
                </div>
            </div>

            <div id="workflow-button-box"
                 align="right"
                 className="pull-right"
                 style={{
                            width: "100%",
                            height : "auto" ,
                            padding: "10px",
                            backgroundColor : "white",
                            boxShadow: "1px 0 10px 1px rgba(0,0,0,0.3)",
                            textAlign: "right",
                            position:"absolute",
                            left:"0px",
                            bottom:"0px"
                            }}>
                <div style={{display : "inlineTable", width: "auto"}}>
                    <button className="btn btn-success shiny workflow_button_margin"
                            onClick={this.handleSaveClick}>{"Yes"}</button>
                    <button onClick={this.handleCancelClick}
                            className="btn btn-default shiny">{"No"}</button>
                </div>
            </div>
        </div>
        );
    }
});
