/**
 * ========================================================
 * Description: A web control for a tag-box (multi-select
 *              drop-down list)
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var EditorDevExtremeTagBox = React.createClass({
    getInitialState: function() {
        return {};
    },
    render: function() {
        var context = this;
        var className = "";
        if (this.props.add_button)
        {
            className = "add-button-margin";
        }
        return <div className={className} ref={function(ref){context.domElement = ref}}>&nbsp;</div>;
    },
    componentWillReceiveProps:function(nextProps) {
        var newValues = nextProps.values;
        if (newValues) {
                if (this && this.state && this.state.tagbox_element){
                    var currentValues = this.state.tagbox_element.option('value'),
                        equals = true;
                    if (currentValues.length != newValues.length){
                        equals = false;
                    }
                    else {
                        for(var i = 0, u = newValues.length; i<u; i++){
                            if (currentValues.indexOf(newValues[i]) == -1){
                                equals = false;
                                break;
                            }
                        }
                    }
                    if (!equals){
                        this.state.tagbox_element.option('value', this.parseValues(newValues));
                    }
                }
            }
    },
    parseValues:function(inputValues){
        var values = [];
        var context = this;
        inputValues = inputValues || [];
        for (var i=0;i<inputValues.length;i++)
        {
            var item = inputValues[i];
            if (typeof item == "object")
            {
                if(this.props.dataSource && Array.isArray(this.props.dataSource._items || this.props.dataSource)) {
                    var has_item = general_utils.first_where(this.props.dataSource._items || this.props.dataSource, function(a) {
                        return (a && (a[context.props.valueExpr] == item[context.props.valueExpr]))
                    });
                    if(!has_item && (this.props.dataSource._items || this.props.dataSource).push) {
                        (this.props.dataSource._items || this.props.dataSource).push(item); //need to preserve caption that was provided by the server
                    }
                }
                values.push(item[context.props.valueExpr]);
            }
            else {
                values.push(item);
            }
        }
        return values;
    },
    componentWillMount: function(){
        if(!this.props.skip_will_mount_phase) {
            var ds = this.props.dataSource,
                context = this;
            if ('object' == typeof ds) {
                var items_has_no_length = !ds.items || ((typeof ds.items === 'function') && !ds.items().length) || (!ds.items.length);

                if(items_has_no_length) {
                    if(ds.load) {
                        context.state.loading = true;
                        ds.load().done(function () {
                            if (context.isMounted) {
                                context.state.loading = false;
                                context.init();
                            }
                        });
                    }
                }
            }
        }
    },
    componentDidMount: function(){
        this.isMounted = true;
        if (!this.state.loading){
            this.init();
        }
    },
    init:function() {
        var context = this;
        var tagbox_element = $(this.domElement);
        var values = this.parseValues(context.props.values);
        var searchattribute = true;
        if (document.documentElement.clientWidth <= 650) {
            searchattribute = false;
        }
        var init_object = {
            readOnly: context.props.readOnly,
            value: values,
            displayExpr: context.props.displayExpr,
            valueExpr: context.props.valueExpr,
            dataSource: context.props.dataSource,
            searchEnabled: searchattribute,
            onValueChanged: function(data) {
                context.props.onChange(data.value);
                context.props.onBlur();
            }
        };
        if (context.props.usetagtemplate) {
            init_object.tagTemplate = context.props.tagTemplate;
        }
        this.state.tagbox_element = tagbox_element.dxTagBox(init_object).dxTagBox("instance");
        if ('function' == typeof this.props.set_element_reference){
            this.props.set_element_reference(this.state.tagbox_element);
        }
        this.state.tagbox_element.repaint();
    }
});
