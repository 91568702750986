/**
 * ========================================================
 * Description: Component to render an edit form for an
 *              entity.
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/

var EditFormComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
        var context = this;
        if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + e + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },
    getInitialState: function () {
        this.debugLog('getInitialState', true);

        var context = this;

        var state_object = this.initialize_edit_form();
        if (state_object) {
            return state_object;
        }

        setTimeout(function() {
            context.display_loader();
        }, 100);

        this.debugLog('getInitialState', false);

        return {
            update_fields: {},
            update_multiple_field: [],
            name_multiple_field: [],
            isImage: false
        };
    },
    shouldResize: function(){
        this.debugLog('shouldResize', true);
        var isMobile = !!(/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone/i.test(navigator.userAgent)), retval;
        if (!this.state.windowWidth) this.state.windowWidth = 1;
        if (!this.state.windowHeight) this.state.windowHeight = 1;
        var change = window.innerWidth/this.state.windowWidth -1;
        var isChangingOrientation = (change>0?change:(change*-1))>0.01;
        retval = !isMobile||isChangingOrientation;
        if (retval) {
            this.state.windowWidth= window.innerWidth ;
            this.state.windowHeight= window.innerHeight;
        }
        this.debugLog('shouldResize', false);
        return retval;
    },
    updateDimensions: function (e){
        this.debugLog('updateDimentions', true);
        this.handleResize(e);
        this.debugLog('updateDimentions', false);
    },
    handleKeyUp: function (e) {
        var context = this;

        e = e || window.event;

        if ( !context.state.nested_reference_entity_data
            && !context.state.child_entity_state_data
            && !(e && e.closed_one)
            && !context.state.hidRecurrenceModal ) {
            if ( e.keyCode == 27 || e.code === "Escape" ) {
                context.closePopup(e);
            }
        }
        if (e.code === 'F2') {
            var cur_section_number = context.get_current_section_number();
            if (cur_section_number != null) {
                context.set_focus_on_element_by_section(cur_section_number + 1);
            }
        }
    },
    closePopup: function(e) {
        var context = this,
            close = true,
            noButtonSelector = '.dx-dialog.dx-overlay.dx-popup [aria-label="No"]',
            noButton = $(noButtonSelector),
            cancelButtonSelector = 'input.ricancelbutton:visible',
            cancelButton = $(cancelButtonSelector),
            isConfirmPopupLoaded = context.confirming && (new Date() - context.confirming) > 500;

        if ( !context.is_nested_popup()
            && (isConfirmPopupLoaded || !context.confirming)
            && (noButton.length > 0 || cancelButton.length > 0) ) {
            if (cancelButton.length > 0) cancelButton.click();
            else if (noButton.length > 0) noButton.click();
            close = false;
        }

        if ( context.pop_up ) {
            context.pop_up = false;
        }

        if ( close ) {
            context.handle_close();
            if ( e ) {
                e.closed_one = true;
            }
        }
    },
    handleResize: function(e) {
        this.debugLog('handleResize', true);
        if (this.shouldResize()) {
            this.hidden_parent_style();
            this.forceUpdate();
        }
        this.debugLog('handleResize', false);
    },
    componentWillUpdate: function (){
        this.focusedElement = $(':focus');
        var select_box = this.focusedElement.parents('#dxSelectBox');
        if (select_box.length){
            this.focusedElement = select_box.dxSelectBox('instance');
        }
    },
    componentDidUpdate: function () {
        this.debugLog('componentDidUpdate', true);
        var retval = this.custom_post_render_logic(),
            context = this;
        if (!!document.documentMode){
            $( ".btn-primary" ).click(function() {
                if (context.state && context.state.error){
                    $( ".ie-warning" ).css("display","block");
                }
            });
        }
        if (this.focusedElement){
            this.focusedElement.focus();
        }
        this.debugLog('componentDidUpdate', false);
        return retval;
    },
    componentDidMount: function () {
        this.debugLog('componentDidMount', true);
        var context = this;
        this.state.windowWidth = window.innerWidth;
        this.state.windowHeight = window.innerHeight;
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('keyup', this.handleKeyUp);
        this.recovery_parent_style();
        this.display_loader();
        this.debugLog('componentDidMount', false);
        $('.dx-texteditor-input').css({  height : '40px;'});//Needs To improve
        $("#modal_form").css( "padding-left", "7px" ); //Can't tell where the "padding-left: 0" style is coming from, so I'm overriding here
        if(context.state.entity_attributes && context.state.entity_attributes.custom_form_attributes_function){
            context.on_field_blur("custom_form_attributes_function",context.state.entity_attributes.custom_form_attributes_function);
        }
        return this.custom_post_render_logic();
    },
    display_loader: function(){
        this.debugLog('display_loader', true);
        var context = this;
        if (context.props.handle_executing&&(!context.state.parent_entity_field_attribute||context.state.is_reference_nest)){
            context.props.handle_executing(true);
            setTimeout(function(){
                context.props.handle_executing(false);
            },7000);
        }
        this.debugLog('display_loader', false);
    },
    get_form_object: function(){
        var id = this.state.app_object_code || this.props.app_object_code || this.props.data.app_object_code || this.props.data.app_object.code;
        return id?$('#edit_form[data-object="' + id + '"]'):$('#edit_form');
    },
    focus_first_input: function(){
        this.debugLog('focus_first_input', true);
        var attributes = this.state.entity_attributes.attributes,
            attribute,
            context = this,
            counter = 0,
            is_ie = !!document.documentMode;
        for (var i = 0, u = attributes.length; i<u;i++){
            if (attributes[i].form_visible && attributes[i].db_type == "String"){
                attribute = attributes[i];
                break;
            }
        }
        var checkExistsFunction = function() {
            var form = context.get_form_object(),
                element = attribute ? $('#info_' + (attribute.safe_field_path || attribute.field_path)).parent().first() : form.find('input').first(),
                current_focus = form.find('*:focus');
            if (element.find('input').length) { //some "String" fields may be represented as html select boxes.
                element = element.find('input').first();
            }
            else if (attribute) {
                element = element.find('select').first();
            }
            if (element.length != 0 && element.is(':visible') && !element.is(':focus') && $('.loading-container').length == 0 && (!current_focus.length || (is_ie && counter < 1))) {
                element.focus();
            } else {
                if (element.is(':focus') || current_focus.length) {
                    clearInterval(checkExist);
                }
                else if (counter == 2) {
                    if (attribute) {
                        attribute = null;
                        checkExistsFunction();
                    }
                    else {
                        clearInterval(checkExist);
                    }
                }
            }
            counter++;
        };
        var checkExist = setInterval(checkExistsFunction, 500);
        this.debugLog('focus_first_input', false);
    },
    get_current_section_number: function() {
        var cur_section_number = null;
        var cur_active_element = $(document.activeElement);
        if(cur_active_element) {
            var cur_active_section = cur_active_element.closest("[data-section-number]");
            if(cur_active_section) {
                cur_section_number = cur_active_section.attr("data-section-number");
                if ((cur_section_number != null) && !isNaN(cur_section_number)) {
                    cur_section_number = Number(cur_section_number);
                }
                else {
                    cur_section_number = null;
                }
            }
        }
        return cur_section_number;
    },
    recovery_parent_style: function(){
        this.debugLog('recovery_parent_style', true);
        var context=this;
        setTimeout(function(){
            context.hidden_parent_style();
        },1300);
        this.debugLog('recovery_parent_style', false);
    },
    google_drive_api_load:function(attribute){
        var isIE = !!document.documentMode;
        if (isIE){
            setTimeout(function(){
                $("div.picker:nth-child(2) > iframe:nth-child(1)" ).focusin(function(txt) {
                });
            }, 3500);
        }
    },
    google_drive_api:function(attribute){
        this.debugLog('google_drive_api'. true);
        var context = this;
        var picker_window;
        var get_keys = function() {
            var promise = new Promise(function(resolve, reject){
                var request ={};
                request.code="google_drive_public_keys";
                do_authenticated_http_call({
                    method: "POST",
                    url: "/api/settings/get_system_setting",
                    contentType: "application/json",
                    dataType: 'json',
                    data: JSON.stringify(request),
                    success: resolve,
                    error: reject
                });
            });
            return promise;
        };

        var store_keys = function (data) {
            localStorage.setItem("developerKey", data.data.google_drive_developer_key);
            localStorage.setItem("ClientId", data.data.google_drive_browser_key);
            picker_window = window.open('driver.html', 'picker', "height=500,width=900");
        };

        get_keys()
            .then(store_keys);

        function storage_handler(evt)
        {
            if(evt.key == "url" && evt.newValue != 'nothing'){
                context.props.handle_executing(true);
                var doc = JSON.parse(localStorage.getItem("doc"));
                var url = localStorage.getItem("url");
                context.on_field_change(attribute, true);
                attribute = context.state.entity_attributes.field_paths["drive_url"];
                context.on_field_change(attribute, url);
                context.on_file_name_change(attribute, doc);
                context.setState({file_uploaded:true});
                picker_window.close();
                context.props.handle_executing(false);
            }
        }
        window.addEventListener('storage', storage_handler, false);
        this.debugLog('google_drive_api', false);
        this.google_drive_api_load();
    },
    remove_file:function(attribute) {
        this.debugLog('remove_file', true);
        var context = this;
        context.setState({resetFileUpload:true});
        context.on_field_change(attribute, false);
        attribute = context.state.entity_attributes.field_paths["drive_url"];
        context.on_field_change(attribute);
        context.on_file_name_change(attribute);
        context.setState({file_uploaded:false, fileToUpload: []});

        var forminput  = document.getElementsByClassName("forminput");
        document.getElementById("submit-button").style.display = "block";

        Object.keys(forminput).forEach(function(item) {
         forminput[item].style.display = "block";
        });

        this.debugLog('remove_file', false);
    },
    download_file:function(fileId){
        this.debugLog('download_file', true);
        var context = this, retval = null;
        if(context.state.entity_instance["drive_url"])
        {
            var error = function (data) {
            };
            if (context.state.downloadUrl != context.state.entity_instance["drive_url"]){
                context.setState({downloadUrl: context.state.entity_instance["drive_url"]});
            }
            retval = context.state.entity_instance["drive_url"].value;
        }
        else{
            var getFileCompleted = function (data) {
                if (context.state.downloadUrl != data.data.url){
                    context.setState({downloadUrl: data.data.url});
                }
            };
            var error = function (data) {
            };
            context.downloadFlag = true;
            invoke_method('file', 'get_file', {file_id: fileId}, getFileCompleted, error, function(){context.downloadFlag=false;context.forceUpdate()});
        }

        this.debugLog('download_file', false);
        return retval;
    },
    get_height: function(parentStyle){
        var isModal = this.props.isModal || this.props.parentIsModal || $(this).parents('.modal').length > 0;
        var retval = "auto", navbar = $('.navbar');
        if (parentStyle!="none"){
            var buttonBoxHeight = $('#workflow-button-box').height();
            var parentHeight = $(window).height() - (isModal?0:navbar.height());
            var headerHeight = 80;
            retval = parentHeight - buttonBoxHeight - headerHeight + "px";
        }
        return retval
    },
    hidden_parent_style: function(){
        this.debugLog('hidden_parent_style', true);
        var element = $(".widget-body[data-resize='resize_element']").last();
        element.css("height" , this.get_height('block'));
        this.debugLog('hidden_parent_style', false);
    },
    align_bottom: function (){
        var rows = $('.row'), maxHeight, thisHeight;
        rows.each(function(){
            maxHeight = 0;
            $(this).children().each(function(){
                thisHeight = $(this).height();
                if (maxHeight < thisHeight){
                    maxHeight = thisHeight;
                }
            });
            $(this).children(':not(.dx-popover)').each(function(){
                if($(this).hasClass('prevent_margin')){
                }else{
                    if($(this).parent().height() >= 90 ) {
                        $(this).css('margin-top', maxHeight - $(this).height());
                    }
                }
            });

        });
    },
    focus_function: function(limit, timeout){
        this.debugLog('focus_function', true);
        var context = this,
            isLookup = this.state.currentAttribute.lookup,
            element = isLookup?$("#info_"+(this.state.currentAttribute.safe_field_path || this.state.currentAttribute.field_path)).parent().find("#dxSelectBox").dxSelectBox('instance'):$("#info_"+(this.state.currentAttribute.safe_field_path || this.state.currentAttribute.field_path)).parent().find(".dx-texteditor-input");
        if(new Date() - timeout > limit) {
            this.debugLog('focus_function', false);
            return;
        }
        if (element && element.length && $(element[0]).is(':visible')) {
            if ($(element[0]).is(':focus')) {
                this.debugLog('focus_function', false);
                return;
            } else {
                var isIE = !!document.documentMode;
                if (isIE){
                    setTimeout(function(){
                        $(element[0]).focus();
                        $(element[0]).blur();
                    }, 1000);
                }else{
                    $(element[0]).focus();
                }
            }
        }
        else if(isLookup){
            if(element) {
                element.focus();
            }
            this.debugLog('focus_function', false);
            return;
        }
        setTimeout(function(){context.focus_function(limit, timeout?timeout:new Date());}, 200);
        this.debugLog('focus_function', false);
    },
    custom_post_render_logic: function () {
        this.debugLog('custom_post_render_logic', true);
        var context = this;
        $('.date-picker').datepicker();
        //ie error display
        var isIE = !!document.documentMode;
        if (isIE){
            $( ".dx-icon" ).click(function() {
                $( ".ie-warning" ).css("display","none");
            });
            $( ".ie-close" ).click(function() {
                $( ".ie-warning" ).css("display","none");
            });
            $( ".btn-primary" ).click(function() {
                if (context.state && context.state.error){
                    $( ".ie-warning" ).css("display","block");
                }
            });
        }
        //popup tooltips
        for (var i = 0; this.state.entity_attributes && i < this.state.entity_attributes.attributes.length; i++) {
            var attribute = this.state.entity_attributes.attributes[i];
            if (!attribute.form_visible) {
                continue;
            }

            var info_id = "#info_" + (attribute.safe_field_path || attribute.field_path.replace(/:/g, '-'));

            var field_path = info_id + ' + *';
            if($(info_id + '_desc').text()){
                var popover_desc = $(info_id + "_desc").dxPopover({
                    target: info_id,
                    position: "top",
                    width: 300
                }).dxPopover("instance");
                var popover_control = $(info_id + "_desc").dxPopover({
                    target: field_path,
                    position: "top",
                    width: 300
                }).dxPopover("instance");

                var appear = function (popover) {
                    return function () {
                        popover.show();
                    }
                };
                var disappear = function (popover) {
                    return function () {
                        popover.hide();
                    }
                };
                field_path += ':not(div), ' + field_path + ' *:not(button)';
                $(info_id).hover(appear(popover_desc), disappear(popover_desc));
                //$(field_path).focusin(appear(popover_control));
                $(field_path).focusout(disappear(popover_control));
            }
        }
        //error scroll to top
        if (this.state.error && !context.state.scrolled) {
            var element = context.get_form_object().find('.widget-body');
            element.scrollTop(0);
            context.setState({scrolled : true});
        }

        $('.fachange_history').removeClass('fachange_history').addClass( "fa fa-pencil-square-o" );
        $('.facomment').removeClass('fachange_history').addClass( "fa fa-comment-o" );
        $('.comment-footer[note_type!="comment"]').css("display" , "none");
        $('.faworkflow').removeClass('fachange_history').addClass( "fa fa-check-circle-o" );
        $('.fasystem_event').removeClass('fachange_history').addClass( "fa fa-asterisk" );

        //discard changes confirmation dialog
        window.onbeforeunload = function (evt) {
            if(context.props.run_on_user_closes_window) {
                context.props.run_on_user_closes_window();
            }
            if (context.state.modified) {
                var message = R.message_changes_discarded;
                if (typeof evt == 'undefined') {
                    evt = window.event;
                }
                if (evt) {
                    evt.returnValue = message;
                }
                return message;
            }
        };

        //multiselect object instantiation
        try{
            $('.rolesmultiple').each(function(){
                var element = $(this);
                element.multiselect({
                    enableHTML:true,
                    onChange: function(option, checked, select,value) {
                        var roles_attribute= $.parseJSON(element.attr('data-attribute'));
                        context.state.entity_instance[roles_attribute.field_path] = context.state.entity_instance[roles_attribute.field_path] || [];
                        var elementPos = context.state.entity_instance[roles_attribute.field_path].map(function(x) {return x; }).indexOf($(option).val());
                        if(elementPos == -1)
                        {
                            context.state.entity_instance[roles_attribute.field_path].push($(option).val());
                        }
                        if (elementPos != -1)
                        {
                            context.state.entity_instance[roles_attribute.field_path].splice(elementPos, 1);
                        }
                        context.on_field_both(roles_attribute,context.state.entity_instance[roles_attribute.field_path]); // Blur on field value change, just to be more responsive to user input.
                    },
                    //onDropdownHide: function() {
                        //Could use this for an onBlur method, but decided to blur on field value change, just to be more responsive to user input.
                    //},
                    buttonTitle: function(options, select) {
                        return ;
                    }
                });
            });
        }
        catch(error){
        }

        // Detect when an option is new, call event on_field_change
        if( context.state.flagSaveDropdown==2 ){
            context.state.flagSaveDropdown=0;
            var currentAttribute = context.state.currentAttribute;
            if(currentAttribute.select_options || currentAttribute.dataSource){
                setTimeout(function(){ //timeout zero to have this run on a different thread and not stall main thread with polling loop.
                    currentAttribute=context.state.currentAttribute;
                    var newKey=context.state.newKey, newCaption=context.state.newCaption;
                    var optionToChange = null;
                    var done = function(){
                        currentAttribute.cached_rendered_component = null;
                        context.setState({newKey:''});
                        if(currentAttribute.db_type!='file'){
                            context.focus_function(4000);
                        }
                    };

                    if(currentAttribute.dataSource &&
                        "function" == typeof currentAttribute.dataSource.load &&
                        currentAttribute.db_type!="file" &&
                        newKey &&
                        (!get_json_data_using_field_paths(currentAttribute.field_path, context.state.entity_instance) ||
                        newKey != get_json_data_using_field_paths(currentAttribute.field_path, context.state.entity_instance).entity_id)){
                        if (newCaption) {
                            currentAttribute.dataSource.searchValue(newCaption);
                            currentAttribute.dataSource.searchExpr('caption');
                        }
                        currentAttribute.dataSource.load().done(function(data){
                            if (currentAttribute.select_options){
                                for (var i = 0, u = currentAttribute.select_options.length; i<u;i++){
                                    optionToChange = find_by_field('entity_id', currentAttribute.select_options[i].entity_id, data);
                                    if (!optionToChange){
                                        data.push(currentAttribute.select_options[i]);
                                    }
                                }
                            }

                            currentAttribute.select_options = data;
                            optionToChange = find_by_field('entity_id', newKey, data);
                            currentAttribute.custom_key_tag = create_uid(); //force redraw of this component
                            context.on_field_change(currentAttribute, optionToChange);
                            context.on_field_blur(currentAttribute);
                            done();
                            return data;
                        });
                    }
                    else {
                        if (newKey && get_json_data_using_field_paths(currentAttribute.field_path, context.state.entity_instance) && (newKey == get_json_data_using_field_paths(currentAttribute.field_path, context.state.entity_instance).entity_id)) {
                            if (newCaption!=get_json_data_using_field_paths(currentAttribute.field_path, context.state.entity_instance).caption){
                                if (newCaption) {
                                    currentAttribute.dataSource.searchValue(newCaption);
                                    currentAttribute.dataSource.searchExpr('caption');
                                }
                                currentAttribute.dataSource.load().done(function(data){
                                    get_json_data_using_field_paths(currentAttribute.field_path, context.state.entity_instance).caption = newCaption;
                                    if (currentAttribute.select_options){
                                        for (var i = 0, u = currentAttribute.select_options.length; i<u;i++){
                                            optionToChange = find_by_field('entity_id', currentAttribute.select_options[i].entity_id, data);
                                            if (!optionToChange){
                                                data.push(currentAttribute.select_options[i]);
                                            }
                                        }
                                    }
                                    currentAttribute.select_options = data;
                                    done();
                                    return data;
                                });
                            }
                        } else {
                            if(currentAttribute.dataSource){
                                currentAttribute.dataSource.load().done(function(){
                                    done();
                                })
                            }
                            done();
                        }
                    }
                },0)
            }
        }

        this.hidden_parent_style();

        this.align_bottom();

        if (this.recover_scroll){
            this.restoreScroll = true;
            this.restore_scroll_position();
        }

        this.debugLog('custom_post_render_logic', false);
    },
    componentWillUnmount: function () {
        this.debugLog('componentWillUnmount', true);
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('keyup', this.handleKeyUp);
        if (this.state.modified) {
            this.state.modified = false;
        }
        $('.dx-overlay-wrapper.prevent_margin.dx-popup-wrapper.dx-popover-wrapper.dx-popover-without-title').remove();
        $('.dx-overlay-wrapper.rowses.dx-popup-wrapper.dx-popover-wrapper.dx-popover-without-title').remove();
        this.debugLog('componentWillUnmount', false);
    },
    initialize_edit_form: function (new_entity_json, force_grid_refresh_on_close) {
        this.debugLog('initialize_edit_form', true);
        var context = this;
        var given_data = this.props.data;
        var is_new_instance = false;
        var success = function (data) {
            fix_response_data(data, data.data.entity_attributes.field_paths, data.data.entity_attributes.nested_list_entities);
            data = data.data;
            data = $.extend({}, given_data, data);
            data.is_new_instance = is_new_instance;
            if (is_new_instance) {
                context.populate_update_fields(data.entity_attributes, data.values);
            }
            if (data.app_object && data.app_object.workflow_states) {
                data.workflow_states = data.app_object.workflow_states;
                delete data.app_object.workflow_states;
            }
            if (data.values) {
                data.entity_instance = data.values;
                delete data.values;
            }
            data.entity_instance = data.entity_instance || {};
            var force_on_blur_for_linked_to = false;
            if(new_entity_json || context.props.new_entity_json) {
                var local_new_entity_json = new_entity_json || context.props.new_entity_json;
                if((Object.keys(local_new_entity_json).length === 1) && (local_new_entity_json.linked_to)) {
                    force_on_blur_for_linked_to = true;
                }
                $.extend(data.entity_instance, local_new_entity_json);
                data.update_fields = data.update_fields || {};
                $.extend(data.update_fields, local_new_entity_json);
            }
            var caption = (data.app_object.name && (data.app_object.name.trim() != "")) ? data.app_object.name : data.entity_attributes.caption_singular;
            if (data.entity_attributes.caption_fields && data.entity_attributes.caption_fields.length > 0) {
                data.caption_function = function (entity_data) {
                    return make_friendly_caption(data.entity_attributes.caption_fields, entity_data, data.entity_attributes.attributes);
                };
                if (!is_new_instance) {
                    caption = data.caption_function(data.entity_attributes.attributes);
                }
            }
            data.init = true;
            data.force_grid_refresh = force_grid_refresh_on_close;
            if(data.app_object.custom_properties && data.app_object.custom_properties.custom_html_template) {
                data.custom_html_template = data.app_object.custom_properties.custom_html_template;
            }
            context.setState(data);
            var _id;
            _id = request._id;
            _id = _id ? "&_id=" + _id : "";
            if (!data.parent_react_entity && !context.props.isModal && !context.props.skip_location_push) {
                push_href_location(caption + " - " + (R.client_application_title || "Xenforma"), "/edit_form?code=" + (data.app_object_code || data.app_object.code) + _id  );
            }

            if(context.state.entity_attributes && context.state.entity_attributes.custom_form_attributes_function)
            {
                context.on_field_blur("custom_form_attributes_function", context.state.entity_attributes.custom_form_attributes_function);
            }
            else if (force_on_blur_for_linked_to && is_new_instance) {
                data.entity_attributes.field_paths["linked_to"].modified = true;
                context.on_field_blur(data.entity_attributes.field_paths["linked_to"]);
            }

            context.hidden_parent_style();
            /* end to load data */
            setTimeout(function(){
                context.post_init();
            }, 1);
        };

        var error = function (err) {
            context.hidden_parent_style();
            context.setState({error: err.message});
            server_error_action(err);
        };

        if (given_data && !given_data.is_reference_nest) { //nested edit_form

            if (given_data.is_new_instance) {
                is_new_instance = true;
            }
            else {
                given_data.update_fields = {};
                given_data.init = true;
                setTimeout(function(){
                    context.post_init(given_data.entity_attributes);
                }, 1);
                if (given_data.entity_attributes.caption_fields && given_data.entity_attributes.caption_fields.length > 0) {
                    given_data.caption_function = function (entity_data) {
                        return make_friendly_caption(given_data.entity_attributes.caption_fields, entity_data, given_data.entity_attributes.attributes);
                    };
                }
                return given_data;
            }
        }
        else { //top-level edit_form
            current_navigation_listener = function (callback, optional_context_override) {
                if(optional_context_override) {
                    context = optional_context_override;
                }
                if (context.state.modified) {
                    return context.confirm(R.label_confirm, R.message_changes_discarded, function (confirmation) {
                        if (confirmation) {
                            return callback(true);
                        }
                        else {
                            return callback(false);
                        }
                    });
                }
                else {
                    return callback(true);
                }
            };
        }

        var request = {
            app_object_code: this.props.app_object_code || this.props.data.app_object_code || this.props.data.app_object.code,
            separate_values: true,
            duplicate_id:context.props.duplicate_id
        };
        if (!new_entity_json && this.props._id) {
            request._id = this.props._id;
        }
        else if (this.state && this.state.is_new_instance != undefined) {
            request._id = this.state._id;
            is_new_instance = this.state.is_new_instance;
        }
        else {
            is_new_instance = true;
        }

        /*Task/85652962 flag */
        var not_get_by_code;
        try {
            if( (typeof this.state.not_get_by_code != "undefined" ) ){
                not_get_by_code = this.state.not_get_by_code ;
            }
        }catch(err) {}
        if(!not_get_by_code){
            invoke_method("app_object", "get_by_code", request, success, error);
        }

        $(document).on('hidden.bs.modal', '#recurrencemodal', function (e) {
            $('body').addClass('modal-open');
            $(".modal input:first").focus();
            context.state.hidRecurrenceModal = true;
            setTimeout(function() {
                context.state.hidRecurrenceModal = false;
            }, 500);
        });

        this.debugLog('initialize_edit_form', false);
    },
    post_init: function (entity_attributes, override) {
        this.debugLog('post_init', true);

        var context = this;

        if (!entity_attributes) {
            entity_attributes = this.state.entity_attributes;
        }
        for (var i = 0; entity_attributes && i < entity_attributes.attributes.length; i++) {
            var attribute = entity_attributes.attributes[i];
            if(attribute.field_path == "assigned_to_user")
            {
                this.refresh_reference_grid(attribute);
            }
            if (!attribute.form_visible) {
                continue;
            }
            if (attribute.is_nested_entity && attribute.data_is_nested == false && attribute.is_array) {
                if (!context.state.custom_html_template) {
                    this.refresh_reference_grid(attribute);
                }
            }
            else if(attribute.lookup && !attribute.is_array){
                this.init_lookup(attribute);
            }
            else if(attribute.entity_reference_variant){
                this.init_variant(attribute);
            }
        }
        //show modal after everything is rendered
        $("#modal_form").css( "visibility", "visible" );
        $("#modal_form").css( "overflow", "hidden" );
        if(override || !context.props.duplicate_id){
            if(context.props.handle_executing) {
                context.props.handle_executing(false);
            }
        }
        if (context.state.is_new_instance) {
            context.focus_first_input();
        }
        this.debugLog('post_init', false);
    },
    refresh_reference_grid: function (attribute, callback, lookup_id) {
        this.debugLog('refresh_reference_grid', true);
        var context = this;
        var entity = attribute.db_type;
        var request = {};
        if (attribute.entity_reference && attribute.entity_reference.is_list){
            attribute.refresh_on_load = callback;
        }
        else{
            request.app_object_code = this.props.app_object_code || this.props.data.app_object_code || this.props.data.app_object.code;
            request.parent_entity_name = attribute.parent_entity_name;
            request.field_path = get_local_field_path_from_full_path(attribute.field_path);
            request.is_new = context.state.is_new_instance;
            if(lookup_id) {
                request.conditions = {
                    entity_id: lookup_id
                };
            }

            var success = function (attribute) {
                return function (data) {
                    fix_response_data(data);
                    data = data.data;
                    if (!attribute.select_options) {
                        attribute.select_options = [];
                    }
                    var optionToChange;
                    attribute.select_options = attribute.select_options.filter(function(a) { //somehow this gets non-object values in it
                        return a.entity_id;
                    });
                    for (var i = 0, u = attribute.select_options.length; i<u;i++){
                        optionToChange = find_by_field('entity_id', attribute.select_options[i].entity_id, data);
                        if (!optionToChange){
                            data.push(attribute.select_options[i]);
                        }
                    }
                    attribute.select_options = data;
                    if (['assigned_to_user'].indexOf(attribute.field_path != -1)){
                        context.forceUpdate();
                    }

                    if (callback) {
                        callback(data);
                    }
                }
            }(attribute);

            var error = function (error) {
                context.setState({error: error.message});
                server_error_action(error);
            };
            invoke_method(entity, "read_with_nested_permissions", request, success, error);
        }
        this.debugLog('refresh_reference_grid', false);
    },
    init_lookup: function (attribute) {
        this.debugLog('init_lookup', true);
        var context = this;
        var initial_value = get_json_data_using_field_paths(attribute.field_path, context.state.entity_instance);
        if (!attribute.select_options) {
            attribute.select_options = [];
        }
        if (initial_value) {
            attribute.select_options.push(initial_value);
        }

        //context.setState({init_lookup: true});
        this.debugLog('init_lookup', false);
    },
    init_variant: function (attribute) {
        this.debugLog('init_variant', true);
        var context = this;
        var initial_value = get_json_data_using_field_paths(attribute.field_path, context.state.entity_instance);
        context.state.init_variant = true;
        if (!attribute.select_options) {
            attribute.select_options = [];
        }
        if (initial_value){
            attribute.select_options.push(initial_value);
        }
        context.setState({init_variant: true});
        this.debugLog('init_variant', false);
    },
    variant_load:function(attribute){
        this.debugLog('variant_load', true);
        var context = this;
        if (attribute.dataSource){
            this.debugLog('variant_load', false);
            return attribute.dataSource;
        }
        var dataSource = new DevExpress.data.DataSource({
            paginate:true,
            load: function (loadOptions) {
                var d = new $.Deferred();
                var params = {};
                var context = this;
                //Getting filter options
                if (loadOptions.filter)  {
                    params.filter = JSON.stringify(loadOptions.filter);
                }
                //Getting sort options
                if (loadOptions.sort)  {
                    params.sort = JSON.stringify(loadOptions.sort);
                }
                //Getting group options
                if (loadOptions.group)  {
                    params.group = JSON.stringify(loadOptions.group);
                }
                //skip and take are used for paging
                params.skip = loadOptions.skip; //A number of records that should be skipped
                params.take = loadOptions.take; //A number of records that should be taken

                //If the select expression is specified
                if (loadOptions.select)  {
                    params.select= JSON.stringify(loadOptions.select);
                }

                //If a user typed something in dxAutocomplete, dxSelectBox or dxLookup

                if (loadOptions.searchValue)  {
                    params.searchValue=loadOptions.searchValue;
                    params.searchOperation= loadOptions.searchOperation;
                    params.searchExpr= loadOptions.searchExpr;
                }

                var request = {};
                request.data={};
                request.data.entity_reference_category = attribute.entity_reference_variant.entity_reference_category;
                request.data.skip = loadOptions.skip; //A number of records that should be skipped
                request.data.limit = loadOptions.take; //A number of records that should be taken
                if(loadOptions.searchValue) {
                    request.data.conditions={};
                    request.data.conditions.$regex = loadOptions.searchValue;
                    request.data.conditions.$options = "i";
                }
                var success = function (attribute) {
                    return function (data) {
                        fix_response_data(data);
                        data = data.data;
                        context[attribute.field_path+"array"] = data;
                        d.resolve(data);
                    }
                }(attribute);

                var error = function (error) {
                    context.setState({error: error.message});
                    server_error_action(error);
                    d.fail(error);
                };

                if (!context.isLoading){
                    context.isLoading = true;
                    do_authenticated_http_call({
                        method: "POST",
                        url: "/api/entity/variant_reference_entity_lookup",
                        contentType: "application/json",
                        dataType: 'json',
                        data: JSON.stringify(request),
                        success: success,
                        error: error,
                        complete: function(){context.isLoading = false;}
                    });
                }
                return d.promise();
            },
            byKey: function (key){
                var retval;
                if (key){
                    var items = attribute.dataSource.items();
                    retval = find_by_field('entity_id',key, items);
                    if (!retval){ retval = find_by_field('entity_id',key, attribute.select_options);}
                }
                if (!retval){
                    var data_value = get_json_data_using_field_paths(attribute.field_path, context.state.entity_instance);
                    if (!key){
                        retval = data_value;
                    }else{
                        if ((data_value &&
                            key == data_value.entity_id)){
                            retval = data_value;
                        }
                    }
                }
                return retval;
            }
        });
        attribute.dataSource = dataSource;
        this.debugLog('variant_load', false);
        return dataSource
    },
    autocomplete_load:function(attribute){
        this.debugLog('autocomplete_load', true);
        var context = this;
        if (attribute.dataSource){
            this.debugLog('autocomplete_load', false);
            return attribute.dataSource;
        }
        var dataSource = new DevExpress.data.DataSource({
            paginate:true,
            load: function (loadOptions) {
                var d = new $.Deferred();
                var params = {};
                //Getting filter options
                if (loadOptions.filter)  {
                    params.filter = JSON.stringify(loadOptions.filter);
                }
                //Getting sort options
                if (loadOptions.sort)  {
                    params.sort = JSON.stringify(loadOptions.sort);
                }
                //Getting group options
                if (loadOptions.group)  {
                    params.group = JSON.stringify(loadOptions.group);
                }
                //skip and take are used for paging
                params.skip = loadOptions.skip; //A number of records that should be skipped
                params.take = loadOptions.take; //A number of records that should be taken

                //If the select expression is specified
                if (loadOptions.select)  {
                    params.select= JSON.stringify(loadOptions.select);
                }

                //If a user typed something in dxAutocomplete, dxSelectBox or dxLookup


                params = [];
                var request = {}
                request.data={};
                request.data.entity = context.state.entity_attributes.entity;
                request.data.field_path = attribute.field_path;
                request.data.skip = loadOptions.skip; //A number of records that should be skipped
                request.data.limit = loadOptions.take; //A number of records that should be taken
                if(loadOptions.searchValue) {
                    request.data.conditions={};
                    request.data.conditions.$regex = loadOptions.searchValue;
                    request.data.conditions.$options = "i";
                    params.push(loadOptions.searchExpr);
                    params.push(loadOptions.searchOperation);
                    params.push(loadOptions.searchValue);

                    // request.data.conditions = make_conditions_from_devextreme_filter(params);
                }
                var success = function (attribute) {
                    return function (data) {
                        fix_response_data(data);
                        data = data.data;
                        d.resolve(data);
                    }
                }(attribute);

                var error = function (error) {
                    context.setState({error: error.message});
                    server_error_action(error);
                };

                do_authenticated_http_call({
                    method: "POST",
                    url: "/api/entity/autocomplete_lookup",
                    contentType: "application/json",
                    dataType: 'json',
                    data: JSON.stringify(request),
                    success: success,
                    error: error
                });
                return d.promise();
            },
            byKey: function (key){
                var retval;
                if (key){
                    var items = attribute.dataSource.items();
                    retval = find_by_field('entity_id',key, items);
                    if (!retval){ retval = find_by_field('entity_id',key, attribute.select_options);}
                }
                if (!retval){
                    var data_value = get_json_data_using_field_paths(attribute.field_path, context.state.entity_instance);
                    if (!key){
                        retval = data_value;
                    }else{
                        if ((data_value &&
                            key == data_value.entity_id)){
                            retval = data_value;
                        }
                    }
                }
                return retval;
            }
        });
        attribute.dataSource = dataSource;
        this.debugLog('autocomplete_load', false);
        return dataSource
    },
    lookup_load:function(attribute) {
        this.debugLog('lookup_load', true);
        var context = this;
        if (attribute.dataSource){
            this.debugLog('lookup_load', false);
            return attribute.dataSource;
        }
        var entity = attribute.db_type;
        var dataSource = new DevExpress.data.DataSource({
            paginate:true,
            load: function (loadOptions) {
                var d = new $.Deferred();
                var ds = attribute.dataSource;
                if (ds.isLoadingFlag || (attribute.lookup && !loadOptions.searchValue && !attribute.is_array)) {
                    d.reject(new Error("DataSource is busy..."))
                }
                else {
                    ds.isLoadingFlag = true;
                    var params = [];
                    var request = {};
                    request.app_object_code = context.props.app_object_code || context.props.data.app_object_code || context.props.data.app_object.code;
                    request.parent_entity_name = attribute.parent_entity_name;
                    request.field_path = attribute.local_field_path || attribute.field_path;
                    request.is_new = context.state.is_new_instance;
                    request.skip = loadOptions.skip; //A number of records that should be skipped
                    request.limit = loadOptions.take; //A number of records that should be taken
                    if (loadOptions.searchValue && !(attribute.is_array && !attribute.lookup)) {
                        //If a user typed something in dxAutocomplete, dxSelectBox or dxLookup
                        params.push(loadOptions.searchExpr);
                        params.push(loadOptions.searchOperation);
                        params.push(loadOptions.searchValue);
                        request.conditions = make_conditions_from_devextreme_filter(params);
                    }
                    var success = function (attribute) {
                        return function (data) {
                            fix_response_data(data);
                            data = data.data;

                            var optionToChange;
                            if(!attribute.select_options) {
                                attribute.select_options = [];
                            }
                            /*merge existing records with incoming*/
                                for (var i = 0, u = data.length; i<u;i++){
                                    optionToChange = find_by_field('entity_id', data[i].entity_id, attribute.select_options);
                                    if (!optionToChange){
                                        attribute.select_options.push(data[i]);
                                    }
                                }

                            try {
                                d.resolve(data);
                            }
                            catch(err) {

                            }
                        }
                    }(attribute);

                    var error = function (error) {
                        context.setState({error: error.message});
                        server_error_action(error);

                    };
                    invoke_method(entity, "read_with_nested_permissions", request, success, error, function(){
                        ds.isLoadingFlag = false;
                    });
                }
                return d.promise();
            },
            byKey: function (key){
                var retval;
                if (key){
                    var items = attribute.dataSource.items();
                    retval = find_by_field('entity_id',key, items);
                    if (!retval){ retval = find_by_field('entity_id',key, attribute.select_options);}
                }
                if (!retval){
                    var data_value = get_json_data_using_field_paths(attribute.field_path, context.state.entity_instance);
                    if (!key){
                        retval = data_value;
                    }else{
                        if ((data_value &&
                            key == data_value.entity_id)){
                            retval = data_value;
                        }
                    }
                }
                return retval;
            },
            loadError: function (key){
                console.log(key);
            }
        });
        attribute.dataSource = dataSource;
        this.debugLog('lookup_load', false);
        return dataSource;
    },
    populate_update_fields: function (entity_attributes, entity_instance_data, out_val) {
        this.debugLog('populate_update_fields', true);
        if (!out_val) {
            out_val = this.state.update_fields || {};
        }

        for (var i = 0; i < entity_attributes.attributes.length; i++) {
            var attribute = entity_attributes.attributes[i];
            if (attribute.form_visible) {
                var attribute_value;

                attribute_value = entity_instance_data[attribute.field_path];

                //if(attribute_value && (typeof attribute_value == "object")) {
                //    out_val[attribute.field_path] = ($.extend(true, {}, { value: attribute_value })).value;
                //}
                //else {
                out_val[attribute.field_path] = attribute_value;
                //}
            }
        }
        this.debugLog('populate_update_fields', false);
        return out_val;
    },
    on_file_name_change: function (attribute, file, fileLength ) {
        this.debugLog('on_file_name_change', true);
        this.state.xenforma_file_attachments = [];
        if (file) {
            var file_dot_index = file.name.lastIndexOf(".");
            var file_temp_name = file.name.substring(0,file_dot_index).replace(" ","_").replace(/[^a-zA-Z0-9_]/g,"") || "default_file_name";
            var file_extension = file.name.substring(file_dot_index + 1, file.name.length);
            var file_name = file_temp_name + "." + file_extension; 
            Object.defineProperty(file, 'name', {
                writable: true,
                value: file_name
            });
            if (this.state.multiple && fileLength > 1) {
                var object_file = {
                    description: '',
                    file_name: file_name,
                    uploaded: false
                };
                this.state.update_multiple_field = this.state.update_multiple_field.concat(object_file);
                this.state.name_multiple_field = this.state.name_multiple_field.concat(object_file);
            } else {
                this.state.update_multiple_field = [];
                this.state.name_multiple_field = [];
            }
            this.state.update_fields['file_name'] = file_name;
            this.state.entity_instance['file_name'] = file_name;
        }
        else {
            this.state.update_fields['file_name'] = null;
            this.state.entity_instance['file_name'] = null;
        }
        if(attribute.field_path != "drive_url") {
            var fileToUpload = this.state.fileToUpload ? this.state.fileToUpload : [];
            this.setState({
                fileToUpload: fileToUpload.concat(file),
                file_uploaded:true,
                error:undefined
            });
        }
        this.debugLog('on_file_name_change', false);
    },
    on_file_upload_end: function () {
        var context = this;
        this.debugLog('on_file_upload_end', true);
        /*End to upload file */
        /* 84230669/add this file to function uploadFiles_array*/
        var fileToUpload = this.state.fileToUpload;

        if (this.state.multiple) {
            if(fileToUpload) {
                if(this.state.update_multiple_field.length > 0){
                this.state.update_multiple_field.map(function(file) {
                    context.state.update_fields = file;
                    if( (typeof context.state.uploadFiles_array != 'undefined' ) ) {
                        var instance_id = fileToUpload.find(function(element) { return element.name === file.file_name;})._id;
                        file._id = instance_id;
                        file.description = context.state.fileToUpload.find(function(x) { return x._id == file._id}).description;
                        context.state.uploadFiles_array(instance_id , context.state.checkbox_fileupload );
                    }
    
                    context.update_fields_before_save(context.state.multiple, file, instance_id);
    
                });
            } else {
                    if( (typeof this.state.uploadFiles_array != 'undefined' ) ){
                        this.state.uploadFiles_array(this.state.entity_instance._id, this.state.checkbox_fileupload);
                    }
                    this.update_fields_before_save();
                    this.forceUpdate();
                }
            }
        } else {
            if( (typeof this.state.uploadFiles_array != 'undefined' ) ){
                this.state.uploadFiles_array(this.state.entity_instance._id, this.state.checkbox_fileupload);
            }
            this.update_fields_before_save();
            this.forceUpdate();
        }
        
        this.state.fileToUpload = undefined;
        this.debugLog('on_file_upload_end', false);
    },
    update_fields_before_save: function(multiple, file) {

        this.state.update_fields['date_uploaded'] = new Date();
        this.state.entity_instance['date_uploaded'] = new Date();
        this.state.update_fields['uploaded'] = true;
        this.state.entity_instance['uploaded'] = true;
        this.state.uploadFileModal = false;
        this.state.areButtonsDisabled = false;
        this.state.resetFileUpload = false;
        this.handle_submit();
    },
    on_file_upload_cancel: function (event) {
        this.debugLog('on_file_upload_cancel', true);
        this.setState({uploadFileModal: false, areButtonsDisabled: false});
        this.debugLog('on_file_upload_cancel', false);
    },
    set_focus_on_element_by_field_path: function(element_field_path) {
        if(element_field_path && (element_field_path != "")) {
            if (this.state.entity_attributes && this.state.entity_attributes.field_paths && this.state.entity_attributes.field_paths[element_field_path]) {
                $("#" + this.state.entity_attributes.field_paths[element_field_path].safe_field_path).focus();
            }
            else {
                $("#" + element_field_path.replace(/\[|]|:/g, "")).focus();
            }
        }
    },
    set_focus_on_element_by_section: function(section_number) {
        if(section_number > 999) {
            section_number = 999;
        }
        var form = this.get_form_object();
        if(form) {
            var goto_section = form.find("[data-section-number='" + section_number + "']").filter(function(i, element) {
                //filter down to only sections which have valid selectable (focusable) controls
                var num_valid_local_elements = $(this).find('input, select, textarea, button:visible').not('[readonly]').not('[disabled]').length;
                return (num_valid_local_elements > 0);
            });
            if(!goto_section.length) {
                var goto_section_arr = form.find("[data-section-number]");
                if(!goto_section_arr.length) {
                    return;
                }
                goto_section_arr = goto_section_arr.filter(function(i, element) {
                    //filter down to only sections which have valid selectable (focusable) controls
                    var num_valid_local_elements = $(this).find('input, select, textarea, button:visible').not('[readonly]').not('[disabled]').length;
                    return (num_valid_local_elements > 0);
                });
                if(!goto_section_arr.length) {
                    return;
                }
                goto_section_arr.sort(function(a, b) {
                    a = parseInt($(a).attr("data-section-number"), 10);
                    b = parseInt($(b).attr("data-section-number"), 10);
                    if(a > b) {
                        return 1;
                    } else if(a < b) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                goto_section = goto_section_arr.filter(function(i, element) {
                    var section_index = parseInt($(this).attr("data-section-number"), 10);
                    if(isNaN(section_index)) {
                        return false;
                    }
                    return (section_index > section_number);
                }).first();
            }
            if (goto_section) {
                var section_index = parseInt(goto_section.attr("data-section-number"), 10);
                var valid_focus_elements;
                var num_options;
                var element;
                if(section_index == 999) {
                    valid_focus_elements = goto_section.find('button:visible');
                    num_options = valid_focus_elements.length;
                    if(num_options) {
                        element = valid_focus_elements[num_options-1]; //Skip to the last button, which is usually the Save button.
                    }
                }
                else {
                    valid_focus_elements = goto_section.find('input, select, textarea, button').not('[readonly]').not('[disabled]');
                    num_options = valid_focus_elements.length;
                }
                if(!element && num_options) {
                    element = valid_focus_elements.first();
                }
                if(element) {
                    element.focus();
                }
            }
        }
    },
    on_field_mouse_down: function(attribute, event) {
        attribute.mouse_down = true;
    },
    on_field_click: function(attribute, event) {
        if ( attribute.mouse_down && attribute.modified_after_click ) {
            attribute.mouse_down = false;
            this.on_field_blur(attribute, attribute.last_change_event, true);
        }
    },
    on_field_key_down: function(attribute, event) {
        attribute.mouse_down = false;
        var keyCode = event.keyCode ? event.keyCode : event.which;

        // 9: Tab, 13: Enter, 32: Space
        if(attribute.skip_next_blur === undefined) {
            attribute.skip_next_blur = ((keyCode != 9) && (keyCode != 13) && (keyCode != 32)); //13 and 32 open the selection menu
        }
        else {
            attribute.skip_next_blur = ((keyCode != 9) && (keyCode != 13));
        }
    },
    on_field_both: function (attribute, event) {
        this.debugLog('on_field_both', true);
        this.on_field_change(attribute, event);
        if (attribute.skip_next_blur) {
            delete attribute.skip_next_blur;
        }
        else {
            this.on_field_blur(attribute, event, true);
        }
        this.debugLog('on_field_both', false);
    },
    set_object_image: function(attribute, obj, imageLenght, caption_organization) {
        this.state.isImage = true;
        this.state.multiple = true;
        this.state.caption_organization = caption_organization;
        
        var findObj = this.state.update_multiple_field.find(function(item){
            return item.index == obj.index;
        });
        if (!findObj) {
            this.state.update_multiple_field.push(obj);
        }

        if (imageLenght === this.state.update_multiple_field.length) {
            this.state.error = null;
            if (this.state.entity_attributes.attributes && this.state.entity_attributes.field_paths.item_no) {
                this.state.entity_attributes.field_paths.item_no.required = false;
                attribute.required = false;
            }
            this.handle_submit();
        }
    },
    on_field_change: function (attribute, event) {
        this.debugLog('on_field_change', true);
        attribute.was_clicked_once = true;
        attribute.cached_rendered_component = null;
        attribute.last_react_key = null;
        attribute.last_change_event = event;
        attribute.modified_after_click = !!attribute.mouse_down;

        if(this.state.entity_instance.xenforma_file_attachments && this.state.newKey) {
            this.state.entity_instance.xenforma_file_attachments.push(this.state.newKey);
        }

        if(attribute.dependant_field) {
            if(this.state.entity_attributes && this.state.entity_attributes.field_paths && this.state.entity_attributes.field_paths[attribute.dependant_field]) {
                this.state.entity_attributes.field_paths[attribute.dependant_field].cached_rendered_component = null;
                delete this.state.entity_instance[attribute.dependant_field];
                delete this.state.update_fields[attribute.dependant_field];
            }
        }

        var isReferenced = attribute.is_nested_entity && !attribute.data_is_nested && !attribute.is_array,
            previousValue = get_json_data_using_field_paths(attribute.field_path, this.state.entity_instance),
            bothEmpty = !previousValue && !event,
            invalidate_html_template_cache = false;

        if (isReferenced && (bothEmpty || (previousValue && event && previousValue.entity_id && event.entity_id && (previousValue.entity_id == event.entity_id)))){
            this.debugLog('on_field_change', false);
            return
        }
        var previous_state = this.state.modified;
        if (!this.state.modified) {
            this.state.modified = true
        }
        this.state.error = undefined;
        if (attribute) {
            var new_value;
            var type = attribute.attribute_type == null ? attribute.db_type : attribute.attribute_type;
            if (event == null) {
                new_value = event; // event is data
            }
            else if (event.target) {
                switch (type) {
                    case "Boolean":
                        if(event.target.type=="button") {
                            $('#'+event.target.id.slice(0, -1)).find("button").addClass("btn-primary");
                            $('#'+event.target.id).removeClass("btn-primary");

                            if(event.target.innerText=="True" || event.target.innerText=="Yes") {
                                new_value = true;
                            }
                            else if(event.target.innerText=="False" || event.target.innerText=="No"){
                                new_value = false;
                            }
                            else{
                                new_value = null;
                            }
                        }
                        else{
                            new_value = event.target.checked;
                        }
                        break;
                    case "CheckBox":
                        var cb = event.target;
                        new_value = !!cb.checked;
                        break;
                    case "Number":
                        if (!event.target.value.match(/^-?\d*(\.\d*)?$/g)) {
                            new_value = event.target.value.substr(0, event.target.value.length - 1);
                            if (!new_value.match(/^-?\d*(\.\d*)?$/g)) {
                                new_value = '';
                            }
                            this.state.modified = previous_state;
                        }
                        else
                            new_value = event.target.value;
                        break;
                    case "whole_number":

                        new_value = event.target.value;
                        if( isNaN(event.target.value) ||  (this.getType(event.target.value)=='float' || event.target.value.indexOf('.')!=-1)  ){
                            var element_substr=event.target.value.substr(0, event.target.value.length - 1);
                            new_value = isNaN(event.target.value) ? element_substr :  Math.round(element_substr);
                            if(this.getType(event.target.value) != 'string') {
                                this.state.modified = previous_state;
                            }
                        }
                        if(this.getType(event.target.value)=='string') new_value='';
                        new_value = $.trim(new_value);
                        if(isNaN(new_value))  new_value=0;
                        break;
                    case "positive_number":
                        if (!event.target.value.match(/^\d*(\.\d*)?$/g)) {
                            new_value = event.target.value.substr(0, event.target.value.length - 1);
                            if (!new_value.match(/^\d*(\.\d*)?$/g)) {
                                new_value = '';
                            }
                            this.state.modified = previous_state;
                        }
                        else
                            new_value = event.target.value;
                        break;
                    case "currency":
                        if (!event.target.value.match(/^-?\d*(\.\d*)?$/g)) {
                            new_value = event.target.value.substr(0, event.target.value.length - 1);
                            if (!new_value.match(/^-?\d*(\.\d*)?$/g)) {
                                new_value = '';
                            }
                            this.state.modified = previous_state;
                        }
                        else{
                            new_value = event.target.value;
                        }
                        break;
                    default:
                        new_value = event.target.value;
                        break;
                }
            }
            else if (event.component) { // Data coming from entity reference lookup
                new_value = event.component._valuesData; // Data is coming from DevExtreme component
            }
            else {
                new_value = event; // event is data
            }
            var update_fields_value = get_json_data_using_field_paths(attribute.field_path, this.state.update_fields);
            if(attribute.data_is_nested && update_fields_value != undefined) {
                if (attribute.is_nested_entity == false) {
                    invalidate_html_template_cache = set_json_data_using_field_paths(attribute.field_path, this.state.entity_instance, new_value, true, true, this.state.update_fields, this.state.is_new_instance);
                }
                else {
                    if (this.state.is_new_instance) {
                        invalidate_html_template_cache = set_json_data_using_field_paths(attribute.field_path, this.state.entity_instance, new_value, true, true, this.state.update_fields, this.state.is_new_instance);
                    }
                    else if (Array.isArray(update_fields_value)) {
                        if (update_fields_value.length) { //for adding new vlues:  The newest added value is generally at the top of the list
                            var entity_id;
                            if(new_value && new_value[0] && new_value[0]._id) {
                                entity_id = new_value[0]._id;
                            }
                            if(find_index_by_id(update_fields_value, entity_id) == null) {
                                update_fields_value.push(new_value[0]);
                            }
                        }
                        else {
                            add_to_list(update_fields_value, new_value);
                        }
                    }
                }
                if (attribute.field_path == "public" &&
                    this.props && this.props.data && this.props.data.app_object_code != undefined &&
                    this.props.data.app_object_code == "file_default_edit_form") {
                    this.setState({checkbox_fileupload: new_value});
                }
            }
            else{
                if(!attribute.data_is_nested) {
                    if (!Array.isArray(new_value) && !attribute.is_array) {
                        if (new_value && (new_value.entity_id == '')) {
                            new_value = null;
                        }
                        if(new_value) {
                            var do_else_lookup = true;
                            var entity_id = new_value.entity_id || new_value;
                            if (attribute.select_options && attribute.select_options.length) {
                                var temp = general_utils.first_where(attribute.select_options, function (a) {
                                    return (a && (a.entity_id == entity_id));
                                });
                                if (temp) {
                                    new_value = temp;
                                    do_else_lookup = false;
                                    temp = null;
                                }
                            }
                            if (do_else_lookup && attribute.select_options && attribute.select_options.length) {
                                new_value = general_utils.first_where(attribute.select_options, function (a) {
                                        return (a && (a.entity_id == entity_id));
                                    }) || new_value;
                                attribute.select_options = new_value;
                            }
                        }
                    }
                    else {
                        //set each value caption?
                    }

                    if(attribute.is_array) {
                        if(Array.isArray(new_value)) {
                            invalidate_html_template_cache = set_json_data_using_field_paths(attribute.field_path, this.state.entity_instance, new_value, true, true, this.state.update_fields, this.state.is_new_instance);
                        }
                    }
                    else if (Array.isArray(new_value)) {
                        if (attribute.db_type == 'file') {
                            if (this.state.popover) {
                                this.state.popover.hide();
                            }
                            if (attribute.loading) {
                                if (this.state.popover_loading) {
                                    this.state.popover_loading.hide();
                                }
                                attribute.cancel_loading = true;
                            }
                        }
                        invalidate_html_template_cache = set_json_data_using_field_paths(attribute.field_path, this.state.entity_instance, new_value, true, true, this.state.update_fields, this.state.is_new_instance);
                    }
                    else {
                        invalidate_html_template_cache = set_json_data_using_field_paths(attribute.field_path, this.state.entity_instance, new_value, true, true, this.state.update_fields, this.state.is_new_instance);
                        if(attribute.field_path == "assigned_to_user") {
                            this.set_user_assigned_select_value(new_value);
                        }
                    }
                }
                else if(!attribute.is_nested_entity) {
                    invalidate_html_template_cache = set_json_data_using_field_paths(attribute.field_path, this.state.entity_instance, new_value, true, true, this.state.update_fields, this.state.is_new_instance);
                }
            }
            if (attribute.revalidate_on_field_changed) {
                attribute.modified = true;
            }
        }
        if(invalidate_html_template_cache && this.state.entity_attributes) {
            this.state.entity_attributes.cached_custom_html_args = null;
            if (this.state.custom_html_template && ((new_value == null) || (new_value == ""))) { //a nested field was removed by this field change - run the execute_business_rule function for that whole field
                var parent_field_attribute;
                if (this.state.entity_attributes.field_paths && (attribute.field_path.indexOf(":") != -1)) {
                    var parent_attribute_field_path = attribute.field_path.substring(0, attribute.field_path.indexOf(":"));
                    parent_field_attribute = this.state.entity_attributes.field_paths[parent_attribute_field_path];
                    if (parent_field_attribute) {
                        parent_field_attribute.modified = true;
                    }
                }
                this.on_field_blur(parent_field_attribute || attribute, event);
            }
        }
        this.forceUpdate();
        this.debugLog('on_field_change', false);
    },
    on_field_blur: function (attribute, event, onChangeAndBlur) {
        var context = this;
        context.debugLog('on_field_blur', true);
        context.blur_active = true;

        if ( !onChangeAndBlur && attribute.skip_next_blur ) {
            delete attribute.skip_next_blur;
            context.blur_active = false;
            context.debugLog('on_field_blur', false);
            return;
        } else if ( onChangeAndBlur ) {
            attribute.skip_next_blur = true;
        }

        var nested_entity_name = attribute.nested_entity_name;
        var custom_attribute = "";
        var attribute_value;
        var entity_name = this.state.entity_attributes.entity;
        var field_paths = context.state.entity_attributes.field_paths;
        var request_boundary_check = context.state.request_boundary_check;
        attribute.cached_rendered_component = null;
        attribute.modified_after_click = false;

        if ((attribute.revalidate_on_field_changed && attribute.modified) || attribute == "custom_form_attributes_function" ) {
            if (attribute != "custom_form_attributes_function" ) {
                delete attribute.modified;
                attribute_value = get_json_data_using_field_paths(attribute.field_path, this.state.entity_instance);

                if (attribute.attribute_type === "email" && attribute_value) {
                    if (!isEmailAttributeValid(attribute_value)) {
                        this.setState({error: R.invalid_email});
                    }
                }
            }
            else {
                custom_attribute = attribute;
                attribute = {};
            }

            if (!this.state.error && !this.state.submitting && !attribute.validating){
                attribute.validating = true;

                var request = {};
                request.field_path = attribute.field_path;
                request.entity_instance = {};

                if (custom_attribute != "custom_form_attributes_function" ) {
                    if (attribute.validation_requires_full_entity) {
                        request.entity_instance = $.extend(true, {}, this.state.entity_instance);
                        context.handle_deleted_records(request.entity_instance, true);
                        if (this.state.parent_react_entity
                            && this.state.parent_entity_field_attribute
                            && this.state.parent_entity_field_attribute.data_is_nested) {
                            request.parent_entity = this.get_top_parent_entity();
                        }
                    }
                    else {
                        if (nested_entity_name) {
                            entity_name = nested_entity_name;
                            request.entity_instance[attribute.local_field_path] = attribute_value;
                            request.field_path = attribute.local_field_path;
                            var index_break = attribute.field_path.lastIndexOf(attribute.local_field_path);
                            if(index_break != -1 && context.state.entity_attributes.nested_list_entities[entity_name]) {
                                request.base_field_path = attribute.field_path.substring(0, index_break);
                                field_paths = context.state.entity_attributes.nested_list_entities[entity_name].field_paths || field_paths;
                            }
                        }
                        else {
                            request.entity_instance[attribute.field_path] = attribute_value;
                        }
                    }
                }

                // handling percentage fields to format them correctly
                if(attribute && (attribute.attribute_type === 'percent') || (request.entity_instance["probability"])) {
                    context.formatPercentageField(request, attribute);
                }

                var success = function (data) {
                    if (context.state.request_boundary_check != request_boundary_check) {
                        return;
                    }

                    var invalidate_html_template_cache = false;

                    fix_response_data(data, context.state.entity_attributes.field_paths, context.state.entity_attributes.nested_list_entities);

                    if (data.success) {
                        var updated_view_data = data.updated_view_data;
                        var entity_attributes = context.state.entity_attributes;
                        var modify_state = false;

                        if (updated_view_data
                            && updated_view_data._id
                            && updated_view_data._id.value
                            && (updated_view_data._id.value == context.state.entity_instance._id)) { //indicates that these fields are intended for this entity

                            for (var field_path in updated_view_data) {
                                if (field_path != "_id") {
                                    var entity_attribute_update = updated_view_data[field_path];
                                    var entity_attribute = get_attribute_for_field(entity_attributes, field_path);
                                    var entity_attribute_from_field_path = entity_attributes.field_paths[field_path]; //These currently fail to find the attribute if the attribute is a nested_list_entities attribute

                                    for (var field_attribute in entity_attribute_update) {
                                        if (field_attribute == "value") {
                                            var matching_attribute = entity_attribute || entity_attribute_from_field_path || {};
                                            if (matching_attribute.is_array
                                                && matching_attribute.is_nested_entity
                                                && matching_attribute.data_is_nested
                                                && !context.state.is_new_instance) {
                                                //Don't set to update_fields because this could erase changes we need to keep (i.e. removals)
                                            }
                                            else {
                                                invalidate_html_template_cache = set_json_data_using_field_paths(field_path, context.state.update_fields, entity_attribute_update[field_attribute], true) || invalidate_html_template_cache;
                                            }
                                            invalidate_html_template_cache = set_json_data_using_field_paths(field_path, context.state.entity_instance, entity_attribute_update[field_attribute], true, true, null, context.state.is_new_instance) || invalidate_html_template_cache;
                                            modify_state = true;
                                            if (entity_attribute) {
                                                entity_attribute.cached_rendered_component = null;
                                                entity_attribute.last_react_key = null;
                                            }
                                            if (entity_attribute_from_field_path) { //Unfortunately, at the moment, entity attributes stored in .field_paths are Not a shared reference with those in .attributes
                                                entity_attribute_from_field_path.cached_rendered_component = null;
                                                entity_attribute_from_field_path.last_react_key = null;
                                            }
                                            if ((entity_attribute || entity_attribute_from_field_path)
                                                && (entity_attribute || entity_attribute_from_field_path).update_grid) {
                                                (entity_attribute || entity_attribute_from_field_path).update_grid();
                                            }
                                        }
                                        else if (field_attribute == "errors"
                                            && entity_attribute_update.errors
                                            && entity_attribute_update.errors.length > 0) {

                                            context.state.error = entity_attribute_update.errors.join(' || ');
                                            if ("object" === typeof context.state.error) {
                                                context.state.error = "Database Error";
                                            }
                                            modify_state = true;
                                        }
                                        else {
                                            if (entity_attribute) {
                                                entity_attribute.cached_rendered_component = null;
                                                entity_attribute[field_attribute] = entity_attribute_update[field_attribute];
                                                modify_state = true;
                                            }
                                            if (entity_attribute_from_field_path) { //Unfortunately, at the moment, entity attributes stored in .field_paths are Not a shared reference with those in .attributes
                                                entity_attribute_from_field_path.cached_rendered_component = null;
                                                entity_attribute_from_field_path[field_attribute] = entity_attribute_update[field_attribute];
                                                modify_state = true;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if (modify_state) {
                            if (invalidate_html_template_cache && context.state.entity_attributes) {
                                context.state.entity_attributes.cached_custom_html_args = null;
                            }
                            context.forceUpdate();
                        }
                    }
                };
                var error = function (err) {
                    context.setState({error: err.message});
                    server_error_action(err);
                };
                var complete = function() {
                    attribute.validating = false;
                    context.blur_active = false;
                    if (context.pending_submit) {
                        context.pending_submit();
                    }
                };

                var final_request = $.extend(true, {}, request);

                if (custom_attribute != "custom_form_attributes_function") {
                    var nested_list_entities = context.state.entity_attributes.nested_list_entities;
                    fix_request_data(final_request, field_paths, nested_list_entities);
                }
                else {
                    final_request.entity_instance =context.state.entity_instance;
                    final_request.custom_form_attributes_function = event;
                    final_request.parent_entity = {};
                    var parent_state = context.props.data.parent_react_entity.state;
                    final_request.parent_entity._id = parent_state.entity_instance._id;
                    final_request.parent_entity.entity_name = parent_state.app_object.entity;
                    final_request.parent_entity.field_path = context.props.data.parent_entity_field_attribute.field_path + ":" + "[" + context.state.entity_instance._id + "]:";
                }

                final_request.entity_instance._id = context.state.entity_instance._id;

                invoke_method(entity_name, "execute_business_rule", final_request, success, error, complete);
            }
        }

        context.blur_active = false;
        this.debugLog('on_field_blur', false);
    },
    getType : function (input) {
        this.debugLog('getType', true);
        var m = (/[\d]+(\.[\d]+)?/).exec(input), retval = 'string';
        if (m) {
            // Check if there is a decimal place
            if (m[1]) { retval = 'float'; }
            else { retval = 'int'; }
        }
        this.debugLog('getType', false);
        return retval;
    },
    get_field_path_from_parent_entity: function() {
        this.debugLog('get_field_path_from_parent_entity', true);
        var field_path;
        if(this.state && this.state.parent_entity_field_attribute && this.state.parent_entity_field_attribute.field_path) {
            field_path = this.state.parent_entity_field_attribute.field_path;
            if(this.state.parent_entity_array) { //Means this entity is a member of an array.  Should always be true if it has a parent entity.
                field_path = field_path + ":[" + this.state.entity_instance._id + "]:";
            }
        }
        this.debugLog('get_field_path_from_parent_entity', false);
        return field_path;
    },
    get_top_parent_entity: function() {
        this.debugLog('get_top_parent_entity', true);
        var parent_entity_info = this.get_parent_entity_helper();
        if(!parent_entity_info) {
            this.debugLog('get_top_parent_entity', false);
            return parent_entity_info;
        }
        var top_parent_entity = parent_entity_info;
        var field_path = parent_entity_info.field_path;
        while(top_parent_entity.parent_entity) {
            top_parent_entity = top_parent_entity.parent_entity;
            field_path = top_parent_entity.field_path + field_path;
        }

        top_parent_entity.field_path = field_path;
        this.debugLog('get_top_parent_entity', false);
        return top_parent_entity;
    },
    get_parent_entity_helper: function() {
        this.debugLog('get_parent_entity_helper', true);
        if(!this.state || !this.state.parent_react_entity || !this.state.parent_react_entity.state || !this.state.parent_react_entity.state.entity_instance || !this.state.parent_react_entity.state.entity_instance._id) {
            this.debugLog('get_parent_entity_helper', false);
            return undefined;
        }
        var parent_parent_entity;
        if(this.state.parent_react_entity.get_parent_entity_helper) {
            parent_parent_entity = this.state.parent_react_entity.get_parent_entity_helper();
        }
        this.debugLog('get_parent_entity_helper', false);
        return {
            _id: this.state.parent_react_entity.state.entity_instance._id,
            entity_name: this.state.parent_react_entity.state.entity_attributes.entity,
            parent_entity: parent_parent_entity,
            field_path: this.get_field_path_from_parent_entity()
        };
    },
    delete_nested_entity: function (nested_entity_field_attribute, nested_entity_array, entity_id) {
        this.debugLog('delete_nested_entity', true);
        var nested_entity_index = find_index_by_id(nested_entity_array, entity_id),
            elementPos = nested_entity_array.map(function(x) {return x.entity_id; }).indexOf(entity_id),
            delta;
        if (elementPos == -1) {
            elementPos = nested_entity_array.map(function(x) {return x; }).indexOf(entity_id);
        }
        if (elementPos != -1) {
            nested_entity_index = elementPos;
        }
        if (nested_entity_index != null) {
            delta = nested_entity_array.splice(nested_entity_index, 1)[0];
            if(this.state.entity_attributes) {
                this.state.entity_attributes.cached_custom_html_args = null; //will have to redraw to reflect new size of list
            }
        }
        if (!nested_entity_field_attribute.data_is_nested) {
            return this.on_field_change(nested_entity_field_attribute, nested_entity_array);
        } else if (delta) {
            delta.change_type = "removal";
            //nested_entity_array.splice(nested_entity_index, 0, delta);
            if (!this.state.update_fields) {
                this.state.update_fields = {};
            }
            if (!this.state.update_fields[nested_entity_field_attribute.field_path]) {
                this.state.update_fields[nested_entity_field_attribute.field_path] = [];
            }
            var deleted_records_array_index = find_index_by_id(this.state.update_fields[nested_entity_field_attribute.field_path], entity_id);
            if (deleted_records_array_index == null) {
                if(!this.state.is_new_instance) {
                    this.state.update_fields[nested_entity_field_attribute.field_path].push(delta);
                }
            } else if(this.state.is_new_instance) { //just remove it from the update fields
                this.state.update_fields[nested_entity_field_attribute.field_path].splice(deleted_records_array_index, 1);
            } else {
                this.state.update_fields[nested_entity_field_attribute.field_path][deleted_records_array_index].change_type = "removal";
            }
            nested_entity_field_attribute.modified = true;
            this.on_field_blur(nested_entity_field_attribute);
            this.forceUpdate();
        }
        this.debugLog('delete_nested_entity', false);
    },
    update_from_response: function (entity_inst_deltas, optional_parent_field_path, startingPoint, skip_nested_window_logic) {
        this.debugLog('update_from_response', true);
        this.state.update_fields = this.state.update_fields || {};
        var field_attributes = this.state.entity_attributes.attributes;
        if(entity_inst_deltas.xenforma_file_attachments === null)
        {
            entity_inst_deltas.xenforma_file_attachments = []; 
        }
        for(var i = 0; i < field_attributes.length; i++) {
            //iterate through data for nested entity arrays
            var field_att = field_attributes[i];
            var field_name = field_att.field_path;
            if (field_att && field_att.is_nested_entity && field_att.data_is_nested && field_att.is_array) { //is a directly nested array
                if (entity_inst_deltas[field_name] && (entity_inst_deltas[field_name].length > 0)) {
                    //These next 3 lines are required to force a rerender of these dxDataGrid contents
                    field_att.last_react_key = field_att.field_path + general_utils.create_guid();
                    this.state.update_fields[field_att.field_path] = [];
                    this.forceUpdate();
                    for (var k = 0; k < entity_inst_deltas[field_name].length; k++) {
                        this.upsert_nested_entity(field_att, this.state.entity_instance[field_name], entity_inst_deltas[field_name][k]);
                    }
                }
                else if (entity_inst_deltas && (field_name == optional_parent_field_path)) {
                    this.upsert_nested_entity(field_att, this.state.entity_instance[field_name], entity_inst_deltas);
                }
            }
            else if (entity_inst_deltas.hasOwnProperty(field_name) && !this.state.entity_instance.hasOwnProperty(optional_parent_field_path)) {
                if (field_att.is_array && !Array.isArray(entity_inst_deltas[field_name])) {
                    if (this.state.entity_instance[field_name] && Array.isArray(this.state.entity_instance[field_name])) {
                        this.state.entity_instance[field_name].push(entity_inst_deltas[field_name]);
                    }
                    else {
                        this.state.entity_instance[field_name] = [entity_inst_deltas[field_name]];
                    }
                }
                else {
                    this.state.entity_instance[field_name] = entity_inst_deltas[field_name];
                }
                if (this.state.is_new_instance) {
                    this.on_field_change(field_att, entity_inst_deltas[field_name]);
                }
            }
        }

        if(!skip_nested_window_logic) {
            this.close_nested_entity_view(null, startingPoint);
        }
        this.forceUpdate();
        this.debugLog('update_from_response', false);
    },
    upsert_nested_entity: function (nested_entity_field_attribute, nested_entity_array, entity_inst_deltas) {
        this.debugLog('upsert_nested_entity', true);
        var entity_id = entity_inst_deltas._id;
        var has_change = true;
        var change_type;
        delete nested_entity_field_attribute.cached_rendered_component;
        delete nested_entity_field_attribute.last_react_key;
        if(this.state.entity_attributes && this.state.entity_attributes.field_paths && this.state.entity_attributes.field_paths[nested_entity_field_attribute.field_path] && this.state.entity_attributes.field_paths[nested_entity_field_attribute.field_path].cached_rendered_component) {
            delete this.state.entity_attributes.field_paths[nested_entity_field_attribute.field_path].cached_rendered_component;
            delete this.state.entity_attributes.field_paths[nested_entity_field_attribute.field_path].last_react_key;
        }
        var current_change_array = this.state.update_fields[nested_entity_field_attribute.field_path] || [];
        var nested_entity_index = find_index_by_id(nested_entity_array, entity_id);
        var current_change_array_index = find_index_by_id(current_change_array, entity_id);
        if (nested_entity_index != null) {
            if (nested_entity_array[nested_entity_index].change_type == "removal") {
                change_type = "removal";
                if (current_change_array_index != null) {
                    if(this.state.is_new_instance) {
                        //remove from update_fields
                        current_change_array.splice(current_change_array_index, 1);
                    }
                    else {
                        current_change_array[current_change_array_index] = {_id: entity_id, change_type: "removal"};
                    }
                }
                else {
                    if(!this.state.is_new_instance) {
                        current_change_array.push({_id: entity_id, change_type: "removal"});
                    }
                }
            }
            else {
                change_type = "modified";
                has_change = false;
                for (var field_name in entity_inst_deltas) {
                    if (field_name != "_id") {
                        has_change = true;
                        break;
                    }
                }
                $.extend(nested_entity_array[nested_entity_index], entity_inst_deltas);
                //Updates have been changed to happen instantly, so no need to track in update_fields, unless it's a new entity
                if(this.state.is_new_instance) {
                    current_change_array[current_change_array_index] = nested_entity_array[nested_entity_index];
                }
            }
        }
        else {
            change_type = "created";
            if (nested_entity_array) {
                nested_entity_array.unshift(entity_inst_deltas);
            }
            //Inserts have been changed to happen instantly, so no need to track in update_fields, unless it's a new entity
            if(this.state.is_new_instance && (current_change_array != nested_entity_array)) {
                current_change_array.push(entity_inst_deltas);
            }
        }
        if (has_change) {
            if (!this.state.update_fields[nested_entity_field_attribute.field_path]){
                this.state.update_fields[nested_entity_field_attribute.field_path] = [];
            }
            entity_inst_deltas.change_type = change_type;
            var deleted_records_array = this.state.update_fields[nested_entity_field_attribute.field_path];
            for (var i = 0; i < current_change_array.length; i++) { //don't let nested_entity_array get out of sync with current_change_array after updates
                if (current_change_array[i] && current_change_array[i]._id) {
                    entity_id = current_change_array[i]._id;
                    if ((current_change_array[i].change_type == "removal")) {
                        var deleted_entry = find_by_field('_id', entity_id, deleted_records_array);
                        if (!deleted_entry) {
                            deleted_records_array.push(current_change_array[i]);
                        }
                        else {
                            deleted_entry.change_type = "removal";
                        }
                    }
                }
            }

            this.on_field_change(nested_entity_field_attribute, nested_entity_array);
            set_json_data_using_field_paths(nested_entity_field_attribute.field_path, this.state.update_fields, current_change_array, true); //overrides on_field_change default behavior
            this.on_field_blur(nested_entity_field_attribute);
        }
        this.debugLog('upsert_nested_entity', false);
    },
    close_nested_entity_view: function (added_id, startingPoint, upsert_id) {
        this.debugLog('close_nested_entity_view', true);
        this.recover_scroll = true;
        var newEntity = this.state.child_entity_state_data;
        delete this.state.child_entity_state_data;
        delete this.state.reference_entity_id;

        if(this.state.individual_attributes_per_entity && newEntity && newEntity.base_field_path) {
            var local_entity_attributes = this.state.individual_attributes_per_entity[newEntity.base_field_path];
            if(local_entity_attributes) {
                for(var local_field_path in local_entity_attributes) {
                    if(local_entity_attributes.hasOwnProperty(local_field_path) && local_entity_attributes[local_field_path] && local_entity_attributes[local_field_path].cached_rendered_component) {
                        delete local_entity_attributes[local_field_path].cached_rendered_component; //force redraw of this attribute
                    }
                }
            }
        }

        var context = this, i;
        if(added_id) {
            if (!context.state.modified) {
                context.state.modified = true;
            }
        }
        if (this.state.nested_reference_entity_data) {
            var attribute = this.state.nested_reference_entity_data.attribute;
            if(attribute) { // Not having an attribute will just force the logic to skip refresh_reference_grid
                if (attribute.entity_reference_variant) {
                    context
                        .variant_ref_add_button(attribute)
                        .then(function (attribute) {
                            context.forceUpdate();
                        });
                } else {
                    if (!context.state.grid_is_on_edit_form) {
                        context.refresh_reference_grid(attribute, function (data_list) {
                            var cur_value = get_json_data_using_field_paths(attribute.field_path, context.state.entity_instance);
                            if (data_list && (cur_value != null)) {
                                if (Array.isArray(cur_value)) {
                                    for (i = 0; i < cur_value.length; i++) {
                                        if (typeof cur_value[i] == "object") {
                                            cur_value[i] = cur_value[i]["entity_id"];
                                        }
                                    }
                                    if (context.state.privateFiles &&
                                        context.state.privateFiles[attribute.field_path] &&
                                        context.state.privateFiles[attribute.field_path].length &&
                                        (attribute.db_type == "file")) {
                                        var privateFilesWithCaptions = context.state.privateFiles[attribute.field_path].filter(function (a) {
                                            return (a.entity_id != null);
                                        });
                                        context.state.privateFiles[attribute.field_path] = privateFilesWithCaptions;
                                        for (i = 0; i < privateFilesWithCaptions.length; i++) {
                                            var exists = first_where(data_list, function (a) {
                                                return (a && (privateFilesWithCaptions[i].entity_id == a.entity_id));
                                            });
                                            if (!exists) {
                                                data_list.push(privateFilesWithCaptions[i]);
                                            }
                                        }
                                        context.state.new_filelist_data = data_list;
                                    }
                                    if (added_id) {
                                        var elementPos = cur_value.map(function (x) {
                                            return x;
                                        }).indexOf(added_id);
                                        if (elementPos == -1) {
                                            for (var i = 0; i < data_list.length; i++) {
                                                if (data_list[i].entity_id == added_id) {
                                                    cur_value.push(added_id);
                                                    break;
                                                }
                                            }
                                        }
                                    }

                                    delete attribute.dataSource; //force select list to fill with newly received data (attribute.select_options)
                                }
                                else if (cur_value.caption && cur_value.entity_id) {
                                    //update attribute entity reference value
                                    var updated_value = first_where(data_list, function (a) {
                                        return (a && (a.entity_id == cur_value.entity_id));
                                    });
                                    if (updated_value && updated_value.caption && (updated_value.caption != cur_value.caption)) {
                                        cur_value.caption = updated_value.caption;
                                    }
                                }
                                attribute.cached_rendered_component = null;
                                attribute.custom_key_tag = create_uid(); //force redraw of this component
                                context.forceUpdate();
                            }
                        }, added_id || upsert_id);
                    }                    
                }
            }
            delete this.state.nested_reference_entity_data;
        }
        this.state.areButtonsDisabled = false;
        this.setState(this.state);
        /*End to upsert*/
        if(context.state.tag_template){
            context.setState({tag_template: false});
        }
        context.recovery_parent_style();
        if (startingPoint){
            this.nested_entity_handler(newEntity);
        } else{
            if (this.props.handle_executing) {
                this.props.handle_executing(false);
            }
        }
        this.debugLog('close_nested_entity_view', false);
    },
    handle_close: function (event, fromAction) {
        this.debugLog('handle_close', true);
        if(this.state.nested_reference_entity_data || this.state.child_entity_state_data || (event && event.closed_one)) { //jquery calls this method directly on pressing escape: skip if a nested edit_form is open, or if this event has already closed one edit_form
            return false; //prevent this event from bubbling up:  Otherwise Jquery will bypass this method and hide the form anyway
        }
        this.close_all_tooltip_popovers();
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        if (this.state.areButtonsDisabled){
            this.debugLog('handle_close', false);
            return;
        }

        if (this.props.handle_executing) {
            this.props.handle_executing(false);
        }
        var context = this;
        if($('.dx-dialog.dx-overlay.dx-popup').length && context.confirming){
            context.pop_up = true;
        }
        var close_func;
        if (context.is_nested_popup()) {
            context.recovery_parent_style();
            //If changes have been made prompt the user
            if (context.state.modified && (context.state.update_fields.content != "" || context.state.update_fields.subject != null )) {
                context.debugLog('handle_close', false);
                return context.confirm(R.label_confirm, R.message_changes_discarded, function (confirmation) {
                    if (confirmation) {
                        return context.props.data.parent_react_entity.close_nested_entity_view();
                    }
                });
            }
            else {
                context.debugLog('handle_close', false);
                return context.props.data.parent_react_entity.close_nested_entity_view();
            }
        }
        else {
            close_func = function () {
                current_navigation_listener = undefined;
                if (context.props.isModal) {
                    if (event) {// action comes from button
                        var success;
                        var error;
                        /* 84230669/ list of files uploaded ,trying for delete file when instance  is closed */
                        try {
                            if ((typeof context.state.filesUploaded != 'undefined' )) {
                                for(var i=0; i<context.state.filesUploaded.length; i++ ){
                                    var currentNewFileUpload=context.state.filesUploaded[i]._id;
                                    var is_public=context.state.filesUploaded[i].is_public;
                                    if( is_public=="false" ){
                                        var data = {_id: currentNewFileUpload};
                                        invoke_method("file", "delete", data, success, error);
                                    }
                                }
                            }
                        }catch (err){
                        }
                        context.props.navigation_handler("close", context.state.force_grid_refresh);
                    }
                    else {
                        if (fromAction) {
                            context.props.navigation_handler("workflow_action", context.state.force_grid_refresh);
                        }
                        else {
                            context.props.navigation_handler(context.state.entity_instance, context.state.force_grid_refresh);
                        }
                    }
                    return;
                }
                var ie11andabove = navigator.userAgent.indexOf('Trident') != -1 && navigator.userAgent.indexOf('MSIE') == -1;
                var ie10andbelow = navigator.userAgent.indexOf('MSIE') != -1;
                var history_length = (ie11andabove||ie10andbelow)?3:2;
                if (window.history.length && (window.history.length > history_length) && context.props.app_object_code != "user_invite" && context.props.app_object_code != "contact_us_default_edit_form") {
                    context.debugLog('handle_close', false);
                    window.history.back();
                }
                else {
                    context.debugLog('handle_close', false);
                    context.props.navigation_handler("home");
                }
            }
        }
        if (current_navigation_listener) {
            return current_navigation_listener(function (result) {
                if (result) {
                    context.debugLog('handle_close', false);
                    return close_func();
                }
            }, context);
        }
        else {
            context.debugLog('handle_close', false);
            return close_func();
        }
    },
    tagTemplate: function(local_attribute, itemData,itemElement){
        this.debugLog('tagTemplate', true);
        var context = this, retval;
        if(local_attribute && local_attribute.db_type =="file") {
            var deleteb_handler = function (target) {
                if (target && target.className == "dx-tag-remove-button") {
                    var entity_id = target.id;
                    context.setState({tag_template: false});
                    if(context.state.popover){
                        context.state.popover.hide();
                    }
                } 
                else {

                var entity_id = context.state.tag_template_values._id;
                context.setState({tag_template: false});
                context.state.popover.hide();
            }
                var elementPos = context.state.entity_instance[local_attribute.field_path].map(function(x) {return x; }).indexOf(entity_id);
                if (elementPos != -1)
                {
                    context.state.entity_instance[local_attribute.field_path].splice(elementPos, 1);
                    var splice_result = {
                        entity_instance: _defineProperty(
                          {},
                          local_attribute.field_path,
                          context.state.entity_instance[local_attribute.field_path]
                        )
                      };

                    context.setState({splice_result:splice_result});
                }
                else{
                    elementPos = context.state.entity_instance[local_attribute.field_path].map(function(x) {return x.entity_id; }).indexOf(entity_id);
                    if (elementPos != -1)
                    {
                        context.state.entity_instance[local_attribute.field_path].splice(elementPos, 1);
                        context.forceUpdate();
                    }
                }
                context.on_field_change(local_attribute, context.state.entity_instance[local_attribute.field_path]);

            };
            var editt_handler = function () {
                var entity_id =context.state.tag_template_values._id;
                context.setState({reference_entity_id: entity_id, currentAttribute:local_attribute});
                context.setState({tag_template: false});
                context.forceUpdate();
                context.reference_entity_handler(local_attribute,entity_id);
                context.state.popover.hide();
            };
            var downloadt_handler = function () {

                var entity_id =context.state.tag_template_values._id;
                context.setState({tag_template: false});
                context.state.popover.hide();
                var d_data = {
                    file_id: entity_id
                };
                var download_success = function(response){
                    function saveFile(url) {
                        // Get file name from url.
                        var filename = context.state.tag_template_values.file_name;
                        var xhr = new XMLHttpRequest();
                        xhr.onload = function() {
                            window.saveAs(xhr.response, filename);
                        };
                        xhr.open('GET', url);
                        xhr.responseType = 'blob';
                        xhr.send();
                    }
                    saveFile(response.data.url);
                };
                var download_error = function(error){
                    context.setState({error: error.message});
                    server_error_action(error);
                };
                if(context.state.tag_template_values.drive_url){
                    if(context.state.tag_template_values.drive_url){
                        window.open(context.state.tag_template_values.drive_url);
                    }
                    else{

                        invoke_method("file", "get_file", d_data, download_success, download_error);
                    }
                }
                else {
                    invoke_method("file", "get_file", d_data, download_success, download_error);
                }
            };
            var Filetaghandler = function (event) {
                local_attribute.loading = true;
                if(event.target.className == "dx-tag-remove-button")
                {
                   deleteb_handler(event.target);
                    return;
                }
                event.preventDefault();
                event.stopPropagation();
                context.setState({tag_popover_loading: false, tag_template: false});
                $("#" + (local_attribute.safe_field_path || local_attribute.field_path) + " .dx-tagbox").dxTagBox("instance").close();
                if (context.state.popover_loading){
                    context.state.popover_loading.hide();
                }
                context.searching_file = true;
                var request = {
                    _id: itemData.entity_id,
                    parent_entity_id: context.state.entity_instance._id,
                    parent_entity_name: context.state.entity_attributes.entity
                };
                var success = function (response) {
                    if (!local_attribute.cancel_loading){
                        var id_tag = (local_attribute.safe_field_path || local_attribute.field_path) + '_' + response.data.values._id;
                        context.searching_file = false;
                        response.data.values.entity = "file";
                        local_attribute.cached_rendered_component = null; //force this attribute to run through rendering again
                        context.setState({tag_template: true});
                        local_attribute.cached_rendered_component = null; //force this attribute to run through rendering again
                        context.setState({tag_template_values: response.data.values});
                        context.open_popover(id_tag);
                        setTimeout(function(){
                            var element = $("#edit_" + (local_attribute.safe_field_path || local_attribute.field_path) + '_' + itemData.entity_id);
                            element.unbind('click');
                            element.bind('click', editt_handler);
                            element = $("#download_" + (local_attribute.safe_field_path || local_attribute.field_path) + '_' + itemData.entity_id);
                            element.unbind('click');
                            element.bind('click', downloadt_handler);
                            element = $("#delete_" + (local_attribute.safe_field_path || local_attribute.field_path) + '_' + itemData.entity_id);
                            element.unbind('click');
                            element.bind('click', deleteb_handler);
                            $("#" + (local_attribute.safe_field_path || local_attribute.field_path)).find(".dx-tagbox").dxTagBox("instance").close();
                            if (context.state.popover_loading){
                                context.state.popover_loading.hide();
                            }
                        }, 1000);
                    }
                };

                var error = function (error) {
                    context.searching_file = false;
                    context.setState({error: error.message});
                    server_error_action(error);
                };
                if (!local_attribute.cancel_loading){
                    context.open_popover_loading($(event.target).parents('.dx-tag-content').attr('id'), local_attribute);
                    invoke_method("file", "get_file_summary_data", request, success, error, function(){
                        local_attribute.cancel_loading = false;
                        local_attribute.loading = false;
                    });
                }
            };
            var icon = itemData.fa_icon || 'fa-file';
            setTimeout(function(){
                var element = $("#" + (local_attribute.safe_field_path || local_attribute.field_path) + '_' + itemData.entity_id);
                element.unbind('click');
                element.bind('click', Filetaghandler);
            }, 1000);
            var remove_button = !(context.form_is_read_only() || local_attribute.read_only)?'<div id="'+itemData.entity_id+'"class="dx-tag-remove-button"></div>':'';
            retval = '<div id="' + local_attribute.field_path + '_' + itemData.entity_id + '" class="dx-tag-content" ><span><div style="text-de coration: underline;margin: -5px;padding: 5px;>' +
                '<i style="text-decoration: none;margin-right:10px;" class="fa ' + icon + '" aria-hidden="true"></i>' +
                itemData.caption + '</div></span>' + remove_button + '</div>';
        }
        else{
            if (itemData.entity_id){
                retval = '<div id="'+itemData.entity_id+'">' +
                    '<i style=" style="color:#606060;margin-right:5px;" class="fa '+itemData.fa_icon+'" aria-hidden="true"></i>' +
                    itemData.caption + '</div>';
            } else retval = 'tag';
        }

        this.debugLog('tagTemplate', false);
        return retval;
    },
    open_popover:function(id_tag, callback) {
        this.debugLog('open_popover', true);

        var context = this;
        if (this.state.popover) {
            this.state.popover.hide();
        }

        if (document.getElementById(id_tag) && !this.searching_file) {
            var popover_id = $("#id" + id_tag).length > 0
                ? "id" + id_tag
                : id_tag
            ;
            var popover_desc = $("#" + popover_id).dxPopover({
                target: "#" + id_tag,
                position: "top",
                width: 300,
                onContentReady: callback,
                onHidden: function () {
                    context.setState({tag_template: false});
                }
            }).dxPopover("instance");

            popover_desc.show();
            this.setState({popover: popover_desc});
        }
        else {
            if (this.state.popover) {
                this.state.popover.hide();
            }
            if (this.state.popover_loading) {
                this.state.popover_loading.hide();
            }
        }

        this.debugLog('open_popover', false);
    },
    open_popover_loading:function(id_tag, local_attribute){
        this.debugLog('open_popover_loading', true);
        if(this.state.popover) {
            this.state.popover.hide();
        }
        if (document.getElementById(id_tag)) {
            local_attribute.cached_rendered_component = null; //force this attribute to run through rendering again
            this.setState({tag_popover_loading: true,tag_template: true});
            var popover_desc = $("#Loading_tag").dxPopover({
                target: "#" + id_tag,
                position: "top",
                width: 300
            }).dxPopover("instance");
            this.setState({popover_loading: popover_desc});
            popover_desc.show();
        }
        else{
            if(this.state.popover)
            {
                this.state.popover.hide();
            }
            if(this.state.popover_loading)
            {
                this.state.popover_loading.hide();
            }

        }
        this.debugLog('open_popover_loading', false);
    },
    check_recurrent_send_data: function(send_data){
        this.debugLog('check_recurrent_send_data', true);
        if (this.state.entity_attributes) {
            for (var field_path in this.state.update_fields) {

                for(var i=0;i<this.state.entity_attributes.attributes.length;i++)
                {
                    var attr = this.state.entity_attributes.attributes[i];
                    if (attr.attribute_type == "Recurrent" && attr.field_path == field_path)
                    {
                        var value = this.state.update_fields[field_path];
                        if (value == null)
                        {
                            continue;
                        }
                        if (value.slice(0, 6) == 'RRULE:') {
                            value = value.substr(6);
                        }
                        try {
                            RRule.fromString(value);
                        }
                        catch(Exception) {
                            send_data[field_path] = null;
                        }
                    }
                }
            }
        }
        this.debugLog('check_recurrent_send_data', false);
    },
    handle_deleted_records: function(entity_instance) {
        var attribute_array = this.state.entity_attributes.attributes,
            current_attribute,
            value_array,
            deleted_record_array,
            i,j;
        for (i= 0; i<((attribute_array)? attribute_array.length : 0);i++) {
            current_attribute = attribute_array[i];
            if (current_attribute.is_array && this.state.update_fields) {
                value_array = get_json_data_using_field_paths(current_attribute.field_path, entity_instance);
                deleted_record_array = (this.state.update_fields[current_attribute.field_path] || []).filter(function(a) { return a.change_type == "removal";});
                for (j = 0; j<((deleted_record_array)? deleted_record_array.length : 0); j++) {
                    if(deleted_record_array[j]) {
                        if (!find_by_field('_id', deleted_record_array[j]._id, value_array)) {
                        value_array.push(deleted_record_array[j]);
                    }
                }
            }
        }
        }
    },
    handle_submit: function (event, view_options, fromAction, startingPoint, keep_window_open, callback_print_option) {
        var context = this;

        return setTimeout(function() {
        // if a blur is being executed on a field
        if (context.blur_active) {
            // we store the submit call
            context.pending_submit = context.handle_submit.bind(context, event, view_options, fromAction, startingPoint, keep_window_open, callback_print_option);
            return;
        }
        // no submit call pending
        context.pending_submit = undefined;

        var win;

        var isIE = !!document.documentMode;
        if (isIE){
                if (context.state && context.state.error){
                    $('#edit_form').find('.widget-body').scrollTop(0);
                    $( ".ie-warning" ).css("display","block");
                }
        }
        context.debugLog('handle_submit', true);
        if (event && event.preventDefault) {
            context.recovery_parent_style();
            event.preventDefault();
        }
        context.handle_deleted_records(context.state.entity_instance);
        context.state.submitting = true;

        if (!context.state.error){
            var entity_method;
            var send_data;

            if(!context.state.isImage && context.state.multiple && context.state.update_multiple_field && context.state.update_multiple_field.length > 0) {
                send_data =  context.state.update_multiple_field[context.state.update_multiple_field.length - 1];
                context.state.update_multiple_field.pop();

                context.state.update_fields = send_data;
                context.state.entity_instance._id = send_data._id;

                context.state.xenforma_file_attachments = context.state.xenforma_file_attachments.concat(send_data._id);
                send_data.xenforma_file_attachments = context.state.xenforma_file_attachments;

                context.state.entity_instance.date_uploaded = send_data.date_uploaded;
                context.state.entity_instance.file_name = send_data.file_name;
                context.state.newKey = send_data._id;
                context.state.newFile_name = send_data.file_name;
            } else if(context.state.isImage && context.state.multiple && context.state.update_multiple_field && context.state.update_multiple_field.length > 0) {
                send_data = context.state.update_multiple_field;
            } else {
                send_data = context.state.update_fields;
                context.state.update_fields._id = context.state.entity_instance._id;
            }

            context.check_recurrent_send_data(send_data);
            var error = function (err) {
                if ( win ) {
                    win.close();
                }
                context.hidden_parent_style();
                context.recovery_parent_style();
                if (context.props.handle_executing) {
                    context.props.handle_executing(false);
                }//scrollup
                context.setState({error: err.message});
                server_error_action(err);
            };
            var success;
            var entity_name = context.state.entity_attributes.entity;

            if (context.props.data && (context.props.data.parent_react_entity && !context.props.data.is_reference_nest)) { //don't save directly, but do validate first.
                if (!context.state.update_fields) {
                    context.recovery_parent_style();
                    return context.props.data.parent_react_entity.close_nested_entity_view();
                }
                for (var field_path in context.state.update_fields) {
                    if (context.state.update_fields[field_path] !== undefined) {
                        context.state.entity_instance[field_path] = context.state.update_fields[field_path];
                    }
                }
                if (context.state.parent_react_entity.state.is_new_instance) {
                    send_data = context.state.entity_instance; //validate whole entity as a stand-alone (for now).
                }
                else {
                    context.state.entity_instance.change_type = "modified";
                    if (context.state.is_new_instance) {
                        context.state.entity_instance.change_type = "created";
                    }
                    send_data = {};
                    /*If form-edit is modified ignore entities only send _id*/
                        send_data[context.state.parent_entity_field_attribute.field_path] = [context.state.entity_instance];
                    send_data._id = context.state.parent_react_entity.state.entity_instance._id;
                    entity_name = context.state.parent_react_entity.state.app_object.entity;
                }
                success = function (data) {
                    fix_response_data(data, context.state.entity_attributes.field_paths, context.state.entity_attributes.nested_list_entities);
                    if(!context.props.data.parent_react_entity.state.is_new_instance) {
                        Notify(data.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                    }
                    if(data && data.data) {
                        data = data.data;
                    }
                    else {
                        data = {};
                        data[context.state.parent_entity_field_attribute.field_path] = context.state.update_fields;
                    }
                    context.recovery_parent_style();
                    return context.props.data.parent_react_entity.update_from_response(data, context.state.parent_entity_field_attribute.field_path, startingPoint); //field_path is for new entities
                };
            }
            else {
                success = function (data) {
                    context.recovery_parent_style();
                    var nested_entity_array_attributes = context.state.entity_attributes.attributes.filter(function (x){
                        return x.is_array && x.is_nested_entity && x.data_is_nested && !x.field_path.includes(":")
                    });

                    for (var entity in nested_entity_array_attributes) {
                        if(data.data[nested_entity_array_attributes[entity].field_path]
                            && typeof data.data[nested_entity_array_attributes[entity].field_path] === "object") {
                                data.data[nested_entity_array_attributes[entity].field_path] = data.data[nested_entity_array_attributes[entity]
                                                                                                .field_path].filter(function (x){
                                                                                                    return x.change_type !== "removal";
                                                                                                });
                        }

                        if (context.state.entity_instance[nested_entity_array_attributes[entity].field_path]) {
                                context.state.entity_instance[nested_entity_array_attributes[entity].field_path] = 
                                context.state.entity_instance[nested_entity_array_attributes[entity].field_path]
                                .filter(function (x){
                                    return x.change_type !== "removal";
                                });
                            }
                    }

                    fix_response_data(data, context.state.entity_attributes.field_paths, context.state.entity_attributes.nested_list_entities);
                    try {context.update_state_form_edit(data.data);}catch(err) { }
                    if (context.state.is_new_instance) {
                        if (data.data && data.data._id) {
                            context.state._id = data.data._id;
                        }
                    }
                    if (context.state.fileToUpload) {
                        context.state._id = context.state.entity_instance._id;
                        if (context.props.handle_executing) {
                            context.props.handle_executing(false);
                        }
                        context.setState({uploadFileModal: true, areButtonsDisabled: true});
                        return;
                    }

                    if (view_options && view_options.confirmation_message) {
                        Notify(view_options.confirmation_message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                    }
                    else {
                        if (!context.state.is_reference_nest) {
                            Notify(data.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                        }
                        /*If this instance is a pop up, sended data(newIdCreated) for show new element into dropdown*/
                        try {
                            if(  jQuery.isFunction(context.props.data.selectNewItemDropdown) && (typeof context.props.data != "undefined" )) {
                                var caption = '';
                                if ((context.state.caption_function)) {
                                    if (context.state.entity_instance) {
                                        caption = context.state.caption_function(context.state.entity_instance);
                                    }
                                    else {
                                        caption = context.state.caption_function(context.state.entity_attributes.attributes);
                                    }
                                }
                                context.props.data.selectNewItemDropdown(send_data._id, caption);
                            }
                        }catch(err) { }
                        context.setState( { childInstance : 1 } );
                        try {
                            if(
                                jQuery.isFunction(context.props.data.change_field_to_update) &&
                                (typeof context.props.data != "undefined" )
                            ) {
                                var newIdCreated = send_data._id;
                                context.props.data.change_field_to_update(send_data.name);
                            }
                        }catch(err){}
                    }
                    if (context.state.entity_attributes && context.state.entity_attributes.entity == 'translation') {
                        load_translations(false, function() {
                            context.forceUpdate();
                        });
                    }
                    if (context.state.app_object && context.state.app_object.custom_properties && context.state.app_object.custom_properties.refresh_user_data) {
                        context.props.update_avatar();
                        context.props.update_user_info();
                    }
                    if (context.props.data && context.props.data.is_reference_nest) { //nested edit form
                        if (context.state.parent_entity_field_attribute && context.state.parent_entity_field_attribute.db_type == "file" && context.state.entity_instance) {
                            if (!context.props.data.parent_react_entity.state.privateFiles) {
                                context.props.data.parent_react_entity.state.privateFiles = {};
                            }
                            if (!context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path]) {
                                context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path] = [];
                            }
                            if(context.state._id == undefined) {
                                for (var i = 0, l = context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path].length; i < l; i++) {
                                    if (context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path][i]){
                                        if(context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path][i].entity_id== context.props._id)
                                        {
                                            context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path][i].caption = context.state.entity_instance.description || context.state.entity_instance.file_name
                                        }
                                    } else{
                                        context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path].splice(i,1);
                                        i--;
                                        l--;
                                    }
                                }
                            }
                            else {
                                var caption_result;
                                if (context.state.name_multiple_field && context.state.name_multiple_field.length > 0) {
                                    caption =  context.state.name_multiple_field[context.state.name_multiple_field.length - 1];
                                    context.state.name_multiple_field.pop();

                                    caption_result = {
                                        entity_id: caption._id,
                                        caption: caption.file_name
                                    };
                                    context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path] = 
                                        context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path].concat(
                                        caption_result);
                                } else {
                                    caption_result = {
                                        entity_id: context.state._id,
                                        caption: context.state.entity_instance.description || context.state.entity_instance.file_name || file_name
                                    };

                                    context.props.data.parent_react_entity.state.privateFiles[context.state.parent_entity_field_attribute.field_path].push(caption_result);
                                }
                            }

                        }
                        var data_id;
                        if(!context.state._id && data && data.data && data.data._id) {
                            data_id = data.data._id;
                        }
                        context.props.data.parent_react_entity.close_nested_entity_view(context.state._id, null, data_id);
                        return context.props.data.parent_react_entity.on_field_change(context.state.parent_entity_field_attribute, context.state.parent_entity_array);
                    }
                    else {
                        if (view_options) {
                            if (view_options.skip_refresh) {
                                if (context.props.handle_executing) {
                                    context.props.handle_executing(false);
                                }

                                return context.props.navigation_handler("home");
                            }
                        }
                        context.setState({
                            update_fields: {},
                            error: undefined,
                            modified: false,
                            workflow_action: undefined,
                            is_new_instance: false,
                            resetFileUpload: undefined,
                            areButtonsDisabled: false
                        });
                        if( false && startingPoint == "saveandnew"){
                            context.setState({
                                entity_instance:{}
                            });
                        }
                        else if(!data || !data.new_entity_flag) {
                            if(keep_window_open) {
                                context.update_from_response((data && data.data) ? data.data : {}, null, null, true);
                                context.setState({
                                    request_boundary_check: general_utils.create_uid(), //prevent old requests (i.e. "execute_business_rule") from updating our new data
                                    update_fields: {},
                                    error: undefined,
                                    modified: false,
                                    workflow_action: undefined,
                                    is_new_instance: false,
                                    resetFileUpload: undefined,
                                    areButtonsDisabled: false,
                                    force_grid_refresh: true
                                });
                                if (context.props.handle_executing) {
                                    context.props.handle_executing(false);
                                }
                                return;
                            }
                            else {
                                context.handle_close(undefined, fromAction);
                                if(callback_print_option && data && data.data) {
                                    var id_field_value = data.data._id;
                                    if(callback_print_option.entity && callback_print_option.entity.id_field) {
                                        id_field_value = general_utils.get_json_data_using_field_paths(callback_print_option.entity.id_field, data.data);
                                        if(id_field_value) {
                                            if(id_field_value._id) { id_field_value = id_field_value._id; }
                                            else if(id_field_value.entity_id) { id_field_value = id_field_value.entity_id; } //in reference to the way that data comes in from the server
                                            id_field_value = general_utils.is_valid_object_id(id_field_value);
                                        }
                                    }
                                    if(id_field_value) {
                                        print_reports(win, [id_field_value], callback_print_option.reports, function (err) {
                                            if (err && context.props.set_data_list_error) {
                                                context.props.set_data_list_error(err.responseJSON || err.data || err);
                                            }
                                        });
                                    }
                                }
                            }
                            if(data && data.filecontents && data.filename) { //Response contained a file
                                var blob = new Blob([data.filecontents], {type: "text/plain"});
                                saveAs(blob, data.filename);
                            }
                        }
                        if (context.props.handle_executing) {
                            context.props.handle_executing(false);
                        }
                        context.state = {};
                        if(false && startingPoint != "saveandnew") {
                            context.initialize_edit_form(data.data, true);
                        }
                        else{
                            context.initialize_edit_form([], true);
                        }
                    }
                };

            }
            entity_method = "upsert";
            var delete_workflow_status = true;
            if(context.state.entity_attributes['entity']=='org_level_user_data'){
                localStorage.removeItem("home");
                localStorage.removeItem("get_home_layout");
            }
            if (fromAction && context.state.workflow_action && (!context.props.data || context.props.data.is_reference_nest)) { //Filter out direct nests, which don't invoke workflows
                entity_method = "change_workflow_state";
                delete_workflow_status = false;
            }
            if (context.props.handle_executing&&!(!context.state.parent_entity_field_attribute||context.state.is_reference_nest)) {
                context.props.handle_executing(true);
            }
            /*84230669/ When Save button is pressed, detect and delete private files  */
            if( context.state.entity_instance.xenforma_file_attachments != undefined ){
                try{
                    if(context.state.filesUploaded.length){
                        for(var i=0; i<context.state.filesUploaded.length; i++ ) {
                            var matchFile=false;
                            var currentNewFileUpload = context.state.filesUploaded[i]._id;
                            if(context.state.filesUploaded[i].is_public=="false"){
                                for(var j=0; j<context.state.entity_instance.xenforma_file_attachments.length; j++ ) {
                                    var currentXenforma=context.state.entity_instance.xenforma_file_attachments[j];
                                    if(currentNewFileUpload == currentXenforma){
                                        matchFile=true;
                                    }
                                }
                                if(!matchFile) {
                                    var data = {_id: currentNewFileUpload};
                                    invoke_method("file", "delete", data, function(){context.recovery_parent_style(); }, function(){ context.recovery_parent_style();  });
                                }
                            }
                        }
                    }
                }catch (err){}
            }
            /*91221083/ Method when value of LOV is null replace this value of empty before send to server */
            var final_request = $.extend(true, {}, send_data);
            if(delete_workflow_status && final_request.workflow_status){
                //Not allowed to change this through upsert:
                delete final_request.workflow_status;
            }
            fix_request_data(final_request, context.state.entity_attributes.field_paths, context.state.entity_attributes.nested_list_entities);
            context.props.handle_executing(true);
            if(callback_print_option) {
                var requires_window_popup = (isChromeNavigator() && callback_print_option.requires_new_window);
                if(requires_window_popup) {
                    win = window.open('', '', '');
                }
            }

            if(context.state.isImage && entity_method === "upsert") {
                var final_request_custom = final_request;
                final_request_custom.required_photos= [];

                context.state.update_multiple_field.map(function(file) {
                    var reques_object = {};
                    if( context.state.caption_organization === "Chevron") {
                        reques_object = {
                            change_type: "created",
                            photo: file.photo,
                            item_no: file.item_no,
                            description: file.description,
                            _id: file._id
                        }
                    } else {
                        reques_object = {
                            change_type: "created",
                            photo: file.photo,
                            description: file.description,
                            _id: file._id
                        }
                    }
                    final_request.required_photos.push(reques_object);
                });

                invoke_method(entity_name, entity_method , final_request_custom, success, error, function() {  
                    context.recovery_parent_style(); context.hidden_parent_style(); 
                }, (context.props.return_app_object_code || ((context.props.parent_react_entity) ? context.props.parent_react_entity.props.app_object_code : null)));
            } else {
                invoke_method(entity_name, entity_method , final_request, success, error, function() {  
                    context.recovery_parent_style(); context.hidden_parent_style(); 
                }, (context.props.return_app_object_code || ((context.props.parent_react_entity) ? context.props.parent_react_entity.props.app_object_code : null)));
            }

        }
        else if(context.state.scrolled){
            var element = context.get_form_object().find('.widget-body');
            element.scrollTop(0);
        }
        context.state.submitting = false;
        context.debugLog('handle_submit', false);
        
        }, 3000);
    },
    /**
     * Formats the fields that contain percentages before they're saved
     * @param request
     * @param attribute
     */
    formatPercentageField: function(request, attribute) {
        if (request.entity_instance["probability"]) {
            var updated_field_value = request.entity_instance["probability"] ? "" + request.entity_instance["probability"] : request.entity_instance["probability"];
            if (updated_field_value
                && !isNaN(parseInt(updated_field_value, 10))
                && updated_field_value.indexOf('%') === -1) {
                updated_field_value += "%";
            }
            request.entity_instance["probability"] = updated_field_value;
        }
        if(attribute && (attribute.attribute_type === 'percent')) {
            //var cur_value = get_json_data_using_field_paths(attribute.field_path, request.entity_instance);
            var updated_field_value = request.entity_instance[attribute.field_path] ? "" + request.entity_instance[attribute.field_path] : request.entity_instance[attribute.field_path];
            if (updated_field_value
                && !isNaN(parseInt(updated_field_value, 10))
                && updated_field_value.indexOf('%') === -1) {
                updated_field_value += "%";
            }
            request.entity_instance[attribute.field_path] = updated_field_value;
        }
    },
    invalidate_cached_attributes: function() {
        // FIXME empty function, what's the point of this one ?
    },
    handle_delete: function (event) {
        this.debugLog('handle_delete', true);
        event.preventDefault();
        var context = this;
        if (!context.confirming){
            context.confirm(R.label_delete_title, R.message_delete_entity, function (confirmation) {
                if (confirmation) {
                    var is_nested = context.state.parent_entity_field_attribute && context.state.parent_entity_field_attribute.is_nested_entity && context.state.parent_entity_field_attribute.data_is_nested;
                    if (is_nested && context.is_nested_popup()) {
                        context.props.data.parent_react_entity.delete_nested_entity(context.state.parent_entity_field_attribute, context.state.parent_entity_array, context.state.entity_instance._id);
                        //context.invalidate_cached_attributes();
                        context.props.data.parent_react_entity.close_nested_entity_view();
                    }
                    else {
                        var success = function (data) {
                            fix_response_data(data, context.state.entity_attributes.field_paths, context.state.entity_attributes.nested_list_entities);
                            context.setState({
                                update_fields: {},
                                error: undefined,
                                modified: false
                            });
                            Notify(data.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                            if (context.state.entity_attributes && context.state.entity_attributes.entity == 'translation') {
                                load_translations(false, function() {
                                    context.forceUpdate();
                                    if ( !context.is_nested_popup() ) {
                                        context.props.navigation_handler("home");
                                    } else {
                                        context.closePopup();
                                    }
                                });
                            } else {
                                if ( !context.is_nested_popup() ) {
                                    context.props.navigation_handler("home");
                                } else {
                                    context.closePopup();
                                }
                            }
                        };
                        var error = function (err) {
                            context.setState({error: err.message});
                            server_error_action(err);
                        };
                        var data = {_id: context.state.entity_instance._id};
                        context.props.handle_executing(true);
                        invoke_method(
                            context.state.entity_attributes.entity,
                            "delete",
                            data,
                            success,
                            error,
                            function(){
                                context.props.handle_executing(false);
                            }
                        );
                    }
                }
            });
        }
        this.debugLog('handle_delete', false);
    },

    /**
     * Returns true if we're in a frame opened by another frame
     */
    is_nested_popup: function() {
        var context = this;
        return context.props.data && context.props.data.parent_react_entity;
    },

    handle_workflow_action_click: function (action, status, keep_window_open, event, startingPoint, callback_print_option) {
        this.debugLog('handle_workflow_action_click', true);

        if(callback_print_option) {
            if(!callback_print_option.reports || !callback_print_option.reports.length) {
                callback_print_option = null; //Side note: An event sometimes ends up coming in here instead of callback_print_option
            }
        }

        if (event && "function" == typeof event.preventDefault) {
            event.preventDefault();
        }
        this.state.update_fields.workflow_status = status;
        if (action.is_constructor) {
            this.state.update_fields.constructor_used = action.name;
        }

        this.state.workflow_action = action;

        if (action && action.view_options) {
            if (action.confirmation_message) {
                action.view_options.confirmation_message = action.confirmation_message; //for easy passing to handle_submit
            }
        }

        if (action && action.comment) {
            if (action.button_caption) {
                this.setState({workflowCommentPopup: {title:action.button_caption, action:action}});
            }
            else {
                this.setState({workflowCommentPopup: {action:action}});
            }
        }
        else if (action && action.signature_required) {
            this.setState({workflowSignaturePopup: {action:action}});
            if (action.attachment_required){
                this.setState({workflowAttachmentPopup: action.attachment_required});
            }
        }
        else {
            if (action) {
                /*Set Inactive day*/
                this.handle_submit(null, action.view_options, true, startingPoint, keep_window_open, callback_print_option);
            }
            else {
                this.handle_submit(null, undefined, true, startingPoint, keep_window_open, callback_print_option);
            }
        }
        this.debugLog('handle_workflow_action_click', false);
    },
    close_all_tooltip_popovers: function(){
        //$('.dx-dialog .dx-button[aria-label="No"]').trigger('click');
        if (this.state.popover){
            this.state.popover.hide();
        }
        if (this.state.popover_loading){
            this.state.popover_loading.hide();
        }
        for (var i = 0; this.state.entity_attributes && i < this.state.entity_attributes.attributes.length; i++) {
            var attribute = this.state.entity_attributes.attributes[i];
            if (!attribute.form_visible) {
                continue;
            }

            var info_id = "#info_" + (attribute.safe_field_path || attribute.field_path.replace(/:/g, '-'));

            if ($(info_id + '_desc').text()) {
                $(info_id + "_desc").dxPopover("hide");
                //$(info_id + "_desc").dxPopover("hide");

            }
        }
    },
    nested_entity_handler: function (child_entity_state_data) {
        this.debugLog('nested_entity_handler', true);
        this.close_all_tooltip_popovers();
        child_entity_state_data.entity_access = this.state.entity_access;
        this.save_scroll_position();
        this.setState({
            child_entity_state_data: child_entity_state_data,
            areButtonsDisabled: true
        });
        this.debugLog('nested_entity_handler', false);
    },
    reference_entity_handler: function (reference_entity_attribute, reference_entity_id, target_entity) {
        this.debugLog('reference_entity_handler', true);
        if (target_entity){
            this.state.nested_reference_entity_data = {
                reference_entity_name: target_entity,
                reference_entity_id: reference_entity_id,
                attribute: reference_entity_attribute,
                value: get_json_data_using_field_paths(reference_entity_attribute.field_path, this.state.entity_instance)
            };
        } else {
            this.state.nested_reference_entity_data = {
                reference_entity_name: reference_entity_attribute.db_type,
                reference_entity_id: reference_entity_id,
                attribute: reference_entity_attribute,
                value: get_json_data_using_field_paths(reference_entity_attribute.field_path, this.state.entity_instance)
            };
        }
        this.state.areButtonsDisabled = true;
        this.save_scroll_position();
        this.debugLog('reference_entity_handler', false);
        return this.forceUpdate();
    },
    on_error_close: function () {
        this.debugLog('on_error_close', true);
        this.setState({scrolled : false});
        this.setState({error: undefined});
        this.debugLog('on_error_close', false);
    },
    form_is_read_only: function () {
        return (
            this.state
            && this.state.app_object
            && this.state.app_object.custom_properties
            && !this.state.app_object.custom_properties.editable
            || (this.state.entity_access && this.state.entity_access.indexOf('u')==-1 )
        );
    },
    handleCommentSaveClick: function(comment){
        this.debugLog('handleCommentSaveClick', true);
        this.state.update_fields.workflow_comment = comment;
        if (!comment || (comment.trim() == "")) {
            Notify(R.message_enter_workflow_comment, 'bottom-right', notify_timeout, 'red', 'fa-check', true);
        }
        else {
            var action = this.state.workflowCommentPopup.action;
            this.setState({workflowCommentPopup:undefined});
            this.handle_submit(null, action.view_options, true);
        }
        this.debugLog('handleCommentSaveClick', false);
    },
    handleCommentCancelClick: function(){
        this.debugLog('handleCommentCancelClick', true);
        if(this.state.update_fields) {
            delete this.state.update_fields.workflow_status; // To prevent "cancel and save" glitch
        }
        this.setState({workflowCommentPopup:undefined});
        this.debugLog('handleCommentCancelClick', false);
    },
    handleSignatureSaveClick: function(comment){
        this.debugLog('handleSignatureSaveClick', true);
        var action = this.state.workflowSignaturePopup.action;
        this.setState({workflowSignaturePopup:undefined});
        this.handle_submit(null, action.view_options, true);
        this.debugLog('handleSignatureSaveClick', false);
    },
    handleSignatureCancelClick: function(){
        this.debugLog('handleSignatureCancelClick', true);
        var cancel_action = this.state.workflow_action.cancel_workflow_action;
        if(cancel_action) {
            this.state.entity_instance.workflow_status = cancel_action;
            this.state.update_fields.workflow_status = cancel_action;
            this.handle_submit(null, null, true);
        } else {
            if(this.state.update_fields) {
                delete this.state.update_fields.workflow_status; // To prevent "cancel and save" glitch
            }
        }
        this.setState({workflowSignaturePopup:undefined});
        this.debugLog('handleSignatureCancelClick', false);
    },
    handleAttachmentSaveClick: function(){
        this.debugLog('handleAttachmentSaveClick', true);
        this.state.update_fields.workflow_status = this.state.workflow_action.attachment_workflow_action;
        this.state.entity_instance.attach_report_and_files_to_notification = true;
        this.state.update_fields.attach_report_and_files_to_notification = true;
        this.setState({workflowAttachmentPopup:undefined});
        this.handle_submit(null, null, true);
        this.debugLog('handleAttachmentSaveClick', false);
    },
    handleAttachmentCancelClick: function(comment){
        this.debugLog('handleAttachmentCancelClick', true);
        var cancel_action = this.state.workflow_action.cancel_workflow_action;
        if(cancel_action) {
            this.state.entity_instance.workflow_status = cancel_action;
            this.state.update_fields.workflow_status = cancel_action;
            this.state.entity_instance.attach_report_and_files_to_notification = false;
            this.state.update_fields.attach_report_and_files_to_notification = false;
            this.handle_submit(null, null, true);
        } else {
            if(this.state.update_fields) {
                delete this.state.update_fields.workflow_status; // To prevent "cancel and save" glitch
            }
        }
        this.setState({workflowAttachmentPopup:undefined});
        this.debugLog('handleAttachmentCancelClick', false);
    },
    selectNewItemDropdown:function(newKey, newCaption){
        this.debugLog('selectNewItemDropdown', true);
        /*Function to update states, when added a new option for dropdown*/
        this.setState({  flagSaveDropdown : 2 , newKey :newKey  , newCaption: newCaption});
        this.debugLog('selectNewItemDropdown', false);
    },
    update_state_form_edit:function( data ){
        this.debugLog('update_state_form_edit', true);
        this.setState({not_get_by_code : data});
        this.debugLog('update_state_form_edit', false);
    },
    /* 84230669/Custom function to save in state the list of files uploaded(public or not) for after validate if delete */
    uploadFiles_array: function (data,is_public){
        this.debugLog('uploadFiles_array', true);
        if(is_public==undefined) is_public=false;
        var array_files=[];
        if(!this.state.filesUploaded){
            array_files.push($.parseJSON('{ "_id" : "'+data+'" , "is_public" : "'+is_public+'" }')) ;
        }else{
            var currentArray=this.state.filesUploaded;
            currentArray[currentArray.length]=$.parseJSON('{ "_id" : "'+data+'" , "is_public" : "'+is_public+'" }') ;
            array_files=currentArray;
        }
        this.setState({filesUploaded:array_files});
        this.debugLog('uploadFiles_array', false);
    },
    //86972915 setting state When edit button is pressed
    edit_fun: function (local_attribute, attribute_value){
        this.debugLog('edit_fun', true);
        this.setState( {
            reference_entity_id:attribute_value,
            startEdit : true,
            editAttribute : local_attribute,
            flagSaveDropdown : 1,
            currentFieldPath : local_attribute.field_path,
            currentAttribute: local_attribute
        });
        $("#info_"+(local_attribute.safe_field_path || local_attribute.field_path)).parent().find(".dx-texteditor-input").focus();
        this.reference_entity_handler(local_attribute, null, null);
        this.debugLog('edit_fun', false);
    },
    variant_edit_function: function(local_attribute, attribute_value, target_entity){
        this.debugLog('variant_edit_function', true);
        this.setState( {
            reference_entity_id:attribute_value,
            startEdit : true,
            editAttribute : local_attribute
        });
        $("#info_"+(local_attribute.safe_field_path || local_attribute.field_path)).parent().find(".dx-texteditor-input").focus();

        this.reference_entity_handler(local_attribute, attribute_value, target_entity);
        this.debugLog('variant_edit_function', false);
    },
    /* 86972915 On focus and write new text into dropdown when is saved */
    change_field_to_update : function (name){
        this.debugLog('change_field_to_update', true);
        var context=this;
        $("#info_"+(context.state.editAttribute.safe_field_path || context.state.editAttribute.field_path)).parent().find(".dx-texteditor-input").focus();
        context.on_field_blur(context.state.editAttribute);
        this.debugLog('change_field_to_update', false);
    },
    openDescriptionForm: function(idForm, e) {
        this.debugLog('openDescriptionForm', true);
        $(".descriptionForm[data-unique-id='"+idForm+"' ]").slideToggle();
        e.preventDefault();
        this.debugLog('openDescriptionForm', false);
    },
    openLookupFieldOnTab: function() {
        this.debugLog('openLookupFieldOnTab', true);
        $(".dx-lookup-field").click();
        this.debugLog('openLookupFieldOnTab', false);
    },
    variant_ref_add_button: function(attribute) {
        this.debugLog('variant_ref_add_button', true);
        var context = this;

        return new Promise(function (resolve, reject) {
            if (!context.is_variant_ref_initialized(attribute)) {
                context.init_variant_ref(attribute);

                if (!context.is_variant_ref_searching(attribute)) {
                    var success = function (attribute) {
                        return function (data) {
                            if (data.data.length > 1) {
                                context.state.entity_attributes.field_paths[attribute.field_path].choose_entity = data.data;
                            }
                            else if (data.data.length === 1) {
                                context.state.entity_attributes.field_paths[attribute.field_path].var_ref_entity = data.data;
                            }
                            else {
                                context.state.entity_attributes.field_paths[attribute.field_path].hide_var_ref_buttons = true;
                            }

                            context.debugLog('variant_ref_add_button', false);
                            resolve(context.state.entity_attributes.field_paths[attribute.field_path]);
                        }
                    }(attribute);

                    var error = function (error) {
                        context.set_variant_ref_searching(attribute, false);
                        // display error
                        context.setState({error: error.message});
                        server_error_action(error);
                        // return error
                        context.debugLog('variant_ref_add_button', false);
                        reject(error);
                    };

                    var request = {};
                    request.data = {};
                    request.data.entity_reference_category = attribute.entity_reference_variant.entity_reference_category;
                    if (typeof context.state == "string") {
                        request.data.entity_permissions = context.state;
                    }

                    do_authenticated_http_call({
                        method: "POST",
                        url: "/api/entity/get_entities_with_permissions",
                        contentType: "application/json",
                        dataType: 'json',
                        data: JSON.stringify(request),
                        success: success,
                        error: error
                    });

                    context.set_variant_ref_searching(attribute, true);
                }
                else {
                    resolve(context.state.entity_attributes.field_paths[attribute.field_path]);
                    context.debugLog('variant_ref_add_button', false);
                }
            }
            else {
                resolve(context.state.entity_attributes.field_paths[attribute.field_path]);
                context.debugLog('variant_ref_add_button', false);
            }
        });
    },
    init_variant_ref: function(attribute) {
        this[attribute.field_path + "search"] = {};
    },
    is_variant_ref_initialized: function(attribute) {
        return !!this[attribute.field_path+"search"];
    },
    is_variant_ref_searching: function(attribute) {
        return this[attribute.field_path + "search"].is_variant_ref_searching;
    },
    set_variant_ref_searching: function(attribute, value) {
        this[attribute.field_path + "search"].is_variant_ref_searching = value;
    },
    change_private_state:function(){
        this.debugLog('change_private_state', true);
        if(this.state.entity_instance) {
            if(this.state.entity_instance.assigned_to_private_flag)
            {
                this.state.entity_instance.assigned_to_private_flag = false;
                this.on_field_change(this.state.entity_attributes.field_paths["assigned_to_private_flag"],false);
            }
            else{
                this.state.entity_instance.assigned_to_private_flag = true;
                this.on_field_change(this.state.entity_attributes.field_paths["assigned_to_private_flag"],true);
            }
            this.forceUpdate();
        }
        this.debugLog('change_private_state', false);
    },
    change_following_state:function(){
        this.debugLog('change_following_state', true);
        if(this.state.entity_instance) {
            if(this.state.entity_instance.following_flag)
            {
                this.state.entity_instance.following_flag = false;
                this.on_field_change(this.state.entity_attributes.field_paths["following_flag"],false);
            }
            else{
                this.state.entity_instance.following_flag = true;
                this.on_field_change(this.state.entity_attributes.field_paths["following_flag"],true);
            }
            this.forceUpdate();
        }
        this.debugLog('change_following_state', false);
    },
    assign_users:function(local_attribute){
        this.debugLog('assign_users', true);
        var context = this;
        context.open_popover(local_attribute.safe_field_path || local_attribute.field_path, function(){
            var currentValue = get_json_data_using_field_paths(local_attribute.field_path, context.state.entity_instance),
                element=$('#assign-to-user-select'),
                button_box = $('<div/>', {style:"padding:5px"}),
                save_button_var = $('<button/>',
                    {
                        type:'button',
                        style:'margin:5px; float:right; margin-left:5px: margin-top:5px',
                        class:'btn btn-success shiny workflow_button_margin',
                        id:'button_assigned_to_user',
                        text: R.label_ok || "OK",
                        click: context.save_user_assigned
                    }),
                cancel_button_var = $('<button/>',{
                    type:'button',
                    style:'margin:5px; float:right; margin-left:5px: margin-top:5px',
                    class:'btn shiny btn-default',
                    id:'button_cancel_assigned_to_user',
                    text: R.label_cancel,
                    click: function(){
                        //reset field to actual value:
                        context.state.popover.hide();
                        context.set_user_assigned_select_value(get_json_data_using_field_paths(local_attribute.field_path, context.state.entity_instance));
                    }
                });
            if (currentValue){
                element.val( currentValue.entity_id);
            }

            $(cancel_button_var).appendTo(button_box);
            $(save_button_var).appendTo(button_box);
            $(button_box).appendTo($(element).parent()[0]);
        });
        this.debugLog('assign_users', false);
    },
    set_user_assigned_select_value: function(select_value) {
        if(select_value && select_value.entity_id) {
            select_value = select_value.entity_id;
        }
        $('#assign-to-user-select').val(select_value); //change the selected option
    },
    save_user_assigned:function(){
        this.debugLog('save_user_assigned', true);
        var local_attribute = get_attribute_by_field_path(this.state.entity_attributes.attributes, 'assigned_to_user');
        if (local_attribute){
            this.on_field_change(local_attribute,find_by_field("entity_id", $('#assign-to-user-select').val(), local_attribute.select_options));
            this.state.popover.hide();
        }
        this.debugLog('save_user_assigned', false);
    },
    set_open_state: function (attribute, state){
        this.debugLog('set_open_state', true);
        attribute.is_open = state;
        this.debugLog('set_open_state', false);
    },
    save_scroll_position: function(){
        this.debugLog('save_scroll_position', true);
        this.scrollPosition = (this.get_form_object().find('.widget-body').first().scrollTop() || this.scrollPosition) -10;
        if (this.scrollPosition < 0){
            this.scrollPosition = 0;
        }
        this.restoreScroll = false;
        this.debugLog('save_scroll_position', false);
    },
    restore_scroll_position: function (context) {
        this.debugLog('restore_scroll_position', true);
        context = context || this;
        delete context.recover_scroll;
        if (this.attempts == undefined){
            this.attempts = 5;
        }
        if (context.restoreScroll && !isNaN(context.restoreScroll)){
            var element = context.get_form_object().find('.widget-body'),
                currentPosition = element.scrollTop();
            element.scrollTop(context.scrollPosition);
            if (context.scrollPosition == element.scrollTop()){
                delete context.attempts;
                context.scrollPosition = 0;
                context.restoreScroll = false;
            } else {
                if (currentPosition == element.scrollTop()){
                    context.attempts--;
                }
                if (context.attempts > 0){
                    setTimeout(function(){context.restore_scroll_position(context);}, 200)
                }
                else {
                    context.scrollPosition = 0;
                    context.restoreScroll = false;
                    delete context.attempts;
                }
            }
        }
        this.debugLog('restore_scroll_position', false);
    },
    is_save_and_new: function(){
        this.debugLog('is_save_and_new', true);
        var is_cyclic_insert = this.state.app_object && this.state.app_object.custom_properties && this.state.app_object.custom_properties.cyclic_insert;
        this.debugLog('is_save_and_new', false);
        return  is_cyclic_insert && this.state.is_new_instance && this.props.is_nested_grid_child;
    },
    set_element_reference: function(attribute, element){
        this[attribute.field_path] = element;
    },
    set_confirming: function(set){
        if (set) {
            this.confirming = new Date();
        }
        else {
            delete this.confirming;
        }
    },
    confirm: function(title, message, callback){
        var context = this;

        if (!context.confirming || ((new Date() - context.confirming) >  500)){
            context.set_confirming(true);

            if (this.props.is_nested_grid_child){
                this.props.parent_react_entity.confirm(title, message, function(x){
                    context.set_confirming(false);
                    callback(x);
                });
            }
            else {
                display_yes_no_dialogue(title, message, function(x){
                    context.set_confirming(false);
                    callback(x);
                });
            }
        }
        else{
            callback(false);
        }
    },
    print_reports_button_click: function() {
        var context = this;
        var print_args = context.state.app_object.custom_properties.edit_form_reports;
        var win;
        var requires_window_popup = (isChromeNavigator() && print_args.requires_new_window);

        if (requires_window_popup) {
            win = window.open('', '', '');
        }

        return print_reports(win, [context.state.entity_instance._id], print_args.reports, function (err) {
            if (err) {
                context.hidden_parent_style();
                err = (err.responseJSON || err.data || err);
                context.setState({error: err.message || err});
                server_error_action(err);
            }
        });
    },

    ////////////////////
    // RENDER METHODS //
    ////////////////////

    /**
     * Returns the edit form element
     * @returns {*}
     */
    get_edit_form_element: function() {
        var context = this;

        if (context.state.multiple && (context.state.fileToUpload && context.state.fileToUpload.length > 1)) {
            var input_element = (
                <UploadMultiFilesComponent 
                    files={context.state.fileToUpload} 
                    remove_files={context.remove_file}
                    onUploadEnd={context.on_file_upload_end}
                    onUploadCancel={context.on_file_upload_cancel}>
                </UploadMultiFilesComponent>
            );
            return (
                <div className="row" key={"div-"+input_element.key}>
                    <div className={"col-sm-12"}>
                        <div className="form-group"  style={{marginBottom : "0px"}}>
                            {input_element}
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.nested_reference_entity_data && this.state.nested_reference_entity_data.attribute) { //popup reference entity edit form
            var ref_data = {
                is_reference_nest: true,
                multiple: this.state.app_object.custom_properties.multiple,
                parent_react_entity: this,
                app_object_code: this.state.nested_reference_entity_data.reference_entity_name + "_default_edit_form",
                key: this.state.nested_reference_entity_data.reference_entity_name + "_default_edit_form" + this.state.reference_entity_id,
                parent_entity_field_attribute: this.state.nested_reference_entity_data.attribute,
                selectNewItemDropdown: this.selectNewItemDropdown,
                uploadFiles_array: this.uploadFiles_array,
                parent_entity_array: this.state.nested_reference_entity_data.value,
                change_field_to_update: this.change_field_to_update,
                resize_form_edit: this.hidden_parent_style
            };

            var input_element = (
                <EditFormComponent
                    data={ref_data}
                    new_entity_json={context.state.nested_reference_entity_data.new_entity_json}
                    app_object_handler={this.props.app_object_handler}
                    handle_executing={this.props.handle_executing}
                    _id={this.state.reference_entity_id}
                    key={this.state.reference_entity_id}
                    is_mobile={this.props.is_mobile}
                    navigation_handler={this.props.navigation_handler}
                    update_state_form_edit={this.update_state_form_edit}
                    edit_func={this.edit_fun}
                    display_loader={this.display_loader}
                    parentIsModal={this.props.isModal || this.props.parentIsModal}
                    parent_react_entity={this}
                    run_on_user_closes_window={this.props.run_on_user_closes_window}
                />
            );

            return (
                <div className="row" key={"div-"+input_element.key}>
                    <div className={"col-sm-12"}>
                        <div className="form-group"  style={{marginBottom : "0px"}}>
                            {input_element}
                        </div>
                    </div>
                </div>
            );
        }
        else if (this.state.child_entity_state_data) {
            var input_element = (
                <EditFormComponent
                    data={context.state.child_entity_state_data}
                    handle_executing={this.props.handle_executing}
                    parentIsModal={this.props.isModal||this.props.parentIsModal}
                    is_mobile={this.props.is_mobile}
                    is_nested_grid_child={true}
                    parent_react_entity={this}
                    run_on_user_closes_window={this.props.run_on_user_closes_window}
                />
            );

            return (
                <div  className="row" key={"div-"+input_element.key} >
                    <div className={"col-sm-12"}>
                        <div className="form-group" style={{marginBottom : "0px"}}   >
                            {input_element}
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    },

    /**
     * Returns the cancel button
     * @returns {boolean}
     */
    get_cancel_button: function() {
        return (
            <button type="button"
                    id="cancel-button"
                    style={{"margin":"0px", "float":"right","marginLeft":"5px","marginTop":"5px"}}
                    onClick={this.handle_close}
                    className="btn btn-default shiny"
                    disabled={this.state.areButtonsDisabled}
                    key={"QK-form-buttons-cancel"}>
                {R.label_cancel}
            </button>
        );
    },

    /**
     * Returns the delete button
     * @returns {null}
     */
    get_delete_button: function() {
        if ((this.state.entity_access && this.state.entity_access.indexOf('d') !== -1 && !this.state.app_object.custom_properties.disable_delete)
            && !(this.props.data && this.props.data.disable_delete)
            && !this.state.is_new_instance) {
            return (
                <button type="button"
                        id="delete-button"
                        style={{"margin":"0px", "float":"right","marginLeft":"5px" , "marginTop":"5px"}}
                        onClick={this.handle_delete}
                        disabled={this.state.areButtonsDisabled}
                        key={"QK-form-buttons-delete"}
                        className="btn btn-danger shiny workflow_button_margin">
                    {R.label_delete}
                </button>
            );
        }

        return null;
    },

    /**
     * Returns the print button
     * @returns {null}
     */
    get_print_button: function() {
        if (!this.state.is_new_instance
            && (this.state.app_object && this.state.app_object.custom_properties && this.state.app_object.custom_properties.edit_form_reports)) {
            return (
                <button type="button"
                        id="print-button"
                        style={{"margin":"0px", "float":"right","marginLeft":"5px","marginTop":"5px"}}
                        onClick={this.print_reports_button_click}
                        className="btn btn-default shiny"
                        disabled={this.state.areButtonsDisabled}
                        key={"QK-form-buttons-print"}>
                    {R.print || "Print"}
                </button>
            );
        }

        return null;
    },

    /**
     * Return the save buttons
     * @returns {{save_button: *, save_and_close_button: *}}
     */
    get_save_buttons: function() {
        var context = this,
            save_button,
            save_and_close_button;

        if (this.state.entity_access
            && (this.state.entity_access.indexOf('c') !== -1 || this.state.entity_access.indexOf('u') !== -1)
            && !(this.state.app_object.custom_properties && this.state.app_object.custom_properties.disable_save)) {

            var save_click = function(_event) {
                return context.handle_submit(_event, null, null, null, true);
            };

            save_button = (
                <button type="button"
                        id="submit-button"
                        style={{"margin":"0px" , "float" : "right","marginLeft":"5px","marginTop":"5px"}}
                        onClick={save_click}
                        disabled={this.state.areButtonsDisabled}
                        className="btn1 btn btn-success shiny workflow_button_margin"
                        key="QK-form-buttons-save">
                    {R.label_save}
                </button>
            );

            if (!(context.props.data
                && (context.props.data.parent_react_entity || context.props.data.is_reference_nest))) {

                save_and_close_button = (
                    <button type="button"
                            id="submit-button-close"
                            style={{
                                "margin": "0px",
                                    "float": "right",
                                    "marginLeft": "5px",
                                    "marginTop": "5px"
                            }}
                            onClick={context.handle_submit}
                            disabled={this.state.areButtonsDisabled}
                            className="btn1 btn btn-success shiny workflow_button_margin"
                            key="QK-form-buttons-save-and-close">
                        {R.label_save + R.label_save_and_close}
                    </button>
                );
            }
        }

        return {
            save_button: save_button,
            save_and_close_button: save_and_close_button
        }
    },

    /**
     * Returns the caption
     * @param app_object_name
     * @param has_update_access
     * @returns {*}
     */
    get_caption: function(app_object_name, has_update_access) {
        var context = this,
            hasCaption = true,
            caption;

        if (this.state.caption_function) {
            if (this.state.entity_instance) {
                caption = this.state.caption_function(this.state.entity_instance);
                hasCaption = !!caption;
            }
            else {
                caption = this.state.caption_function(this.state.entity_attributes.attributes);
            }
        }

        if ((!caption || ($.trim(caption.trim) == ""))) {
            if (!context.props.duplicate_id) {
                caption = app_object_name;
            }
            if ((this.state.entity_attributes && this.state.entity_attributes.caption_singular)
                && !context.props.duplicate_id) {
                caption = this.state.entity_attributes.caption_singular + ": " + (caption || " ");
            }
        }

        if (this.state.is_new_instance && has_update_access) {
            if (!caption || (caption.trim() == "")) {
                caption = app_object_name;
            }
            else if (caption.indexOf(app_object_name) != -1 && !hasCaption) {
                caption = R.label_new + " " + caption;
            }

            if (caption == app_object_name
                && context.props.duplicate_id
                && !hasCaption) {
                caption = R.label_new + " " + caption;
            }
        }

        return caption;
    },

    /**
     * Returns the save button (workflow)
     * @param workflow_constructor
     * @param workflow_constructor_key
     * @param status
     * @returns {*}
     */
    get_workflow_save_button: function(workflow_constructor, workflow_constructor_key, status) {
        return this.get_workflow_button(
            this.handle_workflow_action_click.bind(this, workflow_constructor, status, false),
            "QK-form-buttons-workflow-" + workflow_constructor_key + "-and-close",
            R.label_save + R.label_save_and_close
        );
    },

    /**
     * Returns the print button (workflow)
     * @param click_action
     * @param button_key
     * @param button_name
     * @returns {*}
     */
    get_workflow_print_button: function(click_action, button_key, button_name) {
        return this.get_workflow_button(
            click_action,
            button_key,
            button_name
        );
    },

    /**
     * Returns the caption button (workflow)
     * @param workflow_constructor
     * @param workflow_constructor_key
     * @param this_default_constructor_closes_edit_form
     * @returns {*}
     */
    get_workflow_caption_button: function(workflow_constructor, workflow_constructor_key, this_default_constructor_closes_edit_form, status) {
        return this.get_workflow_button(
            this.handle_workflow_action_click.bind(this, workflow_constructor, status, !this_default_constructor_closes_edit_form),
            "QK-form-buttons-workflow-" + workflow_constructor_key,
            workflow_constructor.button_caption
        );
    },

    /**
     * Generic method for returning a workflow button
     * @param click_action
     * @param button_key
     * @param button_name
     * @returns {boolean}
     */
    get_workflow_button: function(click_action, button_key, button_name) {
        var workflow_button_style = {
            "margin": "0px",
            "float": "right",
            "marginLeft": "5px",
            "marginTop": "5px"
        };
        var workflow_button_className = "btn btn-primary shiny workflow_button_margin";

        return (
            <button type="button"
                    style={workflow_button_style}
                    disabled={this.state.areButtonsDisabled}
                    onClick={click_action}
                    key={button_key}
                    className={workflow_button_className}>
                {button_name}
            </button>
        );
    },

    /**
     * Generic method for returning a icon button
     * @param data_unique_id
     * @param icon_button_click_action
     * @param icon_button_className
     * @param extraOptions
     * @returns {boolean}
     */
    get_icon_button: function(data_unique_id, icon_button_click_action, icon_button_className, extraOptions) {
        var id,
            additional_style,
            fontSize;

        if (extraOptions) {
            id = extraOptions.id;
            additional_style = extraOptions.additional_style;
            fontSize = extraOptions.fontSize;
        }

        var defaultFontSize = fontSize ? fontSize : "14px",
            icon_button_style = {
                cursor : "pointer",
                color : "rgba(96, 96, 96, 1)",
                display : "inline-flex",
                marginRight: this.props.is_mobile ? "0" : "10px",
                fontSize : this.props.is_mobile ? "18px" : defaultFontSize,
                lineHeight : "34px"
            }
        ;

        if (additional_style) {
            Object.assign(icon_button_style, additional_style);
        }

        if (id) {
            return (
                <i
                    id={id}
                    style={icon_button_style}
                    data-unique-id={data_unique_id}
                    onClick={icon_button_click_action}
                    className={icon_button_className}
                    aria-hidden="true"
                    is-nested="true"
                >
                    &nbsp;
                </i>
            );
        }

        return (
            <i
                style={icon_button_style}
                data-unique-id={data_unique_id}
                onClick={icon_button_click_action}
                className={icon_button_className}
                aria-hidden="true"
                is-nested="true"
            >
                &nbsp;
            </i>
        );
    },

    /**
     * Returns the loading tag
     * @returns {*}
     */
    get_loading_tag: function() {
        if (this.state.tag_popover_loading) {
            return (
                <div id="Loading_tag">
                    <div style={{"textAlign":"center","margin":"auto"}}>
                        <img style={{"height":"30px"}} src="css/icons/loader.gif" />
                    </div>
                </div>
            );
        }

        return '';
    },

    /**
     * Returns the rhs buttons
     * @param header_fields_input_array
     * @returns {{descriptionButton: (*|boolean), lockButton: string, followButton: string, assignButton: *, close_button: (*|boolean)}}
     */
    get_rhs_buttons: function(idEntity, header_fields_input_array) {
        var context = this;

        var lockState = this.state.entity_instance && this.state.entity_instance.assigned_to_private_flag
            ? "fa-lock"
            : "fa-unlock-alt";
        var followState = this.state.entity_instance && this.state.entity_instance.following_flag
            ? "fa-rss-square"
            : "fa-rss";
        var assignState = "fa fa-user-plus";

        var descriptionButton = context.get_icon_button(
            idEntity,
            this.openDescriptionForm.bind(this, idEntity),
            "fa fa-question fa-3"
        );

        var lockButton, followButton, assignButton;

        if (this.state.entity_attributes) {
            var is_assigned_to_private_flag_existing = !!this.state.entity_attributes.field_paths["assigned_to_private_flag"],
                is_assigned_to_private_flag_read_only = is_assigned_to_private_flag_existing && this.state.entity_attributes.field_paths["assigned_to_private_flag"].read_only === true;
            if (is_assigned_to_private_flag_existing && !is_assigned_to_private_flag_read_only) {
                lockButton = context.get_icon_button(
                    idEntity,
                    this.change_private_state.bind(this, idEntity),
                    "fa "+ lockState
                );
            }

            var is_follow_button_field_existing = !!this.state.entity_attributes.field_paths["following_flag"],
                is_follow_button_read_only = (is_follow_button_field_existing && this.state.entity_attributes.field_paths["following_flag"].read_only === true)
                    || this.form_is_read_only();
            if (is_follow_button_field_existing && !is_follow_button_read_only) {
                followButton = context.get_icon_button(
                    idEntity,
                    this.change_following_state.bind(this, idEntity),
                    "fa "+ followState
                );
            }

            var is_assign_field_existing = !!this.state.entity_attributes.field_paths["assigned_to_user"],
                is_assign_read_only = (is_assign_field_existing && this.state.entity_attributes.field_paths["assigned_to_user"].read_only === true)
                    || this.form_is_read_only();
            if (is_assign_field_existing && !is_assign_read_only) {
                var assignedattribute = this.state.entity_attributes.field_paths["assigned_to_user"];
                var assign_button_additional_style;
                if (this.props.is_mobile) {
                    assign_button_additional_style = {
                        paddingLeft: "5px"
                    };
                }

                var assignButtonIcon = context.get_icon_button(
                    idEntity,
                    this.assign_users.bind(this, assignedattribute),
                    "fa "+ assignState,
                    { id: "assigned_to_user", additional_style: assign_button_additional_style }
                );

                assignButton = (
                    <span>
                        {assignButtonIcon}
                        {header_fields_input_array}
                    </span>
                );
            }
        }

        var close_button = context.get_icon_button(
            idEntity,
            this.handle_close,
            "fa fa-times",
            { id: "close-button", fontSize: "14.7px" }
        );

        return {
            descriptionButton: descriptionButton,
            lockButton: lockButton,
            followButton: followButton,
            assignButton: assignButton,
            close_button: close_button
        }
    },

    /**
     * Returns the container for the rhs_buttons
     * @param rhs_buttons
     * @returns {boolean}
     */
    get_full_rhs_buttons_container: function(rhs_buttons) {
        var assignButton = rhs_buttons.assignButton,
            followButton = rhs_buttons.followButton,
            lockButton = rhs_buttons.lockButton,
            descriptionButton = rhs_buttons.descriptionButton,
            close_button = rhs_buttons.close_button;

        if (this.props.is_mobile) {
            return (
                <div className="widget-buttons edit_form_header_content_holder edit_form_header_content_holder_mobile edit_form_header_buttons">
                    <div className="custom_mobile_dropdown">
                        <div className="custom_mobile_dropbtn dx-button-content">
                            <i className="dx-icon dx-icon-overflow"></i>
                        </div>
                        <div className="custom_mobile_dropdown_content">
                            <div>{assignButton}</div>
                            <div>{followButton}</div>
                            <div>{lockButton}</div>
                            <div>{descriptionButton}</div>
                        </div>
                    </div>
                    {close_button}
                </div>
            );
        }

        return (
            <div className="widget-buttons edit_form_header_content_holder">
                {assignButton}
                {followButton}
                {lockButton}
                {descriptionButton}
                {close_button}
            </div>
        );
    },

    /**
     * Returns the error component
     * @param error_display
     * @returns {null}
     */
    get_error_component: function(error_display) {
        var isIE = !!document.documentMode;

        if (this.state) {
            if (isIE) {
                return (
                    <div className="ie-warning alert alert-danger" style={{ display: error_display, "paddingBottom":"15px" }}>
                        <div className="ie-close" style={{ float: "right", color:"black", cursor:"pointer" }} onClick={this.on_error_close}>
                        X
                        </div>
                        {this.state.error}
                    </div>
                );
            }

            return (
                <ErrorNotificationComponent
                    message={this.state.error}
                    on_close={this.on_error_close}
                />
            );
        }

        return null;
    },

    /**
     * Returns the error component container
     * @param error_component
     * @param error_display
     * @param is_creating_new_object
     * @returns {boolean}
     */
    get_error_component_container: function(error_component, error_display, is_creating_new_object) {
        if (is_creating_new_object) {
            return (
                <span style={{display: error_display}}>
                    {error_component}
                </span>
            );
        }

        return (
            <span id="one" style={{display: error_display}}>
                <hr className="wide"/>
                {error_component}
            </span>
        );
    },

    /**
     * Returns a bunch of properties useful to build the workflow components
     * @param has_update_access
     * @returns {{workflow_buttons: Array, workflow_status: string, workflow_constructor: *, has_constructor: *, has_default: *}}
     */
    get_workflow_properties: function(has_update_access) {
        var context = this,
            workflow_buttons = [],
            workflow_status = "",
            workflow_constructor;
        var has_constructor,
            has_default;

            if (has_update_access && this.state.is_new_instance) {
                if (this.state.workflow_states) {
                    for (var status in this.state.workflow_states) {
                        if (this.state.workflow_states.hasOwnProperty(status)) {
                            var workflow_state = this.state.workflow_states[status];

                            if (workflow_state.default) {
                                has_default = true;
                            }

                            if (workflow_state.constructors) {
                                for (var workflow_constructor_key in workflow_state.constructors) {
                                    if (workflow_state.constructors.hasOwnProperty(workflow_constructor_key)) {
                                        workflow_constructor = workflow_state.constructors[workflow_constructor_key];
                                        var keys = [];

                                        has_constructor = true;
                                        workflow_constructor.name = workflow_constructor_key;
                                        workflow_constructor.is_constructor = true;

                                        if (keys.indexOf(workflow_constructor_key) == -1) {
                                            keys.push(workflow_constructor_key);
                                            var this_default_constructor_closes_edit_form = true;

                                            if (workflow_constructor.button_caption == R.label_save
                                                && !(context.props.data && context.props.data.is_reference_nest)) { //"Save" buttons are not supposed to auto-close form, so we add a "Save and Close" button
                                                this_default_constructor_closes_edit_form = false;
                                                workflow_buttons.push(context.get_workflow_save_button(workflow_constructor, workflow_constructor_key, status));
                                            }

                                            if (workflow_constructor.print_option) {
                                                var button_print_key = "QK-form-buttons-workflow-" + workflow_constructor_key + "-and-print";
                                                var button_print_name = workflow_constructor.button_caption + R.label_save_and_print;
                                                workflow_buttons.push(context.get_workflow_print_button(
                                                    this.handle_workflow_action_click.bind(this, workflow_constructor, status, false, null, null, workflow_constructor.print_option),
                                                    button_print_key,
                                                    button_print_name
                                                ));
                                            }

                                            workflow_buttons.push(context.get_workflow_caption_button(workflow_constructor, workflow_constructor_key, this_default_constructor_closes_edit_form, status));
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else if (!this.state.is_new_instance && this.state.workflow_states) { // May or may not "has_update_access"
                var entity_instance_status = this.state.entity_instance.workflow_status;

                if (entity_instance_status) {
                    for (var status in this.state.workflow_states) {
                        if (status == entity_instance_status) {
                            var workflow_state = this.state.workflow_states[status];
                            workflow_status = workflow_state;

                            for (var workflow_action_status in workflow_state.workflow_actions) {
                                var workflow_action = workflow_state.workflow_actions[workflow_action_status];

                                if (workflow_action.button_caption) {
                                    var button_key = "QK-form-buttons-workflow-action-" + workflow_action.button_caption;
                                    var button_name = workflow_action.button_caption;

                                    if(!has_update_access && (!workflow_action.user_roles || !workflow_action.user_roles.length)) {
                                        // Needs to be explicitly listed as a user_role that can do this action (filtering by this would have been done server-side, so it is enough for us to verify the field exists, which would restrict this option by roles)

                                    }
                                    else {
                                        if (workflow_action.print_option) {
                                            workflow_buttons.push(context.get_workflow_print_button(
                                                this.handle_workflow_action_click.bind(this, workflow_action, workflow_action_status, false, null, null, workflow_action.print_option),
                                                button_key,
                                                button_name
                                            ));
                                        }
                                        else {
                                            workflow_buttons.push(context.get_workflow_print_button(
                                                this.handle_workflow_action_click.bind(this, workflow_action, workflow_action_status, false),
                                                button_key,
                                                button_name
                                            ));
                                        }
                                    }
                                }
                            }

                            break;
                        }
                    }
                }
            }

        return {
            workflow_buttons: workflow_buttons,
            workflow_status: workflow_status,
            workflow_constructor: workflow_constructor,
            has_constructor: has_constructor,
            has_default: has_default
        };
    },

    /**
     * Return the form buttons
     * @param workflow_properties
     * @returns {*}
     */
    get_form_buttons: function(workflow_properties) {
        var context = this;

        if (!this.state.init || this.state.areButtonsDisabled || this.state.uploadFileModal || this.state.workflowCommentPopup || this.state.workflowSignaturePopup) {
            return null;
        }

        // Form buttons
        var form_buttons = [];

        // Cancel button
        var cancel_button = context.get_cancel_button();
        // Delete button
        var delete_button = context.get_delete_button();
        // Print button
        var print_button = context.get_print_button();
        // Save buttons
        var save_buttons = context.get_save_buttons();
        var save_button = save_buttons.save_button;
        var save_and_close_button = save_buttons.save_and_close_button;

        //Workflow buttons
        var workflow_buttons = workflow_properties.workflow_buttons,
            workflow_constructor = workflow_properties.workflow_constructor,
            has_constructor = workflow_properties.has_constructor,
            has_default = workflow_properties.has_default;

        //save and new
        //show if is nested grid new item and flag sent from server. Replaces save button.
        var saveandnew;

        if (this.is_save_and_new()) {
            var saveandnew_click = (this.state.workflow_states && this.state.is_new_instance)
                    ? this.handle_workflow_action_click.bind(this, workflow_constructor, status, false)
                    : context.handle_submit.bind(this, null, undefined, true),
                saveandnew_label = R.client_saveandnew_button_label ? R.client_saveandnew_button_label : "Save and new";

            saveandnew = this.get_workflow_button(saveandnew_click, "QK-form-buttons-save-new", saveandnew_label);
        }

        //Fill button box in proper order.
        if (cancel_button) {
            form_buttons.push(cancel_button);
        }
        if (print_button) {
            form_buttons.push(print_button);
        }
        if (delete_button) {
            form_buttons.push(delete_button);
        }

        if (workflow_buttons.length > 0) {
            workflow_buttons.forEach(function(x){
                form_buttons.push(x);
            });
        }

        if (!(has_default || has_constructor) && save_button) {
            if (save_and_close_button) {
                form_buttons.push(save_and_close_button);
            }

            form_buttons.push(save_button);
        }

        if (saveandnew) {
            if (!this.save_and_new) {
                this.save_and_new = true;
            }
            form_buttons.push(saveandnew);
        }

        return form_buttons;
    },

    /**
     * Returns the html displaying the workflow status
     * @param workflow_status
     * @returns {*}
     */
    get_workflow_status_html: function(workflow_status) {
        // intialize border color to light gray
        this.set_border_color("lightgray");

        if (workflow_status
            && workflow_status.caption
            && workflow_status.caption.trim() != "") {

            if (workflow_status.view_options && workflow_status.view_options.class_color) {
                this.set_border_color(workflow_status.view_options.class_color);
            }

            return (
                <span style={{"marginRight":"5px"}}>
                    {" "}
                    <span className={"label label-" + this.get_border_color()}>
                        {" " + workflow_status.caption.toUpperCase() + " "}
                    </span>
                </span>
            );
        }

        return null;
    },

    /**
     * Returns the upload file modal
     * @returns {null}
     */
    get_upload_file_modal: function() {
        if (this.state.uploadFileModal && (this.state.fileToUpload  && this.state.fileToUpload.length === 1)) {
            var fileToUpload = this.state.fileToUpload;
            return ( <EditorFileUploadModal
                file={fileToUpload[0]}
                fileId={this.state.entity_instance._id}
                onUploadEnd={this.on_file_upload_end.bind(this)}
                onUploadCancel={this.on_file_upload_cancel.bind(this)}
            />);
        }
        return null;
    },

    /**
     * Returns the comment popup
     */
    get_workflow_comment_popup: function() {
        if (this.state.workflowCommentPopup) {
            return (
                <EditFormComment
                    title={this.state.workflowCommentPopup.title}
                    handleSaveClick={this.handleCommentSaveClick}
                    handleCancelClick={this.handleCommentCancelClick}
                    comment={this.state.update_fields.workflow_comment}
                    parentIsModal={this.props.isModal || this.props.parentIsModal}
                />
            );
        }
        else if (this.state.workflowSignaturePopup) {
            return (
                <EditFormSignature
                    handleSaveClick={this.handleSignatureSaveClick}
                    handleCancelClick={this.handleSignatureCancelClick}
                    parentIsModal={this.props.isModal || this.props.parentIsModal}
                    hasAttachmentPopUp = {this.state.workflowAttachmentPopup}
                    handleAttachmentSaveClick = {this.state.workflowAttachmentPopup ? this.handleAttachmentSaveClick: undefined}
                    handleAttachmentCancelClick = {this.state.workflowAttachmentPopup ? this.handleAttachmentCancelClick: undefined}
                />
            );
        }
    },

    /**
     * Returns the html for the form description
     * @param styleParent
     * @returns {*}
     */
    get_edit_form_description: function(styleParent) {
        if ((this.state.app_object && this.state.app_object.description)
            || (this.state.entity_attributes && this.state.entity_attributes.description_singular)) {

            var borderColor = this.get_border_color();
            var app_object_desc =
                this.state.app_object && this.state.app_object.description
                    ? this.state.app_object.description
                    : this.state.entity_attributes.description_singular;

            var stylecontainer= {
                "wordBreak": "break-word",
                "borderStyle": "solid",
                "borderWidth": "2px",
                "borderRadius": "5px",
                "marginBottom": "10px",
                "position":"relative",
                "display": styleParent
            };
            var styleback = {
                "backgroundColor": borderColor,
                "width": "100%",
                "opacity":"0.3",
                "height":"100%",
                "position":"absolute"
            };
            var styletext = {
                "padding": "10px",
                "color":"black"
            };

            return (
                <div
                    className={"bordered-bottom bordered-"+borderColor}
                    style={stylecontainer}
                >
                    <div style={styleback}>
                    </div>
                    <div style={styletext}>
                        <i style={{"marginRight":"5px"}} className="fa fa-check-square-o">
                            &nbsp;
                        </i>
                        {app_object_desc}
                    </div>
                </div>
            );
        }

        return "";
    },

    /**
     * Returns html for the title
     * @param caption
     * @param is_creating_new_object
     * @param workflow_status_html
     * @param full_rhs_buttons_container
     * @returns {string}
     */
    get_title_part_div: function(caption, app_object_name, is_creating_new_object, workflow_status_html, full_rhs_buttons_container) {
        var cap = is_creating_new_object ? caption : '';
        if (caption && !is_creating_new_object){
            if (caption.indexOf(':') != -1){
                var cap_array = caption.split(':');
                if (cap_array[1].trim()){
                    cap = cap_array[1].trim();
                } else {
                    cap = cap_array[0].trim();
                }
            } else {
                cap = caption;
            }
        }

        var title_part_div = "",
            title_part_of_edit_form_option_1,
            title_part_of_edit_form_option_2,
            style_object_for_title_option_text = { "textAlign": "left", "lineHeight": "34px" },
            title_part_of_edit_form_3;

        var title_part_of_edit_form_option_1_className =
            is_creating_new_object ? "col-def-xxs-3  col-def-xxm-4" : "col-def-xxs-5 col-def-xxm-7"
            + " widget-caption edit_form_header_content_holder"
            + this.props.is_mobile ? " edit_form_header_content_holder_mobile edit_form_header_title" : "";

        title_part_of_edit_form_option_1 = (
            <div
                id={(this.props.is_mobile) ? "" : "option1"}
                className={title_part_of_edit_form_option_1_className}
            >
                <div
                    className="widget-caption truncate-text"
                    style={style_object_for_title_option_text}
                >
                    <b>{cap}</b>
                </div>
            </div>
        );

        if (this.props.is_mobile) {
            title_part_div = (
                <div className="edit_form_header_content_holder edit_form_header_content_holder_mobile" style={{"width": "100%"}}>
                    {title_part_of_edit_form_option_1}
                    {workflow_status_html}
                    {full_rhs_buttons_container}
                </div>
            );
        }
        else {
            title_part_of_edit_form_option_2 = (
                <div
                    id="option2"
                    className={(is_creating_new_object ? "col-def-xxl-5 col-sm-9" : "col-def-xxl-7 col-sm-8") + " col-md-9 col-lg-9 widget-caption edit_form_header_content_holder"}>
                    <div
                        className="widget-caption truncate-text"
                        style={style_object_for_title_option_text}>
                        <b>  {caption || app_object_name || " "}</b>
                    </div>
                </div>
            );
            title_part_of_edit_form_3 = (
                <div
                    className="col-def-xxs-9 col-def-xxm-8 col-def-xxl-7  col-sm-3 col-md-3 col-lg-3 widget-caption edit_form_header_content_holder"
                    style={{"textAlign": "right", "paddingRight": "0px"}}
                >
                </div>
            );
            title_part_div = (
                <div
                    className="edit_form_header_content_holder"
                    style={{"width": "100%"}}
                >
                    {title_part_of_edit_form_option_1}
                    {title_part_of_edit_form_option_2}
                    {title_part_of_edit_form_3}
                    {workflow_status_html}
                    {full_rhs_buttons_container}
                </div>
            );
        }

        return title_part_div;
    },

    /**
     * Returns the style for the workflow button box
     * @param is_creating_new_object
     * @param styleParent
     * @returns {JSON}
     */
    get_workflow_button_box_style: function(is_creating_new_object, styleParent) {
        var workflow_button_box_style = {
            width: "100%",
            height: "100%",
            padding: "10px",
            backgroundColor: "white",
            boxShadow: "1px 0 10px 1px rgba(0,0,0,0.3)",
            textAlign: "right"
        };
        if (!is_creating_new_object) {
            workflow_button_box_style.display = styleParent == 'block'? 'inline-table' : 'none';
        }

        return workflow_button_box_style;
    },

    ///////////////////////
    // GETTERS - SETTERS //
    ///////////////////////
    get_border_color: function() {
        return this.border_color;
    },
    set_border_color: function(border_color) {
        this.border_color = border_color;
    },

    ////////////
    // RENDER //
    ////////////
    render: function () {
        this.debugLog('render', true);

        var context = this,
            input_array = [],
            styleParent = "block",
            record_fields_box,
            record_fields_box_show,
            header_fields_input_array = [],
            is_creating_new_object = this.props.isModal && this.state.is_new_instance;

        var edit_form = context.get_edit_form_element();

        if ((this.state.nested_reference_entity_data && this.state.nested_reference_entity_data.attribute) || this.state.child_entity_state_data) {
            styleParent = "none";
        }
        else {
            var options = {
                record_fields_only: (this.state.custom_html_template && true),
                header_fields_input_array: header_fields_input_array
            };
            input_array = create_form_controls_for_attributes(context, options);
            record_fields_box_show = options.record_fields_box_show;
            record_fields_box = options.record_fields_box;
            header_fields_input_array = options.header_fields_input_array;
        }

        var error_display = this.state.error ? styleParent : 'none';
        var error_component = context.get_error_component(error_display);
        var error_component_span = context.get_error_component_container(error_component, error_display, is_creating_new_object);

        if (this.state.app_object && this.state.app_object.name) {
            app_object_name = this.state.app_object.name;
        }

        var app_object_name,
            has_update_access = this.state.entity_access && this.state.entity_access.indexOf('u') !== -1;

        //Workflow buttons
        var workflow_properties = context.get_workflow_properties(has_update_access);
        var workflow_status = workflow_properties.workflow_status;

        var form_buttons = context.get_form_buttons(workflow_properties);

        var workflow_status_html = context.get_workflow_status_html(workflow_status);
        if (!workflow_status_html) {
            workflow_status = null;
        }

        var uploadFileModal = context.get_upload_file_modal();

        var workflow_comment_popup = context.get_workflow_comment_popup();

        var edit_form_description = context.get_edit_form_description(styleParent);

        var idEntity = this.state.entity_instance
            ? this.state.entity_instance._id || 0
            : 0;
        var rhs_buttons = context.get_rhs_buttons(idEntity, header_fields_input_array);
        var full_rhs_buttons_container = context.get_full_rhs_buttons_container(rhs_buttons);

        var caption = this.get_caption(app_object_name, has_update_access);
        var title_part_div = context.get_title_part_div(caption, app_object_name, is_creating_new_object, workflow_status_html, full_rhs_buttons_container);

        var dataObject =
            this.state.app_object_code
            || context.props.app_object_code
            || context.props.data.app_object_code
            || context.props.data.app_object.code;

        var workflow_button_box_style_object = context.get_workflow_button_box_style(is_creating_new_object, styleParent);

        var loading_tag = context.get_loading_tag();

        var body = (
            <form id="edit_form"
                  role="form"
                  data-object={dataObject}
                  onSubmit={this.handle_submit}
                  style={{marginBottom: "0px"}}
            >
                <div
                    className="widget"
                    style={{marginBottom: "0px"}}
                    key={idEntity}
                >
                    <div
                        className={"widget-header edit_form_header bordered-bottom bordered-" + this.get_border_color()}
                        style={{display: styleParent}}
                    >
                        {title_part_div}
                    </div>

                    <div
                        id="form-body"
                        data-unique-id=''
                        className="widget-body"
                        data-is-nested={styleParent == 'block' ? 'false' : 'true' }
                        data-resize='resize_element'
                        style={{
                            height: this.get_height(styleParent),
                            overflowX: "hidden",
                            overflowY: "auto",
                            padding: styleParent == 'block' ? "10px" : "0px"
                        }}
                    >
                        <span style={{display: styleParent}}>
                            <span className="descriptionForm"
                                  style={(this.state.app_object && this.state.app_object.custom_properties && this.state.app_object.custom_properties.autoexpand_description) ? {display: "block"} : {display: "none"}}
                                  data-unique-id={idEntity}
                            >
                                {edit_form_description}
                            </span>
                            {uploadFileModal}
                            {workflow_comment_popup}
                        </span>
                        <div
                            style={ ((uploadFileModal == null) && (workflow_comment_popup == null)) ? {paddingBottom: "0px"} : {"display": "none"}}
                        >
                            {error_component_span}
                            {edit_form}
                            {
                                (this.state.custom_html_template && !(this.state.nested_reference_entity_data && this.state.nested_reference_entity_data.attribute) && !this.state.child_entity_state_data)
                                    ?
                                    <CustomEditFormComponent
                                        state_data={this.state}
                                        props_data={this.props}
                                        edit_form_context={this}
                                        nested_list_entities={this.state.nested_list_entities}
                                        entity_attributes={this.state.entity_attributes}
                                        entity_instance={this.state.entity_instance}/>
                                    :
                                        input_array
                            }
                            {loading_tag}
                        </div>
                    </div>

                    <div id="workflow-button-box"
                         align="right"
                         className="pull-right"
                         data-unique-id={idEntity}
                         data-section-number={"999"}
                         style={workflow_button_box_style_object}
                    >
                        <div style={{width: "auto"}}>
                            <div id="record-fields-box" style={{
                                display: record_fields_box_show ? "inline" : "none",
                                overflow: "visible",
                                textAlign: "left",
                                float: "left"
                            }}>
                                {record_fields_box}
                            </div>
                            <div style={{display: "inline"}}>
                                {form_buttons}
                            </div>
                        </div>
                        <div style={{width: "auto", position: "absolute", opacity: "0"}}>
                            <div id="record-fields-box-test" style={{
                                display: "inline",
                                overflow: "visible",
                                textAlign: "left",
                                float: "left",
                                paddingRight: "30px",
                                right: "10px",
                                zIndex: "-1"
                            }}>
                                {record_fields_box}
                            </div>
                            <div style={{display: "inline"}}>
                                {form_buttons}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );

        $('.fachange_history').removeClass('fachange_history').addClass( "fa fa-pencil-square-o" );
        $('.facomment').removeClass('fachange_history').addClass( "fa fa-comment-o" );
        $('.comment-footer[note_type!="comment"]').css("display" , "none");
        $('.faworkflow').removeClass('fachange_history').addClass( "fa fa-check-circle-o" );
        $('.fasystem_event').removeClass('fachange_history').addClass( "fa fa-asterisk" );

        context.debugLog('render', false);

        if (this.props.isModal) {
            return (
                <ModalComponent  handleHideModal={this.handle_close} ref="modal">
                    { body }
                </ModalComponent>
            );
        }

        return body;
    }
});
