/**
 * ========================================================
 * Description: An image upload web control
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var EditorDevExtremeImageUpload = React.createClass({
  getInitialState: function() {
    var organization = get_caption_organization();

    return {
      data: this.props.value ? [this.props.value] : [],
      description: [],
      error: undefined,
      initState: this.props.value,
      caption_organization: organization,
      enable_upload: false,
      loading: false,
    };
  },
  uploadFiles: function(event) {
    event.preventDefault();
    var self = this;
    self.setState({loading: true});

    self.state.description.forEach(function (file, index) {
        var request = {};
        request.entity = "app_object";
        request.method = "get_by_code";
        request.data = {
          app_object_code: "wo_insp_required_photos_default_edit_form",
          separate_values: true
        };

        var headers = { "Content-Type": "application/json" };
        var file_description = file.description;
        var file_itemNo = file.itemNo;
        var idEntity;
        var dataFile;

        do_authenticated_http_call({
          method: "POST",
          url: "/api/entity/invoke_method",
          headers: headers,
          data: JSON.stringify(request),
          success: function (response) {
            self.setState({loading: false});
            self.loading = false;
            idEntity = response.data.values._id;
            dataFile = {
              index: index,
              item_no: file_itemNo,
              description: file_description,
              photo: file.file,
              _id:idEntity
            };
  
            var request_callback = {};
  
            request_callback.entity = "wo_insp_required_photos";
            request_callback.method = "execute_business_rule";
            request_callback.data = {
              entity_instance: {
                _id: dataFile._id,
                photo: dataFile.photo,
                description:  dataFile.description
              },
              field_path: "photo"
            };

            do_authenticated_http_call({
              method: "POST",
              url: "/api/entity/invoke_method",
              headers: headers,
              data: JSON.stringify(request_callback),
              complete: function () {
                self.setState({loading: false});
                self.loading = false;
                self.props.set_object_image(dataFile, self.state.data.length, self.state.caption_organization);
              }
            });
          }
        });
      });
  },
  setDescriptionByFile: function(file, index ,event) {
    var findFile = this.state.description[index];
    if(event.target.value) {
      if(findFile) {
          findFile.description = event.target.value;
      } else {
        this.state.description = this.state.description.concat({description: event.target.value, file: file});
      }
    }
    this.enable_upload();

  },
  setItemNo: function(file, index, event) {
    var findFile = this.state.description[index];
    if(event.target.value) {
      if(findFile) {
          findFile.itemNo = event.target.value;
      } else {
        this.state.description = this.state.description.concat({itemNo: event.target.value, file: file});
      }
    }
    this.enable_upload();
 
  },
  render: function() {
    var context = this;
    var error_display = context.state.error ? 'block' : 'none';

    if (context.props.multiple && context.state.data.filter(function(file) {return file;}).length > 1) {
      return (
        context.state.error != null ? 
          <span id="one" style={{display: error_display}}>
              <hr className="wide"/>
              <ErrorNotificationComponent
                  message={context.state.error}
                  on_close={context.on_error_close}
              />
          </span>: 
          this.state.loading ? <LoadingComponent  />:
        <div> 
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Thumbnail</th>
                {
                  context.state.caption_organization === org.CHEVRON &&
                    <th scope="col">Item No</th>
                }
                <th scope="col">File Description</th>
              </tr>
            </thead>
            <tbody>
              {context.state.data.map(function(file, index) {
                return (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <th>
                      <div className="img-wrap">
                        <img
                          style={{
                            maxWidth: context.props.maxDisplayWidth || "38px",
                            maxHeight: context.props.maxDisplayHeight || "38px"
                          }}
                          src={file}
                        />
                      </div>
                    </th>
                    {
                      context.state.caption_organization === org.CHEVRON &&
                        <th>
                          <input
                            type="text"
                            name="item_no"
                            className="form-control"
                            onChange={context.setItemNo.bind(context, file, index)}
                          />
                        </th>
                    }
                    <th>
                      <input
                        type="text"
                        name="description"
                        className="form-control"
                        onChange={context.setDescriptionByFile.bind(context, file, index)}
                      />
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button className="btn1 btn btn-success shiny workflow_button_margin"  disabled={!context.state.enable_upload} onClick={context.uploadFiles.bind(context)}>
            Upload
          </button>
        </div>
      );
    } else {
      return (
        context.state.error ? <div>
        <span id="one" style={{display: error_display}}>
          <hr className="wide"/>
          <ErrorNotificationComponent
              message={context.state.error}
              on_close={context.on_error_close}
          />
        </span>
      </div> : 
      this.state.loading ? <LoadingComponent  />:
        <div>
          <table>
            <tbody>
              <tr style={{ overflow: "hidden", maxHeight: "38px" }}>
                <th valign="bottom" height="38">
                  {context.state.data == null || context.state.data.length === 0
                    ? null
                    : context.state.data.map(function(file) {
                        return (
                          <div>
                            <div className="img-wrap">
                              {!context.props.readOnly &&
                                context.state.data != null &&
                                context.state.data.length > 0 && (
                                  <span
                                    onClick={context.handle_delete.bind(context, file)}
                                    title={R.label_delete}
                                    className="delete-image-span"
                                  >
                                    &nbsp;
                                  </span>
                                )}
                              <img
                                style={{
                                  maxWidth:
                                    context.props.maxDisplayWidth || "38px",
                                  maxHeight:
                                    context.props.maxDisplayHeight || "38px"
                                }}
                                src={file}
                              />
                            </div>
                          </div>
                        );
                      })}
                </th>
                <th valign="bottom" height="38">
                  &nbsp;&nbsp;
                </th>
                <th valign="bottom" height="38">
                  <div
                    className="editor-image-upload-control"
                    ref={function(ref) {
                      context.domElement = ref;
                    }}
                  >
                    &nbsp;
                  </div>
                </th>
              </tr>
            </tbody>
          </table>
          {
            context.props.entity_instance && context.props.entity_instance.photo &&
            <div>
              <a
                style={{cursor: "pointer"}} 
                id="download_url"
                onClick={this.donwloadFile}>Download image</a>
            </div>
          }
        </div>
      );
    }
  },
  donwloadFile: function() {
    var name_file = this.props.entity_instance.description.replace(/ /g,"_").concat(".png");
    var image = this.props.entity_instance.photo;

    download(image, name_file, "image/png");
  },
  handle_delete: function(fileRemove) {
    var data = this.state.data
      .map(function(file) { return file !== fileRemove && file;})
      .filter(function(file) { return file});

    if (data.length > 1) {
      document.getElementsByClassName("forminput")[1].style.display = "block";
    }

    this.element.reset();
    this.setState({ data: data });
    this.props.onChange(null);
  },
  scaleCanvasWithAlgorithm: function(canvas, callback) {
    var scaledCanvas = document.createElement("canvas");
    scaledCanvas.width = this.props.resizeWidth;
    scaledCanvas.height = this.props.resizeHeight; 
    var pica_lib = new pica();
    return pica_lib.resize(canvas,scaledCanvas,{  unsharpAmount: 80,
      unsharpRadius: 0.6,
      unsharpThreshold: 2}).then(function(result){
      return callback(result);
    })
  },
  getHalfScaleCanvas: function(canvas) {
    var halfCanvas = document.createElement("canvas");
    halfCanvas.width = canvas.width / 2;
    halfCanvas.height = canvas.height / 2;
    halfCanvas
      .getContext("2d")
      .drawImage(canvas, 0, 0, halfCanvas.width, halfCanvas.height);
    return halfCanvas;
  },
  applyBilinearInterpolation: function(srcCanvasData, destCanvasData, scale) {
    function inner(f00, f10, f01, f11, x, y) {
      var un_x = 1.0 - x;
      var un_y = 1.0 - y;
      return f00 * un_x * un_y + f10 * x * un_y + f01 * un_x * y + f11 * x * y;
    }
    var i, j;
    var iyv, iy0, iy1, ixv, ix0, ix1;
    var idxD, idxS00, idxS10, idxS01, idxS11;
    var dx, dy;
    var r, g, b, a;
    for (i = 0; i < destCanvasData.height; ++i) {
      iyv = i / scale;
      iy0 = Math.floor(iyv);
      // Math.ceil can go over bounds
      iy1 =
        Math.ceil(iyv) > srcCanvasData.height - 1
          ? srcCanvasData.height - 1
          : Math.ceil(iyv);
      for (j = 0; j < destCanvasData.width; ++j) {
        ixv = j / scale;
        ix0 = Math.floor(ixv);
        // Math.ceil can go over bounds
        ix1 =
          Math.ceil(ixv) > srcCanvasData.width - 1
            ? srcCanvasData.width - 1
            : Math.ceil(ixv);
        idxD = (j + destCanvasData.width * i) * 4;
        // matrix to vector indices
        idxS00 = (ix0 + srcCanvasData.width * iy0) * 4;
        idxS10 = (ix1 + srcCanvasData.width * iy0) * 4;
        idxS01 = (ix0 + srcCanvasData.width * iy1) * 4;
        idxS11 = (ix1 + srcCanvasData.width * iy1) * 4;
        // overall coordinates to unit square
        dx = ixv - ix0;
        dy = iyv - iy0;
        // I let the r, g, b, a on purpose for debugging
        r = inner(
          srcCanvasData.data[idxS00],
          srcCanvasData.data[idxS10],
          srcCanvasData.data[idxS01],
          srcCanvasData.data[idxS11],
          dx,
          dy
        );
        destCanvasData.data[idxD] = r;

        g = inner(
          srcCanvasData.data[idxS00 + 1],
          srcCanvasData.data[idxS10 + 1],
          srcCanvasData.data[idxS01 + 1],
          srcCanvasData.data[idxS11 + 1],
          dx,
          dy
        );
        destCanvasData.data[idxD + 1] = g;

        b = inner(
          srcCanvasData.data[idxS00 + 2],
          srcCanvasData.data[idxS10 + 2],
          srcCanvasData.data[idxS01 + 2],
          srcCanvasData.data[idxS11 + 2],
          dx,
          dy
        );
        destCanvasData.data[idxD + 2] = b;

        a = inner(
          srcCanvasData.data[idxS00 + 3],
          srcCanvasData.data[idxS10 + 3],
          srcCanvasData.data[idxS01 + 3],
          srcCanvasData.data[idxS11 + 3],
          dx,
          dy
        );
        destCanvasData.data[idxD + 3] = a;
      }
    }
  },
  scale_image: function(image, callback) {
    var img = new Image(),
      context = this;
    $(img).on("load", function() {
      var canvas = document.createElement("canvas");
      /*Repaint Canvas before to load image 87163584 */
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "white"; //Otherwise this is replacing transparencies with Black.
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      canvas.getContext("2d").drawImage(img, 0, 0, canvas.width, canvas.height);
      if (
        canvas.width > context.props.resizeWidth ||
        canvas.height > context.props.resizeHeight
      ) {
         return context.scaleCanvasWithAlgorithm(canvas, function(scaled_canvas){
            canvas = scaled_canvas;
            return callback(scaled_canvas.toDataURL("image/jpeg", 1));
        });
      }
      return callback(canvas.toDataURL("image/jpeg", 1));
    });
    $(img).on("error", function() {
      console.log("Error ocurred");
      context.setState({error: "File Image is corrupt"});
    });
    img.src = image;
  },
  componentDidMount: function() {
    var context = this;
    if (this.props.readOnly) {
      return;
    }
    context.isResettingElement = false;
    this.element = $(this.domElement)
      .dxFileUploader({
        accept: "image/*",
        showFileList: false,
        multiple: this.props.multiple,
        selectButtonText: this.props.selectButtonText,
        uploadMode: "useForm",
        onValueChanged: function(data) {
          if(!context.isResettingElement){
            if (data.value != null) {
              if (data.value.length > 1) {
                document.getElementsByClassName("forminput")[1].style.display = "none";
                document.getElementById("submit-button").style.display = "none";
                document.getElementById("info_photo").style.display = "none";
                document.getElementById("description").style.display = "none";
                document.getElementById("info_description").style.display = "none";
              }
             context.state.data = [];
              data.value.forEach(function(file) {
                if (!_.isUndefined(FileReader)) {
                  var reader = new FileReader();
                  reader.onload = function(e) {
                    var setImg = function(image) {
                      if (image === "data:,") {
                        image = file;
                      }
                      var data = context.state.data.concat(image);
                      context.setState({ data: data });
                      context.props.onChange(image);
                      try {
                        context.props.onBlur();
                      } catch (err) {}
                    };
  
                    var image = e.target.result;
                    if (context.props.resizeWidth && context.props.resizeHeight) {
                      context.scale_image(image, setImg);
                    } else setImg(image);
                  };
                  reader.readAsDataURL(file || new Blob());
                }
              });
              context.isResettingElement = true;
              context.element.reset();
              context.isResettingElement = false;
            } else {
              context.setState({ data: context.state.initState });
              context.props.onChange(context.state.initState);
            }
          }

        }
      })
      .dxFileUploader("instance");
    $(".dx-fileuploader-files-container").css("display", "none");
  },
  on_error_close: function () {
    this.setState({error: undefined});
    this.state.error= undefined;
    //this.forceUpdate();
    //this.setState(this.getInitialState());
  },
  enable_upload:function(){
    if (this.state.caption_organization === org.CHEVRON) {
      var invalid_files = this.state.description.find(function(file){
        return (!file.description || file.description == "" || !file.itemNo || file.itemNo == "")
      });
      this.setState({enable_upload: !invalid_files && this.state.description.length === this.state.data.length});
    }else{
      var invalid_files = this.state.description.find(function(file){
        return (!file.description || file.description == "")
      });
      this.setState({enable_upload: !invalid_files && this.state.description.length === this.state.data.length});
    }
  }
});
