/**
 * ========================================================
 * Description: A web control for editing a text field which
 *              can contain html
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/

function removeHtmlComments(source) {
    //Removing this line so that we can inspect the full invalid html in the report workflow, just in case we are somehow messing up the html string ourselves, and to better analyze the original input
    //return shared_model_utils.remove_invalid_html_from_field(source);
    return source;
}

var EditorHtml = React.createClass({
    render: function() {
        return <div ref="domElement" />;
    },
    componentDidMount:function()
    {
        var context = this;
        context.oldValue = this.props.value;
        $(this.refs.domElement).html(this.props.value).tinymce({
            readonly:this.props.readOnly,
            plugins: "textcolor",
            invalid_styles: 'color font-size font-family',
            toolbar: 'undo redo | styleselect | bold italic backcolor | bullist | link image',
            textcolor_map: [
                "ffff00", "Yellow"
            ],
            menubar: false,
            setup : function(ed){
                ed.on('change', function(e){
                    var content = ed.getContent();
                    content = removeHtmlComments(content);
                    if (context.oldValue == content) {
                        return;
                    }
                    context.oldValue = content;
                    context.props.onChange(content);
                    context.props.onBlur();
                });
            }
        });
    }
});
