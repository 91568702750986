/**
 * ========================================================
 * Description: All general utilities (helper methods) which
 *              Do Not use the command "require" are put here.
 *              This entire file should be able to
 *              import on client-side development.
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/

if(typeof exports == "undefined")
{
    var exports;
}
var general_utils = exports || {};
var default_random_guid_length = 24;
var default_random_guid_chars = "abcdef0123456789";
var default_random_uid_chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

/**Returns a clone of a JSON object.  Quick, but Very imperfect.*/
function clone_json(a) {
    return JSON.parse(JSON.stringify(a));
}

function add_to_list(collection, new_items) {
    if(collection == null) {
        collection = [];
    }
    else if(!Array.isArray(collection)) {
        var item = collection;
        collection = [];
        collection.push(item);
    }
    
    if(new_items != null) {
        if (Array.isArray(new_items)) {
            for (var i = 0; i < new_items.length; i++) {
                collection.push(new_items[i]);
            }
        }
        else {
            collection.push(new_items);
        }
    }
    return collection;
}

/**Assumes input array (ret_arr) to be sorted.  Adds new_item in its place in this array using the order_extracting_function to generate comparisons between items.*/
function add_to_list_sorted_by(ret_arr, new_item, order_extracting_function, options) {
    options = set_options(options, {
        ascending: true
    });
    var compare_func = function(a, b) {
        var order_a = order_extracting_function(a);
        var order_b = order_extracting_function(b);
        if(order_a > order_b) {
            return 1;
        }
        if(order_a < order_b) {
            return -1;
        }
        return 0;
    };
    var compare_func_desc = function(a, b) {
        var order_a = order_extracting_function(a);
        var order_b = order_extracting_function(b);
        if(order_a < order_b) {
            return 1;
        }
        if(order_a > order_b) {
            return -1;
        }
        return 0;
    };
    return add_to_sorted_list(ret_arr, new_item, (options.ascending)? compare_func : compare_func_desc);
}

/**Assumes input array (ret_arr) to be sorted in ascending order according to compare_func.  Adds new_item in its place in this array using compare_func.*/
function add_to_sorted_list(ret_arr, new_item, compare_func) {
    if(!ret_arr) {
        ret_arr = [];
    }
    if((ret_arr.length == 0) || (compare_func(new_item, ret_arr[0]) <=0)) {
        ret_arr.unshift(new_item);
        return ret_arr;
    }

    var i=1;
    while(i<ret_arr.length && (compare_func(new_item, ret_arr[i]) > 0)) {
        i++;
    }
    if(i == ret_arr.length) {
        ret_arr.push(new_item);
    }
    else {
        ret_arr.splice(i, 0, new_item);
    }

    return ret_arr;
}

function is_array(obj) {
    return (Object.prototype.toString.call(obj) === '[object Array]');
}

function is_object_or_array(obj) {
    var string_val = Object.prototype.toString.call(obj);
    return ((string_val === '[object Array]') || (string_val === '[object Object]'));
}

function is_object(obj) {
    return (Object.prototype.toString.call(obj) === '[object Object]');
}

/**Renames a property and all of it's occurences within an object*/
function rename_property(object, original_prop_name, new_prop_name, options) {
    if ((original_prop_name == new_prop_name) || !object || (!(is_object_or_array(object)))) {
        return;
    }

    options = set_options(options, {
        do_recursively: true,
        replace_existing_values: true
    });

    if(!options.replace_existing_values && (object[new_prop_name] != null)) {
        return;
    }

    if (options.do_recursively) {
        for (var field_name in object) {
            rename_property(object[field_name], original_prop_name, new_prop_name, options);
        }
    }
    if (object[original_prop_name] !== undefined) {
        object[new_prop_name] = object[original_prop_name];
        delete object[original_prop_name];
    }
}

/**Removes a named property and all of it's occurences within an object*/
function remove_property(object, prop_name, do_recursively) {
    if (!object) {
        return;
    }
    if (object[prop_name] != undefined) {
        delete object[prop_name];
    }
    if (do_recursively) {
        remove_property_recursive_helper(extract_object_structure(object, {include_null: true, include_undefined: true, include_functions: true, traverse_arrays:true}), object, prop_name);
    }
}

function remove_property_recursive_helper(object_structure, object, prop_name) {
    if (!object_structure || !object) {
        return;
    }
    if (object[prop_name] !== undefined) {
        delete object[prop_name];
        delete object_structure[prop_name];
    }
    for (var field_name in object_structure) {
        if (object_structure[field_name] && (is_object_or_array(object_structure[field_name]) || Array.isArray(object_structure[field_name]))) {
            remove_property_recursive_helper(object_structure[field_name], object[field_name], prop_name);
        }
    }
    
}

/**Extends a given options object with a set of default options*/
function set_options(options, default_options, shallow_extend) {
    if (!options) {
        options = {};
    }
    if (shallow_extend) {
        for (var field_name in default_options) {
            if (options[field_name] == null) {
                options[field_name] = default_options[field_name];
            }
        }
    }
    else {
        extend_object(options, default_options);
    }
    return options;
}

/**Makes a copy of the object but ignores any repeated (multiple ref'd) objects
 Can also set options to do the following: 
 * include_functions (false), 
 * include_null (false), 
 * include_undefined (false),
 * mark_object_ref_duplicates (false), //marks them by number and returns an array for you to index.
 * traverse_arrays (false),
 * recursive (true)
*/
function extract_object_structure(object, options) {
    var obj_ref_arr = [];
    if (object == null || !(is_object_or_array(object))) {
        if (options.mark_object_ref_duplicates) {
            options.obj_ref_arr = obj_ref_arr;
        }
    
        return object;
    }
    options = set_options(options, {
        include_null: false,
        include_undefined: false,
        include_functions: false,
        mark_object_ref_duplicates: false,
        traverse_arrays: false,
        recursive: true
    }, true);
    
    obj_ref_arr.push(object); //don't allow fields to reference the parent object.
    
    var copy_obj = (Array.isArray(object))? [] : {};
    
    if (Array.isArray(object)) {

        if (!options.traverse_arrays) {
            options.recursive = false;
        }

        for (var i = 0; i < object.length; i++) {
            extract_object_structure_helper(object, copy_obj, i, obj_ref_arr, options);
        }
        
        if (options.mark_object_ref_duplicates) {
            options.obj_ref_arr = obj_ref_arr;
        }

        return copy_obj;
    }
    else {
        for (var field_name in object) {
            if(object.hasOwnProperty(field_name)) {
                extract_object_structure_helper(object, copy_obj, field_name, obj_ref_arr, options);
            }
        }
    }
    
    if (options.mark_object_ref_duplicates) {
        options.obj_ref_arr = obj_ref_arr;
    }
    
    return copy_obj;
}

/**Recursive helper function for extract_object_structure.*/
function extract_object_structure_helper(object, copy_obj, prop_name, obj_ref_arr, options) {
    if (object[prop_name] == null) {
        if ((Array.isArray(object)) || ((object[prop_name] === null && options.include_null) || (object[prop_name] === undefined && options.include_undefined))) {
            copy_obj[prop_name] = true;
        }
        return;
    }
    
    var prop_type = Object.prototype.toString.call(object[prop_name]);

    if (!options.recursive) {
        if (((prop_type == "function") && options.include_functions) || (prop_type != "function")) {
            copy_obj[prop_name] = true;
        }
        else if (Array.isArray(object)) {
            copy_obj[prop_name] = null;
        }
        return;
    }

    switch (prop_type) {
        case "[object Object]":
        { //may be obj or array.
            var prop_obj = object[prop_name];
            var index_of_ref = obj_ref_arr.indexOf(prop_obj);
            if (index_of_ref == -1) {
                obj_ref_arr.push(prop_obj);
                copy_obj[prop_name] = {};
                for (var field_name in prop_obj) {
                    if(prop_obj.hasOwnProperty(field_name)) {
                        extract_object_structure_helper(prop_obj, copy_obj[prop_name], field_name, obj_ref_arr, options);
                    }
                }
            }
            else if (options.mark_object_ref_duplicates) {
                copy_obj[prop_name] = index_of_ref;
            }
            break;
        }
        case "[object Array]":
        { //may be obj or array.
            var prop_obj = object[prop_name];
            var index_of_ref = obj_ref_arr.indexOf(prop_obj);
            if (index_of_ref == -1) {
                obj_ref_arr.push(prop_obj);
                if (options.traverse_arrays) {
                    copy_obj[prop_name] = [];
                    for (var i = 0; i < prop_obj.length; i++) {
                        extract_object_structure_helper(prop_obj, copy_obj[prop_name], i, obj_ref_arr, options);
                    }
                }
                else {
                    copy_obj[prop_name] = true;
                }
            }
            else if (options.mark_object_ref_duplicates) {
                copy_obj[prop_name] = index_of_ref;
            }
            break;
        }
        case "[object Function]":
        {
            if (options.include_functions) {
                copy_obj[prop_name] = true;
            }
            else if (Array.isArray(object)) {
                copy_obj[prop_name] = null;
            }
            break;
        }
        default:
        {
            copy_obj[prop_name] = true;
            break;
        }
    }
    return;
}

/** Makes a shallow copy (1 layer deep) of an object. 
 * 
 * @param obj
 */
function shallow_copy(obj) {
    return shallow_extend_object(null, obj);
}

/**Formats a string given in the form "text {0} more text {1} more.." by replacing {n} with the given arguments and returning the final string*/
function format(str, args) {
    if ((arguments.length > 1) && !Array.isArray(args)) {
        args = Array.prototype.slice.call(arguments, 1);
    }
    if (str && args && Array.isArray(args) && args.length > 0) {
        if (!str.replace) {
            throw ["general_utils.format: str should be a string value"]; //CONST
        }
        return str.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
        ? ((Array.isArray(args[number]))? make_string_from_array(args[number]) : args[number])
        : match;
        });
    }
    return str;
};

/**
 * Given an array of strings, returns arr[0] + ", " + arr[1] + ", " + ...   (where delimiter = ", " by default)
 * @param arr
 * @param delimiter
 * @returns {*}
 */
function make_string_from_array(arr, delimiter) {
    if (!delimiter) {
        delimiter = ", ";
    }
    var ret_val;
    if (arr && arr.length > 0) {
        ret_val = arr[0];
        for (var i = 1; i < arr.length; i++) {
            ret_val = ret_val + delimiter + arr[i];
        }
    }
    return ret_val;
}

function make_array_from_string(str, delimiter) {
    if (!delimiter) {
        delimiter = ", ";
    }
    if(!str) {
        return [];
    }
    return str.split(delimiter).map(function(a) {return a.trim()}).filter(function(a) {return a;});
}

function parse_array_from_comma_delimited_string(str) {
    return make_array_from_string(str);
}

/**returns a string-formatted MongoDb Id (ObjectId), or returns false*/
function is_valid_object_id(id) {
    if (id == null) return null;
    var type = typeof id;
    if (type == 'object' && id._bsontype == 'ObjectID') {
        return is_valid_object_id(id.toHexString(), false);
    }
    if (type == 'ObjectId') {
        return id;
    }
    if (type == 'number') {
        return id;
    }
    if (type == 'string') {
        if (id.length == 12 || (id.length == 24 && (new RegExp("^[0-9a-fA-F]{24}$")).test(id))) {
            return id;
        }
    }
    return null;
};

/**Compares 2 ObjectIds for equality, returns true if equal.*/
function compare_object_ids(obj_id_a, obj_id_b, allow_match_without_id_validation) {
    if (allow_match_without_id_validation && (obj_id_a == obj_id_b)) {
        return true;
    }
    var id_string_a, id_string_b;
    id_string_a = is_valid_object_id(obj_id_a, true);
    id_string_b = is_valid_object_id(obj_id_b, true);
    if (!id_string_a || !id_string_b) {
        return false;
    }
    return (id_string_a == id_string_b) || (obj_id_a.valueOf && (obj_id_a.valueOf() == id_string_b)) || (obj_id_b.valueOf && (obj_id_b.valueOf() == id_string_a)) || (obj_id_a.valueOf && obj_id_b.valueOf && (obj_id_a.valueOf() == obj_id_b.valueOf()));
}

/**
 * Extends the first level of the base object with the
 * first level of the extended object
 *
 * @param base_object
 * @param extender_object
 */
function shallow_extend_object(base_object, extender_object) {
    if(!extender_object) {
        return base_object;
    }
    if(!base_object) {
        base_object = (Array.isArray(extender_object)) ? [] : {};
    }
    for (var field_name in extender_object) {
        if(extender_object.hasOwnProperty(field_name)) {
            if (base_object[field_name] == null) {
                base_object[field_name] = extender_object[field_name];
            }
        }
    }
    if(extender_object.__proto__) {
        if(base_object.__proto__ !== extender_object.__proto__) {
            base_object.__proto__ = extender_object.__proto__;
        }
    }
    return base_object;
}

/**Extends a base object with the members of the extender object, and returns the base object.
 * Allows the following options to customize the extender behavior:
    shallow_copy: false,
    replace_whole_obj_fields: false,
    replace_shallow_fields: false,
    ignore_functions: false,
    ignore_null_and_undefined: true,
    trigger_deletes_on_extender_nulls: false, --Fields set to null in the extender object will be REMOVED from base object.
    extend_arrays: false, --"extends" arrays by adding missing members.
    array_match_field: "", //if specified: treats arrays as uniquely indexed collections and extends elements in the array if they match values in this field, or adds new elements to array.  If array_match_field is left blank then arrays are matched by index in array
    replace_null_values: false
*/
function extend_object(base_object, extender_object, options) {
    if (base_object === extender_object) {
        return base_object;
    }
    if (!base_object) {
        if(!is_object_or_array(extender_object)) {
            return extender_object;
        }
        if(Array.isArray(extender_object)) {
            base_object = [];
        }
        else {
            base_object = {};
        }
    }
    
    options = set_options(options, {
        shallow_copy: false,
        replace_whole_obj_fields: false,
        replace_shallow_fields: false,
        ignore_functions: false,
        ignore_null_and_undefined: true,
        trigger_deletes_on_extender_nulls: false,
        extend_arrays: false,
        array_match_field: "",
        replace_null_values: false,
        do_not_override_prototype: false
    }, true);
    options.set_func_array = [];
    var obj_struc_options = { include_null: true, include_undefined: true, include_functions: !options.ignore_functions, traverse_arrays: true, mark_object_ref_duplicates: true };
    var obj_structure = extract_object_structure(extender_object, obj_struc_options);
    extend_object_recursive_helper(obj_structure, base_object, extender_object, options, obj_struc_options.obj_ref_arr);
    
    for (var i = 0; i < options.set_func_array.length; i++) {
        options.set_func_array[i]();
    }

    return base_object;
}


function extend_object_recursive_helper(extend_object_structure, base_object, extender_object, options, obj_ref_arr) {
    if (!extend_object_structure || !base_object) {
        return;
    }

    if (base_object === extender_object) {
        return base_object;
    }

    if (!is_object_or_array(extend_object_structure)) {
        console.log("[general_utils] Attempting to extend object without field identifier");
        return base_object;
    }
    
    var my_index = -1;
    if (extender_object && is_object_or_array(extender_object)) {
        my_index = obj_ref_arr.indexOf(extender_object);
    }
    if(!options.do_not_override_prototype) {
        if (extender_object.__proto__) {
            if (base_object.__proto__ !== extender_object.__proto__) {
                base_object.__proto__ = extender_object.__proto__;
            }
        }
    }
    if (my_index >= 0) {
        obj_ref_arr[my_index] = base_object; //replace references in this array as you cross them.
    }

    for (var field_name in extend_object_structure) {
        if (extend_object_structure[field_name] && typeof extend_object_structure[field_name] == "number") { //reference object

            options.set_func_array.push(function (f_name) {
                return function () {
                    if((base_object[f_name] === undefined) || options.replace_whole_obj_fields) {
                        base_object[f_name] = obj_ref_arr[extend_object_structure[f_name]];
                    }
                    else if (options.replace_shallow_fields) {
                        var temp = options.set_func_array;
                        delete options.set_func_array;
                        extend_object(base_object[f_name], obj_ref_arr[extend_object_structure[f_name]]);
                        options.set_func_array = temp;
                    }
                };
            }(field_name));
        }
        else {
            if (extender_object[field_name] == null) {
                if (options.trigger_deletes_on_extender_nulls) {
                    delete base_object[field_name];
                }
                if (!options.ignore_null_and_undefined) {
                    base_object[field_name] = extender_object[field_name];
                }
            }
            else if (options.ignore_functions && typeof extender_object[field_name] == "function") {
            //do nothing.
            }
            else if ((base_object[field_name] === undefined) || ((base_object[field_name] === null) && options.replace_null_values) || (options.replace_whole_obj_fields && !options.shallow_copy)) {
                if (options.shallow_copy) {
                    base_object[field_name] = extender_object[field_name];
                }
                else if (Array.isArray(extend_object_structure[field_name])) {
                    base_object[field_name] = [];
                    extend_object_recursive_helper(extend_object_structure[field_name], base_object[field_name], extender_object[field_name], options, obj_ref_arr);
                }
                else if (is_object_or_array(extend_object_structure[field_name])) {
                    base_object[field_name] = {};
                    base_object[field_name].__proto__ = extender_object[field_name].__proto__;
                    extend_object_recursive_helper(extend_object_structure[field_name], base_object[field_name], extender_object[field_name], options, obj_ref_arr);
                }
                else {
                    base_object[field_name] = extender_object[field_name];
                }
            }
            else if (options.replace_whole_obj_fields && options.shallow_copy) {
                if (is_object_or_array(extender_object[field_name])) {
                    var my_index = obj_ref_arr.indexOf(extend_object_structure);
                    if (my_index >= 0) {
                        obj_ref_arr[my_index] = extender_object[field_name]; //replace references in this array as you cross them.
                    }
                }
                base_object[field_name] = extender_object[field_name];
            }
            else if (options.replace_shallow_fields) {
                if (!is_object_or_array(base_object[field_name]) || !is_object_or_array(extender_object[field_name])) { //replace directly if either entity has a shallow type.
                    base_object[field_name] = extender_object[field_name];
                }
                else {
                    extend_object_recursive_helper(extend_object_structure[field_name], base_object[field_name], extender_object[field_name], options, obj_ref_arr);
                }
            }
            else if (is_object_or_array(extender_object[field_name]) && is_object_or_array(base_object[field_name])) {
                if (Array.isArray(base_object[field_name])) {  //don't touch existing arrays unless extend_arrays is set to true.
                    if (options.extend_arrays && Array.isArray(base_object[field_name]) && Array.isArray(extender_object[field_name])) {
                        extend_list(base_object[field_name], extender_object[field_name], options.array_match_field);
                    }
                }
                else {
                    extend_object_recursive_helper(extend_object_structure[field_name], base_object[field_name], extender_object[field_name], options, obj_ref_arr);
                }
            }
        }
    }
    return base_object;
}


/**Adds to base_arr items missing from base_arr, but present in extend_arr*/
function extend_list(base_arr, extend_arr, array_match_field) {
    if (!base_arr) { base_arr = []; }
    if(!extend_arr || !Array.isArray(base_arr) || !Array.isArray(extend_arr)) {
        return;
    }
    for (var i = 0; i < extend_arr.length; i++) {
        if (array_match_field && array_match_field.length > 0) {
            var extend_arr_value = extend_arr[i][array_match_field];
            if (extend_arr_value) {
                var found = false;
                for (var j = 0; j < base_arr.length; j++) {
                    if (base_arr[j][array_match_field] == extend_arr_value) {
                        found = true;
                        for (var key in extend_arr[i]) {
                            if (base_arr[j][key] == undefined) {
                                base_arr[j][key] = extend_arr[i][key];
                            }
                        }
                        break;
                    }
                }

                if (!found) {
                    if (base_arr.indexOf(extend_arr[i]) == -1) {
                        base_arr.push(extend_arr[i]);
                    }
                }
            }
            else {
                if (base_arr.indexOf(extend_arr[i]) == -1) {
                    base_arr.push(extend_arr[i]);
                }
            }
        }
        else {
            if (base_arr.indexOf(extend_arr[i]) == -1) {
                base_arr.push(extend_arr[i]);
            }
        }
    }
    return base_arr;
}

/**Deletes entire contents of a ref object while maintaining the ref.*/
function clear_object(obj) {
    for (var field_name in obj) {
        delete obj[field_name];
    }
}

/**Takes an array and a field name, and returns a dictionary of the items in the array grouped by their values in that field name (dictionary entries are arrays)*/
function group_by(arr, inst_field_name) { //inst_field_name value must have a valid .toString() method
    var ret_dictionary = {};
    for (var i = 0; i < arr.length; i++) {
        var inst = arr[i];
        var dic_field_name = inst[inst_field_name].toString();
        if (!ret_dictionary[dic_field_name]) {
            ret_dictionary[dic_field_name] = [];
        }
        ret_dictionary[dic_field_name].push(inst);
    }
    return ret_dictionary;
}

/**Takes an array and a selector function which returns an object of type: {key: key_value, value: value_value} (or return null to skip), and returns a dictionary of the items in the array grouped by those values*/
function group_by_function(arr, kv_selector_func) {
    var ret_dictionary = {};
    arr = arr || [];
    for (var i = 0; i < arr.length; i++) {
        var kv = kv_selector_func(arr[i]);
        if (kv) {
            if (!ret_dictionary[kv.key]) {
                ret_dictionary[kv.key] = [];
            }
            ret_dictionary[kv.key].push(kv.value);
        }
    }
    return ret_dictionary;
}

/**Takes an array and a selector function which returns an object of type: {key: key_value, value: value_value} (or return null to skip), and returns a dictionary of the items in the array grouped by those values*/
function convert_to_dictionary(arr, kv_selector_func, options) {

    options = set_options(options, {
        existing_dictionary: null,
        replace_fields: true
    }, true);

    var ret_dictionary = options.existing_dictionary || {};
    if(!arr) {
        return ret_dictionary;
    }
    for (var i = 0; i < arr.length; i++) {
        var kv = kv_selector_func(arr[i]);
        if (kv) {
            if((ret_dictionary[kv.key] == null) || options.replace_fields) {
                ret_dictionary[kv.key] = kv.value;
            }
        }
    }
    return ret_dictionary;
}

/**Takes an array and a selector object of type: {key: key_field_name, value: value_field_name}, and returns a dictionary of the items in the array grouped by those values*/
function convert_to_dictionary_by_field_names(arr, kv_selector, options) {
    options = set_options(options, {
        existing_dictionary: null,
        replace_fields: true
    }, true);
    var ret_dictionary = options.existing_dictionary || {};
    if(!arr || !arr.length) {
        return ret_dictionary;
    }
    var key_field_name = kv_selector.key;
    var value_field_name = kv_selector.value;
    for (var i = 0; i < arr.length; i++) {
        var inst = arr[i];
        var dic_field_name = inst[key_field_name];
        var dic_entry;
        if(value_field_name == null) {
            dic_entry = inst;
        }
        else {
            dic_entry = inst[value_field_name];
        }
        if(dic_field_name) {
            if((ret_dictionary[dic_field_name] == null) || options.replace_fields) {
                if(dic_entry != null) {
                    ret_dictionary[dic_field_name] = dic_entry;
                }
                else {
                    delete ret_dictionary[dic_field_name];
                }
            }
        }
    }
    return ret_dictionary;
}

/**Takes an iterable object and returns an array of the items in that object.  Skips null entries, and functions.*/
function to_array(obj, options) {
    options = general_utils.set_options(options, {
        use_field_values: true,
        skip_falsy_values: true,
        ignore_functions: true
    }, true);
    if (!obj) {
        return null;
    }
    var ret_arr = [];
    for (var field_name in obj) {
        if(obj.hasOwnProperty(field_name)) {
            if((!options.skip_falsy_values || obj[field_name]) && (!options.ignore_functions || (typeof obj[field_name] != "function"))) {
                if (options.use_field_values) {
                        ret_arr.push(obj[field_name]);
                }
                else {
                    ret_arr.push(field_name);
                }
            }
        }
    }
    return ret_arr;
}

function convert_to_dictionary_of_true_values(arr, options) {
    options = set_options(options, {
        existing_dictionary: null
    }, true);

    var ret_dictionary = options.existing_dictionary || {};
    if(!arr) {
        return ret_dictionary;
    }
    for (var i = 0; i < arr.length; i++) {
        ret_dictionary[arr[i]] = true;
    }
    return ret_dictionary;
}

/**Finds the folders that contain a certain file by parsing its path.  Returns an array of folder names(paths).  Used for permissions. 
 * Has the following options to customize its behavior:
 * use_backslash: false,
   use_leading_slash: false,
   use_trailing_slash: false
*/
function get_containing_folders_array(file_path, options) {
    options = set_options(options, {
        use_backslash: false,
        use_leading_slash: false,
        use_trailing_slash: false
    });

    var ret_arr = [];
    if (!file_path) {
        return ret_arr;
    }
    var builder_arr = file_path.split(/(\\|\/)/g);
    var builder_string;
    for (var i = 0; i < builder_arr.length - 1; i++) { //leave off actual file, just build folder path.
        if (builder_arr[i] != "/" && builder_arr[i] != "\\") {
            var folder_name = builder_arr[i];
            if (!builder_string) {
                builder_string = folder_name;
                if (options.use_leading_slash) {
                    builder_string = ((options.use_backslash)? "\\" : "/") + builder_string;
                }
                if (options.use_trailing_slash) {
                    builder_string = builder_string + ((options.use_backslash)? "\\" : "/");
                }
            }
            else {
                if (!options.use_trailing_slash) {
                    builder_string = builder_string + ((options.use_backslash) ? "\\" : "/") + folder_name;
                }
                else {
                    builder_string = builder_string + folder_name + ((options.use_backslash) ? "\\" : "/") ;
                }
            }

            ret_arr.push(builder_string);
        }
    }

    return ret_arr;
}

/**A shortcut for extend_object(null, obj, options)*/
function copy_object(obj, options) {
    return extend_object(null, obj, options);
}

/**
 * Returns an array sorted by order_extracting_function
 * @param arr_unsorted
 * @param order_extracting_function
 * @param options
 * @returns {*}
 */
function order_by(arr_unsorted, order_extracting_function, options) {
    options = set_options(options, {
        new_array: true,
        ascending: true
    });
    if(!arr_unsorted || !arr_unsorted.length || !Array.isArray(arr_unsorted)) {
        if(options.new_array) {
            return [];
        }
        return arr_unsorted;
    }
    var ret_arr = arr_unsorted;
    if(options.new_array) {
        ret_arr = arr_unsorted.slice(0);
    }
    var compare_func_asc = function(a, b) {
        var order_a = order_extracting_function(a);
        var order_b = order_extracting_function(b);
        if(order_a == order_b) {
            return 0;
        }
        if(order_a > order_b) {
            return 1;
        }
        return -1;
    };
    var compare_func_desc = function(a, b) {
        var order_a = order_extracting_function(a);
        var order_b = order_extracting_function(b);
        if(order_a == order_b) {
            return 0;
        }
        if(order_a < order_b) {
            return 1;
        }
        return -1;
    };
    ret_arr.sort((options.ascending)? compare_func_asc : compare_func_desc);
    return ret_arr;
}

/**Returns a new array with only members which pass the where predicate*/
function where(arr, where_func) {
    if(!arr) {
        return null;
    }
    if(!where_func) {
        return arr.slice(0);
    }
    var ret_arr = [];
    for(var i=0; i<arr.length; i++) {
        if(where_func(arr[i], i)) {
            ret_arr.push(arr[i]);
        }
    }
    return ret_arr;
}


/**
 * Description: Like where, but works for objects. selector_func and where_func take: field_name, field_value.
 *
 * Changes:
 * Chris Frantz, 12/31/2015
 *
 * @param arr
 * @param where_func
 * @param selector_func
 * @returns {*}
 */
function obj_where(arr, where_func, selector_func) {
    if(!arr) {
        return null;
    }
    if(!where_func) {
        return shallow_copy(arr, {shallow_copy: true});
    }
    var ret_arr = [];
    for(var field_name in arr) {
        var field_value = arr[field_name];
        if(where_func(field_name, field_value)) {
            if(selector_func) {
                field_value = selector_func(field_name, field_value);
            }

            ret_arr.push(field_value);
        }
    }
    return ret_arr;
}

/**Returns a new array with only members which pass the where predicate*/
function first_where(arr, where_func, options) {
    if(!arr) {
        return undefined;
    }
    var options = set_options(options, {
        save_index: false,
        allow_dictionary_iteration: false,
        iterate_backwards: false
    });
    if(Array.isArray(arr)) {
        if(options.iterate_backwards) {
            for (var i = arr.length - 1; i >= 0; i--) {
                if (where_func(arr[i], i)) {
                    if (options.save_index) {
                        options.index = i;
                    }
                    return arr[i];
                }
            }
        }
        else {
            for (var i = 0; i < arr.length; i++) {
                if (where_func(arr[i], i)) {
                    if (options.save_index) {
                        options.index = i;
                    }
                    return arr[i];
                }
            }
        }
    }
    else if ((typeof arr == "object") && options.allow_dictionary_iteration) {
        for (var field_name in arr) {
            if (arr.hasOwnProperty(field_name)) {
                if (where_func(arr[field_name], field_name)) {
                    if (options.save_index) {
                        options.index = field_name;
                    }
                    return arr[field_name];
                }
            }
        }
    }
    return undefined;
}

/**Returns a (options: {new_array: T/F}) array sorted by compare_func*/
function select(arr, value_getter_function, options) {
    if(!arr || !Array.isArray(arr)) {
        return [];
    }
    var options = set_options(options, {new_array: true});
    var ret_arr = arr;
    if(options.new_array) {
        ret_arr = arr.slice(0);
    }
    for(var i=0; i<ret_arr.length; i++) {
        ret_arr[i] = value_getter_function(ret_arr[i]);
    }
    return ret_arr;
}

/**Gets from (dictionary1[key]) || (2[key]) || (3[key]) .. */
function multi_phase_getter(key, dictionaries) {
    var ret_val;
    if ((arguments.length > 1) && !Array.isArray(dictionaries)) {
        dictionaries = Array.prototype.slice.call(arguments, 1);
    }
    if (dictionaries && Array.isArray(dictionaries) && dictionaries.length > 0) {
        for(var i = 0; i < dictionaries.length; i++) {
            var dictionary = dictionaries[i];
            if (dictionary) {
                ret_val = dictionary[key];
            }
            if (ret_val != null) {
                return ret_val;
            }
        }
    }
    return ret_val;
}

/**Gets from (dictionary[key1][key2][key3][...]) safely (no crash ==> returns undefined)*/
function safe_getter(key_array, dictionary) {
    var ret_val;
    if (Array.isArray(key_array) && key_array.length > 0) {
        for(var i = 0; i < key_array.length; i++) {
            if(!dictionary || (key_array[i] == null)) {
                return null;
            }
            dictionary = dictionary[key_array[i]];
            ret_val = dictionary;
            if (!dictionary) {
                return undefined;
            }
        }
    }
    else if(dictionary){
        return dictionary[key_array];
    }
    return ret_val;
}

/**Gets the ID of the current user.  Used for schema model field 'default' functions*/
function get_current_user_id(state) {
    if(state && state.user) {
        var ret_val = is_valid_object_id(state.user._id) || is_valid_object_id(state.user);
        if(ret_val) {
            return ret_val;
        }
    }
    return null;
}

/** Pads a number or string with leading 0's up to a certain length
 *
 * @param num
 * @param size
 * @returns {string}
 */
function do_pad_num(num, size) {
    var ret_string = num;
    if((num != null) && (typeof num == "number")) {
        ret_string = num.toString();
        if(ret_string && (ret_string.length >= size)) {
            return ret_string;
        }
        var s = "000000000000000" + num; //assumes a max value of 16
        ret_string = s.substr(s.length - size);
    }
    return ret_string;
}

/**
 * Wraps JSON.stringify in a try/catch to avoid issues
 * with circular references
 *
 * @param object
 * @returns {*}
 */
function safe_stringify(object) {
    var stringified_obj;

    try {
        stringified_obj = JSON.stringify(object, null, 3);
    }
    catch (err) {
        stringified_obj = "Unable to stringify object"
    }

    return stringified_obj;
}

/**
 * Description: Removes file extension
 *
 * Changes:
 * Chris Frantz, 1/6/2016
 *
 * @param file_name
 * @returns {XML|string|void|*}
 */
function remove_file_extension(file_name) {
    return file_name.replace(/\.[^/.]+$/, "")
}


function remove_sensitive_fields(json) {

    var fields_to_remove = [
        "password",
        "secret_answer",
        "password_hash",
        "secret_answer_hash",
        "secret_question",
        "ssn",
        "social_security_number"
    ];

    if (is_object(json)) {
        for (var field in json) {
            var current_field = json[field];

            if (Array.isArray(current_field)) {

                for (var i = 0; i < current_field.length; i++) {
                    var array_field = current_field[i];
                    remove_sensitive_fields(array_field);
                }
            }

            if (fields_to_remove.indexOf(field) != -1) {
                delete json[field];
            }
        }
    }
}

/** A helper method for parsing money (strings) specified in various intl. formats. Returns a Number value or null (on fail)
 * (modified version of accounting.js's unformat method: http://openexchangerates.github.io/accounting.js/ )
 *
 * @param value
 * @param decimal_character
 * @returns {*}
 */
function parse_money(value, decimal_character) {
    if(value == null) {
        return null;
    }

    // Return the value as-is if it's already a number:
    if (typeof value === "number") return value;

    // Default decimal point comes from settings, but could be set to eg. "," in opts:
    decimal_character = decimal_character || ".";

    // Build regex to strip out everything except digits, decimal point and minus sign:
    var regex = new RegExp("[^0-9-" + decimal_character + "]", ["g"]),
        unformatted = parseFloat(
            ("" + value)
                .replace(/\((.*)\)/, "-$1") // replace bracketed values with negatives
                .replace(regex, '')         // strip out any cruft
                .replace(decimal_character, '.')      // make sure decimal point is standard
        );

    // This will fail silently which may cause trouble, let's wait and see:
    return ((isNaN(unformatted)) ? null : unformatted);
}

/** Creates a list of items in a format that is still translatable
 *
 * @param array_of_values
 * @param translation_getter_function - optional, will use array entry value if a function is not specified.
 * @param list_item_separator - if you desire a different delimiter instead of a ", ".
 * @returns {*}
 */
function create_translatable_list(array_of_values, translation_getter_function, list_item_separator) {
    var translated_caption_array;
    list_item_separator = list_item_separator || ", ";
    for(var i = 0; i < array_of_values.length; i++) {
        var new_entry_caption = ((translation_getter_function) ? translation_getter_function(array_of_values[i]) : array_of_values[i]);
        if(translated_caption_array) {
            translated_caption_array = [R("recursive_list_template_with_custom_delimiter"), translated_caption_array, list_item_separator, new_entry_caption];
        }
        else {
            translated_caption_array = new_entry_caption;
        }
    }
    return translated_caption_array;
}

/** A helper function for standardizing the format of object overrides to avoid clutter when this is needed
 *
 * @param obj
 * @param override_instructions_obj - an object with the desired override functions, the first parameter of which is now the base obj's original function
 * @returns {*}
 */
function override_object_fields(obj, override_instructions_obj) {
    if(!obj || !override_instructions_obj) {
        return;
    }
    for (var field_name in override_instructions_obj) {
        if(override_instructions_obj.hasOwnProperty(field_name)) {
            var original_function = obj[field_name];
            var override_function = function(this_field_name, this_field_original_function) {
                return function() {
                    var next_args = convert_function_arguments_to_real_array(arguments);
                    next_args.unshift(this_field_original_function);
                    return override_instructions_obj[this_field_name].apply(obj, next_args);
                };
            }(field_name, original_function);
            obj[field_name] = override_function;
        }
    }
    return obj;
}

/** A function designed to replace Array.prototype.slice.call(arguments) since that is inefficient in V8. See: https://github.com/petkaantonov/bluebird/wiki/Optimization-killers#3-managing-arguments
 *  Created a whole function for this one line to give it a friendly name rather than force programmers to parse the confusing return statement and try to decipher why it's being done this way.
 *
 * @param args
 * @returns {*[]}
 */
function convert_function_arguments_to_real_array(args) {
    return (args.length === 1 ? [args[0]] : Array.apply(null, args));
}

/** A function for extracting an array of unique values from an existing array.
 *
 * @param array
 * @returns {Array}
 */
function get_unique_values_from_string_array(array) {
    if(!array || !array.length) {
        return [];
    }
    var dictionary_of_entries = {};
    for(var i = 0; i < array.length; i++) {
        if(array[i] && (typeof array[i] == "string")) {
            dictionary_of_entries[array[i]] = true;
        }
    }
    var ret_val = [];
    for(var array_entry in dictionary_of_entries) {
        if(dictionary_of_entries.hasOwnProperty(array_entry)) {
            ret_val.push(array_entry);
        }
    }
    return ret_val;
}

/** A function for extracting an array of unique values from an existing array.
 *
 * @param array
 * @param tostring_function - a function which takes a single parameter (the entry in the array) and returns a unique identifying string
 * @param use_last_value
 * @returns {Array}
 */
function get_unique_values_using_tostring_function(array, tostring_function, use_last_value) {
    if(!array || !array.length) {
        return [];
    }
    var key_array = select(array, tostring_function);
    var dictionary_of_entries = {};
    for(var i = 0; i < key_array.length; i++) {
        if(key_array[i] && (typeof key_array[i] == "string")) {
            if(use_last_value || (dictionary_of_entries[key_array[i]] == null)) {
                dictionary_of_entries[key_array[i]] = array[i];
            }
        }
    }
    var ret_val = [];
    for(var array_entry in dictionary_of_entries) {
        if(dictionary_of_entries.hasOwnProperty(array_entry)) {
            ret_val.push(dictionary_of_entries[array_entry]);
        }
    }
    return ret_val;
}

/** A function for extracting an array of unique values from an existing array.
 *
 * @param array
 * @returns {Array}
 */
function get_unique_values__slow(array) {
    if(!array || !array.length) {
        return [];
    }

    var ret_val = [];
    for(var i = 0; i < array; i++) {
        var array_entry = array[i];
        if(ret_val.indexOf(array_entry) == -1) {
            ret_val.push(array_entry);
        }
    }
    return ret_val;
}

/**
 * Description: Returns true if the string is empty or contains whitespace.  Moved from model_utils
 * @param string
 * @param return_true_for_non_strings - if true, this will return true if string is Not a string
 * @returns {boolean}
 */
function string_is_empty_or_whitespace(string, return_true_for_non_strings) {
    if(string == null) {
        return true;
    }
    if(return_true_for_non_strings) {
        if(typeof string != "string") {
            return true;
        }
    }
    return (typeof string == "string") && ((string == "") || (string.trim() == ""));
}

/** If string is null or string_is_empty_or_whitespace, then returns null.  Else returns string.trim()
 *
 * @param string
 * @returns {*}
 */
function get_nonempty_trimmed_string_or_null(string) {
    if ((string == null) || (string == "")) {
        return null;
    }
    if (typeof string != "string") {
        return null;
    }
    string = string.trim();
    if(!string || (string == "")) {
        return null;
    }
    return string;
}

/** If string is null or string_is_empty_or_whitespace, then returns null.  Else returns string.trim()
 *
 * @param string
 * @returns {*}
 */
function get_nonempty_trimmed_string_or_undefined(string) {
    if ((string == null) || (string == "")) {
        return undefined;
    }
    if (typeof string != "string") {
        return undefined;
    }
    string = string.trim();
    if(!string || (string == "")) {
        return undefined;
    }
    return string;
}

/** Goes through data object and checks for any dates formatted as strings and converts to Date
 *
 * @param data
 * @param field_paths
 * @param nested_list_entities
 * @param change_default_data
 * @returns {*}
 */
function fix_response_data(data, field_paths, nested_list_entities, change_default_data) {
	if ( data && data.data && data.data.entity_attributes ) {
        if ( !field_paths && data.data.entity_attributes.field_paths ) {
            field_paths = data.data.entity_attributes.field_paths;
        }
        if ( !nested_list_entities && data.data.entity_attributes.nested_list_entities ) {
            nested_list_entities = data.data.entity_attributes.nested_list_entities;
        }
    }

    for ( var x in data ) {
        if(data.hasOwnProperty(x) && data[x]) {
            //recursively call on objects
            var this_field_path_attributes = null;
            if (x.indexOf(":[") !== -1 && nested_list_entities) {
                var field_path_splitted = x.split(":");
                if (field_path_splitted.length == 3) {
                    var parent_field_name = field_path_splitted[0];
                    var local_field_name = field_path_splitted[2];
                    if (local_field_name && field_paths && field_paths[parent_field_name]) {
                        var parent_db_type = field_paths[parent_field_name].db_type;
                        if (nested_list_entities[parent_db_type] && nested_list_entities[parent_db_type].field_paths) {
                            this_field_path_attributes = nested_list_entities[parent_db_type].field_paths[local_field_name];
                        }
                    }
                }
            }
            if (typeof data[x] == 'object') {
                if ((this_field_path_attributes || (field_paths && field_paths[x])) && data[x].value) { //this format is used for fieldBlur responses from server
                    this_field_path_attributes = this_field_path_attributes || field_paths[x];
                    if (this_field_path_attributes.attribute_type == "Date") {
                        data[x].value = new Date(data[x].value.slice(0, 4), data[x].value.slice(5, 7) - 1, data[x].value.slice(8, 10), 0, 0, 0, 0);
                    } else if (this_field_path_attributes.attribute_type == "DateTimeLocal") {
                        //Allow Javascript to parse as a Date normally:
                        data[x] = new Date(data[x]);
                    } else if (this_field_path_attributes.attribute_type == "DateTime") {
                        data[x].value = new Date(data[x].value.slice(0, 4), data[x].value.slice(5, 7) - 1, data[x].value.slice(8, 10),
                            data[x].value.slice(11, 13), data[x].value.slice(14, 16), data[x].value.slice(17, 20));
                    } else if (this_field_path_attributes.attribute_type == "Time") {
                        data[x].value = new Date(0, 0, 0, data[x].value.slice(11, 13), data[x].value.slice(14, 16), data[x].value.slice(17, 20));
                    }
                }
                else {
                    if (field_paths && field_paths[x] && field_paths[x].db_type && nested_list_entities && nested_list_entities[field_paths[x].db_type]) {
                        data[x] = fix_response_data(data[x], nested_list_entities[field_paths[x].db_type].field_paths, nested_list_entities, change_default_data);
                    }
                    else {
                        data[x] = fix_response_data(data[x], field_paths, nested_list_entities, change_default_data);
                    }
                }
            } else if ('string' == typeof data[x] && field_paths && field_paths[x]) {
                if (field_paths[x].attribute_type == "Date") {
                    data[x] = new Date(data[x].slice(0, 4), data[x].slice(5, 7) - 1, data[x].slice(8, 10), 0, 0, 0, 0);
                } else if (field_paths[x].attribute_type == "DateTimeLocal") {
                    //Allow Javascript to parse as a Date normally:
                    data[x] = new Date(data[x]);
                } else if (field_paths[x].attribute_type == "DateTime") {
                    data[x] = new Date(data[x].slice(0, 4), data[x].slice(5, 7) - 1, data[x].slice(8, 10),
                        data[x].slice(11, 13), data[x].slice(14, 16), data[x].slice(17, 20));
                } else if (field_paths[x].attribute_type == "Time") {
                    data[x] = new Date(0, 0, 0, data[x].slice(11, 13), data[x].slice(14, 16), data[x].slice(17, 20));
                }
            }

            if ('string' == typeof data[x] && change_default_data){
                if (/\x02/g.test(data[x])){
                    data[x] = data[x].replace(/\x02/g, '');
                }
            }
        }
    }

    if (data
        && data.updated_view_data
        && data.updated_view_data["probability"]
        && data.updated_view_data["probability"].value) {

        var probabilityValue = "" + data.updated_view_data["probability"].value;
        var probabilityValueFloat = parseFloat(probabilityValue);
        if (!isNaN(probabilityValueFloat)) {
            var probabilityPercentageValue = "" + Math.round((probabilityValueFloat * 100) * 100) / 100 + "%";
            data.updated_view_data["probability"].value = probabilityPercentageValue;
        }
    }

    return data;
}

/** A helper method for applying skip, limit, and projections to in-memory arrays of objects
 *
 * @param arr
 * @param options
 * @returns {*}
 */
function apply_mongo_search_options(arr, options) {
    if(!arr || !arr.length || !options || (!options.skip && !options.limit)) { //TODO: Projections
        return arr;
    }
    var start = 0;
    if(options.skip) {
        start = options.skip;
    }
    if(options.limit) {
        return arr.slice(start, Math.min(start + options.limit, arr.length));
    }
    return arr.slice(start);
}

/** Creates a tree structure from an array of elements which specify their parents
 *
 * @param item_arr
 * @param identifier_field
 * @param parent_field
 * @param children_field
 * @param transformation_function
 * @returns {{}}
 */
function create_hierarchical_structure_from_array(item_arr, identifier_field, parent_field, children_field, transformation_function) {
    if(!item_arr || !item_arr.length || !Array.isArray(item_arr)) {
        return {};
    }
    var dic_func = function(entry) {
        entry = (transformation_function) ? transformation_function(entry) : entry;
        var ret_entry = {key: entry[identifier_field]};
        ret_entry.value = entry;
        return ret_entry;
    };

    var dictionary_of_entities_by_identifier = convert_to_dictionary(item_arr, dic_func);
    create_nonchildren_dictionary_from_dictionary(dictionary_of_entities_by_identifier, parent_field, children_field);
    return to_array(dictionary_of_entities_by_identifier);
}

/** A helper function for create_hierarchical_structure_from_array which moves all children from a dictionary into child elements (arrays) of their parents
 *
 * @param dictionary_of_all_entities
 * @param parent_field
 * @param children_field
 * @returns {boolean}
 */
function create_nonchildren_dictionary_from_dictionary(dictionary_of_all_entities, parent_field, children_field) {
    var delete_children_arr = [];
    for(var entity_id in dictionary_of_all_entities) {
        if(dictionary_of_all_entities.hasOwnProperty(entity_id) && dictionary_of_all_entities[entity_id]) {
            var entity = dictionary_of_all_entities[entity_id];
            var parent_entity_id = entity[parent_field];
            if(parent_entity_id != null) {
                if(dictionary_of_all_entities[parent_entity_id]) {
                    dictionary_of_all_entities[parent_entity_id][children_field] = dictionary_of_all_entities[parent_entity_id][children_field] || [];
                    dictionary_of_all_entities[parent_entity_id][children_field].push(entity);
                    delete_children_arr.push(entity_id);
                }
            }
        }
    }

    if(delete_children_arr.length) {
        for(var i = 0; i < delete_children_arr.length; i++) {
            delete dictionary_of_all_entities[delete_children_arr[i]];
        }
    }

    return false; //no more changes
}

/** A small helper function for sorting arrays
 *
 * @param arr
 * @param field_name
 * @param options
 * @returns {*}
 */
function sort_by_field_name(arr, field_name, options) {
    options = set_options(options, {
        new_array: true,
        ascending: true
    });
    return order_by(arr, function(a) {
        if(a==null) {
            return undefined;
        }
        return a[field_name];
    }, options);
}

/** A small helper function for getting a value from a passed parameter which may be a simple constant or a function which requires a context (and an optional parameter referred to as state)
 *
 * @param local_context_object
 * @param constant_or_function
 * @param state
 */
function get_value_from_function_or_constant(local_context_object, constant_or_function, state) {
    var ret_val;
    if (typeof constant_or_function == 'function') {
        ret_val = constant_or_function.call(local_context_object, state);
    }
    else {
        ret_val = constant_or_function;
    }
    return ret_val;
}

/** Splits an array into an array of arrays of given batch size.
 *
 * @param arr
 * @param batch_size
 * @returns {*}
 */
function split_into_batches(arr, batch_size) {
    if(!arr || !batch_size || !arr.length) {
        return [[]];
    }
    var chunks = [];
    var i = 0;
    var n = arr.length;
    while (i < n) {
        chunks.push(arr.slice(i, i += batch_size));
    }
    return chunks;
}

/**
 * A function which removes the <!Doctype> (etc) headers from an html string
 * @param eval_string
 * @returns {{eval_string: string, style_string: string}}
 */
function remove_html_tags_and_extract_style(eval_string) {
    var style_string = "";
    eval_string = (eval_string || "").replace(/{{.+?}}/gm, "");
    eval_string = eval_string.replace(/<br>/gm, "<br />");

    //remove comments from template://
    eval_string = eval_string.replace(/\/\*[\s\S]*?\*\//gm, "");
    eval_string = eval_string.replace(/\/\/.*/g, "");
    eval_string = eval_string.replace(/<!--[\s\S]*?-->/gm, ""); //remove html comments
    //////////////////////////////////

    //Extract and format style header section from string//
    eval_string = eval_string.replace(/<style[\s\S]+?<\/style>/gm, function(match_string) {
        style_string = match_string;
        return "";
    });
    ///////////////////////////////////////////////////////

    //Remove !DocType, html, etc, tags:
    eval_string = eval_string.replace("<!DOCTYPE html>", "");
    eval_string = eval_string.replace(/<html[\s\S]*?>/g, "");
    eval_string = eval_string.replace("</html>", "");
    eval_string = eval_string.replace(/<head[\s\S]+?<\/head>/gm, ""); //remove entire head section of html (since style has already been extracted)
    eval_string = eval_string.replace(/<body[\s\S]*?>/g, "<div>"); //replace body tag with a simple div tag
    eval_string = eval_string.replace("</body>", "</div>");

    return {eval_string: eval_string, style_string: style_string || ""};
}

/**
 * A function for getting a value from a tiered JSON data-structure using a field "path" with :s instead of .s
 * @param field_path
 * @param data
 * @returns {*}
 */
function get_json_data_using_field_paths(field_path, data) {
    var ret_val;
    if (field_path && (field_path != "")) {
        if (!data) {
            ret_val = undefined;
        }
        else if (data[field_path] !== undefined) { //compromise for already-flattened data
            ret_val = data[field_path];
        }
        else {
            if(field_path.indexOf("[") === 0) { //means data is an array
                var close_brace_index = field_path.indexOf("]");
                if(!Array.isArray(data) || !data.length || (close_brace_index == -1) || (close_brace_index == 1)) {
                    ret_val = undefined;
                }
                else {  //fields are indexed in array by _id in this format
                    var string_id = field_path.substring(1, close_brace_index);
                    data = first_where(data, function(a) {return (a && (a._id == string_id));});
                    if(!data) {
                        ret_val = undefined;
                    }
                    else if (field_path.length == (close_brace_index + 1)) {
                        ret_val = data;
                    }
                    else {
                        var next_field_path = field_path.substr(close_brace_index + 1);
                        if(next_field_path.indexOf(":") == 0) {
                            next_field_path = next_field_path.substr(1);
                        }
                        if(next_field_path && (next_field_path != "")) {
                            ret_val = get_json_data_using_field_paths(next_field_path, data);
                        }
                    }
                }
            }
            else {
                if (field_path.indexOf(':') == -1) {
                    ret_val = data[field_path];
                }
                else {
                    var firstColon, pathPart1, pathPart2;
                    firstColon = field_path.indexOf(':');
                    pathPart1 = field_path.substr(0, firstColon);
                    pathPart2 = field_path.substr(firstColon + 1);
                    ret_val = get_json_data_using_field_paths(pathPart2, data[pathPart1]);
                }
            }
        }
    }
    else {
        ret_val = null;
    }
    return ret_val;
}

/**Returns an array of field names, parsed from a colon-delimited list.  Used for path traversing to reach a nested object.*/
function parse_field_path_into_array(str) {
    var ret_arr;
    if (str && str.length != 0) {
        str = str.trim();
        if (str.length > 0) {
            ret_arr = str.split(":");
            if (!ret_arr || ret_arr.length == 0) {
                return undefined;
            }
        }
    }
    if(!ret_arr) {
        return undefined;
    }
    return ret_arr.filter(function(a) {
        return !string_is_empty_or_whitespace(a, true);
    });
}

/**
 * A function for setting a value to a tiered JSON data-structure using a field "path" with :s instead of .s
 * @param field_path
 * @param entity
 * @param new_value
 * @param data_is_flattened - This is a compromise since client-side data is flattened, except for arrays which still require pathed-set logic
 * @param delete_empty_array_values - For setting in arrays, when setting a field null, check to see if array entry has any non-null fields (besides _id):  if all null, remove entry.
 * @param sync_fields_tracking_object
 * @param is_new_entity
 * @returns {*}
 */
function set_json_data_using_field_paths(field_path, entity, new_value, data_is_flattened, delete_empty_array_values, sync_fields_tracking_object, is_new_entity) {
    var did_create_or_delete_nested_array_entity = false;
    if(sync_fields_tracking_object) {
        did_create_or_delete_nested_array_entity = set_json_data_using_field_paths(field_path, sync_fields_tracking_object, new_value, data_is_flattened) && is_new_entity;
    }
    if(data_is_flattened) { //TODO refactor to pass the field path string down instead of parsing up front, so that flat values can be set on individual array entries
        if(field_path && (field_path.indexOf("[") == -1)) {
            //just set the value:
            entity[field_path] = new_value;
            if(Array.isArray(new_value)) {
                return true; //just in case it did delete (or add) a nested entity by setting this array
            }
            return did_create_or_delete_nested_array_entity;
        }
    }
    var parentObj = entity;
    var last_parent_object;
    var i = 0;
    var path_arr = parse_field_path_into_array(field_path) || [];

    for (i = 0; i < path_arr.length - 1; i++) {
        if (path_arr[i].indexOf("[") == 0) {
            var object_id = path_arr[i].slice(1, path_arr[i].length - 1);
            if (Array.isArray(parentObj)) {
                var next_parentObj = first_where(parentObj, function (a) {
                    return a && (a._id == object_id);
                });
                if (!next_parentObj) {
                    next_parentObj = {_id: object_id};
                    parentObj.push(next_parentObj);
                    did_create_or_delete_nested_array_entity = true;
                }
                last_parent_object = parentObj;
                parentObj = next_parentObj;
            }
        }
        else {
            if (!parentObj[path_arr[i]]) {
                parentObj[path_arr[i]] = (path_arr[i + 1] && (path_arr[i + 1].indexOf("[") == 0)) ? [] : {};
            }
            last_parent_object = parentObj;
            parentObj = parentObj[path_arr[i]];
        }
    }

    if(path_arr[i]) {
        if (path_arr[i].indexOf("[") == 0) {
            var object_id = path_arr[i].slice(1, path_arr[i].length - 1);
            if (Array.isArray(parentObj)) {
                var index_options = {save_index: true};
                var next_parentObj = first_where(parentObj, function (a) {
                    return a && (a._id == object_id);
                }, index_options);
                if (!next_parentObj) {
                    if(new_value != null) {
                        parentObj.push(new_value);
                        did_create_or_delete_nested_array_entity = true;
                    }
                }
                else if (new_value == null) {
                    parentObj.splice(index_options.index, 1);
                }
                else {
                    parentObj[index_options.index] = new_value;
                }
            }
        }
        else {
            parentObj[path_arr[i]] = new_value;
        }
    }

    if(delete_empty_array_values && ((new_value == null) || (new_value == "")) && last_parent_object && Array.isArray(last_parent_object) && !Array.isArray(parentObj) && parentObj._id) {
        for(var field_name in parentObj) {
            if((field_name != "_id") && (field_name != "change_type") && parentObj.hasOwnProperty(field_name) && ((parentObj[field_name] != null) && (parentObj[field_name] != ""))) {
                return did_create_or_delete_nested_array_entity; //no deletion required
            }
        }
        //no non-null fields found:  delete row from array.
        var index_options = {save_index: true};
        var temp = first_where(last_parent_object, function (a) {
            return a && (a._id == parentObj._id);
        }, index_options);
        if (temp) {
            did_create_or_delete_nested_array_entity = true;
            last_parent_object.splice(index_options.index, 1); //remove item
            if(sync_fields_tracking_object) {
                var item_field_path = field_path.substring(0, field_path.lastIndexOf(":"));
                if(is_new_entity) {
                    set_json_data_using_field_paths(item_field_path, sync_fields_tracking_object, null); //remove in the same manner as on the entity instance because it's still new
                }
                else {
                    set_json_data_using_field_paths(item_field_path + ":change_type", sync_fields_tracking_object, "removal"); //removals in sync_fields_tracking_object on client-side are not field_paths, but actual arrays in proper nested json format
                }
            }
        }
    }
    return did_create_or_delete_nested_array_entity;
}


function get_random_int(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function create_uid(len, chars) {
    if(!len || (typeof len != "number")) {
        len = null;
    }
    if(!chars || (typeof chars != "string")) {
        chars = null;
    }
    len = len || default_random_guid_length;
    chars = chars || default_random_uid_chars;
    var buf = [];
    var charlen = chars.length;

    for (var i = 0; i < len; ++i) {
        buf.push(chars[get_random_int(0, charlen - 1)]);
    }

    return buf.join('');
}

function create_guid() {
    return create_uid(default_random_guid_length, default_random_guid_chars);
}

function flatten_2d_array_into_single_array_of_values(array_of_arrays) {
    var ret_val = [];
    if(!array_of_arrays || !array_of_arrays.length) {
        return ret_val;
    }
    for(var i = 0; i < array_of_arrays.length; i++) {
        add_to_list(ret_val, array_of_arrays[i]);
    }
    return ret_val;
}

/** A simple utility function which replaces : with . to match the normal mongo convention for field separators
 *
 * @param entity_path
 * @returns {*}
 */
function get_dot_path_from_entity_path(entity_path) {
    if(!entity_path) {
        return entity_path;
    }
    return entity_path.replace(/:/g, ".");
}

/** A simple utility function which replaces : with . to match the normal mongo convention for field separators
 *
 * @param entity_path
 * @returns {*}
 */
function get_entity_path_from_dot_path(entity_path) {
    if(!entity_path) {
        return entity_path;
    }
    return entity_path.replace(/\./g, ":");
}

function convert_entity_attributes_field_paths_to_dot_paths(entity_attributes) {
    if (entity_attributes) {
        var did_one = false;
        if (entity_attributes.attributes && entity_attributes.attributes.length) {
            for (var i = 0; i < entity_attributes.attributes.length; i++) {
                if (entity_attributes.attributes[i].field_path && (entity_attributes.attributes[i].field_path.indexOf(":") != -1)) {
                    did_one = true;
                    entity_attributes.attributes[i].field_path = get_dot_path_from_entity_path(entity_attributes.attributes[i].field_path);
                }
            }
        }
        if (did_one) {
            entity_attributes.field_paths = convert_to_dictionary_by_field_names(entity_attributes.attributes, {key: "field_path"});
        }
    }
    return entity_attributes;
}

function convert_entity_attributes_dot_paths_to_field_paths(entity_attributes) {
    if (entity_attributes) {
        var did_one = false;
        if (entity_attributes.attributes && entity_attributes.attributes.length) {
            for (var i = 0; i < entity_attributes.attributes.length; i++) {
                if (entity_attributes.attributes[i].field_path && (entity_attributes.attributes[i].field_path.indexOf(".") != -1)) {
                    did_one = true;
                    entity_attributes.attributes[i].field_path = get_entity_path_from_dot_path(entity_attributes.attributes[i].field_path);
                }
            }
        }
        if (did_one) {
            entity_attributes.field_paths = convert_to_dictionary_by_field_names(entity_attributes.attributes, {key: "field_path"});
        }
    }
    return entity_attributes;
}


function split_array_by_condition(input_arr, condition_func) {
    if(!condition_func || (typeof condition_func != "function")) {
        condition_func = null;
    }
    var ret_val = group_by_function(input_arr, function(a) {
        var key;
        if(!condition_func || condition_func(a)) {
            key = "array_a";
        }
        else {
            key = "array_b";
        }
        return {key: key, value: a};
    });
    ret_val.array_a = ret_val.array_a || [];
    ret_val.array_b = ret_val.array_b || [];
    return ret_val;
}

function dummy_filter_function(a) {
    return a;
}

/**
 * A simple helper function to determine if an Object is a Date object
 * @param date_object
 * @returns {*|boolean}
 */
function object_is_date(date_object) {
    return (date_object && (typeof date_object == "object") && (Object.prototype.toString.call(date_object) === '[object Date]'));
}

function case_insensitive_string_sort_function(a, b) {
    b = b.toLowerCase();
    a = a.toLowerCase();
    if(a == b) {
        return 0;
    }
    if(a > b) {
        return 1;
    }
    if(a < b) {
        return -1;
    }
    return 0;
}


function get_monday_of_week(d) {
    if(object_is_date(d)) {
        d = new Date(d);
        var day = d.getDay();
        var new_date = d.getDate() - (day - 1);
        d.setDate(new_date);
        return d;
    }
}

function variable_has_nonempty_non_false_value(value) {
    if((value == null) || (value === false) || (Array.isArray(value) && !value.length)) {
        //considered empty:
        return false;
    }
    return true;
}

general_utils.format = format;
general_utils.add_to_list = add_to_list;
general_utils.is_valid_object_id = is_valid_object_id;
general_utils.extend_object = extend_object;
general_utils.rename_property = rename_property;
general_utils.clear_object = clear_object;
general_utils.group_by = group_by;
general_utils.compare_object_ids = compare_object_ids;
general_utils.remove_property = remove_property;
general_utils.to_array = to_array;
general_utils.get_containing_folders_array = get_containing_folders_array;
general_utils.clone_json = clone_json;
general_utils.set_options = set_options;
general_utils.extract_object_structure = extract_object_structure;
general_utils.convert_to_dictionary = convert_to_dictionary;
general_utils.convert_to_dictionary_by_field_names = convert_to_dictionary_by_field_names;
general_utils.group_by_function = group_by_function;
general_utils.copy_object = copy_object;
general_utils.order_by = order_by;
general_utils.where = where;
general_utils.select = select;
general_utils.multi_phase_getter = multi_phase_getter;
general_utils.first_where = first_where;
general_utils.add_to_list_sorted_by = add_to_list_sorted_by;
general_utils.add_to_sorted_list = add_to_sorted_list;
general_utils.safe_getter = safe_getter;
general_utils.get_current_user_id = get_current_user_id;
general_utils.is_array = is_array;
general_utils.is_object_or_array = is_object_or_array;
general_utils.is_object = is_object;
general_utils.obj_where = obj_where;
general_utils.do_pad_num = do_pad_num;
general_utils.shallow_extend_object = shallow_extend_object;
general_utils.shallow_copy = shallow_copy;
general_utils.safe_stringify = safe_stringify;
general_utils.remove_file_extension = remove_file_extension;
general_utils.remove_sensitive_fields = remove_sensitive_fields;
general_utils.parse_money = parse_money;
general_utils.create_translatable_list = create_translatable_list;
general_utils.override_object_fields = override_object_fields;
general_utils.convert_function_arguments_to_real_array = convert_function_arguments_to_real_array;
general_utils.get_unique_values_from_string_array = get_unique_values_from_string_array;
general_utils.get_nonempty_trimmed_string_or_null = get_nonempty_trimmed_string_or_null;
general_utils.string_is_empty_or_whitespace = string_is_empty_or_whitespace;
general_utils.get_nonempty_trimmed_string_or_undefined = get_nonempty_trimmed_string_or_undefined;
general_utils.fix_response_data = fix_response_data;
general_utils.apply_mongo_search_options = apply_mongo_search_options;
general_utils.create_hierarchical_structure_from_array = create_hierarchical_structure_from_array;
general_utils.sort_by_field_name = sort_by_field_name;
general_utils.get_value_from_function_or_constant = get_value_from_function_or_constant;
general_utils.get_unique_values__slow = get_unique_values__slow;
general_utils.get_unique_values_using_tostring_function = get_unique_values_using_tostring_function;
general_utils.split_into_batches = split_into_batches;
general_utils.make_string_from_array = make_string_from_array;
general_utils.remove_html_tags_and_extract_style = remove_html_tags_and_extract_style;
general_utils.get_json_data_using_field_paths = get_json_data_using_field_paths;
general_utils.set_json_data_using_field_paths = set_json_data_using_field_paths;
general_utils.parse_field_path_into_array = parse_field_path_into_array;
general_utils.default_random_guid_length = default_random_guid_length;
general_utils.create_guid = create_guid;
general_utils.create_uid = create_uid;
general_utils.flatten_2d_array_into_single_array_of_values = flatten_2d_array_into_single_array_of_values;
general_utils.get_dot_path_from_entity_path = get_dot_path_from_entity_path;
general_utils.get_entity_path_from_dot_path = get_entity_path_from_dot_path;
general_utils.convert_entity_attributes_field_paths_to_dot_paths = convert_entity_attributes_field_paths_to_dot_paths;
general_utils.convert_entity_attributes_dot_paths_to_field_paths = convert_entity_attributes_dot_paths_to_field_paths;
general_utils.split_array_by_condition = split_array_by_condition;
general_utils.dummy_filter_function = dummy_filter_function;
general_utils.object_is_date = object_is_date;
general_utils.case_insensitive_string_sort_function = case_insensitive_string_sort_function;
general_utils.convert_to_dictionary_of_true_values = convert_to_dictionary_of_true_values;
general_utils.get_monday_of_week = get_monday_of_week;
general_utils.parse_array_from_comma_delimited_string = parse_array_from_comma_delimited_string;
general_utils.make_array_from_string = make_array_from_string;
general_utils.variable_has_nonempty_non_false_value = variable_has_nonempty_non_false_value;
