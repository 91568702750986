/**
 * ========================================================
 * Description: Renders the web application as a whole
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/

var MainComponent = React.createClass({
    logout_invoke_error : function (errorMessage){
        this.setState({logged_in : false, error :errorMessage});
        this.logout();
        window.location='/';
    },
    invoke_error: function (errorMessage){
        this.setState({error:errorMessage});
    },
    measure_idle_time: function () {
        var context = this;

        //TODO: Lock disabled until it's fixed
        /*clearInterval(context.state.idle_interval);

         idle_interval = setInterval(function() {
         idle_time += 1;
         if (idle_time > 600) {
         context.setState({locked:true});
         clearInterval(context.state.idle_interval);
         context.logout();
         }
         }, 1000);

         this.state.idle_interval = idle_interval;

         $(document).ready(function() {
         $(this).mousemove(function (e) {
         idle_time = 0;
         });

         $(this).keypress(function (e) {
         idle_time = 0;
         });
         });*/
    },
    get_user_info: function (callback) {
        var request = {};
        request.entity = "user";
        request.method = "get_info";

        var context = this;
        if (localStorage.getItem("get_info") === null || localStorage.getItem("get_info") =="" || localStorage.getItem("get_info") === undefined) {
            do_authenticated_http_call({
                method: "POST",
                url: "/api/entity/invoke_method",
                dataType: 'json',
                data: request,
                success: function (data) {
                    localStorage.setItem("get_info", JSON.stringify(data));
                    if (data.data && data.data._id) {
                        user_id = data.data._id;
                    }
                    context.setState({billing: data.billing});
                    context.setState({user_info: data.data});
                },
                error: function (error) {
                    server_error_action(error);
                    if (error.responseJSON) {
                        context.setState({error: error.responseJSON});
                    }
                },
                complete: function () {
                    if(callback) callback();
                }
            });
        }
        else{
            var data = localStorage.getItem("get_info");
            data = JSON.parse(data);
            if (data.data && data.data._id) {
                user_id = data.data._id;
            }
            context.setState({billing: data.billing});
            context.setState({user_info: data.data});
        }
    },

    get_user_avatar: function () {
        var request = {};
        request.entity = "user";
        request.method = "get_avatar";

        var context = this;
        if (localStorage.getItem(request.method) === null || localStorage.getItem(request.method) === undefined){
            do_authenticated_http_call({
                method: "POST",
                url: "/api/entity/invoke_method",
                dataType: 'json',
                data: request,
                error: function (error) {
                    server_error_action(error);
                    if (error.responseJSON) {
                        context.setState({error: error.responseJSON});
                    }
                    context.setState({user_avatar: error.responseText});
                    localStorage.setItem(request.method, error.responseText);
                },
                complete: function () {
                    console.log("get_user_avatar completed");
                }
            }).done(function (data) {
                localStorage.setItem(request.method, JSON.stringify(data));
                context.setState({user_avatar: data});
            });
    }
        else{
            var data = localStorage.getItem(request.method);
            context.setState({user_avatar: data});

        }
    },
    logout: function () {
        localStorage.removeItem("login_token");
        this.on_logged_off_handler();

    },
    initialize_state: function () {
        var context = this;

        return is_logged_in(function (logged_in, data) {

            var messageError='';
            var responseJSON;

            try{
                responseJSON=data.responseJSON;
                messageError = responseJSON.message;
            }catch(err){   }
            if(messageError){
                context.setState({error: messageError});
            }

            if (logged_in) {
                context.on_login_handler();
            }
            else {

                context.on_logged_off_handler();
            }
            context.setState({startup_loading: false});
        });
    },
    on_login_handler: function () {
        var context = this;
        this.setState({logged_in: true});

        load_translations(false, function () {
            context.forceUpdate();
        });

        setTimeout(function() {
            context.initialize_user();
        }, 0);
        setTimeout(function() {
            context.handle_logged_in_locations();
        }, 0);

        window.onpopstate = function (event) {
            g_popped = true;
            context.handle_logged_in_locations();

        };
    },
    on_logged_off_handler: function () {

        var context = this;
        localStorage.clear();
        g_login_token = null;
        this.setState({logged_in: false});
        load_translations(true, function () {
            context.forceUpdate();
        });

        this.handle_locations();

        window.onpopstate = function (event) {
            g_popped = true;
            context.handle_locations();
        };
    },
    initialize_user: function () {
        var context = this;
        setTimeout(function() {
            context.measure_idle_time();
        }, 0);
        setTimeout(function() {
            context.get_user_info();
        }, 0);
        setTimeout(function() {
            context.get_user_avatar();
        }, 0);

    },
    getInitialState: function () {
        var context = this;
        setTimeout(function () {
            context.initialize_state();
        }, 1);
        var compactstate = browser_is_mobile();

        return {startup_loading: true, isCompact: compactstate, is_mobile: compactstate};
    },
    menu_click: function () {
        this.set_compact(!this.state.isCompact);
    },
    set_compact: function (isCompact) {
        this.setState({isCompact: isCompact});
    },
    handle_logged_in_locations: function () {
        var state_object = get_clear_state(this.state.is_mobile);

        if (window.location.pathname == "/") {
            state_object.home = true;
        }
        else if (window.location.pathname == "/settings") {
            state_object.settings = true;
        }
        else if (window.location.pathname == "/billing_settings") {
            state_object.billing_settings = this.state.billing;
            if (!this.state.billing) window.location.pathname = "/";
        }
        else if (window.location.pathname == "/edit_form") {
            if (get_parameter_by_name("code")) {
                state_object.edit_form = true;
                state_object.app_object_code = get_parameter_by_name("code");
                state_object.edit_form_id = get_parameter_by_name("_id");
            }
        }
        else if (window.location.pathname == "/data_list") {
            if (get_parameter_by_name("code")) {
                state_object.data_list = true;
                state_object.app_object_code = get_parameter_by_name("code");
            }
        }
        else if (window.location.pathname == "/search") {
            if (get_parameter_by_name("search_string")) {
                state_object.search = true;
                state_object.search_string = get_parameter_by_name("search_string");
            }
        }
        else if (window.location.pathname == "/user_list") {
            state_object.user_data_list = true;
        }
        else if (window.location.pathname == "/previewer_docx") {
            state_object.previewer_docx = true;
        }
        this.setState(state_object);
    },
    handle_locations: function () {
        var state_object = get_clear_state(this.state.is_mobile);
        if (window.location.pathname == "/setup_user") {
            state_object.setup_user = true;

            var setup_token = get_parameter_by_name("setup_token");
            if (setup_token != "") {
                state_object.setup_token = setup_token;
            }
        }
        else if (window.location.pathname == "/reset_password") {
            state_object.reset_password = true;

            var reset_password_token = get_parameter_by_name("reset_password_token");
            if (reset_password_token != "") {
                state_object.reset_password_token = reset_password_token;
            }
        }
        else if(window.location.pathname == "/registration"){
            state_object.registration = true;

            var registration_token = get_parameter_by_name("registration_token");
            if (registration_token != "") {
                state_object.registration_token = registration_token;
            }
        }

        this.setState(state_object);
    },
    handle_login_success: function (forgot_password) {
        if (!forgot_password) {
            this.on_login_handler();
        }
        else {
            this.setState({forgot_password: true});
        }
    },
    handle_forgot_password_completion: function () {
        this.setState({forgot_password: false});
    },
    handle_setup_user_success: function () {
        var interval = setInterval(function () {
            clearInterval(interval);
            window.location.href = window.location.protocol + "//" + window.location.host;
        }, 1000);
    },
    handle_registration_success: function () {
        var interval = setInterval(function () {
            clearInterval(interval);
            window.location.href = window.location.protocol + "//" + window.location.host;
        }, 1000);
    },
    handle_reset_password_completion: function () {
        this.setState({reset_password: false});
        var uri = window.location.toString();
        var clean_uri = uri.substring(0, uri.indexOf("?"));
        window.history.replaceState({}, document.title, clean_uri);
        window.location.pathname = "/";


    },
    handle_executing:function(status) {
        if (this.state.executing != status){
            this.setState({executing:status});
        }
    },
    navigation_handler: function (location) {
        var settings_menu = $("ul.account-area");
        if (settings_menu) settings_menu.children().removeClass('open');
        var context = this;
        var handle_navigation = function () {
            var state_object=undefined;
            if (location == "home") {
                if (!context.state.home) context.handle_executing(true);
                state_object = get_clear_state(context.state.is_mobile);
                state_object.home = true;
                context.setState(state_object);
            }
            else if (location == "settings") {
                if (!context.state.settings) context.handle_executing(true);
                state_object = get_clear_state(context.state.is_mobile);
                state_object.settings = true;
                context.setState(state_object);
            }
            else if (location == "billing_settings") {
                if (!context.state.billing_settings) context.handle_executing(true);
                state_object = get_clear_state(context.state.is_mobile);
                state_object.billing_settings = true;
                context.setState(state_object);
            }
            else if (location == "invite_users") {
                if (!context.state.invite_users) context.handle_executing(true);
                state_object = get_clear_state(context.state.is_mobile);
                state_object.invite_users = true;
                context.setState(state_object)
            }
            else if (location == "user_data_list") {
                if (!context.state.user_data_list) context.handle_executing(true);
                state_object = get_clear_state(context.state.is_mobile);
                state_object.user_data_list = true;
                context.setState(state_object);
            }
            else if (location == "previewer_docx") {
                if (!context.state.previewer_docx) context.handle_executing(true);
                state_object.previewer_docx = true;
                context.setState(state_object);
            }
            else if (location == "logout") {
                context.handle_executing(true);
                context.on_logged_off_handler();
            }
        };

        if (current_navigation_listener) {
            return current_navigation_listener(function (confirm_location_change) {
                if (confirm_location_change) {
                    handle_navigation();
                }
            });
        }
        else {
            handle_navigation();
        }
    },
    app_object_handler: function (app_object, skip_location_push) {
        var settings_menu = $("ul.account-area");
        if (settings_menu) settings_menu.children().removeClass('open');
        var context = this;

        var handle_app_object = function () {
            var state_object = get_clear_state(context.state.is_mobile);
            var code = app_object.code;
            var data_list = app_object.type == "data_list";
            var edit_form = app_object.type == "edit_form";

            var drilldown_conditions = $.extend({}, app_object.drilldown_conditions || {});
            var app_object_conditions = $.extend({}, app_object.conditions || {});
            delete state_object.app_object_conditions;
            delete state_object.drilldown_conditions_holder;
            delete state_object.full_app_object_conditions;
            if (app_object.workflow_conditions) {
                app_object_conditions.workflow_status = app_object.workflow_conditions.workflow_status; //overrides standing conditions.
            }

            state_object.static_component = null;
            state_object.data_list = data_list;
            state_object.edit_form = edit_form;
            state_object.app_object_code = code;

            if(app_object.custom_properties && app_object.custom_properties.view_modes) {
                var static_component = app_object.custom_properties.view_modes.find(function(component) {
                    return static_components_config.component_names.includes(component);
                });
                if( static_component) {
                    state_object.static_component = static_component;
                    state_object.static_data = app_object.custom_properties.static_data;
                    state_object.data_list = false;
                    state_object.edit_form = false;
                }
            }

            if (data_list) {
                state_object.app_object_conditions = app_object_conditions;
                state_object.drilldown_conditions_holder = {drilldown_conditions: drilldown_conditions};
                state_object.full_app_object_conditions = shared_model_utils.add_and_clause_to_conditions(drilldown_conditions, app_object_conditions);
                if(!Object.keys(state_object.full_app_object_conditions).length) {
                    state_object.full_app_object_conditions = null;
                }
            }
            state_object.edit_form_id = app_object._id;
            state_object.skip_location_push = skip_location_push;
            context.setState(state_object);
        };

        if (current_navigation_listener) {
            return current_navigation_listener(function (confirm_location_change) {
                if (confirm_location_change) {
                    handle_app_object();
                }
            });
        }
        else {
            return handle_app_object();
        }
    },
    search_handler: function (search_string) {
        var state_object = get_clear_state(this.state.is_mobile);
        state_object.search = true;
        state_object.search_string = search_string;
        this.setState(state_object);
    },
    request_error_parent : function (){
        var context=this;

        var errorMessage=this.state.error=='' ? undefined : this.state.error;

        if(this.state.error){
            /*setTimeout(function(){
                context.setState({error:undefined });
            },2000);*/
        }
        return errorMessage;
    },
    logged_in_false:function(errorMessage){
        this.eraseCookie('intercom-id');
        this.setState({logged_in : false, error :errorMessage });
        this.forceUpdate();
    },
    createCookie:  function createCookie(name,value,days) {
        var expires='';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            expires = "; expires="+date.toGMTString();
        }
        document.cookie = name+"="+value+expires+"; path=/";
    },

    readCookie: function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },
    eraseCookie:function eraseCookie(name) {
        createCookie(name,"",-1);
    },
    render: function () {
        if (this.state.startup_loading) {
            return (<LoadingComponent  logged_in_false={this.logged_in_false} />);
        }
        if (this.state.logged_in) {
            var full_page_content;
            var page_content = "";
            if (this.state.home) {
                if(!this.state.isCompact && this.state.is_mobile && !(this.state.user_info && this.state.user_info.should_hide_menu)) { //don't need the dashboard parts, mobile has the menu open (and this user Has a menu)
                    push_href_location((R.label_dashboard || "Home") + " - " + (R.client_application_title || "Xenforma"), "/");
                    this.state.executing = false;
                }
                else {
                    page_content = <HomeDashboardComponent
                        key="home"
                        navigation_handler={this.navigation_handler}
                        handle_executing={this.handle_executing}
                        app_object_handler={this.app_object_handler}
                        logged_in_false={this.logged_in_false}/>;
                }
            }
            else if (this.state.settings) {
                page_content =
                    <UserSettingsComponent update_avatar={this.get_user_avatar}
                                           update_user_info={this.get_user_info}
                                           navigation_handler={this.navigation_handler}
                                           handle_executing={this.handle_executing}
                                           avatar={this.state.user_avatar}
                                           logged_in_false={this.logged_in_false}/>;
                if(this.state.is_mobile) {
                    full_page_content = page_content;
                }
            }
            else if (this.state.billing_settings) {
                page_content =
                    <BillingSettingsComponent
                        update_avatar={this.get_user_avatar}
                        update_user_info={this.get_user_info}
                        navigation_handler={this.navigation_handler}
                        handle_executing={this.handle_executing}
                        avatar={this.state.user_avatar}
                        logged_in_false={this.logged_in_false}/>;
                if(this.state.is_mobile) {
                    full_page_content = page_content;
                }
            }
            else if (this.state.invite_users) {
                page_content = <InviteUsersComponent  logged_in_false={this.logged_in_false}/>;
                if(this.state.is_mobile) {
                    full_page_content = page_content;
                }
            }
            else if (this.state.edit_form) {
                page_content = <EditFormComponent app_object_code={this.state.app_object_code} data={this.state.data}
                                                  handle_executing={this.handle_executing}
                                                  navigation_handler={this.navigation_handler}
                                                  _id={this.state.edit_form_id}
                                                  key={this.state.app_object_code + this.state.edit_form_id}
                                                  app_object_handler={this.app_object_handler}
                                                  update_avatar={this.get_user_avatar}
                                                  update_user_info={this.get_user_info}
                                                  is_mobile={this.state.is_mobile}
                                                  logged_in_false={this.logged_in_false}/>;
                if(this.state.is_mobile) {
                    full_page_content = page_content;
                }
            }
            else if (this.state.data_list && this.state.app_object_code != "faq_default_edit_form" && this.state.app_object_code != "contact_us_default_edit_form") {
                var key = this.state.app_object_code;
                if (this.state.full_app_object_conditions) {
                    key += (":" + JSON.stringify(this.state.full_app_object_conditions));
                }
                page_content = <DefaultDataListComponent key={key}
                                                         app_object_code={this.state.app_object_code}
                                                         app_object_conditions={this.state.app_object_conditions}
                                                         app_object_handler={this.app_object_handler}
                                                         drilldown_conditions_holder={this.state.drilldown_conditions_holder || {}}
                                                         handle_executing={this.handle_executing}
                                                         is_mobile={this.state.is_mobile}
                                                         logged_in_false={this.logged_in_false}
                                                         navigation_handler={this.navigation_handler}
                                                         skip_location_push = {this.state.skip_location_push} />;
                if(this.state.is_mobile && !(this.state.user_info && this.state.user_info.should_hide_menu && this.state.skip_location_push)) {
                    full_page_content = page_content;
                }
            }
            else if (this.state.search) {
                page_content = <SearchComponent search_string={this.state.search_string}
                                                key={this.state.search_string}
                                                app_object_handler={this.app_object_handler}
                                                navigation_handler={this.navigation_handler}
                                                logged_in_false={this.logged_in_false}
                                                handle_executing={this.handle_executing}/>;
                if(this.state.is_mobile) {
                    full_page_content = page_content;
                }
            }
            else if (this.state.user_data_list) {
                page_content = <UserListComponent key="user_data_list"
                                                  app_object_handler={this.app_object_handler}
                                                  logged_in_false={this.logged_in_false}/>;
            }
            else if (this.state.previewer_docx) {
                page_content = <Pdfjs key="previewer_docx" url=""/>;
            }
            else if (this.state.static_component) {
                var StaticComponent = static_components_config.DOM_Component[this.state.static_component];
                page_content = <StaticComponent static_data={this.state.static_data}></StaticComponent>;
            }
            
            var loader = null;
            if (this.state.executing){
                loader = <LoadingComponent logged_in_false={this.logged_in_false} />;
            }
            var side_bar_component;
            if((this.state.user_info && this.state.user_info.should_hide_menu) || (full_page_content && this.state.is_mobile)) {
                side_bar_component = undefined;
            }
            else {
                if(this.state.is_mobile && page_content && (page_content != "")) {  //!full_page_content, so we need to make room for the page_content
                    this.state.isCompact = true;
                }
                side_bar_component = (<SideBarComponent navigation_handler={this.navigation_handler}
                                                    app_object_handler={this.app_object_handler}
                                                    search_handler={this.search_handler}
                                                    isCompact={this.state.isCompact}
                                                    compact_change_handler={this.set_compact}
                                                    logged_in_false={this.logged_in_false}
                                                    is_mobile={this.state.is_mobile}
                                                    handle_executing={this.handle_executing}/>);
            }

            if (full_page_content) {
                return (<div className="mobile_shadow_with_margin">{full_page_content}</div>);
            }

            var main_body_div;
            if (side_bar_component) {
                main_body_div = (<div className="main-container container-fluid">
                    <div className="page-container">
                        {side_bar_component}
                        <div className="page-content">
                            <div className="page-body">
                                {page_content}
                            </div>
                        </div>
                    </div>
                </div>);
            }
            else {
                main_body_div = (<div className="main-container container-fluid">
                    <div className="page-container">
                        <div className="page-body">
                            {page_content}
                        </div>
                    </div>
                </div>);
            }

            return (<div>
                {loader}
                <NavBarComponent navigation_handler={this.navigation_handler}
                                 user_info={this.state.user_info}
                                 user_avatar={this.state.user_avatar}
                                 menu_click={this.menu_click}
                                 billing={this.state.billing}
                                 is_mobile={this.state.is_mobile}
                                 logged_in_false={this.logged_in_false}/>
                {main_body_div}
            </div>);
        }

        if (this.state.forgot_password) {
            return (<ForgotPasswordComponent  logged_in_false={this.logged_in_false} on_complete={this.handle_forgot_password_completion}/>);
        }
        else if (this.state.reset_password) {
            return (<ResetPasswordComponent  logged_in_false={this.logged_in_false}  on_complete={this.handle_reset_password_completion}/>);
        }
        else if (this.state.setup_user) {
            return (<SetupUserComponent  logged_in_false={this.logged_in_false} on_success={this.handle_setup_user_success}/>);
        }
        else if (this.state.registration) {

            return (<PaymentRegistrationComponent
                logged_in_false={this.logged_in_false}
                handle_executing={this.handle_executing}
                on_success={this.handle_registration_success}/>);
        }
        else if (this.state.locked) {
            return (<LockComponent  logged_in_false={this.logged_in_false}
                                    user_info={this.state.user_info}
                                    user_avatar={this.state.user_avatar}
                                    on_success={this.handle_login_success}
                                    eraseCookie={ this.eraseCookie }/>);
        } else {

            return (<LoginComponent
                on_success={this.handle_login_success}
                request_error_parent={this.request_error_parent}
                handle_executing={this.handle_executing}
                logged_in_false={this.logged_in_false}
                eraseCookie={this.eraseCookie}

            />);
        }
    }
});
