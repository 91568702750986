/**
 * ========================================================
 * Description: A web control for uploading a file (non-modal)
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var EditorFileUpload = React.createClass({
    getInitialState: function () {
        return null;
    },
    componentWillReceiveProps:function(nextProps) {
        if (nextProps.resetFileUpload) {
            this.fileUploadReset();
        }
    },
    render: function () {
        var context = this;
        var multiple_files = {};

        if (this.props.readOnly) {
            return <div></div>
        }
        if(this.props.multiple) {
            multiple_files = {
                multiple: this.props.multiple
            };
        }
        return( 
            <div style={{"marginTop":"6px"}}>
                <input ref={function(ref) { context.domElement = ref }} multiple={multiple_files.multiple} type="file" style={{"display":"inline"}}/>
            </div>
        );
    },
    componentDidMount: function () {
        var context = this;
        if (this.props.readOnly)
            return;
        $(this.domElement).on('change', function (e) {
            Object.keys(e.target.files).forEach(function(file) {
                context.props.onFileNameChange(e.target.files[file], e.target.files.length);
            });
        });

        if (this.props && this.props.fileName) {
            this.setState({fileName: this.props.fileName});
        }
    },
    fileUploadReset: function () {
        $(this.domElement).wrap('<form>').closest('form').get(0).reset();
        $(this.domElement).unwrap();
    },
});
