/**
 * ========================================================
 * Description: Component to render a server error to the page
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var EditFormSignature = React.createClass({
    propTypes: {
        handleSaveClick: React.PropTypes.func,
        handleCancelClick: React.PropTypes.func,
        hasAttachmentPopUp: React.PropTypes.bool,
        handleAttachmentSaveClick: React.PropTypes.func,
        handleAttachmentCancelClick: React.PropTypes.func
    },
    getInitialState: function () {
        return {};
    },
    handleCancelClick: function(e)
    {
        e.preventDefault();
        this.props.handleCancelClick();
    },
    handleAttachmentSaveClick: function(e)
    {
        this.props.handleAttachmentSaveClick();
        this.setState({showAttachmentPopUp: false});
    },
    handleAttachmentCancelClick: function(e)
    {
        this.props.handleAttachmentCancelClick();
        this.setState({showAttachmentPopUp: false});
    },
    handleSaveClick: function(e)
    {
        e.preventDefault();
        if (this.props.hasAttachmentPopUp){
            this.setState({showAttachmentPopUp: true});

        } else{
            this.props.handleSaveClick();
        }
    },
    
    render: function () {
        if(this.state.showAttachmentPopUp){
            return (
                <EditFormAttachment
                    handleSaveClick={this.handleAttachmentSaveClick}
                    handleCancelClick={this.handleAttachmentCancelClick}
                />
            );
        }
        return (
        <div id="comment-box">
            <div className="row">
                <div className="form-group">
                    <div className="col-sm-12 form-group" style={{textAlign:"center", lineHeight: "200px"}}>
                        <label className="col-sm-12">{R.confirm_signature_message || "To continue, you will need to affixing your digital signature to this document.  Do you wish to proceed?"}</label>
                    </div>
                </div>
            </div>

            <div id="workflow-button-box"
                 align="right"
                 className="pull-right"
                 style={{
                            width: "100%",
                            height : "auto" ,
                            padding: "10px",
                            backgroundColor : "white",
                            boxShadow: "1px 0 10px 1px rgba(0,0,0,0.3)",
                            textAlign: "right",
                            position:"absolute",
                            left:"0px",
                            bottom:"0px"
                            }}>
                <div style={{display : "inlineTable", width: "auto"}}>
                    <button className="btn btn-success shiny workflow_button_margin"
                            onClick={this.handleSaveClick}>{R.label_continue}</button>
                    <button onClick={this.handleCancelClick}
                            className="btn btn-default shiny">{R.label_cancel}</button>
                </div>
            </div>
        </div>
        );
    }
});
