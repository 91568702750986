/**
 * ============================================================
 * Description:     Default data list view component
 * Creation Date:   11/29/16
 * Author:          Anthony C
 * ============================================================
 **/
 var DataGridViewComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
        if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + msg + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },
    propTypes: {
        app_object: React.PropTypes.object,
        app_object_code: React.PropTypes.string,
        app_object_conditions: React.PropTypes.object,
        apply_app_object_sorting: React.PropTypes.func,
        apply_personalized_columns_from_local_storage: React.PropTypes.func,
        assign_to_element: React.PropTypes.any,
        call_data_list_set_state: React.PropTypes.func,
        confirm: React.PropTypes.func,
        data_list_parent_react_entity: React.PropTypes.object,
        delete_nested_entity: React.PropTypes.func,
        description_grid: React.PropTypes.object,
        disable_create: React.PropTypes.bool,
        disallow_grid_filters: React.PropTypes.bool,
        disallow_grid_workflows: React.PropTypes.bool,
        display_loader: React.PropTypes.func,
        entity_access: React.PropTypes.string,
        entity_attributes: React.PropTypes.object,
        entity_instances: React.PropTypes.array,
        error_component: React.PropTypes.object,
        export_component: React.PropTypes.object,
        export_grid_conditions: React.PropTypes.object,
        get_custom_grid_properties: React.PropTypes.func.isRequired,
        get_data_list_state_value: React.PropTypes.func,
        get_drilldown_conditions: React.PropTypes.func,
        get_full_conditions: React.PropTypes.func,
        getDbStoragePersonalizedLayoutCode: React.PropTypes.func,
        getLocalStoragePersonalizedLayoutCode: React.PropTypes.func,
        grid_id: React.PropTypes.string,
        grid_init_callback: React.PropTypes.func,
        grid_is_on_edit_form: React.PropTypes.bool,
        handle_add: React.PropTypes.func,
        handle_delete: React.PropTypes.func,
        handle_executing: React.PropTypes.func,
        is_mobile: React.PropTypes.bool,
        is_nested: React.PropTypes.bool,
        is_ref_lookup: React.PropTypes.bool,
        is_xenforma_note: React.PropTypes.bool,
        nested_entity_handler: React.PropTypes.func,
        nested_list_entities: React.PropTypes.any,
        nested_reference_entity_data: React.PropTypes.object,
        parent_entity_array: React.PropTypes.array,
        parent_entity_field_attribute: React.PropTypes.any,
        read_only: React.PropTypes.bool,
        requestAndSetCount: React.PropTypes.func,
        set_data_list_state: React.PropTypes.func,
        skip_location_push: React.PropTypes.any,
        showBodyDatagrid: React.PropTypes.string,
        update_fields_for_entity_instances: React.PropTypes.array,
        update_height: React.PropTypes.func,
        updateDimensions: React.PropTypes.func
    },
    getInitialState: function() {
        var context = this;
        context.debugLog('getInitialState', true);

        context.debugLog('getInitialState', false);
        return {
            scrollViewContentElementId: create_uid(),
            export_grid_conditions: context.props.export_grid_conditions,
            entity_instances: context.props.entity_instances
        };
    },
    componentDidMount: function () {
        var context = this;
        context.debugLog('componentDidMount', true);

        $(context.popupContentElement).dxScrollView({
            useNative: true,
            scrollByThumb: true
        }).dxScrollView("instance");

        context.popup = $(context.popupElement).dxPopup({
            showTitle: true
        }).dxPopup("instance");

        if ( context.props.get_data_list_state_value("shouldInitGrid") ) {
            context.init_grid_columns();
            context.init_grid();
        }

        context.debugLog('componentDidMount', false);
    },
    componentDidUpdate: function () {
        var context = this;
        context.debugLog('componentDidUpdate', true);

        if ( context.props.get_data_list_state_value("shouldInitGrid") ) {
            context.init_grid_columns();
            context.init_grid();
        }

        context.debugLog('componentDidUpdate', false);
    },
    componentWillUnmount: function () {
        var context = this;
        context.debugLog('componentWillUnmount', true);

        if ( context.getGridViewInstance() ) {
            context.getGridViewInstance().hideColumnChooser();
        }

        context.debugLog('componentWillUnmount', false);
    },
    componentWillReceiveProps: function (nextProps) {
        if(this.props.is_nested && nextProps.entity_instances && (nextProps.entity_instances != this.state.entity_instances)) {
            this.state.entity_instances = nextProps.entity_instances;
            var grid = this.getGridViewInstance();
            if(grid) {
                grid.option("dataSource", this.state.entity_instances);
                grid.refresh();
            }
        }
    },
    /**
     * Resize of the data grid
     */
    resizeDataGrid: function(){
        var context = this;
        context.debugLog('resizeDataGrid', true);

        var grid = context.getGridViewInstance();
        if ( grid ) {
            grid.updateDimensions();
        }

        context.debugLog('resizeDataGrid', false);
    },
    /**
     * Initialization of the data grid
     */
    init_grid: function () {
        var context = this;
        context.debugLog('init_grid', true);

        var grid_properties = {};
        var custom_properties = context.props.app_object.custom_properties ? context.props.app_object.custom_properties : {},
            custom_grid_properties = context.props.get_custom_grid_properties({
                columns: context.state.columns,
                custom_properties: custom_properties,
                grid_id: context.props.grid_id
            }),
            multiSelect = custom_properties.grid_multi_select == undefined ? false : custom_properties.grid_multi_select;

        for ( var custom_grid_property_key in custom_grid_properties ) {
            grid_properties[custom_grid_property_key] = custom_grid_properties[custom_grid_property_key];
        }

        if ( grid_properties.paging && grid_properties.paging.enabled ) {
            grid_properties.paging.pageSize = context.props.app_object.page_size || 50;
        }
        grid_properties.dataSource = context.load_dataSource();
        grid_properties.columnFixing = { enabled : true };
        grid_properties.loadPanel = { enabled : true };
        grid_properties.columns = context.state.columns;
        grid_properties.columnAutoWidth = false;
        //Activates horizontal scroll bar if true.
        if ( document.documentElement.clientWidth >= 800 ) {
            grid_properties.columnAutoWidth = (grid_properties.columns > 5);
        }
        grid_properties.allowColumnResizing = true;
        grid_properties.allowColumnReordering = true;
        grid_properties.showRowLines = true;
        grid_properties.wordWrapEnabled = true;
        grid_properties.rowAlternationEnabled = true;
        grid_properties.headerFilter = { visible: true };
        if ( grid_properties.export && grid_properties.export.enabled ) {
            grid_properties.export.fileName = context.props.app_object.name;
            grid_properties.export.allowExportSelectedData = multiSelect;
        }
        grid_properties.stateSorting = { enabled: true, type: 'localStorage', storageKey: 'xenforma' };
        grid_properties.selection = { mode: multiSelect ? "multiple" : "none", showCheckBoxesMode: multiSelect ? "always" : "none" };
        grid_properties.editing = {
            mode: "cell",
            allowAdding: (custom_properties.editable && !context.props.disable_create && context.entity_access_contains('c')),
            allowUpdating: true,
            allowDeleting: false, //((!custom_properties.disable_delete) && (custom_properties.editable)),
            texts: {
                addRow: R.add_row || "Create new"
            }
        };
        if(context.props.handle_delete) {
            grid_properties.editing.texts.confirmDeleteMessage = ""; //Prevents the delete confirmation message from popping up
        }

        grid_properties.onRowPrepared = context.onRowPrepared;
        grid_properties.onCellPrepared = context.onCellPrepared;
        grid_properties.onEditorPreparing = context.onEditorPreparing;
        grid_properties.onOptionChanged = context.onOptionChanged;
        grid_properties.onContentReady = function (e) {
            context.debugLog('init_grid onContentReady c', true);

            //add report button
            if ( custom_properties.reports ) {
                context.insert_report_button(custom_properties.reports);
            }
            //add export button
            if ( grid_properties.export.enabled && custom_properties.custom_export ) {
                context.insert_export_button(custom_properties.custom_export, e);
            }
            if ( e.component.columnOption('command:edit', 'visible') ) {
                e.component.columnOption('command:edit', 'visible', false);
            }

            if(context.props.grid_init_callback) {
                context.props.grid_init_callback(e.component, context.props.requestAndSetCount);
            }

            context.debugLog('init_grid onContentReady c', false);
        };

        if ( !context.props.is_xenforma_note ) {
            grid_properties.onEditorPrepared = function (e) {
                if ( e.editorName == "dxSelectBox" ) {
                    e.editorElement.dxSelectBox('instance').option('searchEnabled', false);
                }
                if ( e.updateValueTimeout ) {
                    e.updateValueTimeout = 1500;
                }
                if ( (e.parentType == "dataRow") && (e.dataField) ) {
                    if ( e.editorElement[e.editorName] && (typeof e.editorElement[e.editorName] == "function") ) {
                        var local_temp_dx_element = e.editorElement[e.editorName]('instance');
                        if ( local_temp_dx_element ) {
                            local_temp_dx_element.on('disposing', function (local_event) {
                                if(e.row && e.row.key) {
                                    context.change_workflow_button_count(false, e.dataField + e.row.key);
                                }
                                context.run_selection_buttons_display_logic();
                            });
                        }
                    }
                }
            };
        }

        if ( context.props.is_nested ) {
            if (context.props.app_object.edit_app_object ) {
                var nested_popup_func = nested_entity_row_popup_function.bind(context, context);
                if(custom_properties.edit_in_grid) {

                }
                else {
                    grid_properties.onEditingStart = function (event) {
                        context.debugLog('init_grid onEditingStart', true);

                        event.cancel = true;

                        var data_row = event.data;
                        if (data_row._id) {
                            data_row = $.extend(true, {}, data_row);

                            context.debugLog('init_grid onEditingStart', false);
                            return nested_popup_func(
                                data_row,
                                undefined,
                                context.props.app_object,
                                context.props.entity_attributes,
                                context.props.nested_list_entities,
                                context.props.parent_entity_array,
                                context.props.parent_entity_field_attribute,
                                context.props.data_list_parent_react_entity
                            );
                        }

                        context.debugLog('init_grid onEditingStart', false);
                    };
                }

                if(custom_properties.edit_in_grid) {
                    grid_properties.editing = grid_properties.editing || {};
                    grid_properties.editing.mode = "cell";
                }

                grid_properties.onContentReady = function (e) {
                    context.debugLog('onContentReady b', true);
                    $("#" + context.props.grid_id + ' .dx-datagrid-addrow-button').click(function () {
                        if (custom_properties.edit_in_grid) {

                        }
                        else {

                            var data_row = {};
                            return nested_popup_func(
                                data_row,
                                true,
                                context.props.app_object,
                                context.props.entity_attributes,
                                context.props.nested_list_entities,
                                context.props.parent_entity_array,
                                context.props.parent_entity_field_attribute,
                                context.props.data_list_parent_react_entity
                            );
                        }
                    });

                    //allow reader users or read only forms too add notes
                    if ( !e.component.option('editing.allowAdding') && (!context.props.read_only || context.props.is_xenforma_note) ) {
                        e.component.option('editing.allowAdding', true);
                    }
                    if ( e.component.columnOption('command:edit', 'visible') ) {
                        e.component.columnOption('command:edit', 'visible', false);
                    }

                    $('.fachange_history').removeClass('fachange_history').addClass("fa fa-pencil-square-o");
                    $('.facomment').removeClass('fachange_history').addClass("fa fa-comment-o");
                    $('.comment-footer[note_type!="comment"]').css("display", "none");
                    $('.faworkflow').removeClass('fachange_history').addClass("fa fa-check-circle-o");
                    $('.fasystem_event').removeClass('fachange_history').addClass("fa fa-asterisk");

                    e.component.searchByText(""); //Can't see searchPanel anyway, and for some reason this filter is erroneously being inherited from other grids
                    if(context.props.grid_init_callback) {
                        context.props.grid_init_callback(e.component, context.props.requestAndSetCount);
                    }

                    context.fix_notes_columns();

                    context.debugLog('onContentReady b', false);
                };
            }

            var on_change = function (e) {
                context.debugLog('on_change', true);

                if ( e.data && e.data.__KEY__ ) {
                    e.cancel = true;
                }
                else {
                    if ( context.props.on_change ) {
                        context.props.on_change(context.state.entity_instances);
                    }
                    else if (context.props.update_fields_for_entity_instances) {
                        add_modified_entity_to_update_fields(context.props.update_fields_for_entity_instances, (e.data || $.extend({}, e.oldData, e.newData)), context.props.parent_entity_array);
                    }
                }

                context.debugLog('on_change', false);
            };

            var on_insert = function (e) {
                if ( context.props.on_change ) {
                    context.props.on_change(context.state.entity_instances);
                }
                else if (context.props.update_fields_for_entity_instances && !(context.props.data_list_parent_react_entity && context.props.data_list_parent_react_entity.state && context.props.data_list_parent_react_entity.state.is_new_instance)) { // Not a new instance:
                    add_modified_entity_to_update_fields(context.props.update_fields_for_entity_instances, (e.data || $.extend({}, e.oldData, e.newData)), context.props.parent_entity_array);
                }
                else if (e.data) {
                    // context.props.update_fields_for_entity_instances will be the same array already being used in context.props.data_list_parent_react_entity.state.entity_instance (if it exists)
                    e.data.change_type = "created";
                    e.data._id = e.data._id || general_utils.create_guid();
                    if(context.props.parent_entity_field_attribute && context.props.parent_entity_field_attribute.field_path) {
                        var field_path = context.props.parent_entity_field_attribute.field_path;
                        if (context.props.data_list_parent_react_entity && context.props.data_list_parent_react_entity.state && context.props.data_list_parent_react_entity.state.update_fields && context.props.data_list_parent_react_entity.state.entity_instance && (!context.props.data_list_parent_react_entity.state.update_fields[field_path] && context.props.data_list_parent_react_entity.state.entity_instance[field_path])) {
                            context.props.data_list_parent_react_entity.state.update_fields[field_path] = context.props.data_list_parent_react_entity.state.entity_instance[field_path];
                        }
                    }
                }
            };

            grid_properties.onRowUpdating = on_change;
            grid_properties.onRowInserting = (custom_properties.edit_in_grid) ? on_insert : on_change;
            grid_properties.onRowRemoving = function (e) {
                context.debugLog('onRowRemoving', true);

                var retval = context.props.delete_nested_entity(context.props.parent_entity_field_attribute, context.props.parent_entity_array, e.key._id);
                if ( context.props.data_list_parent_react_entity ) {
                    context.props.data_list_parent_react_entity.set_confirming(false);
                }

                context.debugLog('onRowRemoving', false);
                return retval;
            };
        }
        else {
            grid_properties.onEditingStart = function (event) {
                context.debugLog('init_grid onEditingStart', true);

                if ( context.entity_access_contains('u') ) {
                    //allow normal logic flow, but disable workflow buttons
                    if(event.column && event.key) {
                        context.change_workflow_button_count(true, event.column.dataField + event.key);
                    }
                    context.run_selection_buttons_display_logic();
                }
                else {
                    event.cancel = true;
                }

                if ( context.props.is_ref_lookup ) {
                    event.cancel = true;
                    var data_row = event.data;
                    if ( data_row._id ) {
                        var app_object = {
                            code: context.props.app_object.edit_app_object,
                            data_row: data_row,
                            _id: data_row._id
                        };
                        var ref_data = context.props.nested_reference_entity_data;
                        context.props.data_list_parent_react_entity.setState({
                            nested_reference_entity_data: ref_data,
                            reference_entity_id: data_row._id,
                            grid_is_on_edit_form: true
                        });
                    }
                }

                context.debugLog('init_grid onEditingStart', false);
            };
            grid_properties.onCellClick = function(event) {
                if(event && event.key && event.column && event.column.allow_column_edit && event.column.allowEditing && context.entity_access_contains('u')) {
                    context.change_workflow_button_count(true, event.column.dataField + event.key);
                    context.run_selection_buttons_display_logic();
                }
            };

            var temp_grid_properties = $.extend(true, {}, grid_properties);
            temp_grid_properties.dataSource = [];
            temp_grid_properties.visible = false;
            temp_grid_properties.export.enabled = false;
            temp_grid_properties.columns = [];
            $("#hidden_export_" + context.props.grid_id).dxDataGrid(temp_grid_properties);

            var export_success_func_maker = function(local_grid_component) {
                return function (data) {
                context.debugLog('init_grid export_success_func', true);

                fix_response_data(data, context.props.entity_attributes.field_paths, context.props.entity_attributes.nested_list_entities, true);

                context.state.entity_instances.length = 0; //delete all of these
                context.state.entity_instances.push.apply(context.state.entity_instances, data.entity_instances);
                    context.state.entity_instances = context.state.entity_instances || [];
                    temp_grid_properties.dataSource = context.state.entity_instances;
                    temp_grid_properties.visible = false;
                    temp_grid_properties.export.enabled = false;
                    var personalized_state = local_grid_component.state();
                    var personalized_columns;
                    if ( personalized_state && personalized_state.columns && personalized_state.columns.length ) {
                        personalized_columns = personalized_state.columns;
                    }
                    if(personalized_columns && personalized_columns.length) {
                        personalized_columns = personalized_columns.filter(function(a) {return a.visible;});
                        if(personalized_columns.length) {
                            personalized_columns = personalized_columns.map(function(a) {return $.extend({}, context.state.columns_dictionary[a.dataField]) || a;});
                            for(var i = 0; i < personalized_columns.length; i++) {
                                personalized_columns[i].visible = true;
                            }
                        }
                    }
                    if(!personalized_columns || !personalized_columns.length) {
                        personalized_columns = null;
                    }

                    temp_grid_properties.columns = personalized_columns || context.state.columns;
                    temp_grid_properties.onSelectionChanged = function (e) {
                        e.component.exportToExcel(true);
                        context.props.handle_executing(false);
                    };
                    var temp_grid = $("#hidden_export_" + context.props.grid_id).dxDataGrid(temp_grid_properties).dxDataGrid("instance");
                temp_grid.refresh();
                temp_grid.selectAll();//Fires the SelectionChanged event when finished, which forces export.

                context.debugLog('init_grid export_success_func', false);
            };
            };
            var export_error_func = function (error) {
                context.debugLog('init_grid export_error_func', true);

                if (error.responseJSON) {
                    context.props.call_data_list_set_state({error: error.responseJSON.message});
                }
                context.props.handle_executing(false);

                context.debugLog('init_grid export_error_func', false);
            };

            grid_properties.onExporting = function (event) {
                context.debugLog('init_grid onExporting', true);

                event.cancel = true;

                context.props.display_loader();

                var send_data = {
                    conditions: {},
                    last_id: undefined
                };

                var local_send_data = $.extend({}, send_data || {});
                delete local_send_data.last_id;
                delete local_send_data.skip;

                var selected_rows;
                if ( context.getGridViewInstance()._views.headerPanel._exportController._selectionOnly ) {
                    selected_rows = event.component.getSelectedRowKeys().map(
                        function (currentValue) {
                            return currentValue;
                        }
                    );
                }

                var conditions;
                if ( selected_rows && (selected_rows.length > 0) ) {
                    conditions = {"_id": {"$in": selected_rows}};
                }
                if ( !conditions ) {
                    conditions = context.state.export_grid_conditions;
                }

                local_send_data.app_object_code = context.props.app_object_code;
                local_send_data.conditions = conditions;
                local_send_data.sort = context.props.apply_app_object_sorting(local_send_data.sort);

                invoke_method(context.props.entity_attributes.entity, "find_all", local_send_data, export_success_func_maker(event.component), export_error_func);

                context.debugLog('init_grid onExporting', false);
            };

            var success_func_maker = function(e) {
                return function (data) {
                    context.debugLog('init_grid success', true);

                    if ( context.props.entity_attributes && context.props.entity_attributes.entity == 'translation' ) {
                        load_translations(false, function () {
                            context.forceUpdate();
                        });
                    }

                    var grid = context.getGridViewInstance();
                    if ( context.isDeleting || (context.skip_update && context.do_refresh_on_complete) ) {
                        if ( context.do_refresh_on_complete ) {
                            //var entity_row_dictionary = context.do_refresh_on_complete.entity_row_dictionary;
                            //var entity_inst_id_arr = context.do_refresh_on_complete.entity_inst_id_arr;
                            context.do_refresh_on_complete = false;
                            //context.refresh_data_for_rows(entity_row_dictionary, entity_inst_id_arr, grid);
                        }
                        else {
                            grid.refresh();
                        }
                    }
                    else if ( !context.skip_update ) {
                        grid.repaint();
                    }

                    context.debugLog('init_grid success', false);
                };
            };
            var complete = function () {
                context.debugLog('init_grid complete', true);

                if ( context.isDeleting && context.props.grid_is_on_edit_form && context.props.data_list_parent_react_entity ) {
                    context.props.data_list_parent_react_entity.set_confirming(false);
                }

                context.isDeleting = false;
                context.skip_update = false;

                setTimeout(function(){
                    context.props.handle_executing(false);
                }, 0);

                context.debugLog('init_grid complete', false);
            };

            grid_properties.onRowUpdating = function (e) {
                context.debugLog('init_grid onRowUpdating', true);

                var entity = context.props.entity_attributes.entity;
                var method_name = "upsert";
                var from_in_grid_editing = (e.key && !e.key._id);
                var _id = e.key._id || e.key;
                var update_data = e.newData;
                update_data._id = _id;
                var selected_rows = null;
                var do_busy_indicator = false;
                if ( from_in_grid_editing ) {
                    for ( var field_name in e.newData ) {
                        if ( e.newData.hasOwnProperty(field_name) ) {
                            e.oldData[field_name] = e.newData[field_name];
                        }
                    }

                    e.component.cancelEditData();

                    if ( context.force_edit_refresh ) { //overrides default editing behavior (for entity references which behave oddly after editing)
                        context.force_edit_refresh = false;
                        context.skip_update = true;
                    }
                    else {
                        context.skip_update = true;
                        context.do_refresh_on_complete = false; //don't need for only a single row
                    }
                    selected_rows = e.component.getSelectedRowKeys().map(
                        function (currentValue, index, arr) {
                            return currentValue;
                        }
                    );

                    if ( selected_rows && (selected_rows.length > 1) && (selected_rows.indexOf(_id) != -1) ) {
                        //apply this same data change to each row
                        method_name = "upsert_multiple";
                        context.do_refresh_on_complete = {entity_inst_id_arr: selected_rows, entity_row_dictionary: context.props.get_data_list_state_value("last_entity_instances_dictionary")};
                        delete update_data._id;
                        update_data = {upsert_data: update_data};
                        update_data.id_arr = selected_rows;
                        if(selected_rows.length >= 2) {
                            do_busy_indicator = true;
                        }
                    }
                    else {
                        context.do_refresh_on_complete = {entity_inst_id_arr: [_id], entity_row_dictionary: context.props.get_data_list_state_value("last_entity_instances_dictionary")};
                    }
                }

                var error = function (err) {
                    context.debugLog('init_grid onRowUpdating error', true);
                    context.props.call_data_list_set_state({error: err.message});

                    e.component.cancelEditData();

                    if ( context.do_refresh_on_complete ) {
                        context.do_refresh_on_complete = false;
                        context.skip_update = false;
                        e.component.refresh();
                    }

                    context.debugLog('init_grid onRowUpdating error', false);
                };
                var local_success_function = success_func_maker(e);
                var local_complete = function() {
                    context.skip_update = false;
                    context.skip_update_once = true;
                    e.component.refresh(); //set new data, but don't make a true DB call - We have to force run this refresh now to prevent dx from running it, but there is still a bug: //TODO: Fix paging after this refresh skip
                    if(do_busy_indicator) {
                        context.props.handle_executing(false);
                    }
                };
                if(do_busy_indicator) {
                    context.props.handle_executing(true);
                }

                invoke_method(entity, method_name, update_data, (do_busy_indicator)? function(data) {Notify(data.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true); return local_success_function(data);} : local_success_function, error, local_complete);

                if ( selected_rows && context.props.get_data_list_state_value("last_entity_instances_dictionary") ) { //update displayed values on grid for selected rows
                    var newData = update_data.upsert_data;
                    var entity_instances = context.props.get_data_list_state_value("last_entity_instances_dictionary"); //dictionary by _id
                    for ( var i = 0; i < selected_rows.length; i++ ) {
                        var oldData = entity_instances[selected_rows[i]];
                        if ( oldData ) {
                            for ( var field_name in newData ) {
                                if ( newData.hasOwnProperty(field_name) ) {
                                    oldData[field_name] = newData[field_name];
                                }
                            }
                        }
                    }
                    e.component.repaint();
                }

                context.debugLog('init_grid onRowUpdating', false);
                return true;
            };

            grid_properties.onRowInserting = function (e) {
                context.debugLog('init_grid onRowInserting', true);

                var document = e.data;
                var entity = context.props.entity_attributes.entity;
                var error = function (err) {
                    context.props.call_data_list_set_state({ error: err.message });
                    e.component.cancelEditData();
                };
                invoke_method(entity, "upsert", document, success_func_maker(e), error, complete);

                context.debugLog('init_grid onRowInserting', false);
            };

            grid_properties.onRowRemoving = function (e) {
                context.debugLog('init_grid onRowRemoving', true);

                if(context.props.handle_delete) {
                    return;
                }

                var _id = e.data._id;
                var entity = context.props.entity_attributes.entity;
                var error = function (err) {
                    context.props.call_data_list_set_state({error: err.message});
                };
                context.props.display_loader();
                context.isDeleting = true;
                invoke_method(entity, "delete", {_id: _id}, success_func_maker(e), error, complete);

                context.debugLog('init_grid onRowRemoving', false);
            };

            grid_properties.onContentReady = function(e){
                context.debugLog('init_grid onContentReady a', true);

                if ( !custom_properties.disable_create && custom_properties.editable ) {
                    grid_properties.editing.allowAdding = true;
                }
                if ( context.props.disable_create || (context.props.entity_access && context.props.entity_access.indexOf('c') == -1)) {
                    grid_properties.editing.allowAdding = false;
                }
                if ( grid_properties.editing.allowAdding && !custom_properties.edit_in_grid ) {
                    context.insert_add_button(context, e);
                }
                if ( custom_properties.editable && !custom_properties.disable_delete && context.entity_access_contains('d') ) {
                    context.insert_delete_button(context, e);
                }
                if ( custom_properties.assign_to && custom_properties.editable && context.entity_access_contains('u') ) {
                    context.insert_assign_box(context, e);
                }

                context.insert_reset_layout_button(e);

                if ( e.component.columnOption('command:edit', 'visible') ) {
                    e.component.columnOption('command:edit', 'visible', false);
                }
                if(context.props.grid_init_callback) {
                    context.props.grid_init_callback(e.component, context.props.requestAndSetCount);
                }

                context.debugLog('init_grid onContentReady a', false);
            };

            grid_properties.onSelectionChanged = function(e){
                context.state.workflow_button_hide_count = 0;
                return context.run_selection_buttons_display_logic(e);
            };

            grid_properties.height = context.props.update_height;
        }

        for ( var property in custom_properties ) {
            grid_properties[property] = custom_properties[property];
        }

        // Init of the data grid
        $("#" + context.props.grid_id).dxDataGrid(grid_properties);
        context.state.dataGridInstance = $("#" + context.props.grid_id).dxDataGrid('instance');

        context.props.requestAndSetCount();

        context.handle_display_buttons_order();

        context.props.set_data_list_state({ shouldInitGrid: false });

        context.debugLog('init_grid', false);
    },
    onRowPrepared: function (e){
        if ( e.rowType == 'data' && e.data.row_highlight_color ) {
            $(e.rowElement).css('color', e.data.row_highlight_color);
        }
    },
    onCellPrepared: function (e) {
        var column = e.column;
        if ( column.allow_column_edit ) {
            var cellElement = e.cellElement;
            cellElement.addClass('dx-edit'); //Adds a text cursor to indicate the cell is editable
        }
    },
    onEditorPreparing: function (info) {
        if ( info.parentType == 'filterRow' ) {
            if ( info.format == 'shortTime' ) {
                info.editorOptions.format = "time";
            } else if ( info.format == 'shortdateshorttime' ) {
                info.editorOptions.format = "datetime";
            }
        }
    },
    onOptionChanged: function (e) { //move assignbox out of the grid before it searches to prevent devExpress from eliminating it as it redraws itself.
        var context = this;
        context.debugLog('onOptionChanged', true);

        var is_checked_all = $('div',"#" + context.props.grid_id).find('[aria-label="Select all"] [aria-checked]').first().attr('aria-checked') === "true";
        if ( !is_checked_all ) {
            context.state.checked_all = false;  //only used to flag this back to false
        }
        if ( e.fullName == 'searchPanel.text' ) {
            $('#assign-box').prependTo('#' + context.props.grid_id).hide();
        }

        context.debugLog('onOptionChanged', false);
    },
    /**
     * Handles the display order for the data-grid buttons in case the initial order is wrong
     * We should always have the export button on the left of the column chooser button
     */
    handle_display_buttons_order: function() {
        var context = this;
        context.debugLog('handle_display_buttons_order', true);

        var exportButton = $("div#"+context.props.grid_id+" .dx-datagrid-export-button");
        if ( exportButton.length > 0 ) {
            var exportButtonParent = $(exportButton).closest(".dx-toolbar-item");
            var columnChooserButton = $("div#"+context.props.grid_id+" .dx-datagrid-column-chooser-button");
            if ( exportButtonParent.length > 0 && columnChooserButton.length > 0 ) {
                var columnChooserButtonParent = columnChooserButton.closest(".dx-toolbar-item");
                if ( columnChooserButtonParent.length > 0 ) {
                    if ( exportButtonParent.next().find(".dx-datagrid-column-chooser-button").length === 0 ) {
                        columnChooserButtonParent.detach();
                        columnChooserButtonParent.insertAfter(exportButtonParent);
                    }
                }
            }
        }

        context.debugLog('handle_display_buttons_order', false);
    },

    /**
     * Loading the data source for the data grid
     * @returns {*}
     */
    load_dataSource: function() {
        var context = this;
        this.debugLog('load_dataSource', true);

        var send_data = {
            conditions: {},
            last_id: undefined
        };

        var data_source,
            error_func = function (error) {
                context.debugLog('load_dataSource error_func', true);

                if ( error.responseJSON ) {
                    context.props.call_data_list_set_state({ error: error.responseJSON.message });
                } else if ( error.message ) {
                    context.props.call_data_list_set_state({ error: error.message });
                } else if ( error ) {
                    context.props.call_data_list_set_state({ error: error });
                }

                context.debugLog('load_dataSource error_func', false);
            };

        if ( context.props.is_nested ) {
            data_source = fix_response_data(context.state.entity_instances, context.props.entity_attributes.field_paths, context.props.entity_attributes.nested_list_entities);
            context.apply_row_highlighting_to_entity_instances(context.state.entity_instances);

            //Until server starts sending correct flag for these grids.
            context.props.app_object.custom_properties.grid_multi_select = false;
        }
        else {
            data_source = new DevExpress.data.DataSource({
                key: "_id", //this.state.primaryKey,
                isLoading: function(){
                    return this._isLoading;
                },
                load: function (load_options) {
                    context.debugLog('load_dataSource load', true);

                    var grid = context.getGridViewInstance();
                    if ( !grid ) {
                        return;
                    }

                    columnCount = grid.columnCount();
                    var columnCount, i, currentColumnOptions;
                    for ( i = 0; i < columnCount; i++ ) {
                        currentColumnOptions = grid.columnOption(i);
                        if ( currentColumnOptions.selectedFilterOperation === "between" ) {
                            context.skipUpdateDimensions = true;
                        }
                    }

                    if ( context.skip_update || context.skip_update_once ) {
                        context.skip_update_once = false; //used because this happens async with no callback to tell when it is done, and we want to set skip_update to false after this has finished, so we set false before, and use skip_update_once flag.
                        return context.state.last_entity_instances; //use last results if skip_update
                    }
                    context.props.set_data_list_state({ loading: true });
                    this._isLoading = true;

                    var currently_selected_keys = grid.getSelectedRowKeys();
                    var d = new $.Deferred(),
                        conditions,
                        checkedState = !(!($("#" + context.props.grid_id + ' *[aria-label="Select all"]>div').attr('aria-checked') == "true"));

                    var success_func = function (data) {
                        context.debugLog('load_dataSource load success_func', true);

                        var do_reset_inst_array = ((send_data.last_id == null) && !send_data.skip);
                        fix_response_data(data, context.props.entity_attributes.field_paths, context.props.entity_attributes.nested_list_entities);
                        context.apply_row_highlighting_to_entity_instances(data.entity_instances);

                        var first_find = (context.state.was_checked_all == null);
                        context.state.was_checked_all = !(!context.state.checked_all);
                        context.state.checked_all = (first_find) ? false : checkedState;
                        var force_rebuild_selection = false;
                        var skip_set_local_instances = (context.state.checked_all && (context.state.checked_all != context.state.was_checked_all)); //Runs load event when Select All checkbox is clicked to true, but we don't want this to interfere with our results tracking
                        if ( first_find || !skip_set_local_instances ) {
                            if ( do_reset_inst_array || !context.state.last_entity_instances || !context.state.last_entity_instances.length ) {
                                context.state.last_entity_instances = data.entity_instances;
                            }
                            else {
                                context.state.last_entity_instances = add_to_list(context.state.last_entity_instances, data.entity_instances);
                                force_rebuild_selection = true;
                            }
                            context.state.last_entity_instances_dictionary = convert_to_dictionary_by_field_names(context.state.last_entity_instances, {key: "_id"});
                        }

                        d.resolve(data.entity_instances);

                        if ( force_rebuild_selection && currently_selected_keys && currently_selected_keys.length ) {
                            setTimeout(function (a) {
                                grid.selectRows(currently_selected_keys, true);
                            }, 0);
                        }

                        context.debugLog('load_dataSource load success_func', false);
                    };

                    if ( context.state.last_entity_instances
                        && context.state.last_entity_instances.length
                        && context.state.last_entity_instances[context.state.last_entity_instances.length-1] ) {
                        send_data.last_id = context.state.last_entity_instances[context.state.last_entity_instances.length-1]._id;
                    }
                    else {
                        send_data.last_id = undefined;
                    }

                    if ( !load_options.skip || devextreme_sorting_conditions_changed(send_data.last_dx_sort, load_options.sort) ) { //load_options.skip is null or 0 when filter is changed.
                        //reset when conditions change (also: not used in sorted paging)
                        send_data.last_id = undefined;
                        conditions = context.props.get_full_conditions();

                        if ( load_options.filter && Array.isArray(load_options.filter) && !context.props.disallow_grid_filters ) {
                            send_data.conditions = make_conditions_from_devextreme_filter(load_options.filter, ((context.props.entity_attributes && context.props.entity_attributes.field_paths) ? context.props.entity_attributes.field_paths : null)) || {};
                            for ( var field_path in conditions ) { //these filters override custom filters
                                send_data.conditions[field_path] = conditions[field_path];
                            }
                        }
                        else {
                            send_data.conditions = conditions;
                        }

                        if ( load_options.sort && Array.isArray(load_options.sort) ) {
                            send_data.sort = make_sorting_conditions_from_devextreme_sort(load_options.sort);
                            send_data.last_dx_sort = shallow_copy(load_options.sort);
                        }
                        else {
                            send_data.sort = undefined;
                            send_data.last_dx_sort = undefined;
                        }
                    }

                    if ( context.state.export_grid_conditions && Object.keys(context.state.export_grid_conditions).length
                        && context.props.get_data_list_state_value("last_load") !== "data_list"
                        && !context.props.disallow_grid_filters ) {
                        context.set_filters(context.state.export_grid_conditions);

                        if ( Object.keys(send_data.conditions).length == 0 ) {
                            conditions = context.state.export_grid_conditions;
                        }
                        else {
                            conditions = { $and: [context.state.export_grid_conditions, send_data.conditions] };
                            context.state.export_grid_conditions = send_data.conditions;
                            context.props.set_data_list_state({ export_grid_condtions: context.state.export_grid_conditions });
                        }
                    }
                    else {
                        conditions = send_data.conditions;
                        context.state.export_grid_conditions = send_data.conditions;
                        context.props.set_data_list_state({ export_grid_condtions: context.state.export_grid_conditions });
                    }

                    send_data.conditions = conditions;
                    send_data.app_object_code = context.props.app_object_code;
                    send_data.skip = load_options.skip;
                    send_data.sort = context.props.apply_app_object_sorting(send_data.sort);
                    if ( send_data.sort ) {
                        send_data.last_id = undefined; //not used in sorted paging
                    }
                    else {
                        send_data.skip = undefined; //we use sorting by _id for this, with no skip (uses >)
                    }

                    var dxGrid_context = this;
                    var complete_func = function(e){
                        context.debugLog('load_dataSource load complete_func', true);

                        dxGrid_context._isLoading = false;
                        context.props.set_data_list_state({ loading: false, last_load: 'data_list' });

                        context.chck_unck_all_columns(checkedState);

                        var selectedKeys = grid.getSelectedRowKeys(),
                            deselectKeys = [];

                        for ( var x in selectedKeys ) {
                            if ( grid.getRowIndexByKey(selectedKeys[x]) == -1 ) {
                                deselectKeys.push(selectedKeys[x]);
                            }
                        }

                        if ( deselectKeys.length ) {
                            grid.deselectRows(deselectKeys);
                        }

                        if ( load_options.filter ) {
                            var searchPaneldxTextBox = $('.dx-datagrid .dx-datagrid-search-panel').dxTextBox('instance');
                            searchPaneldxTextBox && searchPaneldxTextBox.focus && searchPaneldxTextBox.focus();
                        }

                        if ( !context.skipUpdateDimensions ) {
                            context.props.updateDimensions();
                        }
                        else {
                            context.skipUpdateDimensions = false;
                        }

                        var is_checked_all = !(!($('div',"#" + context.props.grid_id).find('[aria-label="Select all"] [aria-checked]').first().attr('aria-checked') == "true"));
                        if ( !is_checked_all ) {
                            context.state.checked_all = false;  //only used to flag this back to false because of weird behavior in Select All when a grid filter is applied
                        }

                        context.debugLog('load_dataSource load complete_func', false);
                    };

                    if ( context.props.app_object && context.props.app_object.entity_usage ) {
                        send_data.entity_usage = context.props.app_object.entity_usage;
                    }

                    if ( !context.isDeleting ) {
                        invoke_method(context.props.entity_attributes.entity, "find_paged", send_data, success_func, error_func, complete_func);
                    }

                    context.debugLog('load_dataSource load', false);
                    return d.promise();
                },
                remove: function (key) {
                    context.debugLog('load_dataSource remove', true);
                    context.debugLog('load_dataSource remove', false);
                },
                insert: function (values) {
                    context.debugLog('load_dataSource insert', true);
                    context.debugLog('load_dataSource insert', false);
                },
                update: function (key, values) {
                    context.debugLog('load_dataSource update', true);
                }
            });
        }

        this.debugLog('load_dataSource', false);
        return data_source;
    },
    apply_row_highlighting_to_entity_instances: function(entity_instance_arr) {
        var context = this;

        if ( !entity_instance_arr || !entity_instance_arr.length ) {
            return;
        }

        var el;
        for ( var i = 0, u = entity_instance_arr.length; i<u; i++ ) {
            el = entity_instance_arr[i];
            el.row_highlight_color = context.get_row_highlighting(el);
        }
    },
    get_row_highlighting: function(row_info) {
        var context = this;
        context.debugLog('get_row_highlighting', true);

        var retval;
        if ( row_info.workflow_status
            && context.props.app_object
            && context.props.app_object.workflow_states
            && context.props.app_object.workflow_states[row_info.workflow_status]
            && context.props.app_object.workflow_states[row_info.workflow_status].view_options
            && context.props.app_object.workflow_states[row_info.workflow_status].view_options.highlighting ) {
            //use workflow highlighting
            retval = context.apply_row_highlighting(context.props.app_object.workflow_states[row_info.workflow_status].view_options.highlighting, row_info || {});
        }
        else if ( context.props.app_object
            && context.props.app_object.custom_properties
            && context.props.app_object.custom_properties.highlighting ) {
            retval = context.apply_row_highlighting(context.props.app_object.custom_properties.highlighting, row_info || {});
        }

        context.debugLog('get_row_highlighting', false);
        return retval;
    },
    apply_row_highlighting: function(highlighting_obj, entity_inst) {
        var context = this;
        context.debugLog('apply_row_highlighting', true);

        var retval,
            cur_priority = null;
        if ( typeof highlighting_obj === 'object' ) {
            for ( var color in highlighting_obj ) {
                //code to iterate through all conditions children and convert date strings and integers into date objects.
                var conditions = highlighting_obj[color].conditions;
                highlighting_obj[color].conditions = context.highlighting_recursive_fix(conditions, 'conditions', entity_inst);
                if ( matchConditionObj.match(entity_inst, highlighting_obj[color].conditions) ) {
                    if ( (cur_priority == null) || highlighting_obj[color].priority < cur_priority ) {
                        retval = color;
                        cur_priority = highlighting_obj[color].priority;
                    }
                }
            }
        }

        context.debugLog('apply_row_highlighting', false);
        return retval;
    },
    highlighting_recursive_fix: function(obj, objId, entity_inst){
        var context = this;
        context.debugLog('highlighting_recursive_fix', true);

        var t = typeof obj, retval;
        if ( "object" == t ) {
            var keys = Object.keys(obj);
            keys.forEach(function (key){//iterate through conditions object
                if ( !obj[key] ) {//if falsy delete it to prevent bad matches.
                    if ( undefined === entity_inst[key] ) {
                        delete obj[key];
                    }
                }
                else{
                    obj[key] = context.highlighting_recursive_fix(obj[key], key, entity_inst); //fix condition children
                    if ( _.isDate(obj[key]) && entity_inst && entity_inst[objId] && !_.isDate(entity_inst[objId]) ) { //if condition is a date, but the target is not, convert the target.
                        try { entity_inst[objId] = new Date(entity_inst[objId]); } catch(er) {}
                    }
                }
            });
            retval = obj; //after all children are fixed return resulting object.
        }
        else if( "array" == t ) {
            for ( var i = 0; i < obj.length; i++ ) {
                obj[i] = context.highlighting_recursive_fix(obj[i]);
            }
            retval = obj;
        }
        else if ( "string" == t ) {
            try {
                retval = new Date(obj);
                if ( isNaN(retval.getTime()) ) {
                    retval = obj;
                }
            }
            catch (e) {
                retval = obj;
            }
        }
        else {
            retval = obj;
        }

        context.debugLog('highlighting_recursive_fix', false);
        return retval;
    },
    set_filters: function(filter){
        var context = this;
        context.debugLog('set_filters', true);

        var grid = context.getGridViewInstance(),
            columns = context.state.columns,
            key = Object.keys(filter)[0],
            filter_array,
            value;

        if ( '$or' == key ) {
            key = Object.keys(filter['$or'][0])[0];
            var panel = grid.option('searchPanel');
            panel.text = filter['$or'][0][key]['$regex'];
        }
        else {
            filter_array = Array.isArray(filter[key])?filter[key]:[filter];
            for ( var i = 0, u = filter_array.length; i<u; i++ ) {
                key = Object.keys(filter_array[i])[0];
                for ( var j=0,v=columns.length; j<v; j++ ) {
                    if ( columns[j].name == key ) {
                        value = filter_array[i][key]['$regex'] || filter_array[i][key]['$eq'] || filter_array[i][key]['$not']['$regex'] || filter_array[i][key]['$ne'];
                        columns[j].filterValue = filter_array[i][key]['$regex'];
                    }
                }
            }

            grid.option('columns', columns);
        }

        context.debugLog('set_filters', false);
    },
    chck_unck_all_columns:function(check){
        var context = this;
        context.debugLog('chck_unck_all_columns', true);

        if ( !context.checking ) {
            context.checking = true;

            var grid = context.getGridViewInstance();
            if ( grid ) {
                if ( check ) {
                    var indexes = [];
                    for ( var i = 0, u = grid.totalCount(); i < u; i++ ) {
                        indexes.push(i);
                    }
                    grid.selectRowsByIndexes(indexes);
                }
                else {
                    grid.clearSelection();
                }
            }
            context.checking = false;
        }

        context.debugLog('chck_unck_all_columns', false);
    },

    /**
     * Checks if the entity_access contains a specific character
     * @param char
     * @returns {*}
     */
    entity_access_contains: function(char) {
        var context = this;
        return context.props.entity_access && context.props.entity_access.indexOf(char) != -1;
    },

    refresh_data_for_rows: function(entity_row_dictionary, entity_inst_id_arr, gridInstance) {
        var context = this;

        if ( !entity_row_dictionary ) {
            return;
        }

        var request = {
            conditions: {
                _id: {$in: entity_inst_id_arr}
            }
        };

        var success = function (data) {
            context.debugLog('refresh_data_for_rows success', true);

            fix_response_data(data, context.props.entity_attributes.field_paths, context.props.entity_attributes.nested_list_entities);

            if ( data.entity_instances ) {
                data = data.entity_instances;
            }

            if ( data.length > 0 ) {
                for ( var i = 0; i < data.length; i++ ) {
                    var newData = data[i];
                    var oldData = entity_row_dictionary[newData._id];
                    if ( oldData ) { //gut the old row and replace with new data, then refresh.
                        for ( var field_name in oldData ) {
                            if ( !newData.hasOwnProperty(field_name) && (field_name != "_id") ) {
                                delete oldData[field_name];
                            }
                        }
                        for ( var field_name in newData ) {
                            if ( newData.hasOwnProperty(field_name) ) {
                                oldData[field_name] = newData[field_name];
                            }
                        }
                    }
                }
            }

            context.skip_update = false;
            context.do_refresh_on_complete = false;
            context.skip_update_once = true;

            if (gridInstance.refresh) {
                gridInstance.refresh(); //set new data, but don't make a true DB call
            }

            context.debugLog('refresh_data_for_rows success', false);
        };

        var error = function (error) {
            context.debugLog('refresh_data_for_rows error', true);

            context.props.call_data_list_set_state({ error: error.message });
            server_error_action(error);

            context.debugLog('refresh_data_for_rows error', false);
        };

        invoke_method(context.props.app_object.entity, "find_all", request, success, error);
    },

    get_edit_form_data_row: function (edit_form_data_row, entity_instance) {
        var context = this;

        for ( var i = 0; i < context.state.columns.length; i++ ) {
            var dataField = context.state.columns[i].dataField;
            edit_form_data_row[dataField] = entity_instance[dataField];
        }

        return edit_form_data_row;
    },

    /**
     * Update the dimensions of the grid
     */
    updateDimensions: function () {
        var context = this;
        context.debugLog("updateDimensions", true);

        var gridInstance = context.getGridViewInstance();
        if ( gridInstance ) gridInstance.updateDimensions();

        /*if (context.props.is_xenforma_note
            && $('#form-body')[0].scrollHeight > $('#form-body').height()) {
            $('#form-body .dx-editor-cell:last-child .dx-texteditor-buttons-container').css('right', '18px');
        }*/

        context.debugLog("updateDimensions", false);
    },
    updateComponentDimensions: function (isMobile, mobile_columns_are_personalized) {
        var context = this;
        if ( context.resizingComponent ) {
            return undefined;
        }

        var change,
            grid = context.getGridViewInstance();
        if ( grid._options.columns && grid._options.columns.length < 5 && grid._options.columnAutoWidth == true ) {
            grid.option('columnAutoWidth', false);
        }

        context.resizingComponent = true;

        var should_hide = isMobile;
        if ( should_hide != context.props.get_data_list_state_value("columns_hidden_for_mobile_view") ) {
            if ( !mobile_columns_are_personalized ) {
                context.hide_columns(grid, !should_hide);
            }
            grid.columnOption('command:select', 'width', should_hide ? 25 : 35);
            grid.columnOption('hierarchical_parent', 'width', should_hide ? 50 : 300);
            context.props.call_data_list_set_state({ columns_hidden_for_mobile_view: should_hide });
            change = 1; //to force grid repaint
        }

        context.resizingComponent = false;

        return change;
    },
    /**
     * Fix the header columns for the notes grid
     * Removing all the non useful columns for the Notes grid
     */
    fix_notes_columns: function() {
        var context = this;
        context.debugLog('fix_notes_columns', true);

        if (context.props.is_xenforma_note) {
            var ariaLabelNoteColumn = "Column Note";

            var notesHeaderSelector = '.Notescls .dx-datagrid-headers .dx-datagrid-content';
            $(notesHeaderSelector).each(function() {
                // keep only one column in colgroup
                var nbCols = $(this).find('table colgroup col').length;
                $(this).find('table colgroup col').each(function(index) {
                    if (nbCols > 1) {
                        $(this).remove();
                        nbCols -= 1;
                    }
                });
                // keep only the Note column
                $(this).find('table tbody tr td').each(function(index) {
                    var ariaLabel = $(this).attr('aria-label');
                    if (!ariaLabel || ariaLabel.indexOf(ariaLabelNoteColumn) === -1) {
                        $(this).remove();
                    }
                });
            });
        }

        context.debugLog('fix_notes_columns', false);
    },
    /**
     * Hides columns without entity_caption or status for smaller resolution screens
     * @param grid
     * @param value
     */
    hide_columns: function(grid, value) {
        var context = this;
        context.debugLog('hide_columns', true);

        var column,
            dataField,
            attribute,
            attributeArray = context.props.entity_attributes.attributes,
            visible,
            numberofEntityCaption =0;

        for ( var i = 0, l = context.state.columns.length; i<l; i++ ) {
            column = context.state.columns[i];
            dataField = column.dataField;
            if ( dataField ) {
                attribute = get_attribute_by_field_path(attributeArray, dataField);
                if ( attribute ) {
                    visible = value || attribute.entity_caption_flag;
                    if ( attribute.field_path == "workflow_status" ) {
                        column.showInColumnChooser = true;
                    }
                    if ( document.documentElement.clientWidth <= 410 && numberofEntityCaption >= 2 ) {
                        visible = false;
                    }
                    if ( document.documentElement.clientWidth <= 610 && numberofEntityCaption >= 3 ) {
                        visible = false;
                    }
                    if ( attribute.primary_key ) {
                        visible = true;
                    }
                    if ( column.visible != visible ) {
                        column.visible = visible && attribute.list_visible;
                    }
                    if ( visible ) {
                        numberofEntityCaption = numberofEntityCaption +1;
                    }
                }//document.documentElement.clientWidth <= 800
            }
        }
        grid.option('columnAutoWidth', value);
        grid.option('columns', context.state.columns);

        context.debugLog('hide_columns', false);
    },
    /**
     * A counter to keep track of editing rows to turn off workflow buttons via run_selection_buttons_display_logic
     * @param increment
     * @param field_path
     */
    change_workflow_button_count: function(increment, field_path) {
        var context = this;
        context.state.workflow_button_count_dictionary = context.state.workflow_button_count_dictionary || {};
        if(increment) {
            if(!context.state.workflow_button_count_dictionary[field_path]) { //don't allow the same column to be counted twice just because it gets clicked again
                context.state.workflow_button_count_dictionary[field_path] = true;
                if (!context.state.workflow_button_hide_count || (context.state.workflow_button_hide_count <= 0)) {
                    context.state.workflow_button_hide_count = 1; //Acts like a counter
                }
                else {
                    context.state.workflow_button_hide_count++;
                }
            }
        }
        else {
            delete context.state.workflow_button_count_dictionary[field_path];
            if (!context.state.workflow_button_hide_count || (context.state.workflow_button_hide_count <= 1)) {
                context.state.workflow_button_hide_count = 0;
            }
            else {
                context.state.workflow_button_hide_count--;
            }
        }
    },
    /**
     * Handle the buttons display
     * @param e
     */
    run_selection_buttons_display_logic: function(e) {
        var context = this;
        context.debugLog('init_grid run_selection_buttons_display_logic', true);
        var button_container = $('#button-container');
        if(context.state.workflow_button_hide_count) {
            button_container.hide();
            return;
        }

        if ( !e ) {
            e = context.state.last_run_selection_buttons_display_logic_event_args;
        }
        else {
            context.state.last_run_selection_buttons_display_logic_event_args = e;
        }

        if ( !e || !e.selectedRowKeys ) {
            return;
        }

        var grid = context.getGridViewInstance();
        if ( e.selectedRowKeys.length == 0 ) {
            context.chck_unck_all_columns(false);
        }
        else if ( e.selectedRowKeys.length == grid.totalCount() ) {
            context.chck_unck_all_columns(true);
        }

        //show/hide delete button
        if ( context.entity_access_contains('d') ) {
            if ( e.selectedRowKeys.length > 0 ) {
                $('div[data-grid-delete-button="true"]').show();
                if(!context.props.disallow_grid_workflows) {
                    $('div[data-report-print-button="true"]').show();
                    $('#assign-box').show();
                }
            } else {
                $('div[data-grid-delete-button="true"]').hide();
                $('div[data-report-print-button="true"]').hide();
                $('#assign-box').hide();
            }
        }

        //make a list of selected statuses
        var unique_statuses = [];
        function contains(a, obj) {
            context.debugLog('init_grid run_selection_buttons_display_logic contains', true);
            if ( a.length > 0 ) {
                for ( var i = 0; i < a.length; i++ ) {
                    if ( a[i] === obj ) {
                        context.debugLog('init_grid run_selection_buttons_display_logic contains', false);
                        return true;
                    }
                }
            }
            context.debugLog('init_grid run_selection_buttons_display_logic', false);
            return false;
        }

        e.selectedRowsData.forEach(function(row){
            if ( !contains(unique_statuses, row.workflow_status) )
                unique_statuses.push(row.workflow_status);
        });

        var button_count = 0;
        for ( var status in context.props.app_object.workflow_states ) {
            for ( var action in context.props.app_object.workflow_states[status].workflow_actions ) {
                var action_object = context.props.app_object.workflow_states[status].workflow_actions[action];
                action_object.id = action;
                button_container.each(function () {
                    var button_selector = 'div[data-grid-' + status + '-' + action.replace(/\_/g, '-') + '-button="true"][caption="' + action_object.button_caption + '"]';
                    if ( status != unique_statuses[0] || 1 != unique_statuses.length ) {
                        $(button_selector).hide();
                    }
                    else {
                        button_count++;
                        if ( $(this).children(button_selector).length != 0 ) {
                            $(button_selector).show();
                        }
                        else {
                            var tmpDiv = $("<div data-grid-" + status + '-' + action.replace(/\_/g, '-') + "-button='true' class='workflow-button btn btn-primary shiny workflow_button_margin' caption='" + action_object.button_caption + "'/>");
                            tmpDiv.data('action', action_object);
                            tmpDiv.dxButton({
                                //icon: "fa fa-trash", //get icon from server.
                                text: action_object.button_caption,
                                onClick: function (_e) {
                                    //if needs comment, get it.
                                    context.debugLog('init_grid run_selection_buttons_display_logic onClick', true);

                                    var action_object = $(button_selector).data('action');
                                    if ( action_object.comment ) {
                                        if ( !action_object.button_caption ) {
                                            context.setState({ workflowCommentPopup: {action: action_object} });
                                        }
                                        else {
                                            context.setState({
                                                workflowCommentPopup: {
                                                    title: '',
                                                    action: action_object
                                                }
                                            });
                                        }
                                    }
                                    else if ( action_object.signature_required ) {
                                        context.setState({ workflowSignaturePopup: {action: action_object} });
                                        if (action_object.attachment_required){
                                            context.setState({workflowAttachmentPopup: {action : action_object}});
                                        }
                                    }

                                    //send keys for the wf change.
                                    var data = {
                                        id_arr: context.getGridViewInstance().getSelectedRowKeys(),
                                        new_status: action_object.id,
                                        print_option: action_object.print_option
                                    };

                                    context.setState({ workflow_change_data: data });

                                    if ( (!action_object.signature_required || !action_object.attachment_required) && (!action_object.comment || (action_object.comment && context.state.workflow_comment)) ) {
                                        context.handle_workflow_change();
                                    }

                                    context.debugLog('init_grid run_selection_buttons_display_logic onClick', false);
                                }
                            });
                            $(this).append(tmpDiv);
                        }
                    }
                });
            }
        }

        if ( button_count && context.entity_access_contains('u') && !context.props.disallow_grid_workflows ) {
            button_container.show();
        }
        else {
            button_container.hide();
        }

        grid.option('height', context.props.update_height());

        context.debugLog('init_grid run_selection_buttons_display_logic', false);
    },

    /**
     * Initialization of the columns of the grid
     * @returns {Array}
     */
    init_grid_columns: function(){
        var context = this;
        context.debugLog('init_grid_columns', true);

        var attributes = (context.props.entity_attributes && context.props.entity_attributes.attributes) ? context.props.entity_attributes.attributes : [],
            original_attributes =
                context.props.entity_attributes && context.props.entity_attributes.original_attributes
                    ? convert_to_dictionary_by_field_names(context.props.entity_attributes.original_attributes, {key: "field_path"})
                    : [],
            columns = [],
            has_clickable_field = false,
            found_caption_field = false,
            found_pk_field = false,
            first_string_column = undefined,
            first_visible_column = undefined;

        attributes.forEach(function(attribute) {
            if ( attribute.list_visible && !attribute.is_array ) {
                if ( attribute.primary_key && !found_pk_field ) {
                    found_pk_field = attribute;
                }
                if ( !first_visible_column ) {
                    first_visible_column = attribute;
                }
                if ( !first_string_column && (attribute.type == "String") && (attribute.field_path != "workflow_status") ) {
                    first_string_column = attribute;
                }
                if ( attribute.entity_caption_flag ) {
                    if ( !found_caption_field ) {
                        found_caption_field = attribute;
                    }
                }
            }
        });

        if ( found_pk_field ) {
            found_pk_field.display_as_primary_key = true; //Prevents this field from being editable (so that Mobile browsers can click on it to edit the record)
            if ( context.props.entity_attributes && context.props.entity_attributes.field_paths && context.props.entity_attributes.field_paths[found_pk_field.field_path] ) {
                context.props.entity_attributes.field_paths[found_pk_field.field_path].display_as_primary_key = true;
            }
        }
        else if ( first_string_column ) {
            first_string_column.display_as_primary_key = true;
            if ( context.props.entity_attributes && context.props.entity_attributes.field_paths && context.props.entity_attributes.field_paths[first_string_column.field_path] ) {
                context.props.entity_attributes.field_paths[first_string_column.field_path].display_as_primary_key = true;
            }
        }
        else if ( first_visible_column && ((first_visible_column.attribute_type || first_visible_column.db_type) == "user") ) {
            first_visible_column.display_as_primary_key = true;
            if ( context.props.entity_attributes && context.props.entity_attributes.field_paths && context.props.entity_attributes.field_paths[first_visible_column.field_path] ) {
                context.props.entity_attributes.field_paths[first_visible_column.field_path].display_as_primary_key = true;
            }
        }

        if ( !found_caption_field ) {
            if ( first_string_column ) {
                first_string_column.entity_caption_flag = true;
                if ( context.props.entity_attributes && context.props.entity_attributes.field_paths && context.props.entity_attributes.field_paths[first_string_column.field_path] ) {
                    context.props.entity_attributes.field_paths[first_string_column.field_path].entity_caption_flag = true;
                }
            }
            else if ( first_visible_column ) {
                first_visible_column.entity_caption_flag = true;
                if ( context.props.entity_attributes && context.props.entity_attributes.field_paths && context.props.entity_attributes.field_paths[first_visible_column.field_path] ) {
                    context.props.entity_attributes.field_paths[first_visible_column.field_path].entity_caption_flag = true;
                }
            }
        }

        var is_attribute_visible = function(_attribute) {
            if (_attribute.field_path === "workflow_status"
                && context.props.app_object_conditions) {
                return typeof context.props.app_object_conditions.workflow_status === "undefined";
            }

            return _attribute.list_visible;
        };

        for ( var i = 0; i < attributes.length; i++ ) {
            var attribute = attributes[i];
            if ( (attribute.type === "Mixed") || attribute.is_array || (attribute.is_nested_entity && attribute.data_is_nested) ) {
                continue;
            }
            // We first check the "list_visible" property of the "original_attributes" because it can be overridden in the "attributes" list
            if ( (original_attributes[attribute.field_path].list_visible || attribute.list_visible || attribute.form_visible) && !attribute.disallow_in_column_chooser ) { //fields have to be visible somewhere to be filterable
                var properties = {
                    app_object: context.props.app_object,
                    attribute: attribute,
                    is_attribute_visible: is_attribute_visible(attribute),
                    build_datasource: context.build_datasource,
                    disallow_workflow_states_filter: ((context.props.app_object_conditions && context.props.app_object_conditions.workflow_status) && !context.props.get_drilldown_conditions()),
                    data_grid_view_component: context,
                    call_data_list_set_state: context.props.call_data_list_set_state,
                    grid_is_on_edit_form: context.props.grid_is_on_edit_form,
                    launch_edit_form: context.launch_edit_form,
                    popup: context.popup,
                    change_workflow_button_count: context.change_workflow_button_count,
                    run_selection_buttons_display_logic: context.run_selection_buttons_display_logic,
                    scrollViewContentElementId: context.state.scrollViewContentElementId
                };
                var column = create_data_list_column(properties);
                if ( column ) {
                    columns.push(column);
                }
            }
        }

        //make columns fixed
        if ( context.props.entity_attributes && context.props.entity_attributes.field_paths && !context.props.grid_is_on_edit_form ) {
            for ( var i = 0; i < columns.length; i++ ) {
                var column, attribute;
                column = columns[i];
                attribute = context.props.entity_attributes.field_paths[column.dataField];
                if ( column.name == "workflow_status" ) {
                    column.fixed = true;
                    column.fixedPosition = 'right';
                }
                if ( attribute ) {
                    if ( attribute.display_as_primary_key ) {
                        has_clickable_field = true;
                        column.fixed = true;
                        column.fixedPosition = 'left';
                        column.showInColumnChooser = false;
                    }
                    else {
                        if ( attribute.entity_caption_flag && column.visible ) {
                            column.fixed = true;
                            column.fixedPosition = 'left';
                            column.showInColumnChooser = false;
                        }
                    }
                }
            }
        }

        var hide_command_column = has_clickable_field && (this.props.is_mobile && !this.props.is_nested);
        //after all columns are done...
        var command_column = {};
        command_column.width = 75;
        command_column.name = 'controls';
        command_column.allowSorting = false;
        command_column.allowFiltering = false;
        command_column.allowEditing = false;
        command_column.allowHiding = false;
        command_column.showInColumnChooser = false;
        command_column.fixed = true;
        command_column.visible = !hide_command_column;
        command_column.fixedPosition = 'right';
        command_column.cellTemplate = function (container, options) {
            var grid = context.getGridViewInstance();
            if ( context.props.app_object.custom_properties.editable && !context.props.app_object.custom_properties.disable_delete ) {
                if ( !context.props.grid_is_on_edit_form ) {
                    if ( context.entity_access_contains('c') ) {
                        $('<span id="duplicate" class="dx-link fa fa-files-o"></span>')
                            .on('dxclick', function () {
                                //THIS WILL DUPLICATE
                                var app_object = {};
                                app_object.code = context.props.app_object.edit_app_object;
                                app_object.forDuplication = true;
                                if ( context.props.is_ref_lookup ) {
                                    var ref_data = context.props.nested_reference_entity_data;
                                    context.props.data_list_parent_react_entity.setState({
                                        nested_reference_entity_data: ref_data,
                                        grid_is_on_edit_form: true
                                    });
                                }
                                else {
                                    context.props.call_data_list_set_state({
                                        editForm: app_object,
                                        duplicate_id: options.data._id
                                    });
                                }
                            }).appendTo(container);
                    }
                }
                else {
                    if ( !context.props.entity_access || context.entity_access_contains('d') ) {
                        $('<span id="delete" class="dx-link fa fa-trash-o"></span>')
                            .on('dxclick', function(){
                                if(context.props.handle_delete) {
                                    return context.props.handle_delete(grid, [options.data._id], [options.rowIndex], function () {
                                        if (grid) {
                                            grid.refresh();
                                            grid.clearSelection();
                                            if(context.props.requestAndSetCount && context.props.grid_is_on_edit_form && !context.props.is_nested) {
                                                context.props.requestAndSetCount();
                                            }
                                        }
                                    });
                                }
                                if ( context.props.grid_is_on_edit_form ) {
                                    context.props.data_list_parent_react_entity.set_confirming(true);
                                }
                                if ( grid ) {
                                    // TODO debug this line and follow the data grid function called by delete row
                                    grid.deleteRow(options.rowIndex);
                                }
                            }).appendTo(container);
                    }
                }
            }

            if(context.props && context.props.app_object && context.props.app_object.custom_properties && context.props.app_object.custom_properties.edit_in_grid && context.props.data_list_parent_react_entity && context.props.data_list_parent_react_entity.state && context.props.data_list_parent_react_entity.state.is_new_instance) {
                // For some reason the edit button is not working for these

            }
            else {
                $('<span id="edit" class="dx-link fa fa-pencil-square-o" style=margin-left:10px></span>')
                    .on('dxclick', function () {
                        context.launch_edit_form(options.data);
                    }).appendTo(container);
            }
            if ( context.props.get_data_list_state_value("reports") ) {
                $('<span class="dx-link fa fa-print" style=margin-left:10px></span>')
                    .on('dxclick', function (event) {
                        var reports = context.props.get_data_list_state_value("reports");
                        if ( reports.length > 1 ) {
                            context.chooseReport(event.target, options.key);
                        }
                        else {
                            context.printReportRecursive(reports,options.key);
                        }
                    })
                    .appendTo(container);
            }
        };
        columns.push(command_column);
            context.props.call_data_list_set_state({ columns: columns });
            context.state.columns = columns;
        context.state.columns_dictionary = general_utils.convert_to_dictionary_by_field_names(columns, {key: "dataField"});
        context.debugLog('init_grid_columns', false);
    },

    /**
     * Overrides the add button behavior to open the popup that allows the user to add a new instance to the grid
     * @param context
     * @param e
     */
    insert_add_button : function(context, e){
        var context = this;
        context.debugLog('insert_add_button', true);

        var grid = context.getGridViewInstance();
        grid._controllers.editing.addRow = function (_e) { //Hack dxDataGrid to override add button behaviour.
            if ( context.props.is_ref_lookup ) {
                var ref_data = context.props.nested_reference_entity_data;
                context.props.data_list_parent_react_entity.setState({
                    nested_reference_entity_data: ref_data,
                    grid_is_on_edit_form: false
                });
            }
            else {
                var app_object = {};
                app_object.code = context.props.app_object.edit_app_object;
                context.props.call_data_list_set_state({ editForm: app_object });
            }
        };

        context.debugLog('insert_add_button', false);
    },
    /**
     * Inserts the delete button
     * @param context
     * @param e
     */
    insert_delete_button: function(context, e){
        var context = this;
        context.debugLog('insert_delete_button', true);

        var grid = this.getGridViewInstance();
        var success = function(_e){
            context.debugLog('insert_delete_button success', true);
            Notify(_e.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
            context.debugLog('insert_delete_button success', false);
        };
        var complete = function (_e){
            context.debugLog('insert_delete_button complete', true);
            grid.refresh();
            grid.clearSelection();
            if(context.props.requestAndSetCount && context.props.grid_is_on_edit_form && !context.props.is_nested) {
                context.props.requestAndSetCount();
            }
            context.props.handle_executing(false);
            context.debugLog('insert_delete_button complete', false);
        };
        var error = function (err) {
            context.debugLog('insert_delete_button error', true);
            context.props.call_data_list_set_state({ error: err.message });
            context.debugLog('insert_delete_button error', false);
        };

        $('#' + context.props.grid_id + ((context.props.is_mobile) ? ' .dx-datagrid-header-panel' : ' .dx-toolbar-after')).not(".dx-datagrid-column-chooser *").each(function () { //The not clause is to avoid selecting the column chooser toolbar
            if ($(this).children('div[data-grid-delete-button="true"]').length == 0) {
                var tmpDiv = $("<div data-grid-delete-button='true'/>");
                tmpDiv.dxButton({
                    icon: "fa fa-trash",
                    hint: R.delete_row,
                    height: '35px',
                    onClick: function (_e) {
                        context.debugLog('insert_delete_button onClick', true);
                        //list rows
                        var selected_row_keys = grid.getSelectedRowKeys();
                        if ( selected_row_keys.length > 0 ) {
                            if(context.props.handle_delete) {
                                return context.props.handle_delete(grid, selected_row_keys, selected_row_keys.map(function(a) {return grid.getRowIndexByKey(a);}), complete);
                            }
                            context.props.confirm(R.label_delete_title, R.message_delete_entity, function (confirmation) {
                                if ( confirmation ) {
                                    invoke_method(
                                        context.props.app_object.entity,
                                        "delete_multiple",
                                        {id_arr:selected_row_keys},
                                        success, error, complete,
                                        context.props.app_object_code
                                    );
                                }
                            });
                        }
                        context.debugLog('insert_delete_button onClick', false);
                    }
                });
                tmpDiv.addClass("custom_added_button_for_grid_header");
                $(this).prepend(tmpDiv);
                tmpDiv.hide();//initialize hidden.
            }
        });

        context.debugLog('insert_delete_button', false);
    },
    insert_assign_box: function(context, e){
        var context = this;
        context.debugLog('insert_assign_box', true);

        $('#' + context.props.grid_id + ((context.props.is_mobile) ? ' .dx-datagrid-header-panel' : ' .dx-toolbar-after')).not(".dx-datagrid-column-chooser *").each(function () { //The not clause is to avoid selecting the column chooser toolbar
            if ( $(this).children('#assign-box').length == 0 && $('#assign-box').length != 0 ) {
                var tmpDiv = $('#assign-box');
                tmpDiv.addClass("custom_added_button_for_grid_header" + ((context.props.is_mobile) ? ' custom_added_assign_to_button_for_grid_header' : ''));
                $(this).prepend(tmpDiv);
                tmpDiv.hide();//initialize hidden.
            }
        });

        context.debugLog('insert_assign_box', false);
    },
    /**
     * Inserts the reset layout button
     * @param e
     */
    insert_reset_layout_button: function(e){
        var context = this;
        context.debugLog('insert_reset_layout_button', true);

        var success = function(_e){
            Notify(R.preference_saved || "Settings saved", 'bottom-right', notify_timeout, 'green', 'fa-check', true);
        };
        var complete = function (_e){
            context.props.apply_personalized_columns_from_local_storage();
            context.props.call_data_list_set_state({ force_full_refresh: true });
        };
        var error = complete;

        var add_reset_button_using_jquery = function () {
            $('.dx-datagrid-column-chooser > .dx-popup-normal > .dx-visibility-change-handler > .dx-toolbar-items-container > .dx-toolbar-after').css('padding-left', '0');
            $('.dx-datagrid-column-chooser > .dx-popup-normal > .dx-visibility-change-handler > .dx-toolbar-items-container > .dx-toolbar-center').each(function () {
                if ($(this).children('div[data-grid-reset-button="true"]').length == 0) {
                    var tmpDiv = $("<div data-grid-reset-button='true'/>");
                    tmpDiv.dxButton({
                        text: R.reset_layout_caption || "Reset",
                        hint: R.reset_layout_hint || "Resets layout back to default",
                        height: '35px',
                        display: 'inline-block',
                        //padding: '8px',
                        onClick: function (_e) {
                            context.props.confirm(
                                R.reset_layout_confirm_title || "Reset Column Layout?",
                                R.reset_layout_confirm_text || "Are you sure you want to reset the column layouts back to default?",
                                function (confirmation) {
                                    if ( confirmation ) {
                                        var localStoragePersonalizedLayoutCode = context.props.getLocalStoragePersonalizedLayoutCode();
                                        localStorage.removeItem(localStoragePersonalizedLayoutCode);
                                        invoke_method("user", "update_layout", {
                                            app_object_code: context.props.getDbStoragePersonalizedLayoutCode()
                                        }, success, error, complete);
                                    }
                                }
                            );
                        }
                    }).children('.dx-button-content').css("padding", "8px");
                    $(this).prepend(tmpDiv);
                }
            });
        };

        $('.dx-datagrid-column-chooser-button > .dx-button-content').click(function() {
            setTimeout(function() {
                if (!$('.dx-datagrid-column-chooser > .dx-popup-normal > .dx-visibility-change-handler > .dx-toolbar-items-container > .dx-toolbar-center').length) {
                    setTimeout(function() {
                        if (!$('.dx-datagrid-column-chooser > .dx-popup-normal > .dx-visibility-change-handler > .dx-toolbar-items-container > .dx-toolbar-center').length) {
                            setTimeout(function() {
                                add_reset_button_using_jquery();
                            }, 500); //Mobile can take a little longer to get this element onscreen because of the effect used
                        }
                        else {
                            add_reset_button_using_jquery();
                        }
                    }, 100); //Mobile can take a little longer to get this element onscreen because of the effect used
                }
                else {
                    add_reset_button_using_jquery();
                }
            }, 0);
        });

        context.debugLog('insert_reset_layout_button', false);
    },
    /**
     * Inserts the print report button
     * @param reports
     */
    insert_report_button: function(reports){
        var context = this;
        context.debugLog('insert_report_button', true);

        if ( reports ) {
            context.setState({ report_options: reports });
        }

        $('#' + context.props.grid_id + ((context.props.is_mobile) ? ' .dx-datagrid-header-panel' : ' .dx-toolbar-after')).not(".dx-datagrid-column-chooser *").each(function () { //The not clause is to avoid selecting the column chooser toolbar
            if ( $(this).children('div[data-report-print-button="true"]').length == 0 ) {
                var report_print_button_div = $("<div id='report-button' data-report-print-button='true'/>");

                report_print_button_div.dxButton({
                    icon: "fa fa-print",
                    hint: R.report_print_button_hint,
                    height: '35px',
                    onClick: function () {
                        context.debugLog('insert_report_button onClick', true);

                        if ( reports.length > 1 ) {
                            context.chooseReport("report-button");
                        }
                        else {
                            context.printReportRecursive(reports);
                        }

                        context.debugLog('insert_report_button onClick', false);
                    }
                });

                report_print_button_div.addClass("custom_added_button_for_grid_header");
                $(this).prepend(report_print_button_div);
                report_print_button_div.hide();//initialize hidden.
            }
        });

        context.debugLog('insert_report_button', false);
    },

    chooseReport:function(target, key){
        var context = this;
        context.debugLog('chooseReport', true);

        context.print_key = key;
        context.open_popover("choose_report_popover", function(){
            context.debugLog('chooseReport open_popover', true);

            try {
                $('#selectchoose_report_popover').each(function(){
                    var element = $(this);
                    element.multiselect({
                        enableHTML:true,
                        buttonWidth: '100%',
                        buttonTitle: function(options, select) {
                            context.selected_reports = options;
                            var printButton = $('#button_choose_report_popover');
                            if ( options.length > 0 ) {
                                printButton.show();
                            } else {
                                printButton.hide();
                            }
                        }
                    });

                    var button_box = $('<div/>', {style:"padding:5px"}),
                        save_button_var = $('<button/>', {
                            type:'button',
                            style:'margin:5px; float:right; margin-left:5px: margin-top:5px;margin-bottom:10px',
                            class:'btn btn-success shiny workflow_button_margin',
                            id:'button_choose_report_popover',
                            text: R.print ? R.print : "Print",
                            click: context.printSelectedReports
                        }),
                        cancel_button_var = $('<button/>', {
                            type:'button',
                            style:'margin:5px; float:right; margin-left:5px: margin-top:5px;margin-bottom:10px',
                            class:'btn shiny btn-default',
                            id:'button_cancel_assigned_to_user',
                            text: R.label_cancel,
                            click: function() { context.state.popover.hide(); }
                        });

                    cancel_button_var.appendTo(button_box);
                    save_button_var.appendTo(button_box);
                    $(button_box).appendTo($(element).parents()[1]);
                    save_button_var.hide();

                    //Add code to hide dropdown list after mouseleave (after some time)
                    var dropdown_list_elements = $(".multiselect-container.dropdown-menu");
                    var last_mouse_enter = new Date();
                    var dropdown_timeout_in_seconds = 0.5;
                    dropdown_list_elements.on("mouseleave", function() {
                        setTimeout(function(){
                            var current_time = new Date();
                            current_time.setSeconds(current_time.getSeconds() - dropdown_timeout_in_seconds); //subtract some seconds for comparison
                            if(current_time > last_mouse_enter) {
                                dropdown_list_elements.hide();
                            }
                        }, dropdown_timeout_in_seconds * 1000);
                    });
                    dropdown_list_elements.on("mouseenter", function() {
                        last_mouse_enter = new Date();
                    });
                    $(".multiselect.dropdown-toggle.btn.btn-default").on("click", function() {
                        dropdown_list_elements.show();
                    });
                });
            }
            catch(error){}

            context.debugLog('chooseReport open_popover', false);
        }, target);

        context.debugLog('chooseReport', false);
    },
    /**
     * Prints the selected reports
     * @param key
     */
    printSelectedReports: function (key){
        var context = this;
        context.debugLog('printSelectedReports', true);

        if ( context.selected_reports && context.selected_reports.length ) {
            var selected_report_array;
            if(context.state.report_options) {
                var selected_report_code_array = [];
                for(var i = 0; i < context.selected_reports.length; i++) {
                    selected_report_code_array[i] = ((context.selected_reports[i].report_code) ? context.selected_reports[i].report_code : context.selected_reports[i].value);
                }
                selected_report_array = context.state.report_options.filter(function (a) {
                    return (selected_report_code_array.indexOf(a.report_code) != -1);
                });
            }
            context.printReportRecursive(selected_report_array || context.selected_reports, context.print_key);
        }

        context.debugLog('printSelectedReports', false);
    },
    /**
     * Calls the print_report method recursively to print all the selected reports
     * @param reports
     * @param key
     */
    printReportRecursive: function(reports, key){
        var context = this;
        context.debugLog('printReportRecursive', true);

        if ( reports.length ) {
            var grid = context.getGridViewInstance();

            if ( grid ) {
                var selectedRows = grid.getSelectedRowKeys();
                if (key || selectedRows.length) {

                    if(selectedRows.length) {
                        selectedRows = selectedRows.map(
                            function (currentValue, index, arr) {
                                return currentValue;
                            }
                        );
                    }
                    else {
                        selectedRows = [key];
                    }

                    print_reports(window.open('', '', ''), selectedRows, reports, function(err) {
                        if(err) {
                            context.props.call_data_list_set_state({ error: err.message });
                        }
                    });
                }
            }
        }

        context.debugLog('printReportRecursive', false);
    },
    open_popover: function(id_tag, callback, target) {
        var context = this;
        context.debugLog('open_popover', true);

        if ( typeof target == "string" ) {
            target = "#" + target;
        }

        var popover_desc = $("#id"+id_tag).dxPopover({
            target: target,
            position: "top",
            width: 300,
            onContentReady: callback
        }).dxPopover("instance");

        popover_desc.show();

        context.setState({ popover: popover_desc });

        context.debugLog('open_popover', false);
    },

    /**
     * Inserts the export button
     * @param custom_export
     * @param e
     */
    insert_export_button: function(custom_export, e) {
        var context = this;
        context.debugLog('insert_export_button', true);

        $('#' + context.props.grid_id + ' .dx-datagrid-export-button').each(function () {
            if (typeof $(this).data("custom-export") == "undefined") {
                var grid = context.getGridViewInstance();
                $(this).data("custom-export", '');
                grid._controllers.export.selectionOnly(true);
                grid._$element.find('.dx-datagrid').data('options', { //edited dx-viz.min.js to make the _renderExportMenu read this from the datagrid, include the new option in the menu and override the excel export if there is an onClick event defined.
                    icon: "doc",
                    text: custom_export.caption,
                    internalFields: {childrenKeys: []},
                    onClick: function(ev){
                        ev.jQueryEvent.preventDefault();
                        var send_data = {};
                        send_data.format = custom_export.format;
                        send_data.data = grid.getSelectedRowKeys();
                        send_data.gridConditions = context.state.export_grid_conditions;

                        var error = function (err) {
                            context.debugLog('insert_export_button onClick onClick error', true);

                            context.props.call_data_list_set_state({ error: err.message });

                            context.debugLog('insert_export_button onClick onClick error', false);
                        };
                        var success = function (_e) {
                            context.debugLog('insert_export_button onClick onClick success', true);

                            //create file and show as download
                            var blob = new Blob([_e.filecontents], {type: "text/plain"});
                            saveAs(blob, _e.filename);

                            grid.refresh();

                            context.debugLog('insert_export_button onClick onClick success', false);
                        };

                        invoke_method(context.props.entity_attributes.entity, "custom_export", send_data, success, error);
                    }
                });
            }
        });

        context.debugLog('insert_export_button', false);
    },

    /**
     * Opens the edit form
     * @param data_row
     * @returns {*}
     */
    launch_edit_form: function (data_row) {
        var context = this;
        context.debugLog('init_grid launchedit', true);

        var custom_properties = this.props.app_object.custom_properties ? this.props.app_object.custom_properties : {};
        if ( data_row._id ) {
            if ( this.props.is_nested ) {
                var nested_popup_func = nested_entity_row_popup_function.bind(context, context);
                var data_row = data_row;
                if ( data_row._id ) {
                    data_row = $.extend(true, {}, data_row);
                    context.debugLog('init_grid launchedit', false);
                    return nested_popup_func(
                        data_row,
                        undefined,
                        context.props.app_object,
                        context.props.entity_attributes,
                        context.props.nested_list_entities,
                        context.props.parent_entity_array,
                        context.props.parent_entity_field_attribute,
                        context.props.data_list_parent_react_entity
                    );
                }
            }
            else {
                var app_object = {};
                app_object.code = context.props.app_object.edit_app_object;
                app_object.data_row = data_row;
                app_object._id = data_row._id;
                if ( context.props.is_ref_lookup ) {
                    var ref_data = context.props.nested_reference_entity_data;
                    context.props.data_list_parent_react_entity.setState({
                        nested_reference_entity_data: ref_data,
                        reference_entity_id: data_row._id,
                        grid_is_on_edit_form: true
                    });
                }
                else {
                    context.props.call_data_list_set_state({ editForm: app_object }); //setState is called by the eventHandler which
                }
            }
        }

        context.debugLog('init_grid launchedit', false);
    },


    /**
     * Builds the data source for the lookup field concerned
     * @param entity
     * @param field_path
     * @param add_empty
     * @returns {*}
     */
    build_datasource: function (entity, field_path, add_empty) {
        var context = this;
        context.debugLog('build_datasource', true);

        var dataSource = new DevExpress.data.DataSource({
            load: function (loadOptions) {
                context.debugLog('build_datasource load', true);

                var d = new $.Deferred();
                var params = [];
                var request = {};
                request.app_object_code = context.props.app_object_code;
                request.field_path = field_path;
                request.is_new = false;
                request.skip = loadOptions.skip; //A number of records that should be skipped
                request.limit = loadOptions.take; //A number of records that should be taken

                if ( loadOptions.searchValue ) {
                    params.push(loadOptions.searchExpr);
                    params.push(loadOptions.searchOperation);
                    params.push(loadOptions.searchValue?loadOptions.searchValue.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"):'');

                    if ( context.props.disallow_grid_filters ) {
                        request.conditions = context.props.get_full_conditions();
                    }
                    else {
                        request.conditions = make_conditions_from_devextreme_filter(
                            params,
                            ((context.props.entity_attributes && context.props.entity_attributes.field_paths) ? context.props.entity_attributes.field_paths : null)
                        );
                    }
                }

                if ( !request.conditions || !Object.keys(request.conditions).length ) {
                    d.reject(new Error("DataSource requires conditions"))
                }
                else {
                    var success = function (data) {
                        context.debugLog('build_datasource load success', true);

                        if ( data.data.length < 11 && dataSource.items().length == 0  ) {
                            dataSource.paginate(false);
                        }
                        else {
                            dataSource.paginate(true);
                        }

                        fix_response_data(data, context.props.entity_attributes.field_paths, context.props.entity_attributes.nested_list_entities);

                        data = data.data;
                        if ( add_empty && data.length > 0 ) {
                            var empty_record = {};
                            for ( var field in data[0] ) {
                                empty_record[field] = null;
                            }
                            data.unshift(empty_record);
                        }

                        d.resolve(data);

                        context.debugLog('build_datasource load success', false);
                    };
                    var error = function (error) {
                        context.debugLog('build_datasource load error', true);

                        context.props.call_data_list_set_state({ error: error.message });
                        server_error_action(error);

                        context.debugLog('build_datasource load error', false);
                    };

                    invoke_method(entity, "read_with_nested_permissions", request, success, error);
                }

                context.debugLog('build_datasource load', false);
                return d.promise();
            },
            paginate: true,
            byKey: function (key){
                context.debugLog('build_datasource byKey', true);

                var retval;
                if ( key && this.items && (typeof this.items == "function") ) {
                    var items = this.items();
                    retval = find_by_field('entity_id',key, items);
                }

                context.debugLog('build_datasource byKey', false);
                return retval;
            }
        });

        context.debugLog('build_datasource', false);
        return dataSource;
    },
    /**
     * Builds the "Assign to..." field
     * @returns {XML}
     */
    build_assign_box: function(){
        var context = this;
        context.debugLog('build_assign_box', true);

        var grid = context.getGridViewInstance();
        var success = function(e) {
            context.debugLog('build_assign_box success', true);

            Notify(e.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
            if ( grid ) {
                grid.refresh();
                grid.clearSelection();
            }

            context.debugLog('build_assign_box success', false);
        };
        var complete = function(e){
            context.debugLog('build_assign_box complete', true);

            if ( grid ) {
                grid.clearSelection();
            }
            context.props.handle_executing(false);

            context.debugLog('build_assign_box complete', false);
        };
        var error = function(err) {
            context.debugLog('build_assign_box error', true);

            if ( grid ) {
                grid.clearSelection();
                grid.refresh();
            }
            context.props.call_data_list_set_state({ error: err.message });

            context.debugLog('build_assign_box error', false);
        };
        var on_assigned_user_change = function(data) {
            context.debugLog('build_assign_box on_assigned_user_change', true);
            //list rows
            if ( grid ) {
                var selected_row_keys = grid.getSelectedRowKeys();
                if ( selected_row_keys.length > 0 ) {
                    var request = {};
                    request.id_arr = selected_row_keys;
                    request.upsert_data = {};
                    request.upsert_data.assigned_to_user = data ? data.entity_id : null;

                    invoke_method(context.props.app_object.entity, "upsert_multiple", request, success, error, complete);
                }
            }
            context.debugLog('build_assign_box on_assigned_user_change', false);
        };
        if ( !context.users_datasource ) {
            context.users_datasource = context.build_datasource("user", "assigned_to_user", true);
        }

        var assign_box_style = {
            display: "block",
            float: "left"
        };
        if (context.props.is_mobile) {
            assign_box_style.marginLeft = "20px";
        }

        context.debugLog('build_assign_box', false);
        return (
            <div id="assign-box" style={assign_box_style}>
                <EditorDevExtremeLookup
                    dataSource={context.users_datasource}
                    displayExpr="caption"
                    valueExpr="entity_id"
                    value=''
                    placeholder={R.assign_to ? R.assign_to+"..." : "Assign to..."}
                    onChange={on_assigned_user_change}
                    readOnly={false} />
            </div>
        );
    },

    /**
     * Returns the display of the report selection
     * @returns {*}
     */
    getChooseReportTemplate: function() {
        var context = this;

        var reports = context.state.report_options;
        if ( reports ) {
            var options_report = [];
            for ( var j = 0; j < reports.length; j++ ) {
                var lov = reports[j],
                    option_report = <option key={lov.report_code} data-html="true" value={lov.report_code}>
                        {lov.report_caption}
                    </option>;
                options_report.push(option_report);
            }

            return <div style={{"overflow":"inherit!important"}} className="col-xs-12 reportclass" key={"idchoose_report_popover"} id={"idchoose_report_popover"}>
                <div style={{"textAlign":"center","marginBottom":"10px"}}>
                    {R.choose_print_template ? R.choose_print_template : 'Select Reports to Print'}
                </div>
                <div data-html="true" className="reportcls form-group">
                    <select id="selectchoose_report_popover"
                            multiple="multiple"
                            className="form-control"
                            key="selectchoose_report_popover">
                        {options_report}
                    </select>
                </div>
            </div>;
        }

        return undefined;
    },

    /**
     * Returns the popup for the workflow comment
     * @returns {*}
     */
    getWorkflowCommentPopup: function() {
        if ( this.state.workflowCommentPopup ) {
            return (
                <ModalComponent handleHideModal={this.handle_close} ref="modal">
                    <EditFormComment
                        title={this.state.workflowCommentPopup.title}
                        handleSaveClick={this.handleCommentSaveClick}
                        handleCancelClick={this.handleCommentCancelClick}
                        comment={this.state.workflow_comment}
                        style={{padding: '10px'}}/>
                </ModalComponent>
            );
        }
        else if ( this.state.workflowSignaturePopup ) {
            return (
                <ModalComponent handleHideModal={this.handle_close} ref="modal">
                    <EditFormSignature
                        handleSaveClick={this.handleSignatureSaveClick}
                        handleCancelClick={this.handleSignatureCancelClick}
                        hasAttachmentPopUp = {this.state.workflowAttachmentPopup}
                        handleAttachmentSaveClick = {this.state.workflowAttachmentPopup ? this.handleAttachmentSaveClick: undefined}
                        handleAttachmentCancelClick = {this.state.workflowAttachmentPopup ? this.handleAttachmentCancelClick: undefined}
                        />
                        
                </ModalComponent>
            );
        }

        return undefined;
    },
    handleCommentCancelClick: function(){
        var context = this;
        context.debugLog('handleCommentCancelClick', true);

        context.setState({ workflowCommentPopup: undefined });

        context.debugLog('handleCommentCancelClick', false);
    },
    handleCommentSaveClick: function(comment){
        var context = this;
        context.debugLog('handleCommentSaveClick', true);

        context.state.workflow_change_data.workflow_comment = comment;
        if ( !comment || (comment.trim() == "") ) {
            Notify(R.message_enter_workflow_comment, 'bottom-right', notify_timeout, 'red', 'fa-check', true);
        }
        else {
            context.setState({ workflowCommentPopup: undefined });
            context.handle_workflow_change();
        }

        context.debugLog('handleCommentSaveClick', false);
    },
    handleSignatureCancelClick: function(){
        var context = this;
        context.debugLog('handleSignatureCancelClick', true);
        context.setState({ workflowSignaturePopup: undefined });
        var cancel_action = this.state.workflowSignaturePopup.action.cancel_workflow_action;
        if(cancel_action) {
            this.state.workflow_change_data.new_status = cancel_action;
            this.handle_workflow_change();
        }
        context.debugLog('handleSignatureCancelClick', false);
    },
    handleSignatureSaveClick: function(comment){
        var context = this;
        context.debugLog('handleSignatureSaveClick', true);
        context.setState({ workflowSignaturePopup: undefined });
        context.handle_workflow_change();
        context.debugLog('handleSignatureSaveClick', false);
    },
    handleAttachmentSaveClick: function(){
        var context = this;
        context.debugLog('handleAttachmentSaveClick', true);
        context.state.workflow_change_data.new_status = context.state.workflowAttachmentPopup.action.attachment_workflow_action;
        context.setState({workflowAttachmentPopup:undefined, workflowSignaturePopup:undefined });
        context.handle_workflow_change();
        context.forceUpdate();
        context.debugLog('handleAttachmentSaveClick', false);
    },
    handleAttachmentCancelClick: function(comment){
        var context = this;
        context.debugLog('handleAttachmentCancelClick', true);
        context.state.workflow_change_data.new_status = context.state.workflowAttachmentPopup.action.cancel_workflow_action;
        context.setState({workflowAttachmentPopup:undefined,  workflowSignaturePopup:undefined });
        context.handle_workflow_change();
        context.forceUpdate();
        context.debugLog('handleAttachmentCancelClick', false);
    },
    handle_workflow_change : function(){
        var context = this;
        context.debugLog('handle_workflow_change', true);

        if ( context.state.workflow_change_data ) {
            var callback_print_option = context.state.workflow_change_data.print_option;
            delete context.state.workflow_change_data.print_option;
            var win;
            var grid = context.getGridViewInstance();
            var entity_method = 'change_multiple_entity_workflow_states';

            context.props.display_loader();

            if(callback_print_option && callback_print_option.reports && callback_print_option.reports.length) {
                var requires_window_popup = (isChromeNavigator() && callback_print_option.requires_new_window);
                if(requires_window_popup) {
                    win = window.open('', '', '');
                }
            }

            var error = function (data) {
                context.debugLog('handle_workflow_change error', true);
                if ( win ) {
                    win.close();
                }
                context.props.call_data_list_set_state({error: data.message});

                context.debugLog('handle_workflow_change error', false);
            };
            var success = function (data) {
                context.debugLog('handle_workflow_change success', true);
                Notify(data.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                if(callback_print_option && callback_print_option.reports && callback_print_option.reports.length && data && data.print_id_arr && data.print_id_arr.length) {
                    print_reports(win, data.print_id_arr, callback_print_option.reports, function (err) {
                        if (err && context.props.set_data_list_error) {
                            context.props.set_data_list_error(err.responseJSON || err.data || err);
                        }
                    });
                }
                else if ( win ) {
                    win.close();
                }

                if(data.filecontents && data.filename) { //Response contained a file
                    var blob = new Blob([data.filecontents], {type: "text/plain"});
                    saveAs(blob, data.filename);
                }

                context.debugLog('handle_workflow_change success', false);
            };
            var complete = function () {
                context.debugLog('handle_workflow_change complete', true);
                if (context.props.handle_executing) {context.props.handle_executing(false);}
                //reset checked rows
                if (grid) {
                    grid.clearSelection();
                    grid.refresh();
                }

                context.debugLog('handle_workflow_change complete', false);
            };
            invoke_method(context.props.app_object.entity,
                entity_method,
                context.state.workflow_change_data,
                success,
                error,
                complete,
                context.props.app_object_code
            );
        }

        context.debugLog('handle_workflow_change', true);
    },
    getPopoverDiv: function(choose_report_template) {
        return (
            <div id="popover_div" style={{"position": "absolute", "left": "-9999px", "visibility": "hidden"}}>
                {choose_report_template}
            </div>
        )
    },
    getButtonContainer: function() {
        return (
            <div id="button-container"
                 className="col-sm-12 widget-body"
                 style={{
                     textAlign: "right",
                     paddingRight :"5px" ,
                     paddingTop : "5px",
                     backgroundColor : "white",
                     display : "none"
                 }}>
                &nbsp;
            </div>
        );
    },
    getPopupElement: function() {
        var context = this;

        return (
            <div ref={function(ref) { context.popupElement = ref }}>
                <div ref={function(ref) { context.popupContentElement = ref }}>
                    <div id={context.state.scrollViewContentElementId}>&nbsp;</div>
                </div>
            </div>
        );
    },

    /**
     * Returns the grid view instance
     * @returns {*}
     */
    getGridViewInstance: function () {
        var context = this;

        try {
            return context.state.dataGridInstance
                ? context.state.dataGridInstance
                : $('#' + context.props.grid_id).dxDataGrid('instance');
        } catch(e) {
            return undefined;
        }
    },

    render: function() {
        var context = this;
        context.debugLog("render", true);

        var workflow_comment_popup = context.getWorkflowCommentPopup(),
            popover_div = context.getPopoverDiv(context.getChooseReportTemplate()),
            button_container = context.getButtonContainer(),
            popup_element = context.getPopupElement(),
            assign_to_element;
        if ( context.props.app_object
            && context.props.app_object.custom_properties
            && context.props.app_object.custom_properties.assign_to
            && !context.props.get_data_list_state_value("is_nested_data_list")
            && !context.props.disallow_grid_workflows) {
            assign_to_element = this.build_assign_box();
        }

        context.debugLog("render", false);
        return (
            <div>
                {workflow_comment_popup}
                {popover_div}
                <div className="widget-body"
                     style={{ display: context.props.showBodyDatagrid, overflow: 'hidden' }}
                     data-unique-id={context.props.grid_id}>
                    {context.props.description_grid}
                    {context.props.error_component}
                    {assign_to_element}
                    <div id={context.props.grid_id}>
                        &nbsp;
                    </div>
                    {context.props.export_component}
                </div>
                {button_container}
                {popup_element}
            </div>
        );
    }
});
